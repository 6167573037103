<template>
  <base-dialog
    :value="value"
    :title="TextMixin_GetTitle('add', TextMixin_GetParameter(type))"
    @input="$emit('input', $event)"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <base-field-autocomplete
        v-model="selected"
        dense
        item-value="value"
        item-text="text"
        :items="Items"
        :label="TextMixin_GetLabel(type)"
        :rules="[validationMixin.rules.required]"
      />
    </v-form>
    <template
      #actions
    >
      <v-btn
        color="primary"
        @click="Link()"
      >
        {{ TextMixin_GetLabel('add') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import ContentMixin from '@/mixins/content-mixin'
import DialogMixin from '@/mixins/dialog-mixin'
import BaseFieldAutocomplete from '@/components/shared/base/base-field-autocomplete'

export default {
  name: 'DialogLink',
  components: {
    BaseFieldAutocomplete
  },
  mixins: [
    ContentMixin,
    DialogMixin
  ],
  props: {
    // Controller it should use
    controller: String,
    // Currently linked items, for filtering purposes
    currentItems: {
      default: () => [],
      type: Array
    },
    // Type of element to link
    type: String
  },
  data () {
    return {
      items: [],
      selected: null,
      valid: false
    }
  },
  computed: {
    Items () {
      return this.items ? this.items.filter(item => !this.currentItems.includes(item.value)) : []
    }
  },
  watch: {
    value () {
      this.Initialize()
    }
  },
  methods: {
    async Initialize () {
      this.selected = null
      if (this.value && this.items.length <= 0) {
        await this.ContentMixin_Service_BC_InitializeContentService(this.controller)
        this.items = await this.ContentMixin_Service_BC_PickSource()
      }
    },
    Link () {
      this.$refs.form.validate()

      if (this.valid) {
        // Selected item should be linked
        // @arg selected item
        this.$emit('link', this.selected)
      }
    }
  }
}
</script>
