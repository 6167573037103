<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row
      v-if="EntityCardMixin_ShownOptions(EntityCardMixin_CopySource.functionalityGroups, 'isLinkedToUserGroup')"
    >
      <v-col
        cols="12"
        lg="12"
        md="12"
      >
        <v-checkbox
          v-for="(functionalityGroup, index) in EntityCardMixin_ShownOptions(EntityCardMixin_CopySource.functionalityGroups, 'isLinkedToUserGroup')"
          :key="index"
          class="my-0"
          :input-value="functionalityGroup.isLinkedToUserGroup.value"
          :disabled="EntityCardMixin_InputFieldIsDisabled(functionalityGroup.isLinkedToUserGroup)"
          :indeterminate="functionalityGroup.isLinkedToUserGroup.value == null"
          :label="functionalityGroup.description.value"
          :readonly="EntityCardMixin_InputFieldIsReadonly(functionalityGroup.isLinkedToUserGroup)"
          @change="ContentMixin_UpdateElementWithPocoStatus(functionalityGroup, 'isLinkedToUserGroup', $event)"
        />
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardUserManagementRolesDetailProfiles',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('profiles')
    }
  }
}
</script>
