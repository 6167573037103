<template>
  <base-field-container
    v-if="value"
    v-model="childMixin.element"
    :base-field-container-class="baseFieldContainerClass"
    class="base-field"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <template
      #[`${baseFieldContainerType}`]="{ property }"
    >
      <slot
        name="prepend"
      />
      <component
        :is="Components[baseFieldType]"
        v-model="property.value"
        :auto-grow="autoGrow"
        :autocomplete="autocomplete"
        :base-field-class="baseFieldClass"
        :color="color"
        :chips="chips"
        :clearable="clearable"
        :decimals="decimals"
        :dense="dense"
        :disabled="BaseFieldIsDisabled(property)"
        :file-types="fileTypes"
        :format="format"
        :icon="icon"
        :item-value="itemValue"
        :item-text="itemText"
        :items="Items"
        :label="label"
        :label-time="labelTime"
        :outlined="outlined"
        :prefix="prefix"
        :readonly="BaseFieldIsReadonly(property)"
        :rows="rows"
        :rules="Rules"
        :show-navigation="showNavigation"
        :suffix="suffix"
        @change="BaseFieldEventChange($event)"
        @clear="ChildMixin_Emit('clear', $event)"
        @data="ChildMixin_Emit('data', $event)"
        @download="ChildMixin_Emit('download')"
        @keyup="BaseFieldEventKeyUp($event)"
        @name="ChildMixin_Emit('name', $event)"
      >
        <template
          #inner-append
        >
          <slot
            name="inner-append"
          />
        </template>
      </component>
      <slot
        name="append"
      />
    </template>
  </base-field-container>
</template>

<script>
import BaseFieldAutocomplete from '@/components/shared/base/base-field-autocomplete'
import BaseFieldCheckbox from '@/components/shared/base/base-field-checkbox'
import BaseFieldContainer from '@/components/shared/base/base-field-container'
import BaseFieldDate from '@/components/shared/base/base-field-date'
import BaseFieldDateTime from '@/components/shared/base/base-field-date-time'
import BaseFieldFileInput from '@/components/shared/base/base-field-file-input'
import BaseFieldNumber from '@/components/shared/base/base-field-number'
import BaseFieldText from '@/components/shared/base/base-field-text'
import BaseFieldTextarea from '@/components/shared/base/base-field-textarea'
import BaseFieldTime from '@/components/shared/base/base-field-time'
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseField',
  components: {
    BaseFieldAutocomplete,
    BaseFieldCheckbox,
    BaseFieldContainer,
    BaseFieldDate,
    BaseFieldDateTime,
    BaseFieldFileInput,
    BaseFieldNumber,
    BaseFieldText,
    BaseFieldTextarea,
    BaseFieldTime
  },
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // Value for BaseField
    value: null,
    // Class for the BaseFieldContainer
    baseFieldContainerClass: String,
    // Type of BaseFieldContainer, only accepts div and v-col
    baseFieldContainerType: {
      default: 'div',
      type: String
    },
    // Type of BaseField
    baseFieldType: {
      default: 'text',
      type: String
    },
    // Display files as chips if BaseField is of type fileInput
    chips: Boolean,
    // BaseField is of type autocomplete and subType code
    code: Boolean,
    // Width when on small screen if container is 'v-col'
    cols: [
      Number,
      String
    ],
    // Ignore default eventHandler
    ignoreDefaultEventHandler: {
      default: false,
      type: Boolean
    },
    // Width when on large screen if container is 'v-col'
    lg: [
      Number,
      String
    ],
    // v-mask
    mask: String,
    // Width when on medium screen if container is 'v-col'
    md: [
      Number,
      String
    ]
  },
  computed: {
    Components () {
      return {
        autocomplete: 'base-field-autocomplete',
        checkbox: 'base-field-checkbox',
        date: 'base-field-date',
        dateTime: 'base-field-date-time',
        fileInput: 'base-field-file-input',
        number: 'base-field-number',
        text: 'base-field-text',
        textarea: 'base-field-textarea',
        time: 'base-field-time'
      }
    },
    Items () {
      return this.code ? this.ContentMixin_Codes(this.value) : this.items
    },
    Rules () {
      const rules = []

      if (!(this.BaseFieldIsDisabled(this.childMixin.element) || this.BaseFieldIsReadonly(this.childMixin.element))) {
        const validation = this.childMixin.element?.meta?.validation

        this.rules.forEach(rule => {
          rules.push(rule)
        })

        if (validation) {
          if (validation.maxLength) {
            rules.push(this.validationMixin.rules.charactersMax(this.childMixin.element.value, validation.maxLength))
          }

          if (validation.required) {
            rules.push(this.validationMixin.rules.required)
          }
        }
      }

      return rules
    }
  },
  watch: {
    items () {
      this.SetContentMixinCodes()
    }
  },
  mounted () {
    this.SetContentMixinCodes()
  },
  methods: {
    AutocompleteEventChange (value) {
      if (!this.ignoreDefaultEventHandler) {
        if (this.code) {
          this.ContentMixin_HandleSelectCodeChange(this.childMixin.element, value)
        } else {
          this.ContentMixin_HandleSelectChange(this.childMixin.element, this.items, value)
        }
      }

      this.ChildMixin_Emit('change', value)
    },
    BaseFieldEventChange (event) {
      if (this.baseFieldType === 'autocomplete') {
        this.AutocompleteEventChange(event)
      } else {
        this.ChildMixin_Emit('change', event)
      }
    },
    BaseFieldEventKeyUp (event) {
      this.ChildMixin_Emit('keyup', {
        event: event,
        key: event.key,
        value: this.childMixin.element.value
      })
    },
    BaseFieldIsDisabled (baseField) {
      return this.disabled || this.AuthenticationMixin_InputFieldIsDisabled(baseField)
    },
    BaseFieldIsReadonly (baseField) {
      return this.readonly || this.AuthenticationMixin_InputFieldIsReadonly(baseField)
    },
    BaseFieldIsType (type) {
      return this.baseFieldType === type
    },
    SetContentMixinCodes () {
      if (this.code) {
        this.contentMixin.codes = this.items
      }
    }
  }
}
</script>

<style>
.base-field .v-btn {
  height: 20px !important;
  width: 20px !important;
}

.base-field .hide-icons .v-icon {
  visibility: hidden;
}

 .base-field:hover .hide-icons .v-btn .v-icon {
  visibility: visible !important;
}
</style>
