<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row
      v-if="!readonly"
      class="pt-0 row-btns"
    >
      <v-col
        v-for="(requestStatus, index) in RequestStatusList"
        :key="index"
        class="mt-3"
        cols="12"
        lg="4"
        md="6"
      >
        <v-btn
          block
          :color="RequestStatusColor(requestStatus)"
          :outlined="!RequestStatusSelected(requestStatus)"
          small
          @click="RequestStatusChange(requestStatus)"
        >
          {{ requestStatus.text }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="readonly || HasRequestStatus('approved') || HasRequestStatus('moreInformationRequired') || HasRequestStatus('resubmitted') || HasRequestStatus('rejected')"
      :class="RowFieldsClass"
    >
      <base-field
        v-if="EntityCardMixin_CopySource.requestStatus && readonly"
        v-model="EntityCardMixin_CopySource.requestStatus"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        :disabled="EntityCardMixin_Disabled"
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('status')"
        :readonly="readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.reasonApproved && HasRequestStatus('approved')"
        v-model="EntityCardMixin_CopySource.reasonApproved"
        base-field-container-type="v-col"
        base-field-type="textarea"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('reason')"
        lg="12"
        md="12"
        :readonly="readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.reasonMoreInfo && (HasRequestStatus('moreInformationRequired') || HasRequestStatus('resubmitted'))"
        v-model="EntityCardMixin_CopySource.reasonMoreInfo"
        base-field-container-type="v-col"
        base-field-type="textarea"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('reason')"
        lg="12"
        md="12"
        :readonly="readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.reasonRejected && HasRequestStatus('rejected')"
        v-model="EntityCardMixin_CopySource.reasonRejected"
        base-field-container-type="v-col"
        base-field-type="textarea"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('reason')"
        lg="12"
        md="12"
        :readonly="readonly"
      />
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardRequestsDetailProcessRequestFeedback',
  mixins: [
    EntityCardMixin
  ],
  computed: {
    RequestStatusList () {
      return this.contentMixin.codes.filter(x => (this.readonly && x.value === this.EntityCardMixin_CopySource.requestStatus.value) || (!this.readonly && x.type === this.EntityCardMixin_CopySource.requestStatus.type)).sort((a, b) => this.RequestStatusOrder(a) - this.RequestStatusOrder(b))
    },
    RowFieldsClass () {
      return this.readonly ? '' : 'pt-9'
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    HasRequestStatus (value) {
      return this.EntityCardMixin_CopySource.requestStatus.value && this.EntityCardMixin_CopySource.requestStatus.value.toUpperCase() === this.constantMixin.codes.requestStatus.values[value].toUpperCase()
    },
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('feedback')
    },
    RequestStatusChange (item) {
      this.EntityCardMixin_CopySource.requestStatus.shortDescription = this.CommonMixin_Clone(item?.shortDescription ?? '')
      this.EntityCardMixin_CopySource.requestStatus.text = this.CommonMixin_Clone(item?.text ?? '')
      this.EntityCardMixin_CopySource.requestStatus.value = this.CommonMixin_Clone(item?.value)
    },
    RequestStatusColor (item) {
      return this.constantMixin.settings.requestStatus[this.RequestStatusDescription(item)].color
    },
    RequestStatusDescription (item) {
      const values = this.constantMixin.codes.requestStatus.values
      return Object.keys(values).find(key => values[key].toUpperCase() === item?.value.toUpperCase())
    },
    RequestStatusOrder (item) {
      return this.constantMixin.settings.requestStatus[this.RequestStatusDescription(item)].order
    },
    RequestStatusSelected (item) {
      return this.EntityCardMixin_CopySource.requestStatus.value === item.value
    }
  }
}
</script>

<style scoped>
.request-status-inactive {
  opacity: 0.25;
}

.row-btns {
  margin-top: -24px;
}
</style>
