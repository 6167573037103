<template>
  <div
    class="base-field-autocomplete"
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-autocomplete
      v-model="childMixin.element"
      :class="Class"
      :clearable="Clearable"
      :color="color"
      :dense="dense"
      :disabled="disabled"
      :item-value="itemValue"
      :item-text="itemText"
      :items="items"
      :label="label"
      :readonly="readonly"
      :return-object="returnObject"
      :reverse="Reverse"
      :rules="rules"
      @blur="Blur()"
      @change="EmitChanges()"
      @focus="Focus()"
    >
      <template
        #append
      >
        <slot
          name="inner-append"
        />
        <v-icon>
          $dropdown
        </v-icon>
      </template>
    </v-autocomplete>
  </div>
  <!-- TODO :search-input.sync="searchText" -->
</template>

<script>
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseFieldAutocomplete',
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // Selected value
    value: [
      Number,
      Object,
      String
    ]
  },
  data () {
    return {
      focus: false,
      hovering: false,
      searchText: null
    }
  },
  computed: {
    Class () {
      return [
        this.baseFieldClass,
        `${this.ShowControls ? 'show' : 'hide'}-icons`
      ]
    },
    Clearable () {
      return this.clearable && this.ShowControls
    },
    Reverse () {
      return this.reverse ?? false
    },
    ShowControls () {
      return !this.readonly && (this.hovering || this.focus)
    }
  },
  watch: {
    'childMixin.element' () {
      this.Emit()
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    searchText () {
      // TODO
    },
    value () {
      this.FormatData()
    }
  },
  mounted () {
    this.FormatData()
  },
  methods: {
    Blur () {
      this.focus = false
    },
    Emit () {
      this.$emit('input', this.childMixin.element)
    },
    EmitChanges () {
      this.$emit('change', this.childMixin.element)
    },
    Focus () {
      this.focus = true
    },
    FormatData () {
      this.childMixin.element = this.value
    },
    MouseLeave () {
      this.hovering = false
    },
    MouseOver () {
      this.hovering = true
    }
  }
}
</script>
