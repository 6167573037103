import Axios from 'axios'

let _svc

export default {
  List (idLanguage, codeTypes, idOptionLevel) {
    try {
      let query = `?idLanguage=${idLanguage}`
      let index = 0

      if (idOptionLevel) {
        query += `&idOptionLevel=${idOptionLevel}`
      }

      codeTypes.forEach(codeType => {
        query += `&codeTypes[${index}]=${codeType}`
        index++
      })

      return _svc.get(`Code/List/${query}`)
    } catch {
      return null
    }
  },
  Init (errorHandler) {
    _svc = Axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        errorHandler(error)
      }
    )
  }
}
