<template>
  <user-management-users />
</template>

<script>
import UserManagementUsers from '@/views/usermanagement/users/components/user-management-users'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'UserManagementUsersView',
  components: {
    UserManagementUsers
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
