import BaseCrudTable from '@/components/shared/base/base-crud-table'
import ContentMixin from '@/mixins/content-mixin'

export default {
  components: {
    BaseCrudTable
  },
  mixins: [
    ContentMixin
  ],
  props: {
    // Show add action button?
    addAction: {
      default: true,
      type: Boolean
    },
    // Label for add-button
    addLabel: {
      default: 'add',
      type: String
    },
    // Class for the container
    containerClass: String,
    // Controller it should use
    controller: String,
    // Custom action for update-command
    customCreate: Boolean,
    // Custom action for create-command
    customUpdate: Boolean,
    // Show delete action button?
    deleteAction: {
      default: true,
      type: Boolean
    },
    // Edit when doubleclicked row
    doubleClickEdit: {
      default: true,
      type: Boolean
    },
    // Show edit action button?
    editAction: {
      default: true,
      type: Boolean
    },
    // Hidden columns
    hiddenColumns: {
      default: () => [],
      type: Array
    },
    // Hide actions
    hideActions: Boolean,
    // Hide filter input
    hideFilter: {
      default: false,
      type: Boolean
    },
    // Hide footer
    hideFooter: Boolean,
    // Hide pagination
    hidePagination: Boolean,
    // readonly
    readonly: {
      default: false,
      type: Boolean
    },
    // Selected rows
    selection: {
      default: () => [],
      type: Array
    },
    // Make items selectable
    showSelect: Boolean,
    // Be able to select only one row
    singleSelect: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      baseListMixin: {
        detailPage: '',
        edit: false,
        filter: '',
        headers: [],
        key: 'id',
        list: [],
        listFiltered: [],
        optionsCurrent: null,
        optionsDefault: {
          itemsPerPage: 10,
          page: 1,
          sortBy: [],
          sortDesc: []
        },
        paging: {
          hasNextPage: false,
          hasPreviousPage: false,
          itemsOnPage: 0,
          pageIndex: 0,
          pageNumber: 1,
          pageSize: 50,
          totalItems: 0,
          totalPages: 1
        },
        refresh: false,
        rights: {
          allowCreate: false,
          allowDelete: false,
          allowRead: false,
          allowUpdate: false
        },
        searchText: null,
        selected: null,
        selection: []
      }
    }
  },
  watch: {
    async 'baseListMixin.selection' (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        const selection = this.selection.filter(x => newValue.includes(x.id))
        newValue.filter(x => !selection.map(x => x.id).includes(x.id)).forEach(element => {
          selection.push(element)
        })

        // Fire when row selection changes
        // @arg selected
        this.$emit('selection', selection)
      }
    },
    async ContentMixin_ElementId (value) {
      if (value && this.contentMixin.controller) {
        await this.BaseListMixin_Refresh()
      }
    },
    async FrameworkMixin_Language () {
      await this.BaseListMixin_Refresh()
    },
    async visible (value) {
      if (value && !this.baseListMixin.edit) {
        await this.BaseListMixin_Refresh()
      }
    }
  },
  methods: {
    BaseListMixin_Filter (value) {
      this.baseListMixin.searchText = value
      this.BaseListMixin_Refresh()
    },
    BaseListMixin_GetAddLabel (readonly, hideFooter, rights) {
      if (!readonly && !hideFooter && rights.allowCreate) {
        let parameter = this.controller.includes('/') ? /[^/]*$/.exec(this.controller)[0] : this.controller
        parameter = `${parameter.charAt(0).toLowerCase()}${parameter.slice(1)}`
        return this.TextMixin_GetLabel(this.addLabel, this.TextMixin_GetParameter(parameter))
      } else {
        return null
      }
    },
    BaseListMixin_Headers () {
      const headers = []

      if (this.baseListMixin.headers) {
        this.baseListMixin.headers.forEach(header => {
          if (!this.hiddenColumns.includes(header.columnKey)) {
            headers.push({
              align: header.dataType === this.constantMixin.dataType.decimal || header.dataType === this.constantMixin.dataType.int ? 'end' : header.dataType === this.constantMixin.dataType.chip ? 'center' : 'start',
              dataType: header.dataType,
              sortable: !!header.sortKey,
              text: this.TextMixin_GetHeader(header.columnKey),
              value: `${header.columnKey}.value`
            })
          }
        })
      }

      return headers
    },
    BaseListMixin_GetCurrentOptions () {
      return this.baseListMixin.optionsCurrent ?? this.baseListMixin.optionsDefault
    },
    async BaseListMixin_Refresh () {
      this.baseListMixin.refresh = true
    },
    BaseListMixin_Select (item) {
      if (this.BaseListMixin_Selected(item)) {
        this.baseListMixin.selected = null
      } else {
        this.baseListMixin.selected = item
      }
    },
    BaseListMixin_Selected (item) {
      return this.baseListMixin.selected != null && this.baseListMixin.selected === item
    },
    async BaseListMixin_Setup (listApiResponse) {
      if (listApiResponse && listApiResponse.data) {
        const items = listApiResponse.data.items
        const meta = listApiResponse.data.meta

        this.baseListMixin.headers = meta?.header?.headers
        this.contentMixin.key = meta?.key?.key
        this.baseListMixin.list = items
        this.baseListMixin.paging = meta?.paging
        this.baseListMixin.rights = meta?.right
      }
    }
  }
}
