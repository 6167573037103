import { defineStore } from 'pinia'

export default defineStore({
  id: 'content',
  state () {
    return {
      detail: {
        dialog: {
          autosavedVersion: null,
          copy: null,
          editable: true,
          element: null,
          parameters: null
        },
        tab: {
          autosavedVersion: null,
          copy: null,
          editable: true,
          element: null,
          parameters: null
        }
      },
      schedulerDate: null,
      schedulerView: null,
      selectedStep: 1,
      selectedTab: 0
    }
  }
})
