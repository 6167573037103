<template>
  <user-management-profiles />
</template>

<script>
import UserManagementProfiles from '@/views/usermanagement/profiles/components/user-management-profiles'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'UserManagementProfilesView',
  components: {
    UserManagementProfiles
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
