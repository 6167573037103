<template>
  <div>
    <entity-card-care-partners-detail-general-information
      v-model="ContentMixin_Element.carePartnerDetail"
      :carePartners="lists.carePartners"
      :codes="lists.codes.carePartner"
      :pickers="[Pickers.carePartner]"
      readonly
      :source="source"
      :title="TextMixin_GetTitle('generalInformation', `(${TextMixin_GetParameter('carePartner').toLowerCase()})`)"
      @pickers-change="EntityCardMixin_PickersChange($event)"
      @pickers-read="EntityCardMixin_PickersRead($event)"
    />
    <entity-card-care-partners-detail-contact-details
      v-if="ContentMixin_Element.carePartner?.value"
      v-model="ContentMixin_Element.carePartnerDetail"
      readonly
      :source="source"
      :title="TextMixin_GetTitle('contactDetails', `(${TextMixin_GetParameter('carePartner').toLowerCase()})`)"
    />
    <entity-card-families-detail-general-information
      v-model="ContentMixin_Element.familyDetail"
      :codes="lists.codes.family"
      :families="lists.families"
      :pickers="[Pickers.family]"
      :posts="posts"
      readonly
      :source="source"
      :title="TextMixin_GetTitle('generalInformation', `(${TextMixin_GetParameter('family').toLowerCase()})`)"
      @pickers-change="FamilySelected($event)"
      @pickers-create="EntityCardMixin_PickersCreate($event)"
      @pickers-read="EntityCardMixin_PickersRead($event)"
      @pickers-update="EntityCardMixin_PickersUpdate($event)"
    />
    <entity-card-families-detail-legal-family-representatives
      v-if="ContentMixin_Element.family?.value"
      v-model="ContentMixin_Element.familyDetail"
      readonly
      :source="source"
    />
    <entity-card-families-detail-situation
      v-if="ContentMixin_Element.family?.value"
      v-model="ContentMixin_Element.familyDetail"
      readonly
      :source="source"
    />
    <entity-card-families-detail-financial-situation-list
      v-if="ContentMixin_Element.family?.value"
      v-model="ContentMixin_Element.familyDetail"
      :name="$options.name"
      readonly
      :source="source"
    />
    <entity-card-families-detail-financial-situation
      v-if="ContentMixin_Element.family?.value"
      v-model="ContentMixin_Element.familyDetail"
      :source="source"
      readonly
    />
    <entity-card-requests-detail-submit-request-intervention-children
      v-if="ContentMixin_Element.family?.value"
      :double-click-edit="false"
      :readonly="readonly"
      :source="source"
      :visible="visible && showChildren"
      @create="EntityCardMixin_PickersCreate(Pickers.child)"
      @update="EntityCardMixin_PickersUpdate(Pickers.child, $event)"
    />
    <dialog-detail
      v-if="entityCardMixin.dialogDetail.show"
      v-model="entityCardMixin.dialogDetail.show"
      :content="entityCardMixin.dialogDetail"
      :parameters="entityCardMixin.dialogDetail.parameters"
      @close="EntityCardMixin_PickersClose()"
      @save="DialogDetailSave()"
    />
  </div>
</template>

<script>
import DialogDetail from '@/components/shared/dialogs/dialog-detail'
import EntityCardCarePartnersDetailContactDetails from '@/components/shared/entitycards/entity-card-care-partners-detail-contact-details'
import EntityCardCarePartnersDetailGeneralInformation from '@/components/shared/entitycards/entity-card-care-partners-detail-general-information'
import EntityCardFamiliesDetailFinancialSituation from '@/components/shared/entitycards/entity-card-families-detail-financial-situation'
import EntityCardFamiliesDetailFinancialSituationList from '@/components/shared/entitycards/entity-card-families-detail-financial-situation-list'
import EntityCardFamiliesDetailGeneralInformation from '@/components/shared/entitycards/entity-card-families-detail-general-information'
import EntityCardFamiliesDetailLegalFamilyRepresentatives from '@/components/shared/entitycards/entity-card-families-detail-legal-family-representatives'
import EntityCardFamiliesDetailSituation from '@/components/shared/entitycards/entity-card-families-detail-situation'
import EntityCardRequestsDetailSubmitRequestInterventionChildren from '@/components/shared/entitycards/entity-card-requests-detail-submit-request-intervention-children'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'RequestsDetailSubmitRequestIntervention',
  components: {
    DialogDetail,
    EntityCardCarePartnersDetailContactDetails,
    EntityCardCarePartnersDetailGeneralInformation,
    EntityCardFamiliesDetailFinancialSituation,
    EntityCardFamiliesDetailFinancialSituationList,
    EntityCardFamiliesDetailGeneralInformation,
    EntityCardFamiliesDetailLegalFamilyRepresentatives,
    EntityCardFamiliesDetailSituation,
    EntityCardRequestsDetailSubmitRequestInterventionChildren
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Request',
      lists: {
        carePartners: [],
        codes: {
          carePartner: [],
          family: []
        },
        families: []
      },
      showChildren: true
    }
  },
  computed: {
    Pickers () {
      return {
        carePartner: this.EntityCardMixin_Pickers(false, 'CarePartner', 'carePartner', 'carePartners', 'care-partners-detail', this.PickersReadonly.carePartner, null, null, null, true, this.PickersRules.carePartner),
        child: this.EntityCardMixin_Pickers(false, 'Child', 'child', 'children', 'children-detail', this.PickersReadonly.child, null, null, null, true, this.PickersRules.child, this.PickersParameters().child),
        family: this.EntityCardMixin_Pickers(false, 'Family', 'family', 'families', 'families-detail', this.PickersReadonly.family, null, !this.PickersReadonly.family, !this.PickersReadonly.family, true, this.PickersRules.family)
      }
    },
    PickersReadonly () {
      return {
        carePartner: this.readonly || !(this.ContentMixin_ElementHasPocoStatus('new') && this.AuthenticationMixin_UserIsAdministrator()),
        child: this.readonly,
        family: this.readonly
      }
    },
    PickersRules () {
      return {
        carePartner: [this.validationMixin.rules.required],
        child: [this.validationMixin.rules.required],
        family: [this.validationMixin.rules.required]
      }
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    PickersParameters () {
      return {
        carePartner: [],
        child: [
          {
            key: 'idCarePartner',
            value: this.ContentMixin_Element.carePartner?.value
          },
          {
            key: 'idFamily',
            value: this.ContentMixin_Element.family?.value
          }
        ],
        family: []
      }
    },
    async DialogDetailSave () {
      this.EntityCardMixin_PickersSave(this.Pickers[this.entityCardMixin.dialogDetail.type])
      if (this.entityCardMixin.dialogDetail.type === 'child') {
        await this.GetChildren()
      }
    },
    async FamilySelected (value) {
      await this.EntityCardMixin_PickersChange(value)
      await this.GetChildren()
    },
    async GetChildren () {
      this.showChildren = false
      await this.ContentMixin_Service_BC_InitializeCodes('CarePartner')
      this.showChildren = true
    },
    async GetPickerCodes () {
      this.lists.codes.carePartner = await this.ContentMixin_Service_BC_InitializeCodes('CarePartner')
      this.lists.codes.family = await this.ContentMixin_Service_BC_InitializeCodes('Family')
    },
    async GetPickerLists () {
      await this.GetPickerCodes()
      await this.GetPickerPickSources()
      await this.EntityCardMixin_PickersInitialize(this.Pickers)
      await this.EntitiCardMixin_PickersInitializeValue(this.Pickers, this.parameters)
    },
    async GetPickerPickSources () {
      this.lists.carePartners = await this.ContentMixin_Service_BC_PickSource('CarePartner')
      this.lists.families = await this.ContentMixin_Service_BC_PickSource('Family')
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller, false)
      await this.GetPickerLists()

      if (this.ContentMixin_Element.family?.value) {
        this.GetChildren()
      }
    }
  }
}
</script>
