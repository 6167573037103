import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_Labels () {
      return {
        active: {
          fr: 'Actif',
          nl: 'Actief'
        },
        activityType: {
          fr: 'Type d\'activité',
          nl: 'Type activiteit'
        },
        add: {
          fr: `Ajouter ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} toevoegen`
        },
        accessToIct: {
          fr: 'Accès aux TIC',
          nl: 'Toegang tot ICT'
        },
        alliance: {
          fr: 'Alliance',
          nl: 'Koepel'
        },
        amount: {
          fr: 'Montant',
          nl: 'Bedrag'
        },
        approved: {
          fr: 'Approuvé',
          nl: 'Goedgekeurd'
        },
        bankAccountNumber: {
          fr: 'Numéro de compte bancaire',
          nl: 'Bankrekeningnummer'
        },
        budget: {
          fr: `Budget ${this.textMixin.placeholders.parameter}`,
          nl: `Budget ${this.textMixin.placeholders.parameter}`
        },
        cancel: {
          fr: 'Annuler',
          nl: 'Annuleren'
        },
        care: {
          fr: 'Soin',
          nl: 'Verzorging'
        },
        carePartner: {
          fr: 'Partenaire social',
          nl: 'Sociale partner'
        },
        changePassword: {
          fr: 'Changer le mot de passe',
          nl: 'Verander wachtwoord'
        },
        child: {
          fr: 'Enfant',
          nl: 'Kind'
        },
        collapse: {
          fr: 'Effondrer',
          nl: 'Inklappen'
        },
        collapseAll: {
          fr: 'Effondrer tous',
          nl: 'Alle inklappen'
        },
        compose: {
          fr: `Composer ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} opstellen`
        },
        confidential: {
          fr: 'Confidentiel',
          nl: 'Vertrouwelijk'
        },
        coverageMissingNeeds: {
          fr: 'Quels besoins manquants seraient couverts par la bourse Pelicano?',
          nl: 'Welke ontbrekende behoeften zouden door de Pelicano-beurs worden gedekt?'
        },
        close: {
          fr: 'Fermer',
          nl: 'Sluiten'
        },
        continueStudy: {
          fr: 'Poursuite des \'études',
          nl: 'Verder studeren'
        },
        currentFamilySituation: {
          fr: 'Situation familiale actuelle',
          nl: 'Huidige gezinssituatie'
        },
        date: {
          fr: 'Date',
          nl: 'Datum'
        },
        dateEnd: {
          fr: `Date de fin ${this.textMixin.placeholders.parameter}`,
          nl: `Einddatum ${this.textMixin.placeholders.parameter}`
        },
        dateEndStudies: {
          fr: 'Date de fin des études',
          nl: 'Datum afgestudeerd'
        },
        dateLogin: {
          fr: 'Date de connexion',
          nl: 'Logindatum'
        },
        dateOfBirth: {
          fr: 'Date de naissance',
          nl: 'Geboortedatum'
        },
        dateStart: {
          fr: `Date de début ${this.textMixin.placeholders.parameter}`,
          nl: `Startdatum ${this.textMixin.placeholders.parameter}`
        },
        debtMediation: {
          fr: 'Médiation de la dette',
          nl: 'Schuldbemiddeling'
        },
        delete: {
          fr: 'Effacer',
          nl: 'Verwijderen'
        },
        description: {
          fr: 'Description',
          nl: 'Beschrijving'
        },
        descriptionStructured: {
          fr: 'Description structurée',
          nl: 'Gestructureerde beschrijving'
        },
        download: {
          fr: 'Télécharger',
          nl: 'Downloaden'
        },
        duplicate: {
          fr: 'Dupliquer',
          nl: 'Dupliceren'
        },
        edit: {
          fr: 'Modifier',
          nl: 'Wijzigen'
        },
        education: {
          fr: 'Éducation',
          nl: 'Onderwijs'
        },
        educationalNetwork: {
          fr: 'Réseau éducatif',
          nl: 'Leernetwerk'
        },
        eMail: {
          fr: `E-mail ${this.textMixin.placeholders.parameter}`,
          nl: `E-mail ${this.textMixin.placeholders.parameter}`
        },
        employment: {
          fr: 'Emploi',
          nl: 'Tewerkstelling'
        },
        estimatedAnnualAmount: {
          fr: 'À quel montant annuel les estimez-vous (listez et chiffrez)?',
          nl: 'Op welk jaarlijks bedrag schat u deze (lijst en schat)?'
        },
        eventType: {
          fr: 'Type d\'évènement',
          nl: 'Type event'
        },
        expand: {
          fr: 'Étendre',
          nl: 'Uitklappen'
        },
        expandAll: {
          fr: 'Étendre tous',
          nl: 'Alle uitklappen'
        },
        explanation: {
          fr: 'Explication',
          nl: 'Uitleg'
        },
        family: {
          fr: 'Famille',
          nl: 'Gezin'
        },
        file: {
          fr: 'Fichier',
          nl: 'Bestand'
        },
        filter: {
          fr: 'Filtre',
          nl: 'Filter'
        },
        firstName: {
          fr: 'Prénom',
          nl: 'Voornaam'
        },
        forgotPassword: {
          fr: 'Mot de passe oublié',
          nl: 'Wachtwoord vergeten'
        },
        function: {
          fr: `Fonction ${this.textMixin.placeholders.parameter}`,
          nl: `Functie ${this.textMixin.placeholders.parameter}`
        },
        gender: {
          fr: 'Sexe',
          nl: 'Geslacht'
        },
        graduatedAs: {
          fr: 'Diplômé comme',
          nl: 'Afgestudeerd als'
        },
        health: {
          fr: 'Santé',
          nl: 'Gezondheid'
        },
        healthyFood: {
          fr: 'Nourriture saine',
          nl: 'Gezonde voeding'
        },
        houseNumber: {
          fr: 'N° de maison',
          nl: 'Huisnr.'
        },
        housing: {
          fr: 'Logement',
          nl: 'Huisvesting'
        },
        ibanNumber: {
          fr: 'IBAN',
          nl: 'IBAN'
        },
        information: {
          fr: 'Information',
          nl: 'Informatie'
        },
        initialBudget: {
          fr: 'Budget initial',
          nl: 'Initieel budget'
        },
        ipAddress: {
          fr: 'Adresse IP',
          nl: 'IP-adres'
        },
        itemsPerPage: {
          fr: 'Données par page',
          nl: 'Gegevens per pagina'
        },
        juridicalStatus: {
          fr: 'Statut juridique',
          nl: 'Juridische status'
        },
        language: {
          fr: 'Langue',
          nl: 'Taal'
        },
        lastName: {
          fr: 'Nom de famille',
          nl: 'Familienaam'
        },
        level: {
          fr: 'Niveau',
          nl: 'Niveau'
        },
        location: {
          fr: 'Lieu',
          nl: 'Locatie'
        },
        logIn: {
          fr: 'Se connecter',
          nl: 'Aanmelden'
        },
        logOut: {
          fr: 'Se déconnecter',
          nl: 'Afmelden'
        },
        mailBcc: {
          fr: 'BCC',
          nl: 'BCC'
        },
        mailCc: {
          fr: 'CC',
          nl: 'CC'
        },
        mailFrom: {
          fr: 'Transmetteur',
          nl: 'Zender'
        },
        mailTo: {
          fr: 'Récepteur(s)',
          nl: 'Ontvanger(s)'
        },
        monitor: {
          fr: 'Accompagnant',
          nl: 'Begeleider'
        },
        month: {
          fr: 'Mois',
          nl: 'Maand'
        },
        moreInformationRequired: {
          fr: 'Plus d\'informations requises',
          nl: 'Meer informatie vereist'
        },
        name: {
          fr: `Nom ${this.textMixin.placeholders.parameter}`,
          nl: `Naam ${this.textMixin.placeholders.parameter}`
        },
        nationality: {
          fr: 'Nationalité',
          nl: 'Nationaliteit'
        },
        new: {
          fr: 'Nouveau',
          nl: 'Nieuw'
        },
        noInfo: {
          fr: 'Aucune information',
          nl: 'Geen informatie'
        },
        password: {
          fr: 'Mot de passe',
          nl: 'Wachtwoord'
        },
        passwordChanged: {
          fr: 'Mot de passe changé',
          nl: 'Wachtwoord veranderd'
        },
        pelicanoBelfiusWebAccount: {
          fr: 'Compte Belfius Pelicano',
          nl: 'Pelicano Belfius Web-rekening'
        },
        post: {
          fr: 'Commune',
          nl: 'Gemeente'
        },
        postalCode: {
          fr: 'Code postale',
          nl: 'Postcode'
        },
        process: {
          fr: 'Traiter',
          nl: 'Verwerken'
        },
        properClothing: {
          fr: 'Vêtements adéquate',
          nl: 'Geschikte kleding'
        },
        province: {
          fr: 'Province',
          nl: 'Provincie'
        },
        publiclyKnown: {
          fr: 'Publiquement connu',
          nl: 'Publiek kenbaar'
        },
        reason: {
          fr: 'Raison',
          nl: 'Reden'
        },
        recreation: {
          fr: 'Détente',
          nl: 'Ontspanning'
        },
        refresh: {
          fr: 'Rafraîchir',
          nl: 'Vernieuwen'
        },
        region: {
          fr: 'Région',
          nl: 'Regio'
        },
        rejected: {
          fr: 'Rejeté',
          nl: 'Afgekeurd'
        },
        remark: {
          fr: 'Remarque',
          nl: 'Opmerking'
        },
        remember: {
          fr: 'Stocker des données',
          nl: 'Gegevens onthouden'
        },
        rename: {
          fr: 'Renommer',
          nl: 'Naam wijzigen'
        },
        repeatPassword: {
          fr: 'Répétez le mot de passe',
          nl: 'Herhaal wachtwoord'
        },
        request: {
          fr: 'Demande',
          nl: 'Aanvraag'
        },
        requests: {
          fr: 'Demandes',
          nl: 'Aanvragen'
        },
        resubmit: {
          fr: 'Resoumettre',
          nl: 'Opnieuw indienen'
        },
        save: {
          fr: 'Enregistrer',
          nl: 'Opslaan'
        },
        school: {
          fr: 'École',
          nl: 'School'
        },
        send: {
          fr: 'Envoyer',
          nl: 'Verzenden'
        },
        sendSomething: {
          fr: `Envoyer ${this.textMixin.placeholders.parameter}`,
          nl: `Stuur ${this.textMixin.placeholders.parameter}`
        },
        siblings: {
          fr: 'Frères et sœurs',
          nl: 'Broers en zussen'
        },
        socialExclusionDegree: {
          fr: 'Niveau d\'exclusion sociale',
          nl: 'Graad van sociale uitsluiting'
        },
        socialNetwork: {
          fr: 'Réseau social',
          nl: 'Sociaal netwerk'
        },
        status: {
          fr: 'Statut',
          nl: 'Status'
        },
        studyType: {
          fr: 'Domaine d\'étude',
          nl: 'Studierichting'
        },
        street: {
          fr: 'Rue',
          nl: 'Straat'
        },
        subCarePartner: {
          fr: 'Sous-partenaire social',
          nl: 'Subsociale partner'
        },
        subject: {
          fr: 'Sujet',
          nl: 'Onderwerp'
        },
        supportOtherOrganisation: {
          fr: 'Soutien aide à la jeunesse',
          nl: 'Ondersteuning andere organisatie'
        },
        telephone: {
          fr: `Téléphone ${this.textMixin.placeholders.parameter}`,
          nl: `Telefoon ${this.textMixin.placeholders.parameter}`
        },
        totalAmount: {
          fr: 'Montant total',
          nl: 'Totale bedrag'
        },
        type: {
          fr: 'Type',
          nl: 'Type'
        },
        uniqueCode: {
          fr: 'Code unique',
          nl: 'Unieke code'
        },
        unmetNeeds: {
          fr: 'Quels sont les besoins non couverts du jeune?',
          nl: 'Welke onvervulde behoeften heeft de jongere?'
        },
        upload: {
          fr: `Télécharger ${this.textMixin.placeholders.parameter} ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} uploaden ${this.textMixin.placeholders.parameter}`
        },
        user: {
          fr: 'L\'utilisateur',
          nl: 'Gebruiker'
        },
        userGroup: {
          fr: 'Rôle',
          nl: 'Rol'
        },
        userType: {
          fr: 'Type d\'utilisateur',
          nl: 'Gebruikerstype'
        },
        whyNot: {
          fr: 'De quel type',
          nl: 'Waarom niet?'
        }
      }
    }
  }
}
