<template>
  <families />
</template>

<script>
import Families from '@/views/families/components/families'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'FamiliesView',
  components: {
    Families
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
