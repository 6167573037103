<template>
  <v-col
    v-if="$scopedSlots['v-col'] && AuthenticationMixin_InputFieldIsShown(value)"
    :cols="cols"
    :class="baseFieldContainerClass"
    :lg="lg"
    :md="md"
  >
    <slot
      :property="value"
      name="v-col"
    />
  </v-col>
  <div
    v-else-if="AuthenticationMixin_InputFieldIsShown(value)"
    :class="baseFieldContainerClass"
  >
    <slot
      :property="value"
      name="div"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseProperty',
  props: {
    // Complete object of the property, including meta data, ...
    value: Object,
    // Class for the container
    baseFieldContainerClass: String,
    // Width when on small screen
    cols: {
      default: 12,
      type: [
        Number,
        String
      ]
    },
    // Width when on large screen
    lg: {
      default: 3,
      type: [
        Number,
        String
      ]
    },
    // Width when on medium screen
    md: {
      default: 6,
      type: [
        Number,
        String
      ]
    }
  }
}
</script>
