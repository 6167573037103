import ChildMixin from '@/mixins/child-mixin'
import CodeMixin from '@/mixins/code-mixin'

export default {
  mixins: [
    ChildMixin,
    CodeMixin
  ],
  props: {
    // Source of detail page
    source: {
      default: 'tab',
      type: String
    },
    // Component name (identifier)
    name: String,
    // Parameters
    parameters: Array,
    // In the current visible tab
    visible: Boolean
  },
  data () {
    return {
      contentMixin: {
        codes: [],
        controller: null,
        idParent: null,
        key: 'id',
        service: null
      }
    }
  }
}
