import { defineStore } from 'pinia'

export default defineStore({
  id: 'authentication',
  state () {
    return {
      authentication: null,
      loggedIn: false,
      menu: null
    }
  }
})
