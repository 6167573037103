<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-user-management-roles-detail-general-information
      :readonly="readonly"
      :source="source"
      :user-types="userTypes"
    />
    <entity-card-user-management-roles-detail-profiles
      :readonly="readonly"
      :source="source"
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardUserManagementRolesDetailGeneralInformation from '@/components/shared/entitycards/entity-card-user-management-roles-detail-general-information'
import EntityCardUserManagementRolesDetailProfiles from '@/components/shared/entitycards/entity-card-user-management-roles-detail-profiles'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'

export default {
  name: 'UserManagementRolesDetail',
  components: {
    BaseFormDetail,
    EntityCardUserManagementRolesDetailGeneralInformation,
    EntityCardUserManagementRolesDetailProfiles
  },
  mixins: [
    BaseFormDetailMixin
  ],
  data () {
    return {
      controller: 'UserGroup',
      userTypes: []
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      this.userTypes = await this.ContentMixin_Service_BC_PickSource('UserType')
    }
  }
}
</script>
