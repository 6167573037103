<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.fileName"
        v-model="EntityCardMixin_CopySource.fileName"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('name')"
        lg="6"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.confidential"
        v-model="EntityCardMixin_CopySource.confidential"
        base-field-container-type="v-col"
        base-field-type="checkbox"
        :disabled="disabled"
        :label="TextMixin_GetLabel('confidential')"
        :readonly="EntityCardMixin_Readonly"
      />
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardDocumentsDetailGeneralInformation',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('generalInformation')
    }
  }
}
</script>
