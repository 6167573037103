<template>
  <div
    class="customScrollbar"
  >
    <v-stepper
      v-model="ContentMixin_Store_SelectedStep"
      non-linear
    >
      <base-steps
        :steps="steps"
      />
      <v-stepper-items>
        <v-stepper-content
          v-for="(step, index) in Steps"
          :key="index"
          :step="index + 1"
        >
          <component
            :is="BaseStepsViewMixin_Component(step)"
            v-if="BaseStepsViewMixin_StepShowContent(step)"
            :visible="BaseStepsViewMixin_StepVisible(index)"
            @dirty="SetDirty(index, $event)"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import BaseSteps from '@/components/shared/base/base-steps'
import BaseUnderConstruction from '@/components/shared/base/base-under-construction'
import RequestsDetailProcessRequest from '@/views/requests/components/requests-detail-process-request'
import RequestsDetailStatusRequest from '@/views/requests/components/requests-detail-status-request'
import RequestsDetailSubmitRequest from '@/views/requests/components/requests-detail-submit-request'
import BaseStepsViewMixin from '@/mixins/base-steps-view-mixin'

export default {
  name: 'BaseStepsView',
  components: {
    BaseSteps,
    BaseUnderConstruction,
    RequestsDetailProcessRequest,
    RequestsDetailStatusRequest,
    RequestsDetailSubmitRequest
  },
  mixins: [
    BaseStepsViewMixin
  ],
  props: {
    // Steps to be shown
    steps: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      dirtySteps: []
    }
  },
  computed: {
    Dirty () {
      return this.dirtySteps && this.dirtySteps.length > 0
    },
    Steps () {
      return this.BaseStepsViewMixin_StepsFilteredByRights(this.steps)
    }
  },
  watch: {
    ContentMixin_Store_DetailSourceElement (value) {
      this.ContentMixin_Store_SelectedStep = value ? this.defaultStepOnSelect : 1
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    Component (step) {
      return step?.component ?? 'base-under-construction'
    },
    Initialize () {
      this.ContentMixin_Store_SelectedStep = this.defaultStepOnSelect ?? 1
    },
    SetDirty (index, value) {
      const wasDirty = this.dirtySteps.includes(index)

      if (value && !wasDirty) {
        this.dirtySteps.push(index)
      } else if (!value && wasDirty) {
        this.dirtySteps.splice(this.dirtySteps.indexOf(index), 1)
      }
    }
  }
}
</script>

<style scoped>
.v-stepper {
  height: calc(100vh - 106px);
  margin: -5px;
  margin-right: 0px;
  overflow-y: scroll;
}
</style>

<style>
.v-sheet.v-stepper:not(.v-sheet--outlined), .v-stepper > .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: unset;
}

.v-stepper__content, .v-stepper .v-card {
  padding: 5px;
}

.v-stepper__wrapper {
  overflow: unset;
}
</style>
