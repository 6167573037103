<template>
  <div
    style="position: relative;"
  >
    <base-tabs
      :tabs="Tabs"
    />
    <v-tabs-items
      v-model="ContentMixin_Store_SelectedTab"
      touchless
    >
      <v-tab-item
        v-for="(tab, index) in Tabs"
        :key="index"
      >
        <component
          :is="BaseTabsViewMixin_Component(tab)"
          v-if="BaseTabsViewMixin_TabShowContent(tab)"
          :mail-type="tab.mailType"
          :visible="BaseTabsViewMixin_TabVisible(index)"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BankStatementsOverview from '@/views/bankstatements/components/bank-statements-overview'
import BaseTabs from '@/components/shared/base/base-tabs'
import BaseUnderConstruction from '@/components/shared/base/base-under-construction'
import CalendarOverview from '@/views/calendar/components/calendar-overview'
import CarePartnersActivities from '@/views/carepartners/components/care-partners-activities'
import CarePartnersBankStatements from '@/views/carepartners/components/care-partners-bank-statements'
import CarePartnersChildren from '@/views/carepartners/components/care-partners-children'
import CarePartnersDetail from '@/views/carepartners/components/care-partners-detail'
import CarePartnersDocuments from '@/views/carepartners/components/care-partners-documents'
import CarePartnersMonitors from '@/views/carepartners/components/care-partners-monitors'
import CarePartnersOverview from '@/views/carepartners/components/care-partners-overview'
import CarePartnersRequests from '@/views/carepartners/components/care-partners-requests'
import ChildrenBankStatements from '@/views/children/components/children-bank-statements'
import ChildrenDetail from '@/views/children/components/children-detail'
import ChildrenDocuments from '@/views/children/components/children-documents'
import ChildrenMonitors from '@/views/children/components/children-monitors'
import ChildrenOverview from '@/views/children/components/children-overview'
import ChildrenRequests from '@/views/children/components/children-requests'
import DocumentsList from '@/views/documents/components/documents-list'
import EMailDetail from '@/views/email/components/e-mail-detail'
import EMailOverview from '@/views/email/components/e-mail-overview'
import FamiliesBankStatements from '@/views/families/components/families-bank-statements'
import FamiliesChildren from '@/views/families/components/families-children'
import FamiliesDetail from '@/views/families/components/families-detail'
import FamiliesDocuments from '@/views/families/components/families-documents'
import FamiliesOverview from '@/views/families/components/families-overview'
import FamiliesRequests from '@/views/families/components/families-requests'
import MonitorsChildren from '@/views/monitors/components/monitors-children'
import MonitorsDetail from '@/views/monitors/components/monitors-detail'
import MonitorsDocuments from '@/views/monitors/components/monitors-documents'
import MonitorsOverview from '@/views/monitors/components/monitors-overview'
import MonitorsRequests from '@/views/monitors/components/monitors-requests'
import RequestsDetail from '@/views/requests/components/requests-detail'
import RequestsDocuments from '@/views/requests/components/requests-documents'
import RequestsOverview from '@/views/requests/components/requests-overview'
import RequestsUpdateHistory from '@/views/requests/components/requests-update-history'
import UserManagementProfilesDetail from '@/views/usermanagement/profiles/components/user-management-profiles-detail'
import UserManagementProfilesOverview from '@/views/usermanagement/profiles/components/user-management-profiles-overview'
import UserManagementRolesDetail from '@/views/usermanagement/roles/components/user-management-roles-detail'
import UserManagementRolesOverview from '@/views/usermanagement/roles/components/user-management-roles-overview'
import UserManagementUsersDetail from '@/views/usermanagement/users/components/user-management-users-detail'
import UserManagementUsersOverview from '@/views/usermanagement/users/components/user-management-users-overview'
import BaseTabsViewMixin from '@/mixins/base-tabs-view-mixin'

export default {
  name: 'BaseTabsView',
  components: {
    BankStatementsOverview,
    BaseTabs,
    BaseUnderConstruction,
    CalendarOverview,
    CarePartnersActivities,
    CarePartnersBankStatements,
    CarePartnersChildren,
    CarePartnersDetail,
    CarePartnersDocuments,
    CarePartnersMonitors,
    CarePartnersOverview,
    CarePartnersRequests,
    ChildrenBankStatements,
    ChildrenDetail,
    ChildrenDocuments,
    ChildrenMonitors,
    ChildrenOverview,
    ChildrenRequests,
    DocumentsList,
    EMailDetail,
    EMailOverview,
    FamiliesBankStatements,
    FamiliesChildren,
    FamiliesDetail,
    FamiliesDocuments,
    FamiliesOverview,
    FamiliesRequests,
    MonitorsChildren,
    MonitorsDetail,
    MonitorsDocuments,
    MonitorsOverview,
    MonitorsRequests,
    RequestsDetail,
    RequestsDocuments,
    RequestsOverview,
    RequestsUpdateHistory,
    UserManagementProfilesOverview,
    UserManagementProfilesDetail,
    UserManagementRolesOverview,
    UserManagementRolesDetail,
    UserManagementUsersDetail,
    UserManagementUsersOverview
  },
  mixins: [
    BaseTabsViewMixin
  ],
  computed: {
    Tabs () {
      return this.BaseTabsViewMixin_TabsFilteredByRights(this.tabs)
    }
  },
  watch: {
    ContentMixin_Store_DetailSourceElement (value) {
      this.ContentMixin_Store_SelectedTab = value ? this.defaultTabOnSelect : 0
    }
  }
}
</script>
