import ContentMixin from '@/mixins/content-mixin'

export default {
  mixins: [
    ContentMixin
  ],
  props: {
    // Default tab on select
    defaultTabOnSelect: {
      default: 1,
      type: Number
    },
    // What tabs are to be shown? Tabs: [{ disabled: Boolean, name: String, types: Array(String) }]
    tabs: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    BaseTabsViewMixin_DisableIfNew () {
      return !this.ContentMixin_Element || this.ContentMixin_ElementHasPocoStatus('new')
    }
  },
  beforeDestroy () {
    this.ContentMixin_Store_SelectedTab = 0
    this.ContentMixin_Store_DetailSourceElement = null
  },
  methods: {
    BaseTabsViewMixin_Component (tab) {
      return tab?.component ?? 'base-under-construction'
    },
    BaseTabsViewMixin_GetDetailsTab () {
      return this.ContentMixin_ElementHasPocoStatus('new') ? 'detailsCreate' : (this.ContentMixin_ElementHasRights('allowUpdate') ? 'detailsUpdate' : 'details')
    },
    BaseTabsViewMixin_TabShowContent (tab) {
      return tab.component && !tab.disabled
    },
    BaseTabsViewMixin_TabsFilteredByRights (tabs) {
      return tabs.filter(tab => this.AuthenticationMixin_HasRights(tab) && !tab.disabled)
    },
    BaseTabsViewMixin_TabVisible (index) {
      return index === this.ContentMixin_Store_SelectedTab
    }
  }
}
