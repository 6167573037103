import ContentMixin from '@/mixins/content-mixin'

export default {
  mixins: [
    ContentMixin
  ],
  props: {
    // Accepted file types
    accept: String,
    // Input gets autocompleted by set key combination if BaseField is of type date or dateTime
    autocomplete: Boolean,
    // AutoGrow if BaseField is of type textarea
    autoGrow: {
      default: true,
      type: Boolean
    },
    // Class for the BaseField
    baseFieldClass: String,
    // Color for the BaseField
    color: String,
    // Is clearable
    clearable: Boolean,
    // How many decimals should be shown if BaseField is of type number
    decimals: Number,
    // Is dense
    dense: Boolean,
    // Is disabled
    disabled: Boolean,
    // Accepted file types if BaseField is of type fileInput
    fileTypes: String,
    // Format of value
    format: String,
    // Icon to be shown if applicable
    icon: String,
    // Items for list if BaseField is of type autocomplete
    items: Array,
    // Property to show as text if BaseField is of type autocomplete
    itemText: {
      default: 'text',
      type: String
    },
    // Property to take as value if BaseField is of type autocomplete
    itemValue: {
      default: 'value',
      type: String
    },
    // Label for element
    label: String,
    // Label for BaseFieldDate if BaseField is of type dateTime
    labelDate: String,
    // Label for BaseFieldTime if BaseField is of type dateTime
    labelTime: String,
    // Allow multiple files if BaseField is of type fileInput
    multiple: Boolean,
    // Is outlined
    outlined: Boolean,
    // Prefix to add to BaseField if applicable
    prefix: String,
    // Is readonly
    readonly: Boolean,
    // Rules for validation
    rules: {
      default: () => [],
      type: Array
    },
    // Return object instead of just value if BaseField is of type autocomplete
    returnObject: Boolean,
    // Align right
    reverse: {
      default: null,
      type: Boolean
    },
    // Number of rows if BaseField is of type textarea
    rows: {
      default: 3,
      type: [
        Number,
        String
      ]
    },
    // Show navigation
    showNavigation: Boolean,
    // Suffix to add to BaseField if applicable
    suffix: String,
    // Use thousands separator if BaseField is of type number
    thousandsSeparator: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      baseFieldMixin: {
        value: null
      }
    }
  }
}
