<template>
  <v-container
    class="px-3"
    fluid
    tag="section"
  >
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        lg="3"
      >
        <v-card
          class="px-5 mt-15"
        >
          <v-img
            src="@/assets/images/logo.png"
          />
        </v-card>
        <framework-login
          class="py-5 text-center"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FrameworkLogin from '@/components/framework/framework-login'

export default {
  name: 'Home',
  components: {
    FrameworkLogin
  }
}
</script>
