import BlobService from '@/services/blob-service'
import DocumentService from '@/services/document-service'

export default {
  data () {
    return {
      blobMixin: {
        blobStatus: {
          unchanged: 0,
          created: 1,
          updated: 2,
          deleted: 3
        }
      }
    }
  },
  mounted () {
    BlobService.Init(this.ErrorMixin_HandleError)
    DocumentService.Init(this.ErrorMixin_HandleError)
  },
  methods: {
    async BlobMixin_Download (blob) {
      if (blob) {
        if (blob.blobDataString) {
          this.BlobMixin_DownloadAction(blob.bestandsnaam, blob.blobDataString)
        } else {
          await this.BlobMixin_DownloadExisting(blob)
        }
      }
    },
    BlobMixin_DownloadAction (filename, data = null, filestream = null) {
      if (filestream) {
        data = URL.createObjectURL(filestream)
      }

      const docUrl = document.createElement('a')
      docUrl.href = data
      docUrl.download = filename.replaceAll('"', '')
      docUrl.click()

      if (filestream) {
        URL.revokeObjectURL(data)
      }
    },
    BlobMixin_DownloadApiGeneratedFile (response, isBase64 = false) {
      this.BlobMixin_DownloadAction(this.BlobMixin_GetFileNameFromHeader(response.headers['content-disposition']), isBase64 ? response.data : null, isBase64 ? null : response.data)
    },
    async BlobMixin_DownloadExisting (blob) {
      if (blob) {
        const response = await BlobService.Download(blob.idBlob)

        if (response && (response.data || response.data !== '')) {
          this.BlobMixin_DownloadAction(blob.bestandsnaam, null, response.data)
        }
      }
    },
    async BlobMixin_DownloadById (id, name) {
      const blob = await DocumentService.Download(id)
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob.data)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async BlobMixin_UploadFile (file) {
      if (file) {
        const response = await BlobService.Upload(file)

        if (response && (response.data || response.data !== '')) {
          return response.data
        }
      }

      return null
    },
    BlobMixin_GetFileNameFromHeader (contentDisposition) {
      const property = 'filename='
      const valueIndex = contentDisposition.indexOf(property) + property.length
      const endIndex = contentDisposition.indexOf(';', valueIndex)
      const name = contentDisposition.substr(valueIndex, endIndex - valueIndex)

      return name
    },
    BlobMixin_GetFileNameShortened (blob) {
      if (blob) {
        const name = blob.bestandsnaam
        const firstPart = 10
        const secondPart = 10
        const maxLength = firstPart + secondPart + 1

        if (name && name.length > maxLength) {
          return `${blob.bestandsnaam.substr(0, firstPart)}…${blob.bestandsnaam.substr(name.length - secondPart)}`
        } else {
          return blob.bestandsnaam
        }
      } else {
        return null
      }
    },
    BlobMixin_GetFileSize (blob, unit = '', showUnit = true, showZero = true) {
      if (blob && blob.blobDataString) {
        let size = Buffer.from(blob.blobDataString, 'base64').length
        let exponent = 0

        if (showZero || size) {
          switch (unit) {
            case 'KB':
              exponent = 1
              break
            case 'MB':
              exponent = 2
              break
            case 'GB':
              exponent = 3
              break
          }

          size /= Math.pow(1024, exponent)

          return showUnit ? `${this.FormatMixin_FormatDecimal(size)}${unit}` : size
        } else {
          return null
        }
      } else {
        return null
      }
    },
    BlobMixin_HandleFileUpload (blob, event, maxFileSize) {
      const fileList = event.target.files || event.dataTransfer.files

      if (fileList.length > 0) {
        const files = []

        for (const property in fileList) {
          const value = fileList[property]
          if (typeof (value) === 'object') {
            files.push(value)
          }
        }

        if (maxFileSize && files.find(file => file.size > maxFileSize * Math.pow(1024, 2))) {
          alert(this.TextMixin_GetMessage('maxFileSize', maxFileSize))
        } else {
          this.BlobMixin_ReadFileUpload(blob, files[0], event.srcElement.value.replace(/^.*?([^\\/]*)$/, '$1'))
        }
      }
    },
    BlobMixin_HandleVFileUpload (blobProperty, file) {
      if (file == null) {
        blobProperty = null
      } else {
        this.BlobMixin_ReadFileUpload(blobProperty, file, file.name)
      }
    },
    BlobMixin_ReadFileUpload (blob, file, filename) {
      const methodName = 'BlobMixin_ReadFileUpload'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const reader = new FileReader()

      reader.onload = (e) => {
        blob.blobDataString = e.target.result
        blob.bestandsnaam = filename
      }

      reader.readAsDataURL(file)
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    }
  }
}
