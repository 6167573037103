<template>
  <base-card
    no-padding
    :title="EntityCardMixin_Title"
  >
    <base-list-overview
      :addAction="false"
      :deleteAction="false"
      controller="BankStatement"
      custom-update
      :filter="Filter"
      inside-card
      :readonly="EntityCardMixin_Readonly"
      :visible="ShowOverview"
      @custom-update="$emit('update', $event)"
    >
      <template
        #[`read-incoming`]="item"
      >
        <v-icon
          class="incoming-arrow-icon pt-3"
          :color="ItemCostColor(item)"
        >
          mdi-arrow-{{ item.incoming.value ? 'down' : 'up'}}
        </v-icon>
      </template>
      <template
        #[`read-itemCost`]="item"
      >
          <span
            :class="`${ItemCostColor(item)}--text`"
          >
            {{ FormatMixin_FormatDecimal(item.itemCost.value) }}
          </span>
      </template>
      <template
        #[`read-child`]="item"
      >
        {{ item.child.value }}
      </template>
    </base-list-overview>
  </base-card>
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardBankStatementsOverview',
  components: {
    BaseListOverview
  },
  mixins: [
    EntityCardMixin
  ],
  props: {
    filterKey: String
  },
  data () {
    return {
      copy: null,
      initialized: false,
      showOverview: true
    }
  },
  watch: {
    async Filter () {
      await this.SetCopy()
    }
  },
  computed: {
    Filter () {
      const key = this.filterKey
      return [
        {
          key: key,
          value: this.EntityCardMixin_CopySource ? this.EntityCardMixin_CopySource[this.filterKey] ?? this.constantMixin.guid.empty : this.constantMixin.guid.empty
        }
      ]
    },
    ShowOverview () {
      return this.visible && this.showOverview
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('bankStatements')
      await this.SetCopy()
    },
    ItemCostColor (item) {
      return item.incoming.value ? 'green' : 'red'
    },
    SelectionChanged (value) {
      if (this.initialized) {
        this.EntityCardMixin_CopySource.bankStatements.items = value
      } else {
        this.initialized = true
      }
    },
    async SetCopy () {
      this.showOverview = false
      this.copy = this.CommonMixin_Clone(this.value)
      this.showOverview = true
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.incoming-arrow-icon {
  margin-top: -18px;
}
</style>
