import ContentMixin from '@/mixins/content-mixin'

export default {
  mixins: [
    ContentMixin
  ],
  props: {
    // Default step on select
    defaultStepOnSelect: {
      default: 1,
      type: Number
    },
    // What steps are to be shown? Steps: [{ editable: Boolean, name: String, description: String }]
    steps: {
      default: () => [],
      type: Array
    }
  },
  beforeDestroy () {
    this.ContentMixin_Store_SelectedStep = 1
  },
  methods: {
    BaseStepsViewMixin_Component (step) {
      return step?.component ?? 'base-under-construction'
    },
    BaseStepsViewMixin_StepsFilteredByRights (steps) {
      return steps.filter(step => !step.disabled)
    },
    BaseStepsViewMixin_StepShowContent (step) {
      return step.component && !step.disabled && step.editable
    },
    BaseStepsViewMixin_StepVisible (index) {
      return index + 1 === this.ContentMixin_Store_SelectedStep
    }
  }
}
