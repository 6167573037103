<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <template
        v-if="EntityCardMixin_Picker"
      >
        <base-picker
          v-if="entityCardMixin.pickers.components[0]"
          v-model="entityCardMixin.pickers.components[0]"
          base-field-container-type="v-col"
          disabled
          :items="children"
          :label="TextMixin_GetLabel('child')"
          readonly
          :rules="pickers[0].rules"
          @pickers-create="$emit('pickers-create', $event)"
          @pickers-read="$emit('pickers-read', $event)"
          @pickers-update="$emit('pickers-update', $event)"
        />
      </template>
      <base-field
        v-if="EntityCardMixin_CopySource.budgetReason"
        v-model="EntityCardMixin_CopySource.budgetReason"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        disabled
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('reason')"
        readonly
      />
      <base-field
        v-if="EntityCardMixin_CopySource.budgetReasonOther && IsOtherReason()"
        v-model="EntityCardMixin_CopySource.budgetReasonOther"
        autogrow
        base-field-container-type="v-col"
        base-field-type="textarea"
        disabled
        :label="TextMixin_GetLabel('explanation')"
        readonly
        rows="1"
        :rules="[validationMixin.rules.required]"
      />
      <v-col
        cols="12"
        lg="3"
        md="6"
      >
        <v-row>
          <base-field
            v-if="EntityCardMixin_CopySource.requestedBudgetTotalAmount"
            v-model="EntityCardMixin_CopySource.requestedBudgetTotalAmount"
            base-field-container-type="v-col"
            base-field-type="number"
            :decimals="2"
            disabled
            :label="TextMixin_GetLabel('budget', `(${TextMixin_GetParameter('requested').toLowerCase()})`)"
            lg="6"
            prefix="€"
            readonly
            :rules="[validationMixin.rules.numberGreaterThan(EntityCardMixin_CopySource.requestedBudgetTotalAmount.value, 0, true)]"
          />
          <base-field
            v-if="EntityCardMixin_CopySource.budgetTotalAmount"
            v-model="EntityCardMixin_CopySource.budgetTotalAmount"
            base-field-container-type="v-col"
            base-field-type="number"
            :decimals="2"
            :disabled="EntityCardMixin_Disabled"
            :label="TextMixin_GetLabel('budget', `(${TextMixin_GetParameter('allocated').toLowerCase()})`)"
            lg="6"
            prefix="€"
            :readonly="EntityCardMixin_Readonly"
            :rules="[validationMixin.rules.numberGreaterThan(EntityCardMixin_CopySource.budgetTotalAmount.value, 0, true)]"
          />
        </v-row>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardRequestsDetailProcessRequestExtraBudgetDetails',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('details')
    },
    IsOtherReason () {
      return this.EntityCardMixin_CopySource.budgetReason.value && this.EntityCardMixin_CopySource.budgetReason.value.toUpperCase() === this.constantMixin.codes.requestExtraBudgetReason.values.other
    }
  }
}
</script>
