<template>
  <base-steps-view
    v-if="ContentMixin_Store_DetailSourceCopy"
    :default-step-on-select="DefaultStep"
    :steps="Steps"
  />
</template>

<script>
import BaseStepsView from '@/components/shared/base/base-steps-view'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import BaseStepsViewMixin from '@/mixins/base-steps-view-mixin'

export default {
  name: 'RequestsDetail',
  components: {
    BaseStepsView
  },
  mixins: [
    BaseFormDetailMixin,
    BaseStepsViewMixin
  ],
  computed: {
    Completed () {
      return this.RequestStatusCheck(true, true, false)
    },
    DefaultStep () {
      return this.LastStepEditable ? this.Steps.length : this.RequestSubmissionCompleted && this.AuthenticationMixin_UserIsAdministrator() ? 2 : 1
    },
    LastStepEditable () {
      return this.RequestStatus.toUpperCase() !== this.constantMixin.codes.requestStatus.values.new.toUpperCase()
    },
    RequestStatus () {
      return this.ContentMixin_Store_DetailSourceElement.requestStatus.value ? this.ContentMixin_Store_DetailSourceElement.requestStatus.value.toUpperCase() : null
    },
    RequestStatusColor () {
      return this.ContentMixin_ElementHasPocoStatus('new') ? 'warning' : this.constantMixin.settings.requestStatus[this.RequestStatusDescription].color
    },
    RequestStatusDescription () {
      const values = this.constantMixin.codes.requestStatus.values
      return Object.keys(values).find(key => values[key].toUpperCase() === this.RequestStatus.toUpperCase())
    },
    RequestStatusIcon () {
      return this.RequestStatusCheck(null, 'mdi-close', 'mdi-progress-clock')
    },
    RequestStatusStatus () {
      return this.ContentMixin_ElementHasPocoStatus('new') ? 'toBeSubmitted' : this.constantMixin.settings.requestStatus[this.RequestStatusDescription].status
    },
    RequestSubmissionCompleted () {
      return !this.ContentMixin_ElementHasPocoStatus('new') && this.RequestStatus !== this.constantMixin.codes.requestStatus.values.moreInformationRequired
    },
    Steps () {
      return [
        {
          color: this.StepColor(this.RequestSubmissionCompleted),
          complete: this.RequestSubmissionCompleted,
          component: 'requests-detail-submit-request',
          editable: !this.Completed || this.AuthenticationMixin_UserIsAdministrator(),
          name: this.TextMixin_GetStep('submit', this.TextMixin_GetParameter('request').toLowerCase())
        },
        {
          color: this.StepColor(this.Completed),
          complete: this.Completed,
          component: 'requests-detail-process-request',
          editable: this.AuthenticationMixin_UserIsAdministrator() && !this.ContentMixin_ElementHasPocoStatus('new'),
          name: this.TextMixin_GetStep('process', this.TextMixin_GetParameter('request').toLowerCase())
        },
        {
          color: this.RequestStatusColor,
          complete: true,
          completeIcon: this.RequestStatusIcon,
          component: 'requests-detail-status-request',
          description: this.ContentMixin_Store_DetailSourceElement?.requestStatus?.text,
          editable: this.LastStepEditable,
          name: this.TextMixin_GetStep(this.RequestStatusStatus)
        }
      ]
    }
  },
  data () {
    return {
      controller: 'Request'
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
    },
    RequestStatusCheck (approvedValue, rejectedValue, defaultValue) {
      switch (this.RequestStatus) {
        case this.constantMixin.codes.requestStatus.values.approved.toUpperCase():
          return approvedValue
        case this.constantMixin.codes.requestStatus.values.rejected.toUpperCase():
          return rejectedValue
        default:
          return defaultValue
      }
    },
    StepColor (completed) {
      return completed ? 'success' : null
    }
  }
}
</script>
