<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <v-col
        cols="12"
        lg="3"
        md="6"
      >
        <v-row>
          <base-field
            v-if="EntityCardMixin_CopySource.initialBudget"
            v-model="EntityCardMixin_CopySource.initialBudget"
            base-field-container-type="v-col"
            base-field-type="number"
            :decimals="2"
            :disabled="EntityCardMixin_Disabled"
            :label="TextMixin_GetLabel('initialBudget')"
            lg="6"
            prefix="€"
            :readonly="EntityCardMixin_Readonly"
            :rules="[validationMixin.rules.numberGreaterThan(EntityCardMixin_CopySource.initialBudget.value, 0, true)]"
          />
        </v-row>
      </v-col>
    </v-row>
    <base-list-overview
      controller="Request"
      :filter="Filter"
      inside-card
      hide-filter
      readonly
      redirect
      redirect-page="Requests"
      :visible="visible"
    >
      <template
        #[`read-requestStatus`]="item"
      >
        <v-chip
          :color="RequestStatusColor(item)"
          small
        >
          {{ item.requestStatus.value }}
        </v-chip>
      </template>
    </base-list-overview>
  </base-card>
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardChildrenDetailBudgetDetails',
  components: {
    BaseListOverview
  },
  mixins: [
    EntityCardMixin
  ],
  computed: {
    Filter () {
      return [
        {
          key: 'IdCodeRequestType',
          value: this.constantMixin.codes.requestType.values.extraBudget
        },
        {
          key: 'idChild',
          value: this.EntityCardMixin_CopySource.idChild
        }
      ]
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('budgetDetails')
      await this.ContentMixin_Service_BC_InitializeContentService('Child')
    },
    RequestStatusColor (item) {
      return this.constantMixin.settings.requestStatus[this.RequestStatusDescription(item)].color
    },
    RequestStatusDescription (item) {
      const values = this.constantMixin.codes.requestStatus.values
      return Object.keys(values).find(key => values[key].toUpperCase() === item.idCodeRequestStatus.value.toUpperCase())
    }
  }
}
</script>
