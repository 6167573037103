<template>
  <div>
    <v-list-item
      v-if="item.to"
      active-class="primary"
      :class="`${$vuetify.theme.isDark ? 'white' : 'white'}--text`"
      :dense="item.dense"
      :href="Href"
      :rel="Href && Href !== '#' ? 'noopener' : undefined"
      :target="Href && Href !== '#' ? '_blank' : undefined"
      :to="item.to"
    >
      <v-list-item-icon
        v-if="hideIcon || text"
        class="v-list-item__icon--text"
        v-text="ComputedText"
      />

      <v-list-item-icon
        v-else-if="item.icon"
      >
        <v-icon
          color="white"
          v-text="item.icon"
        />
      </v-list-item-icon>

      <v-list-item-content
        v-if="item.title || item.subtitle"
      >
        <v-list-item-title
          v-text="item.title"
        />
        <v-list-item-subtitle
          v-text="item.subtitle"
        />
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-else
      active-class="secondary"
      :class="`${$vuetify.theme.isDark ? 'white' : 'white'}--text`"
      :dense="item.dense"
      :rel="Href && Href !== '#' ? 'noopener' : undefined"
      :target="Href && Href !== '#' ? '_blank' : undefined"
      @click="Click()"
    >
      <v-list-item-icon
        v-if="hideIcon || text"
        class="v-list-item__icon--text"
        v-text="ComputedText"
      />
      <v-list-item-icon
        v-else-if="item.icon"
      >
        <v-icon
          color="white"
          v-text="item.icon"
        />
      </v-list-item-icon>
      <v-list-item-content
        v-if="item.title || item.subtitle"
      >
        <v-list-item-title
          v-text="item.title"
        />
        <v-list-item-subtitle
          v-text="item.subtitle"
        />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import ThemeableMixin from 'vuetify/lib/mixins/themeable'

export default {
  name: 'FrameworkSidebarMenuItem',
  mixins: [
    ThemeableMixin
  ],
  props: {
    // Hide icon
    hideIcon: {
      type: Boolean,
      default: false
    },
    // The item to show
    item: {
      default: () => ({
        dense: false,
        href: undefined,
        icon: undefined,
        redirect: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined
      }),
      type: Object
    },
    // Show text instead of icon
    text: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ComputedText () {
      if (this.hideIcon || !this.item || !this.item.title) {
        return ''
      } else {
        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      }
    },
    Href () {
      return this.item.href || (!this.item.to ? '#' : undefined)
    }
  },
  methods: {
    Click () {
      this.$emit('click')
      if (this.$route && this.item.redirect && !(this.CommonMixin_HardCompare(`/${this.item.redirect.path}`, this.$route.path) && this.CommonMixin_HardCompare(this.item.redirect.query, this.$route.query))) {
        this.$router.replace(this.item.redirect).catch(() => {})
      }
    }
  }
}
</script>
