import Moment from 'moment'

export default {
  data () {
    return {
      calculationMixin: {
        finances: {
          btwAmount: 0,
          btwPercentage: 0,
          number: 0,
          totalExcl: 0,
          totalIncl: 0,
          unitPrice: 0
        }
      }
    }
  },
  computed: {
    CalculationMixin_Finances_BtwAmount () {
      return this.CalculationMixin_ValueOrDefault(this.calculationMixin.finances.btwAmount, 0)
    },
    CalculationMixin_Finances_BtwPercentage () {
      return this.CalculationMixin_ValueOrDefault(parseFloat(this.calculationMixin.finances.btwPercentage), 0)
    },
    CalculationMixin_Finances_Number () {
      return this.CalculationMixin_ValueOrDefault(this.calculationMixin.finances.number, 0)
    },
    CalculationMixin_Finances_NumberForDivision () {
      return this.CalculationMixin_ValueOrDefault(parseFloat(this.CalculationMixin_Finances_Number), 1)
    },
    CalculationMixin_Finances_TotalExcl () {
      return this.CalculationMixin_ValueOrDefault(this.calculationMixin.finances.totalExcl, this.calculationMixin.finances.unitPrice)
    },
    CalculationMixin_Finances_UnitPrice () {
      return this.CalculationMixin_ValueOrDefault(this.calculationMixin.finances.unitPrice, 0)
    }
  },
  methods: {
    CalculationMixin_Age (dateOfBirth, format = this.formatMixin.date.database) {
      return Moment().diff(Moment(dateOfBirth, format), 'years')
    },
    async CalculationMixin_Finances_CalculateBtwAmount () {
      this.calculationMixin.finances.btwAmount = this.CalculationMixin_Finances_Validate(this.CalculationMixin_Finances_TotalExcl * this.CalculationMixin_Finances_BtwPercentage)
    },
    async CalculationMixin_Finances_CalculateTotalExcl () {
      this.calculationMixin.finances.totalExcl = this.CalculationMixin_Finances_Validate(this.CalculationMixin_Finances_UnitPrice * this.CalculationMixin_Finances_Number)
    },
    async CalculationMixin_Finances_CalculateTotalIncl () {
      this.calculationMixin.finances.totalIncl = this.CalculationMixin_Finances_Validate(this.CalculationMixin_Finances_TotalExcl + this.CalculationMixin_Finances_BtwAmount)
    },
    async CalculationMixin_Finances_CalculateUnitPrice () {
      this.calculationMixin.finances.unitPrice = this.CalculationMixin_Finances_Validate((this.calculationMixin.finances.totalIncl - (this.CalculationMixin_Finances_BtwPercentage * this.calculationMixin.finances.totalIncl / (this.CalculationMixin_Finances_BtwPercentage + 1))) / this.CalculationMixin_Finances_NumberForDivision)
    },
    async CalculationMixin_Finances_ChangedBtwAmount (value) {
      this.calculationMixin.finances.btwAmount = this.CalculationMixin_Finances_Validate(value)
      this.CalculationMixin_Finances_CalculateTotalIncl()
      this.$forceUpdate()
    },
    async CalculationMixin_Finances_ChangedBtwPercentage (value) {
      this.calculationMixin.finances.btwPercentage = this.CalculationMixin_Finances_Validate(value)
      await this.CalculationMixin_Finances_CalculateBtwAmount()
      await this.CalculationMixin_Finances_CalculateTotalIncl()
      this.$forceUpdate()
    },
    async CalculationMixin_Finances_ChangedNumber (value) {
      this.calculationMixin.finances.number = this.CalculationMixin_Finances_Validate(value)
      await this.CalculationMixin_Finances_CalculateTotalExcl()
      await this.CalculationMixin_Finances_CalculateBtwAmount()
      await this.CalculationMixin_Finances_CalculateTotalIncl()
      this.$forceUpdate()
    },
    async CalculationMixin_Finances_ChangedTotalExcl (value) {
      this.calculationMixin.finances.totalExcl = this.CalculationMixin_Finances_Validate(value)
      await this.CalculationMixin_Finances_CalculateBtwAmount()
      await this.CalculationMixin_Finances_CalculateTotalIncl()
      this.$forceUpdate()
    },
    async CalculationMixin_Finances_ChangedTotalIncl (value) {
      this.calculationMixin.finances.totalIncl = this.CalculationMixin_Finances_Validate(value)
      await this.CalculationMixin_Finances_CalculateUnitPrice()
      await this.CalculationMixin_Finances_CalculateTotalExcl()
      await this.CalculationMixin_Finances_FixBtwAmount()
      this.$forceUpdate()
    },
    async CalculationMixin_Finances_ChangedUnitPrice (value) {
      this.calculationMixin.finances.unitPrice = this.CalculationMixin_Finances_Validate(value)
      await this.CalculationMixin_Finances_CalculateTotalExcl()
      await this.CalculationMixin_Finances_CalculateBtwAmount()
      await this.CalculationMixin_Finances_CalculateTotalIncl()
      this.$forceUpdate()
    },
    CalculationMixin_Finances_Reset () {
      this.calculationMixin.finances.btwAmount = 0
      this.calculationMixin.finances.btwPercentage = 0
      this.calculationMixin.finances.number = 0
      this.calculationMixin.finances.totalExcl = 0
      this.calculationMixin.finances.totalIncl = 0
      this.calculationMixin.finances.unitPrice = 0
    },
    CalculationMixin_Finances_Validate (value) {
      return isNaN(value) ? null : parseFloat(parseFloat(value).toFixed(2))
    },
    async CalculationMixin_Finances_FixBtwAmount () {
      this.calculationMixin.finances.btwAmount = this.CalculationMixin_Finances_Validate(this.calculationMixin.finances.totalIncl - this.CalculationMixin_Finances_TotalExcl)
    },
    CalculationMixin_ValueOrDefault (value, defaultValue) {
      return isNaN(value) ? defaultValue : (value ?? defaultValue)
    }
  }
}
