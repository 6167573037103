<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-families-detail-general-information
      :codes="contentMixin.codes"
      :posts="lists.posts"
      :readonly="readonly"
      :source="source"
    />
    <entity-card-families-detail-legal-family-representatives
      :codes="contentMixin.codes"
      :posts="lists.posts"
      :readonly="readonly"
      :source="source"
    />
    <entity-card-families-detail-situation
      :readonly="readonly"
      :source="source"
    />
    <entity-card-families-detail-financial-situation-list
      :name="$options.name"
      :readonly="readonly"
      :source="source"
    />
    <entity-card-families-detail-financial-situation
      :readonly="readonly"
      :source="source"
    />
    <entity-card-families-detail-extra-information
      :readonly="readonly"
      :source="source"
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardFamiliesDetailExtraInformation from '@/components/shared/entitycards/entity-card-families-detail-extra-information'
import EntityCardFamiliesDetailFinancialSituation from '@/components/shared/entitycards/entity-card-families-detail-financial-situation'
import EntityCardFamiliesDetailFinancialSituationList from '@/components/shared/entitycards/entity-card-families-detail-financial-situation-list'
import EntityCardFamiliesDetailGeneralInformation from '@/components/shared/entitycards/entity-card-families-detail-general-information'
import EntityCardFamiliesDetailLegalFamilyRepresentatives from '@/components/shared/entitycards/entity-card-families-detail-legal-family-representatives'
import EntityCardFamiliesDetailSituation from '@/components/shared/entitycards/entity-card-families-detail-situation'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'

export default {
  name: 'FamiliesDetail',
  components: {
    BaseFormDetail,
    EntityCardFamiliesDetailExtraInformation,
    EntityCardFamiliesDetailFinancialSituation,
    EntityCardFamiliesDetailFinancialSituationList,
    EntityCardFamiliesDetailGeneralInformation,
    EntityCardFamiliesDetailLegalFamilyRepresentatives,
    EntityCardFamiliesDetailSituation
  },
  mixins: [
    BaseFormDetailMixin
  ],
  data () {
    return {
      controller: 'Family',
      lists: {
        posts: []
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.ContentMixin_Service_BC_InitializeCodes()
      this.lists.posts = await this.ContentMixin_Service_BC_PickSource('Post')
    }
  }
}
</script>
