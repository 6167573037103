<template>
  <div
    class="pa-5"
  >
    <base-card
      no-padding
      :title="TextMixin_GetTitle('unprocessed', TextMixin_GetParameter('bankStatements').toLowerCase())"
    >
      <base-list-overview
        :add-action="false"
        :controller="SubcontrollerPath"
        :double-click-edit="false"
        :edit-action="false"
        :filter="unprocessed"
        inside-card
        name="Unprocessed"
        :visible="ShowOverview"
      >
        <template
          slot="footer-prepend-2"
        >
          <v-btn
            color="secondary"
            outlined
            @click="UploadBankStatements()"
          >
            {{ TextMixin_GetLabel("upload", [TextMixin_GetParameter('bankStatements'), `(${TextMixin_GetParameter('zip')})`]) }}
          </v-btn>
          <v-btn
            class="ml-1"
            color="secondary"
            outlined
            @click="ProcessBankStatements()"
          >
            {{ TextMixin_GetLabel("process") }}
          </v-btn>
        </template>
      </base-list-overview>
    </base-card>
    <base-card
      no-padding
      :title="TextMixin_GetTitle('processed', TextMixin_GetParameter('bankStatements').toLowerCase())"
    >
      <base-list-overview
        :controller="SubcontrollerPath"
        :double-click-edit="false"
        hide-actions
        inside-card
        name="Processed"
        :visible="ShowOverview"
      />
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/shared/base/base-card'
import BaseListOverview from '@/components/shared/base/base-list-overview'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'BankStatementsOverview',
  components: {
    BaseCard,
    BaseListOverview
  },
  mixins: [
    ContentMixin
  ],
  data () {
    return {
      controller: 'BankStatement',
      subcontroller: 'Coda',
      showOverview: true,
      unprocessed: [
        {
          key: 'processed',
          value: false
        }
      ]
    }
  },
  computed: {
    ShowOverview () {
      return this.visible && this.showOverview
    },
    SubcontrollerPath () {
      return `${this.controller}/${this.subcontroller}`
    }
  },
  mounted () {
    this.ContentMixin_Service_BC_InitializeContentService(this.controller)
  },
  methods: {
    async ProcessBankStatements () {
      const methodName = 'ProcessBankStatementCodas'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      this.showOverview = false
      await this.ContentMixin_Service_Process()
      this.showOverview = true
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async UploadBankStatements () {
      var input = document.createElement('input')
      input.type = 'file'
      input.accept = [
        'application/x-zip-compressed',
        'application/x-gzip-compressed',
        'application/x-compressed'
      ]
      input.multiple = true

      input.onchange = async e => {
        var files = e.target.files
        const methodName = 'UploadBankStatementCodas'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        this.showOverview = false

        for (let index = 0; index < files.length; index++) {
          const file = files[index]
          await this.ContentMixin_Service_Upload(file)
        }

        this.showOverview = true
        this.FrameworkMixin_Load(this.$options.name, methodName, false)
      }

      input.click()
    }
  }
}
</script>
