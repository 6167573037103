<template>
  <v-tabs
    v-if="Tabs.length > 1"
    v-model="ContentMixin_Store_SelectedTab"
    :active-class="ActiveClass"
    class="pa-1"
    :class="ClassVTabs"
    :fixed-tabs="!dynamicWidth"
    :grow="dynamicWidth"
    :height="Height"
    hide-slider
    show-arrows
    :style="StyleVTabs"
    :vertical="vertical"
  >
    <v-tab
      v-for="(tab, index) in Tabs"
      :key="index"
      class="ma-1"
      :class="ClassVTab"
      :disabled="tab.disabled"
      :style="StyleVTab"
    >
      {{ tab.name }}
    </v-tab>
  </v-tabs>
</template>

<script>
import BaseTabsViewMixin from '@/mixins/base-tabs-view-mixin'

export default {
  name: 'BaseTabs',
  mixins: [
    BaseTabsViewMixin
  ],
  props: {
    // Is the width of the tabs dynamic or are they all equal to each other?
    dynamicWidth: {
      default: true,
      type: Boolean
    },
    // Level of tabs
    level: {
      default: 0,
      type: Number
    },
    // Show tabs vertically
    vertical: Boolean
  },
  computed: {
    ActiveClass () {
      return this.level === 0 ? 'primary' : this.level === 1 ? 'secondary' : ''
    },
    ClassVTab () {
      return this.level > 0 ? 'mt-0' : ''
    },
    ClassVTabs () {
      return this.level > 0 ? 'pt-0' : ''
    },
    Height () {
      return this.level === 1 ? '35' : ''
    },
    StyleVTabs () {
      const base = 'background-color: white; z-index: 3;'

      if (this.level === 0) {
        return `${base} position: sticky; top: 60px;`
      } else if (this.level === 1) {
        return base
      } else {
        return base
      }
    },
    StyleVTab () {
      const base = 'background-color: white;'

      if (this.level === 0) {
        return `${base} border: 2px solid #EA3326; color: #EA3326;`
      } else if (this.level === 1) {
        return `${base} border: 2px solid #123958; color: #123958;`
      } else {
        return ''
      }
    },
    Tabs () {
      return this.tabs ? this.tabs.filter(tab => this.AuthenticationMixin_HasRights(tab)) : []
    }
  },
  watch: {
    tabs (value) {
      if (this.value > value.length - 1) {
        this.ContentMixin_Store_SelectedTab = 0
      }
    }
  }
}
</script>

<style
  scoped
>
.v-tab {
  max-width: unset;
}

.v-tab--disabled {
  opacity: 0.3;
}
</style>
