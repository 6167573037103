<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.date"
        v-model="EntityCardMixin_CopySource.date"
        base-field-container-type="v-col"
        base-field-type="date"
        disabled
        :label="TextMixin_GetLabel('date')"
        readonly
      />
      <base-field
        v-if="EntityCardMixin_CopySource.name"
        v-model="EntityCardMixin_CopySource.name"
        base-field-container-type="v-col"
        base-field-type="text"
        disabled
        :label="TextMixin_GetLabel('name')"
        readonly
      />
      <base-field
        v-if="EntityCardMixin_CopySource.ibanNumber"
        v-model="EntityCardMixin_CopySource.ibanNumber"
        base-field-container-type="v-col"
        base-field-type="text"
        disabled
        :label="TextMixin_GetLabel('ibanNumber')"
        readonly
      />
      <base-field
        v-if="EntityCardMixin_CopySource.itemCost"
        v-model="EntityCardMixin_CopySource.itemCost"
        base-field-container-type="v-col"
        base-field-type="number"
        :base-field-class="`v-input--is-disabled ${ItemCostColor}--text`"
        :color="ItemCostColor"
        :decimals="2"
        :icon="ItemCostIcon"
        :label="TextMixin_GetLabel('amount')"
        readonly
      />
    </v-row>
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.description"
        v-model="EntityCardMixin_CopySource.description"
        base-field-container-type="v-col"
        base-field-type="textarea"
        disabled
        :label="TextMixin_GetLabel('description')"
        lg="6"
        readonly
      />
      <base-field
        v-if="EntityCardMixin_CopySource.descriptionStructured"
        v-model="EntityCardMixin_CopySource.descriptionStructured"
        base-field-container-type="v-col"
        base-field-type="textarea"
        disabled
        :label="TextMixin_GetLabel('descriptionStructured')"
        lg="6"
        readonly
      />
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardBankStatementsDetailGeneralInformation',
  mixins: [
    EntityCardMixin
  ],
  computed: {
    ItemCostColor () {
      return this.EntityCardMixin_CopySource.incoming.value ? 'green' : 'red'
    },
    ItemCostIcon () {
      return `mdi-arrow-${this.EntityCardMixin_CopySource.incoming.value ? 'down' : 'up'}`
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('generalInformation')
    },
    ReadonlyField (field) {
      let readonly = this.EntityCardMixin_Readonly

      if (!readonly && this.source === 'dialog' && this.ContentMixin_Store_DetailSourceParameters) {
        readonly = !!this.ContentMixin_Store_DetailSourceParameters.filter(x => x.key === `id${field}`)
      }

      return readonly
    }
  }
}
</script>

<style>
.green--text.theme--light.v-input * :not(label) {
  color: green !important;
}

.red--text.theme--light.v-input * :not(label) {
  color: red !important;
}
</style>
