<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
  >
    <v-row>
      <base-field
        v-if="ContentMixin_ElementHasPocoStatus('new') && EntityCardMixin_CopySource.from"
        v-model="EntityCardMixin_CopySource.from"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('mailFrom')"
        lg="12"
        md="12"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-else-if="EntityCardMixin_CopySource.from"
        v-model="EntityCardMixin_CopySource.from"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('mailFrom')"
        lg="12"
        md="12"
        :readonly="!ContentMixin_ElementHasPocoStatus('new') || EntityCardMixin_Readonly"
      />
      <v-col
        lg="12"
        md="12"
      >
        <v-combobox
          v-model="mailToList"
          chips
          :clearable="ContentMixin_ElementHasPocoStatus('new') "
          :label="TextMixin_GetLabel('mailTo')"
          multiple
          :readonly="!ContentMixin_ElementHasPocoStatus('new') || EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.eMail, validationMixin.rules.required]"
          variant="solo"
          @change="InputTo($event)"
        />
      </v-col>
      <v-col
        lg="12"
        md="12"
      >
        <v-combobox
          v-model="mailCcList"
          chips
          :clearable="ContentMixin_ElementHasPocoStatus('new') "
          :label="TextMixin_GetLabel('mailCc')"
          multiple
          :readonly="!ContentMixin_ElementHasPocoStatus('new') || EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.eMail]"
          variant="solo"
          @change="InputCc($event)"
        />
      </v-col>
      <v-col
        lg="12"
        md="12"
      >
        <v-combobox
          v-if="ContentMixin_ElementHasPocoStatus('new')"
          v-model="mailBccList"
          chips
          :clearable="ContentMixin_ElementHasPocoStatus('new') "
          :label="TextMixin_GetLabel('mailBcc')"
          multiple
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.eMail]"
          variant="solo"
          @change="InputBcc($event)"
        />
      </v-col>
      <base-field
        v-if="EntityCardMixin_CopySource.subject"
        v-model="EntityCardMixin_CopySource.subject"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('subject')"
        lg="12"
        md="12"
        :readonly="!ContentMixin_ElementHasPocoStatus('new') || readonly"
        :rules="[validationMixin.rules.required]"
      />
      <v-btn
        v-if="ContentMixin_ElementHasPocoStatus('new')"
        class="mx-3 mb-3"
        @click="AddAttachments()"
      >
        {{ TextMixin_GetLabel('add', TextMixin_GetParameter('attachments')) }} ({{ EntityCardMixin_CopySource.attachments ? EntityCardMixin_CopySource.attachments.length : 0 }})
      </v-btn>
      <v-chip
        v-for="(attachment, index) in AttachmentList"
        class="mx-1 my-1"
        :close="ContentMixin_ElementHasPocoStatus('new')"
        :key="index"
        @click="DownloadAttachment(attachment)"
        @click:close="RemoveAttachment(index)"
      >
        {{ attachment.name.value }}
      </v-chip>
    </v-row>
    <pre
      v-if="!ContentMixin_ElementHasPocoStatus('new')"
      class="pa-2 mailPreviewBox"
      v-html="EntityCardMixin_CopySource.body.value"
    />
    <base-html-editor
      v-else
      v-model="EntityCardMixin_CopySource.body.value"
    />
  </base-card>
</template>

<script>
import BaseHtmlEditor from '@/components/shared/base/base-html-editor'
import BlobMixin from '@/mixins/blob-mixin'
import ConstantMixin from '@/mixins/constant-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardEMailDetail',
  components: {
    BaseHtmlEditor
  },
  mixins: [
    BlobMixin,
    ConstantMixin,
    EntityCardMixin
  ],
  data () {
    return {
      from: {
        value: null,
        meta: {
          memberRight: 3
        }
      },
      mailBccList: [],
      mailCcList: [],
      mailToList: []
    }
  },
  computed: {
    AttachmentList () {
      return (this.EntityCardMixin_CopySource.attachments ?? [])// .filter(x => !x.isInline)
    }
  },
  watch: {
    'EntityCardMixin_CopySource' () {
      this.Initialize()
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async AddAttachments () {
      const input = document.createElement('input')
      input.type = 'file'
      input.multiple = true

      input.onchange = e => {
        const attachments = Array.from(e.target.files)
        const attachmentList = this.CommonMixin_Clone(this.EntityCardMixin_CopySource.attachments) ?? []
        attachments.forEach(file => {
          const attachment = {
            file: file,
            name: {
              value: file.name
            },
            size: file.size
          }

          attachmentList.push(attachment)
        })
        const sizes = attachmentList.map(x => x.size)
        const totalSize = sizes.reduce((partialSum, x) => partialSum + x, 0)
        const maxFileSize = 20
        if (totalSize / (1024 * 1024) > maxFileSize) {
          alert(this.TextMixin_GetMessage('maxFileSize', maxFileSize))
          return
        }

        this.EntityCardMixin_CopySource.attachments = attachmentList
      }

      input.click()
    },
    async DownloadAttachment (attachment) {
      this.BlobMixin_DownloadById(attachment.idBlob.value, attachment.name.value)
    },
    Initialize () {
      if (this.ContentMixin_ElementHasPocoStatus('new')) {
        this.EntityCardMixin_CopySource.attachments = []

        console.log(this.EntityCardMixin_CopySource)
      } else {
        // this.loggedInEmail.value = this.AuthenticationMixin_CurrentUser.email
        // this.EntityCardMixin_CopySource.from.value = this.AuthenticationMixin_CurrentUser.email
      }

      this.SetTo(this.EntityCardMixin_CopySource.to.value)
      this.SetCc(this.EntityCardMixin_CopySource.cc.value)
      this.SetBcc(this.EntityCardMixin_CopySource.bcc.value)
    },
    InputBcc (input) {
      this.EntityCardMixin_CopySource.bcc.value = input.toString()
    },
    InputCc (input) {
      this.EntityCardMixin_CopySource.cc.value = input.toString()
    },
    InputTo (input) {
      this.EntityCardMixin_CopySource.to.value = input.toString()
    },
    RemoveAttachment (index) {
      this.EntityCardMixin_CopySource.attachments.splice(index, 1)
    },
    SetBcc (input) {
      this.mailBccList = input?.length > 0 ? input.split(',') : []
    },
    SetCc (input) {
      this.mailCcList = input?.length > 0 ? input.split(',') : []
    },
    SetTo (input) {
      this.mailToList = input?.length > 0 ? input.split(',') : []
    }
  }
}
</script>

<style>
.ck-editor__editable_inline {
  min-height: 300px;
}

.mailPreviewBox {
  border: 1px solid grey;
  width: 100%;
  min-height: 300px;
  overflow-x: auto;
  overflow-wrap: break-word;
}
</style>
