<template>
  <base-list-overview
    controller="UserLog"
    :double-click-edit="false"
    :edit-action="false"
    :visible="visible"
  />
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'UserManagementLoginHistory',
  components: {
    BaseListOverview
  },
  mixins: [
    ContentMixin
  ]
}
</script>
