<template>
  <base-tabs-view
    :tabs="Tabs"
  />
</template>

<script>
import BaseTabsView from '@/components/shared/base/base-tabs-view'
import BaseTabsViewMixin from '@/mixins/base-tabs-view-mixin'

export default {
  name: 'Monitors',
  components: {
    BaseTabsView
  },
  mixins: [
    BaseTabsViewMixin
  ],
  computed: {
    Tabs () {
      return [
        {
          component: 'monitors-overview',
          idComponent: this.constantMixin.menuItem.monitors.general,
          name: this.TextMixin_GetTab('overview')
        },
        {
          component: 'monitors-detail',
          disabled: !this.ContentMixin_Element,
          idComponent: this.constantMixin.menuItem.monitors.general,
          name: this.TextMixin_GetTab(this.BaseTabsViewMixin_GetDetailsTab(), this.TextMixin_GetParameter('monitor'))
        },
        {
          component: 'monitors-children',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.monitors.children,
          name: this.TextMixin_GetTab('children')
        },
        {
          component: 'monitors-documents',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.monitors.documents,
          name: this.TextMixin_GetTab('documents')
        },
        {
          component: 'monitors-requests',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.monitors.requests,
          name: this.TextMixin_GetTab('requests')
        }
      ]
    }
  }
}
</script>
