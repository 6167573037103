<template>
  <base-card
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <template
        v-if="EntityCardMixin_Picker"
      >
        <base-picker
          v-if="entityCardMixin.pickers.components[0]"
          v-model="entityCardMixin.pickers.components[0]"
          base-field-container-type="v-col"
          :items="carePartners"
          :label="TextMixin_GetLabel('carePartner')"
          :rules="pickers[0].rules"
          @pickers-create="$emit('pickers-create', $event)"
          @pickers-read="$emit('pickers-read', $event)"
          @pickers-update="$emit('pickers-update', $event)"
        />
      </template>
      <template
        v-if="EntityCardMixin_CopySource"
      >
        <base-field
          v-if="!EntityCardMixin_Picker && EntityCardMixin_CopySource.name"
          v-model="EntityCardMixin_CopySource.name"
          base-field-container-type="v-col"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('name')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.required, validationMixin.rules.charactersMax(EntityCardMixin_CopySource.name.value, 255)]"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.status"
          v-model="EntityCardMixin_CopySource.status"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('status')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.type"
          v-model="EntityCardMixin_CopySource.type"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('type')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.isActive"
          v-model="EntityCardMixin_CopySource.isActive"
          base-field-container-type="v-col"
          base-field-type="checkbox"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('active')"
          :readonly="EntityCardMixin_Readonly"
        />
      </template>
    </v-row>
    <template
      v-if="EntityCardMixin_CopySource"
    >
      <v-row>
        <base-field
          v-if="EntityCardMixin_CopySource.juridicalStatus"
          v-model="EntityCardMixin_CopySource.juridicalStatus"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('juridicalStatus')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.educationalNetwork"
          v-model="EntityCardMixin_CopySource.educationalNetwork"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('educationalNetwork')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.region"
          v-model="EntityCardMixin_CopySource.region"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('region')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.allowPublicKnown"
          v-model="EntityCardMixin_CopySource.allowPublicKnown"
          base-field-container-type="v-col"
          base-field-type="checkbox"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('publiclyKnown')"
          :readonly="EntityCardMixin_Readonly"
        />
      </v-row>
    </template>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardCarePartnersDetailGeneralInformation',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('generalInformation')
    }
  }
}
</script>
