<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row
      v-for="(item, index) in CarePartners"
      :key="index"
    >
      <base-field
        v-if="item.carePartner"
        v-model="item.carePartner"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        :disabled="EntityCardMixin_Disabled"
        ignore-default-event-handler
        :items="carePartners"
        :label="`${TextMixin_GetLabel('carePartner')} ${index + 1}`"
        :readonly="EntityCardMixin_Readonly"
        @change="ContentMixin_HandleSelectWithPocoStatusChange(item, 'carePartner', $event, carePartners)"
      />
      <base-field
        v-if="item.function"
        v-model="item.function"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        :disabled="EntityCardMixin_Disabled"
        ignore-default-event-handler
        :items="ContentMixin_Codes(item.function)"
        :label="`${TextMixin_GetLabel('function', TextMixin_GetParameter('carePartner').toLowerCase())} ${index + 1}`"
        :readonly="EntityCardMixin_Readonly"
        @change="ContentMixin_HandleSelectWithPocoStatusChange(item, 'function', $event)"
      />
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardMonitorsDetailCarePartners',
  mixins: [
    EntityCardMixin
  ],
  computed: {
    CarePartners () {
      return this.EntityCardMixin_CopySource?.carePartners?.items
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('carePartners')
    }
  }
}
</script>
