<template>
  <div
    class="base-list-treeview-container pa-5"
  >
    <v-row
      class="pa-0"
    >
      <v-col
        class="base-list-treeview-container"
        cols="12"
        lg="3"
        md="3"
      >
        <base-card
          card-class="base-list-treeview-base-card"
          hide-title
        >
          <v-treeview
            activatable
            :active.sync="active"
            class="base-list-treeview-treeview"
            color="primary"
            :item-children="childrenProperty"
            :item-key="keyProperty"
            :item-text="TextProperty"
            :items="items"
            :open.sync="open"
            return-object
            transition
            @update:active="TreeviewUpdateActive($event)"
          >
            <template
              #prepend="{ open }"
            >
              <slot
                name="treeview-prepend"
                :open="open"
              />
            </template>
          </v-treeview>
        </base-card>
      </v-col>
      <v-col
        class="base-list-treeview-container"
        cols="12"
        lg="9"
        md="9"
      >
        <base-card
          card-class="base-list-treeview-base-card mt-3 pa-0"
          card-class-override
          hide-title
        >
          <slot
            name="overview"
          />
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'BaseListTreeview',
  mixins: [
    EntityCardMixin
  ],
  props: {
    activeItem: {
      default: () => {},
      type: Object
    },
    items: {
      default: () => [],
      type: Array
    },
    childrenProperty: {
      default: 'children',
      type: String
    },
    keyProperty: {
      default: 'key',
      type: String
    },
    textProperty: {
      default: 'text',
      type: String
    }
  },
  data () {
    return {
      active: [],
      open: []
    }
  },
  computed: {
    TextProperty () {
      return `${this.textProperty}.value`
    }
  },
  watch: {
    activeItem (item) {
      this.active = []
      this.active.push(item)

      if (item && !this.open.includes(item)) {
        this.open.push(item)
      }
    }
  },
  methods: {
    TreeviewUpdateActive (activeItem) {
      const item = activeItem ? activeItem[0] : null
      this.$emit('active-change', item)
    }
  }
}
</script>

<style scoped>
  .base-list-treeview-container {
    height: calc(100vh - 120px);
    position: relative;
  }

  .base-list-treeview-treeview {
    width: fit-content;
  }
</style>

<style>
  .base-list-treeview-base-card {
    height: calc(100vh - 180px) !important;
    overflow-x: scroll;
    overflow-y: auto;
  }
</style>
