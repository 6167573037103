import Axios from 'axios'

let _svc

export default {
  ChangeLanguage (idCodeLanguage) {
    try {
      return _svc.get(`Authentication/ChangeLanguage?IdLanguage=${idCodeLanguage}`)
    } catch {
      return null
    }
  },
  Init (errorHandler) {
    _svc = Axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        errorHandler(error)
      }
    )
  },
  Login (account) {
    try {
      return _svc.post('Authentication/Login', account)
    } catch {
      return null
    }
  },
  Menu () {
    try {
      return _svc.get('Authentication/Menu/MenuItemIds')
    } catch {
      return null
    }
  },
  RecoverPasswordSendMail (username) {
    try {
      return _svc.post(`Authentication/RecoverPassword/SendMail?EmailAddress=${username}`)
    } catch {
      return null
    }
  },
  RecoverPasswordUpdatePassword (account, token) {
    try {
      return _svc.post(`Authentication/RecoverPassword/UpdatePassword?Emailaddress=${account.username}&NewPassword=${encodeURIComponent(account.password)}&RecoverPasswordToken=${encodeURIComponent(token)}`)
    } catch {
      return null
    }
  },
  RecoverPasswordValidateToken (token) {
    try {
      return _svc.get(`Authentication/RecoverPassword/ValidateToken?RecoverPasswordToken=${encodeURIComponent(token)}`)
    } catch {
      return null
    }
  }
}
