import BaseCard from '@/components/shared/base/base-card'
import BaseField from '@/components/shared/base/base-field'
import BasePicker from '@/components/shared/base/base-picker'
import ContentMixin from '@/mixins/content-mixin'

export default {
  components: {
    BaseCard,
    BaseField,
    BasePicker
  },
  mixins: [
    ContentMixin
  ],
  props: {
    // List of codes needed for autocompletes
    codes: Array,
    // List of carePartners needed for autocompletes
    carePartners: Array,
    // List of children needed for autocompletes
    children: Array,
    // Disable controls
    disabled: Boolean,
    // List of families needed for autocompletes
    families: Array,
    // Hide Title
    hideTitle: Boolean,
    // List of monitors needed for autocompletes
    monitors: Array,
    // Is picker
    pickers: Array,
    // Disable pickers
    pickersDisabled: Boolean,
    // Make pickers readonly
    pickersReadonly: Boolean,
    // List of posts needed for autocompletes
    posts: Array,
    // Make controls readonly
    readonly: Boolean,
    // Path of the actual source
    overrideSourcePath: Array,
    // The overline title
    title: String,
    // List of userGroups needed for autocompletes
    userGroups: Array,
    // List of users needed for autocompletes
    users: Array,
    // List of userTypes needed for autocompletes
    userTypes: Array
  },
  data () {
    return {
      entityCardMixin: {
        defaultTitle: null,
        dialogDetail: {
          component: null,
          element: null,
          show: false,
          title: null,
          type: null
        },
        pickers: {
          components: [],
          initialized: false
        }
      }
    }
  },
  computed: {
    EntityCardMixin_CopySource () {
      return this.childMixin.element ?? this.ContentMixin_Store_DetailSourceCopy
    },
    EntityCardMixin_Disabled () {
      return this.readonly || this.picker
    },
    EntityCardMixin_Picker () {
      return this.pickers && this.pickers.length > 0
    },
    EntityCardMixin_Readonly () {
      return this.readonly || this.picker
    },
    EntityCardMixin_Title () {
      return this.hideTitle ? null : (this.title ? this.title : this.entityCardMixin.defaultTitle ? this.TextMixin_GetTitle(this.entityCardMixin.defaultTitle.title, this.entityCardMixin.defaultTitle.parameters) : null)
    }
  },
  watch: {
    codes (value) {
      this.contentMixin.codes = value
    },
    'entityCardMixin.pickers.components': {
      handler (value) {
        if (!this.CommonMixin_HardCompare(value, this.pickers) || !this.entityCardMixin.pickers.initialized) {
          for (let index = 0; index < this.pickers.length; index++) {
            if (value[index] && this.pickers[index] && !this.CommonMixin_HardCompare(value[index].value, this.pickers[index].value)) {
              // Fire when pickers changed
              // @arg pickers
              this.$emit('pickers-change', {
                picker: this.pickers[index],
                value: value[index]
              })
            }
          }

          this.entityCardMixin.pickers.initialized = true
        }
      },
      deep: true
    },
    pickers: {
      handler () {
        this.EntityCardMixin_Initialize()
      },
      deep: true
    }
  },
  mounted () {
    this.EntityCardMixin_Initialize()
  },
  methods: {
    EntityCardMixin_Initialize () {
      if (this.codes) {
        this.contentMixin.codes = this.codes
      }

      if (this.pickers) {
        this.entityCardMixin.pickers.components = this.CommonMixin_Clone(this.pickers)
        this.$forceUpdate()
      }
    },
    EntityCardMixin_InputFieldIsDisabled (inputField) {
      return this.AuthenticationMixin_InputFieldIsDisabled(inputField) || this.entityCardMixin.disabled
    },
    EntityCardMixin_InputFieldIsReadonly (inputField) {
      return this.AuthenticationMixin_InputFieldIsReadonly(inputField) || this.entityCardMixin.readonly
    },
    EntityCardMixin_InputFieldIsShown (inputField) {
      return this.AuthenticationMixin_InputFieldIsShown(inputField)
    },
    async EntityCardMixin_ListItemAdd (property) {
      const response = await this.ContentMixin_Service_NewDetailForParent(this.type, this.ContentMixin_ElementId())
      if (response && response.data) {
        const item = this.CommonMixin_Clone(response.data)
        this.EntityCardMixin_CopySource[property].items.push(item)
      }
    },
    async EntityCardMixin_ListItemDelete (property, id) {
      const item = this.EntityCardMixin_CopySource[property].items.filter(x => x.id === id)[0]
      if (this.CommonMixin_Confirmation(true, this.TextMixin_GetMessage('confirmationDelete', [this.type]))) {
        if (!this.ContentMixin_ElementHasPocoStatus('new', item)) {
          item.pocoStatus = this.constantMixin.pocoStatus.deleted
        } else {
          this.CommonMixin_ArrayRemoveElement(this.EntityCardMixin_CopySource[property].items, item)
        }
      }
    },
    EntityCardMixin_Pickers (isDataProperty, controller, property, listProperty, detailComponent, readonly, disabled, enableCreate, enableEdit, enableRead, rules, parameters) {
      return {
        controller: controller,
        detailComponent: detailComponent,
        disabled: disabled ?? false,
        enableCreate: enableCreate ?? false,
        enableEdit: enableEdit ?? false,
        enableRead: enableRead ?? false,
        isDataProperty: isDataProperty,
        listProperty: listProperty ?? property,
        parameters: parameters,
        property: property,
        readonly: readonly ?? false,
        rules: rules ?? [],
        value: this.EntityCardMixin_CopySource ? this.EntityCardMixin_CopySource[property] : null
      }
    },
    async EntityCardMixin_PickersChange (value) {
      const methodName = `EntityCardMixin_PickersChange(${value.picker.property})`
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      this.ContentMixin_HandlePickersChange(this.EntityCardMixin_CopySource[value.picker.property], value.value.value)
      await this.EntityCardMixin_PickersGetDetails(value.picker, value.value.value.value)
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async EntityCardMixin_PickersClear (picker) {
      const parameter = {
        picker: picker,
        value: {
          value: {
            text: '',
            value: null
          }
        }
      }

      this.EntityCardMixin_PickersChange(parameter)
    },
    async EntityCardMixin_PickersClose () {
      this.entityCardMixin.dialogDetail.show = false
    },
    async EntityCardMixin_PickersCreate (picker) {
      this.EntityCardMixin_PickersShowDialog(picker, 'create', false, null)
    },
    async EntityCardMixin_PickersGetDetails (picker, value) {
      const methodName = `EntityCardMixin_PickersGetDetails(${picker.property})`
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const result = await this.ContentMixin_Service_BC_DetailBasic(value, picker.controller)

      if (result && result.data) {
        if (picker && picker.isDataProperty) {
          this.details[picker.property] = this.CommonMixin_HardCompare(result.data, {}) ? null : result.data
        } else {
          this.EntityCardMixin_CopySource[`${picker.property}Detail`] = this.CommonMixin_HardCompare(result.data, {}) ? null : result.data
        }
      }

      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async EntityCardMixin_PickersInitialize (pickers, specificPicker) {
      for (const property in pickers) {
        if (!specificPicker || property === specificPicker) {
          const picker = this.Pickers[property]
          if (picker && picker.value && !this.CommonMixin_HardCompare(picker.value.value, {})) {
            this.EntityCardMixin_PickersChange({
              picker: picker,
              value: picker
            })
          }
        }
      }
    },
    async EntitiCardMixin_PickersInitializeValue (pickers, parameters) {
      if (parameters) {
        for (const property in pickers) {
          const picker = pickers[property]
          const idProperty = `id${property.charAt(0).toUpperCase()}${property.slice(1)}`
          await this.EntityCardMixin_PickersChange({
            picker: picker,
            value: {
              value: {
                text: '',
                value: parameters.find(obj => obj.key === idProperty)?.value
              }
            }
          })
        }
      }
    },
    async EntityCardMixin_PickersRead (picker) {
      this.EntityCardMixin_PickersShowDialog(picker, 'read', true)
    },
    async EntityCardMixin_PickersSave (picker) {
      this.lists[picker.listProperty] = await this.ContentMixin_Service_BC_PickSource(picker.controller)
      this.entityCardMixin.dialogDetail.show = false
      const detail = picker.isDataProperty ? this.details[picker.property] : this.EntityCardMixin_CopySource[`${picker.property}Detail`]

      if (detail) {
        this.EntityCardMixin_PickersGetDetails(picker, detail.id)
      }
    },
    async EntityCardMixin_PickersShowDialog (picker, title, readonly = false, id = picker.value?.value) {
      this.entityCardMixin.dialogDetail.title = title
      this.entityCardMixin.dialogDetail.component = picker.detailComponent
      this.entityCardMixin.dialogDetail.element = await this.ContentMixin_Service_BC_DetailDialog(id, picker.controller)
      this.entityCardMixin.dialogDetail.readonly = readonly
      this.entityCardMixin.dialogDetail.type = picker.property
      this.entityCardMixin.dialogDetail.parameters = picker.parameters
      this.entityCardMixin.dialogDetail.show = true
    },
    async EntityCardMixin_PickersUpdate (picker, listRow, property) {
      if (listRow) {
        this.EntityCardMixin_PickersShowDialog(picker, 'update', false, listRow[property ?? `id${picker.controller}`])
      } else {
        this.EntityCardMixin_PickersShowDialog(picker, 'update')
      }
    },
    EntityCardMixin_SetDefaultTitle (title, parameters) {
      this.entityCardMixin.defaultTitle = {
        title: title,
        parameters: parameters
      }
    },
    EntityCardMixin_ShownOptions (options, property) {
      return options ? options.filter(option => option && option[property] && this.EntityCardMixin_InputFieldIsShown(option[property])) : null
    }
  }
}
