// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import App from '@/App'
import Vue from 'vue'
import BaseDebugger from '@/components/shared/base/base-debugger'
import Emitter from 'tiny-emitter'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import VueCookie from 'vue-cookie'
import VueMask from 'v-mask'
import '@/globals'
import '@/plugins/base'
import '@/plugins/chartist'
import '@/plugins/vee-validate'
import AuthenticationMixin from '@/mixins/authentication-mixin'
import CacheMixin from '@/mixins/cache-mixin'
import CalculationMixin from '@/mixins/calculation-mixin'
import CommonMixin from '@/mixins/common-mixin'
import ConstantMixin from '@/mixins/constant-mixin'
import DebugMixin from '@/mixins/debug-mixin'
import ErrorMixin from '@/mixins/error-mixin'
import FormatMixin from '@/mixins/format-mixin'
import FrameworkMixin from '@/mixins/framework-mixin'
import TextMixin from '@/mixins/text-mixin'
import ValidationMixin from '@/mixins/validation-mixin'
import { createPinia, PiniaVuePlugin } from 'pinia'

const pinia = createPinia()
Vue.config.productionTip = false
Vue.prototype.$msalInstance = {}
Vue.prototype.$emitter = new Emitter()
Vue.component('BaseDebugger', BaseDebugger)
Vue.use(PiniaVuePlugin)
Vue.use(VueMask)
Vue.use(VueCookie)
Vue.mixin(AuthenticationMixin)
Vue.mixin(CacheMixin)
Vue.mixin(CalculationMixin)
Vue.mixin(CommonMixin)
Vue.mixin(ConstantMixin)
Vue.mixin(DebugMixin)
Vue.mixin(ErrorMixin)
Vue.mixin(FormatMixin)
Vue.mixin(FrameworkMixin)
Vue.mixin(TextMixin)
Vue.mixin(ValidationMixin)

new Vue({
  pinia,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
