<template>
  <div>
    <v-app-bar
      app
      fixed
      flat
      height="60"
      style="background-color: white; border-bottom: 1px solid rgba(0, 0, 0, 0.12); overflow: hidden;"
    >
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <v-tooltip
            v-if="AuthenticationMixin_LoggedIn"
            bottom
          >
            <template
              #activator="{ on, attrs }"
            >
              <v-icon
                v-bind="attrs"
                class="clickAction float-right ml-3"
                :class="UserIconClass"
                large
                v-on="on"
              >
                mdi-account
              </v-icon>
            </template>
            <span>
              {{ AuthenticationMixin_CurrentUser?.naam }}
            </span>
          </v-tooltip>
          <v-select
            v-if="ShowLanguageSelect"
            :value="FrameworkMixin_Language"
            class="float-right"
            :class="ToolbarTitleClass"
            color="primary"
            dense
            item-text="name"
            item-value="abbreviation"
            :items="textMixin.languages"
            :label="TextMixin_GetLabel('language')"
            outlined
            style="max-width: 150px;"
            @change="TextMixin_SetLanguage($event, true, true)"
          />
          <div
            class="d-flex mt-6"
          >
            <v-btn
              class="mr-3"
              elevation="1"
              fab
              small
              @click="FrameworkMixin_ToggleSidebar()"
            >
              <v-icon>
                mdi-{{ value ? 'view-quilt' : 'dots-vertical' }}
              </v-icon>
            </v-btn>
            <v-toolbar-title
              class="darkMode font-weight-light hidden-sm-and-down"
              style="margin-top: 6px;"
            >
              {{ ToolbarTitle }}
            </v-toolbar-title>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'FrameworkTopbar',
  props: {
    // Page name in textMixin
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ShowLanguageSelect () {
      return this.textMixin.languages.length > 1
    },
    ToolbarTitle () {
      const parts = this.$route.name.split('_')
      let current = ''
      let title = ''

      for (let index = 0; index < parts.length; index++) {
        current += `${current ? '_' : ''}${parts[index]}`
        title += `${title ? ': ' : ''}${this.TextMixin_GetPage(current)}`
      }

      return title
    },
    ToolbarTitleClass () {
      return this.ShowLanguageSelect ? 'mt-7' : this.AuthenticationMixin_LoggedIn ? 'mt-1' : ''
    },
    UserIconClass () {
      return this.ShowLanguageSelect ? 'mt-8' : 'mt-2'
    }
  }
}
</script>
