import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_Messages () {
      return {
        autosaveFailed: {
          fr: 'Vos données n\'ont pas pu être enregistrées via la sauvegarde automatique.',
          nl: 'Uw gegevens konden niet via autosave worden opgeslagen.'
        },
        confirmationDelete: {
          fr: `Voulez-vous vraiment supprimer cet élément de type '${this.textMixin.placeholders.parameter}'?`,
          nl: `Weet u zeker dat u dit element van het type '${this.textMixin.placeholders.parameter}' wilt verwijderen?`
        },
        confirmationRevertChanges: {
          fr: 'Voulez-vous vraiment annuler toutes les modifications?',
          nl: 'Weet u zeker dat u alle wijzigingen ongedaan wil maken?'
        },
        error: {
          fr: 'Quelque chose s\'est mal passé.\nVeuillez réessayez plus tard.',
          nl: 'Er is iets fout gelopen.\nGelieve later opnieuw te proberen.'
        },
        formInvalid: {
          fr: 'Veuillez remplir tous les champs correctement.',
          nl: 'Gelieve eerst alle velden correct in te vullen.'
        },
        mailSent: {
          fr: `L'e-mail pour changer votre mot de passe a été envoyé à '${this.textMixin.placeholders.parameter}'.`,
          nl: `De e-mail om uw wachtwoord te wijzigen is verzonden naar '${this.textMixin.placeholders.parameter}'.`
        },
        maxFileSize: {
          fr: `Le fichier est trop volumineux. (Maximum ${this.textMixin.placeholders.parameter}Mo)`,
          nl: `Het bestand is te groot. (Maximaal ${this.textMixin.placeholders.parameter}MB)`
        },
        noAccess: {
          fr: 'Vous ne pouvez pas accéder à cet écran.',
          nl: 'U heeft geen toegang tot dit scherm.'
        },
        redirect: {
          fr: 'Vous serez automatiquement redirigé vers la page d\'accueil.',
          nl: 'U wordt automatisch doorgestuurd naar de startpagina.'
        },
        saveFailed: {
          fr: 'Vos données n\'ont pas pu être enregistrées.',
          nl: 'Uw gegevens konden niet worden opgeslagen.'
        },
        unsavedChanges: {
          fr: 'Voulez-vous vraiment continuer?\nIl y a encore des données non-enregistrées!',
          nl: 'Bent u zeker dat u verder wilt gaan?\nEr zijn nog niet-opgeslagen gegevens!'
        }
      }
    }
  }
}
