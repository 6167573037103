<template>
  <entity-card-documents-list/>
</template>

<script>
import EntityCardDocumentsList from '@/components/shared/entitycards/entity-card-documents-list'

export default {
  name: 'Documents',
  components: {
    EntityCardDocumentsList
  }
}
</script>
