<template>
  <v-row
    class="base-field-date-time"
  >
    <v-col
      class="base-field-date-time-date"
      cols="12"
      lg="8"
      @mouseleave="MouseLeave('date')"
      @mouseover="MouseOver('date')"
    >
      <v-text-field
        ref="base-field-date-time-date"
        v-model="childMixin.element"
        :class="Class('date', childMixin.element)"
      :color="color"
        :dense="dense"
        :disabled="disabled"
        :label="label"
        :outlined="outlined"
        :readonly="readonly"
        :rules="rules"
        :type="Type('date', childMixin.element)"
        @blur="Blur('date')"
        @change="$emit('change', $event)"
        @click="InputReset()"
        @focus="Focus('date')"
        @keydown="Keydown($event)"
      >
        <template
          v-if="showNavigation"
          #append
        >
          <v-btn
            v-for="(navigationButton, index) in NavigationButtons"
            :key="index"
            class="navigation-button"
            icon
            @click="Navigate(navigationButton.days)"
          >
            <v-icon
              :color="Color('date')"
            >
              {{ navigationButton.icon }}
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-col>
    <v-col
      class="base-field-date-time-time"
      cols="12"
      lg="4"
      @mouseleave="MouseLeave('time')"
      @mouseover="MouseOver('time')"
    >
      <v-text-field
        ref="base-field-date-time-time"
        v-model="time"
        :class="Class('time', time)"
      :color="color"
        :dense="dense"
        :disabled="disabled"
        :label="labelTime"
        :outlined="outlined"
        :readonly="readonly"
        :rules="rules"
        :type="Type('time', time)"
        @blur="Blur('time')"
        @change="$emit('change', $event)"
        @click="InputReset()"
        @focus="Focus('time')"
        @keydown="Keydown($event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseFieldDate',
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // v-model value
    value: String
  },
  data () {
    return {
      dateInput: [],
      focus: {
        date: false,
        time: false
      },
      hovering: {
        date: false,
        time: false
      },
      ignoreBlur: {
        date: false,
        time: false
      },
      time: null
    }
  },
  computed: {
    NavigationButtons () {
      return [
        this.GetNavigationButton(-1, 'left'),
        this.GetNavigationButton(1, 'right')
      ]
    }
  },
  watch: {
    'childMixin.element' () {
      this.Emit()
    },
    'focus.date' (value) {
      if (value && !this.hovering.date) {
        this.ignoreBlur.date = true
      }
    },
    'focus.time' (value) {
      if (value && !this.hovering.time) {
        this.ignoreBlur.time = true
      }
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    time () {
      this.Emit()
    },
    value () {
      this.FormatData()
    }
  },
  mounted () {
    this.FormatData()
  },
  methods: {
    Blur (control) {
      if (this.ignoreBlur[control]) {
        this.ignoreBlur[control] = false
        this.$refs[`base-field-date-time-${control}`].focus()
      } else {
        this.focus[control] = false
      }
    },
    Class (control, value) {
      return [
        this.baseFieldClass,
        `${this.ShowControls(control) ? 'show' : 'hide'}-icons`,
        `base-field-date-time-${this.IsControlField(control) ? control : 'text'}`,
        this.dense ? 'dense' : '',
        value ? '' : 'empty'
      ]
    },
    Color (control) {
      return this.focus[control] ? 'primary' : ''
    },
    Emit () {
      let dateTime = this.childMixin.element
      let format = this.formatMixin.date.form

      if (dateTime && this.time) {
        dateTime = `${dateTime} ${this.time}`
        format = this.formatMixin.date.formDateTime
      }

      // Fire when the date has changed
      // @arg The argument is a datetime value (v-model)
      this.$emit('input', this.FormatMixin_FormatDateTime(dateTime, format, this.formatMixin.date.database))
    },
    Focus (control) {
      this.focus[control] = true
      this.InputReset()
    },
    FormatData () {
      this.childMixin.element = this.FormatMixin_FormatDateTime(this.value, this.formatMixin.date.database, this.formatMixin.date.form)
      this.time = this.FormatMixin_FormatDateTime(this.value, this.formatMixin.date.database, this.formatMixin.date.time)
    },
    GetNavigationButton (days, icon) {
      return {
        days: days,
        icon: `mdi-chevron-${icon}`
      }
    },
    InputReset () {
      this.dateInput = []
    },
    IsControlField (control, value) {
      return this.ShowControls(control) || value
    },
    Keydown (value) {
      if (this.autocomplete) {
        if (parseInt(value.key)) {
          this.dateInput.push(value.key)
        } else if (value.key === 'Backspace' || (value.key === 'Tab' && value.shiftKey)) {
          this.InputReset()
        } else if (value.key === 'Enter' && value.shiftKey && this.dateInput.length) {
          const today = new Date()
          const length = this.dateInput.length > 1 ? 2 : 1
          const month = today.getMonth() + 1
          let dateString = `${today.getFullYear()}-${month < 10 ? `0${month}` : month}-`
          let day = ''

          for (let counter = length; counter > 0; counter--) {
            day += this.dateInput[this.dateInput.length - counter]
          }

          if (day.toString().length < 2) {
            day = `0${day}`
          }

          if (Date.parse(`${dateString}${day}`)) {
            dateString += day
          } else {
            dateString += `0${this.dateInput[this.dateInput.length - 1]}`
          }

          this.childMixin.element = dateString
          this.InputReset()

          let next = false
          let child = this
          let parent = this.$parent
          let found = false

          while (parent.$children[parent.$children.length - 1] === child) {
            if (next) {
              child = parent
              parent = parent.$parent
            }

            parent.$parent.$children.forEach(parentChild => {
              if (next) {
                child = parent
                parent = parentChild
                next = false
              } else {
                next = parentChild === parent
              }
            })
          }

          parent.$children.forEach(child => {
            if (!found && (parent !== this.$parent || next)) {
              let childElement = child

              while (childElement.$children.length > 0 && !childElement.focus) {
                childElement = childElement.$children[0]
              }

              if (childElement.focus) {
                childElement.focus()
                next = false
                found = true
              }
            } else {
              next = child === this
            }
          })
        }
      }
    },
    MouseLeave (control) {
      this.hovering[control] = false
    },
    MouseOver (control) {
      this.hovering[control] = true
    },
    Navigate (days) {
      this.childMixin.element = this.childMixin.element ? this.CommonMixin_GetDateWithDaysAdded(this.childMixin.element, days) : this.CommonMixin_GetDate()
    },
    ShowControls (control) {
      return !this.readonly && (this.hovering[control] || this.focus[control])
    },
    Type (control, value) {
      return this.IsControlField(control, value) ? control : 'text'
    }
  }
}
</script>

<style>
.base-field-date-time .hide-icons input[type="date"]::-webkit-inner-spin-button,
.base-field-date-time .hide-icons input[type="date"]::-webkit-calendar-picker-indicator,
.base-field-date-time .hide-icons input[type="time"]::-webkit-inner-spin-button,
.base-field-date-time .hide-icons input[type="time"]::-webkit-calendar-picker-indicator,
.base-field-date-time .hide-icons .navigation-button {
  visibility: hidden;
  -webkit-appearance: none;
}

.base-field-date-time .navigation-button {
  margin-top: 2px;
}

.base-field-date-time .dense .navigation-button {
  margin-top: 5px;
}

.base-field-date-time-date .dense {
  margin-top: 8px;
}

.base-field-date-time-text.dense.empty {
  margin-top: 10px !important;
}

.base-field-date-time-time .dense {
  margin-top: 7px;
}
</style>
