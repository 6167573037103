<template>
  <div>
    <framework-sidebar-menu-item
      v-if="isSidebar"
      :item="LogItem"
      style="direction: ltr"
      @click="ClickLoginButtonButton($event)"
    />
    <v-btn
      v-if="!isSidebar && !FrameworkMixin_ShowSidebar"
      block
      class="btn primary"
      @click="ClickLoginButtonButton($event)"
    >
    {{ LogItem.title }}
    </v-btn>
    <dialog-login
      v-model="dialogLogin"
      @forgotPassword="ForgotPassword()"
    />
    <dialog-forgot-password
      v-model="dialogForgotPassword"
    />
  </div>
</template>

<script>
import DialogLogin from '@/components/shared/dialogs/dialog-login'
import DialogForgotPassword from '@/components/shared/dialogs/dialog-forgot-password'
import FrameworkSidebarMenuItem from '@/components/framework/framework-sidebar-menu-item'

export default {
  name: 'FrameworkLogin',
  components: {
    DialogForgotPassword,
    DialogLogin,
    FrameworkSidebarMenuItem
  },
  props: {
    // Is login option in sidebar?
    isSidebar: {
      default: false,
      type: Boolean
    },
    // Is check needed for query of password token?
    checkQueryForChangePasswordToken: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      dialogForgotPassword: false,
      dialogLogin: false
    }
  },
  computed: {
    LogItem () {
      const type = `log${this.AuthenticationMixin_LoggedIn ? 'Out' : 'In'}`

      return {
        action: type,
        icon: `mdi-${type.toLowerCase()}`,
        title: this.TextMixin_GetLabel(type)
      }
    }
  },
  created () {
    if (this.checkQueryForChangePasswordToken && this.AuthenticationMixin_HasValidChangePasswordToken()) {
      this.dialogForgotPassword = true
    }
  },
  methods: {
    ClickLoginButtonButton () {
      this.LogItem.action === 'logIn' ? this.Login() : this.Logout()
    },
    ForgotPassword () {
      this.dialogForgotPassword = true
    },
    Login () {
      this.dialogLogin = true
    },
    async Logout () {
      this.AuthenticationMixin_Logout()
    }
  }
}
</script>
