<template>
  <home />
</template>

<script>
import Home from '@/views/home/components/home'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'HomeView',
  components: {
    Home
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
