import ContentMixinData from '@/mixins/content-mixin.data'

export default {
  mixins: [
    ContentMixinData
  ],
  methods: {
    async ContentMixin_Service_Attach (parameters = {}) {
      return await this.contentMixin.service.Attach(this.contentMixin.controller, parameters)
    },
    async ContentMixin_Service_Codes (subroute) {
      return await this.contentMixin.service.Codes(this.contentMixin.controller, subroute)
    },
    async ContentMixin_Service_Custom (path, httpMethod = 'get') {
      return await this.contentMixin.service.Custom(path, httpMethod)
    },
    async ContentMixin_Service_Delete (id) {
      return await this.contentMixin.service.Delete(this.contentMixin.controller, id)
    },
    async ContentMixin_Service_Detach (parameters = {}) {
      return await this.contentMixin.service.Detach(this.contentMixin.controller, parameters)
    },
    async ContentMixin_Service_Detail (id) {
      return await this.contentMixin.service.Detail(this.contentMixin.controller, id)
    },
    async ContentMixin_Service_DetailDuplicate (id) {
      return await this.contentMixin.service.DetailDuplicate(this.contentMixin.controller, id)
    },
    async ContentMixin_Service_DetailSave (detail) {
      return await this.contentMixin.service.DetailSave(this.contentMixin.controller, detail)
    },
    async ContentMixin_Service_Init () {
      return await this.contentMixin.service.Init(this.ErrorMixin_HandleError)
    },
    async ContentMixin_Service_List (page = 1, pageSize = 10, sortBy = [], sortDesc = [], filter = null, searchText = null) {
      return await this.contentMixin.service.List(this.contentMixin.controller, page, pageSize, sortBy, sortDesc, filter, searchText)
    },
    async ContentMixin_Service_NewDetailForParent (childType, id) {
      return await this.contentMixin.service.NewDetailForParent(this.contentMixin.controller, childType, id)
    },
    async ContentMixin_Service_PickSource (ignoreMaxResultCount = true, parameters = {}) {
      return await this.contentMixin.service.PickSource(this.contentMixin.controller, ignoreMaxResultCount, parameters)
    },
    async ContentMixin_Service_Process () {
      return await this.contentMixin.service.Process(this.contentMixin.controller)
    },
    async ContentMixin_Service_Scheduler (date, view) {
      return await this.contentMixin.service.Scheduler(this.contentMixin.controller, date, view)
    },
    async ContentMixin_Service_Upload (file) {
      return await this.contentMixin.service.Upload(this.contentMixin.controller, file)
    }
  }
}
