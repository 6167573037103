<template>
  <reports />
</template>

<script>
import Reports from '@/views/reports/components/reports'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'ReportsView',
  components: {
    Reports
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
