<template>
  <base-tabs-view
    :tabs="Tabs"
  />
</template>

<script>
import BaseTabsView from '@/components/shared/base/base-tabs-view'
import BaseTabsViewMixin from '@/mixins/base-tabs-view-mixin'

export default {
  name: 'UserManagementProfiles',
  components: {
    BaseTabsView
  },
  mixins: [
    BaseTabsViewMixin
  ],
  computed: {
    Tabs () {
      return [
        {
          component: 'user-management-profiles-overview',
          idComponent: this.constantMixin.menuItem.userManagement.profiles.general,
          name: this.TextMixin_GetTab('overview')
        },
        {
          component: 'user-management-profiles-detail',
          disabled: !this.ContentMixin_Element,
          idComponent: this.constantMixin.menuItem.userManagement.profiles.general,
          name: this.TextMixin_GetTab(this.BaseTabsViewMixin_GetDetailsTab(), this.TextMixin_GetParameter('functionalityGroup'))
        }
      ]
    }
  }
}
</script>
