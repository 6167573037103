<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-activities-detail-information
      :care-partners="lists.carePartners"
      :codes="contentMixin.codes"
      :readonly="readonly"
      :source="source"
      :users="lists.users"
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardActivitiesDetailInformation from '@/components/shared/entitycards/entity-card-activities-detail-information'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'

export default {
  name: 'ActivitiesDetail',
  components: {
    BaseFormDetail,
    EntityCardActivitiesDetailInformation
  },
  mixins: [
    BaseFormDetailMixin
  ],
  data () {
    return {
      controller: 'Activity',
      lists: {
        carePartners: [],
        users: []
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.ContentMixin_Service_BC_InitializeCodes()
      await this.GetPelicanoAdministrators()
      await this.GetCarePartners()
    },
    async GetCarePartners () {
      this.lists.carePartners = await this.ContentMixin_Service_BC_PickSource('CarePartner')
    },
    async GetPelicanoAdministrators () {
      this.lists.users = await this.ContentMixin_Service_BC_PickSource('User', {
        PelicanoAdministratorsOnly: true
      })
    }
  }
}
</script>
