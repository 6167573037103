<template>
  <error/>
</template>

<script>
import Error from '@/views/error/components/error'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'ErrorView',
  components: {
    Error
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
