<template>
  <bank-statements />
</template>

<script>
import BankStatements from '@/views/bankstatements/components/bank-statements'

export default {
  name: 'BankStatementsView',
  components: {
    BankStatements
  }
}
</script>
