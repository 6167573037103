<template>
  <children />
</template>

<script>
import Children from '@/views/children/components/children'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'ChildrenView',
  components: {
    Children
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
