export default {
  data () {
    return {
      constantMixin: {
        codes: {
          language: {
            name: 'language',
            values: {
              fr: '57AA8AB4-5793-4B2E-AD39-90A7A1C2FA2C',
              nl: '660F0834-D501-44F3-8D94-BEC5185F140A'
            }
          },
          memberRight: {
            name: 'member_right',
            values: {
              undefined: 0,
              hidden: 1,
              readonly: 2,
              editable: 3
            }
          },
          requestExtraBudgetReason: {
            name: 'request_extra_budget_reason',
            values: {
              other: '7C314C01-5932-487B-9978-462463ADA02C'
            }
          },
          requestStatus: {
            name: 'request_status',
            values: {
              approved: '18B95196-B6B6-4D54-BC19-30F2417D0804',
              moreInformationRequired: '3DE531A2-0B82-4976-9A9F-87D985D55CD9',
              new: '426017B2-AA3D-4A44-BEC2-6AA118E1BA71',
              rejected: 'E841D3B5-0E32-4F1D-892E-3DC838B1C3FA',
              resubmitted: '9E689466-f9A4-4A20-A8d6-9266CC1BD16C'
            }
          },
          requestType: {
            name: 'request_type',
            values: {
              extraBudget: '4a2d7cd7-c974-4138-ad61-fb56f2fbcfc3',
              intervention: 'e010e502-1f0f-45c5-bb1c-afeba4740c14'
            }
          }
        },
        crudRight: {
          allowCreate: 0,
          allowDelete: 1,
          allowRead: 2,
          allowUpdate: 3
        },
        dataType: {
          bool: 'bool',
          chip: 'chip',
          date: 'date',
          datetime: 'datetime',
          decimal: 'decimal',
          guid: 'guid',
          int: 'int',
          string: 'string',
          time: 'time'
        },
        guid: {
          empty: '00000000-0000-0000-0000-000000000000'
        },
        httpMethod: {
          get: 'get',
          post: 'post'
        },
        mailType: {
          all: 0,
          received: 1,
          sent: 2
        },
        menuItem: {
          calendar: {
            general: 'DA039ADE-05EF-43FE-BFD6-FF585044F0B4'
          },
          carePartners: {
            activities: 'A80B7398-0CED-405C-94F8-24898C63EF45',
            children: 'B055F74F-100D-4CB7-85A3-D7DA1DD06A0C',
            documents: '0DB9BFB9-B314-4A8E-A617-60D40E6F3660',
            eMails: '94ADA813-7A36-4634-8FE1-B2BD01953E3F',
            general: 'BAC3F9B0-2281-4A07-9538-6C451C5AD4E3',
            monitors: 'A7E51105-CCC2-491E-9D4F-E07C3908C276',
            requests: '88F8D396-1035-476F-80B6-0DCB6140B9E5',
            bankStatements: 'F31E5F79-1CB0-4ABB-B473-7EFC2166084C'
          },
          children: {
            documents: '0DB9BFB9-B314-4A8E-A617-60D40E6F3660',
            general: 'B055F74F-100D-4CB7-85A3-D7DA1DD06A0C',
            monitors: 'A7E51105-CCC2-491E-9D4F-E07C3908C276',
            requests: '88F8D396-1035-476F-80B6-0DCB6140B9E5',
            bankStatements: 'F31E5F79-1CB0-4ABB-B473-7EFC2166084C'
          },
          dashboard: {
            general: '18F987A3-B6FF-4A43-880D-84EA0E4D4B36'
          },
          documents: {
            general: '0DB9BFB9-B314-4A8E-A617-60D40E6F3660'
          },
          eMail: {
            general: '94ADA813-7A36-4634-8FE1-B2BD01953E3F'
          },
          families: {
            children: 'B055F74F-100D-4CB7-85A3-D7DA1DD06A0C',
            documents: '0DB9BFB9-B314-4A8E-A617-60D40E6F3660',
            general: '790EDE82-26FE-4D6D-B00E-FF5BE0A8CB90',
            requests: '88F8D396-1035-476F-80B6-0DCB6140B9E5',
            bankStatements: 'F31E5F79-1CB0-4ABB-B473-7EFC2166084C'
          },
          monitors: {
            children: 'B055F74F-100D-4CB7-85A3-D7DA1DD06A0C',
            documents: '0DB9BFB9-B314-4A8E-A617-60D40E6F3660',
            general: 'A7E51105-CCC2-491E-9D4F-E07C3908C276',
            requests: '88F8D396-1035-476F-80B6-0DCB6140B9E5'
          },
          reports: {
            general: '271C4A67-DF77-45D1-9AF3-F0E85FDA56D4'
          },
          requests: {
            general: '88F8D396-1035-476F-80B6-0DCB6140B9E5',
            documents: '0DB9BFB9-B314-4A8E-A617-60D40E6F3660'
          },
          bankStatements: {
            general: 'F31E5F79-1CB0-4ABB-B473-7EFC2166084C'
          },
          userManagement: {
            loginHistory: {
              general: '13A37E88-5A1F-496C-8942-BA05A94B0397'
            },
            profiles: {
              general: '78271A29-D815-49CC-9E85-15C6B9FCFFA1'
            },
            roles: {
              general: '4F27D2C7-E67C-486E-8737-75B3DF5FC689'
            },
            users: {
              general: 'F6E89B74-5408-4411-B38D-3EAE60E9B422'
            }
          }
        },
        pocoStatus: {
          unchanged: 0,
          new: 1,
          changed: 2,
          deleted: 3
        },
        settings: {
          requestStatus: {
            approved: {
              color: 'success',
              order: 4,
              status: 'processed'
            },
            moreInformationRequired: {
              color: 'warning',
              order: 2,
              status: 'toBeSubmitted'
            },
            new: {
              color: 'info',
              order: 1,
              status: 'inProcess'
            },
            rejected: {
              color: 'error',
              order: 5,
              status: 'processed'
            },
            resubmitted: {
              color: 'info',
              order: 3,
              status: 'inProcess'
            }
          }
        },
        userType: {
          administrator: 'f44b506e-4227-479c-b831-31ee55c65a06',
          carePartner: 'fe872f23-d45d-4eeb-a150-2393b57bd3ef',
          monitor: '1bbd2547-c6c6-4b32-9ecb-d8a513f0aca7'
        }
      }
    }
  }
}
