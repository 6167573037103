import Axios from 'axios'

let _svc

export default {
  AuthenticationConfiguration () {
    try {
      return _svc.get('Configuration/Authentication/Configuration')
    } catch {
      return null
    }
  },
  Init (errorHandler) {
    _svc = Axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        errorHandler(error)
      }
    )
  }
}
