<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    list-detail
    :title="EntityCardMixin_Title"
  >
    <base-list-detail
      v-if="EntityCardMixin_CopySource.representatives"
      v-model="EntityCardMixin_CopySource.representatives"
      controller="Family/Detail"
      :hidden-columns="hiddenColumns"
      hide-filter
      :readonly="EntityCardMixin_Readonly"
      :type="type"
      :visible="visible"
      @add="EntityCardMixin_ListItemAdd('representatives')"
      @delete="EntityCardMixin_ListItemDelete('representatives', $event)"
    >
      <template
        #[`edit-firstName`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).firstName"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).firstName"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.representatives, item.id, 'firstName', $event.value)"
        />
      </template>
      <template
        #[`edit-lastName`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).lastName"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).lastName"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.representatives, item.id, 'lastName', $event.value)"
        />
      </template>
      <template
        #[`edit-gender`]="item"
      >
        <base-field
          v-if="item.gender"
          v-model="item.gender"
          base-field-container-type="div"
          base-field-type="autocomplete"
          code
          :defaultEventHandler="false"
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :readonly="EntityCardMixin_Readonly"
          @change="ContentMixin_HandleSelectWithPocoStatusChangeListDetail(EntityCardMixin_CopySource, 'representatives', 'gender', item, $event)"
        />
      </template>
      <template
        #[`edit-dateOfBirth`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).dateOfBirth"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).dateOfBirth"
          base-field-type="date"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.representatives, item.id, 'dateOfBirth', $event.value)"
        />
      </template>
      <template
        #[`edit-postBirth`]="item"
      >
        <base-field
          v-if="item.postBirth"
          v-model="item.postBirth"
          base-field-container-type="div"
          base-field-type="autocomplete"
          :defaultEventHandler="false"
          :disabled="EntityCardMixin_Disabled"
          :items="posts"
          :readonly="EntityCardMixin_Readonly"
          @change="ContentMixin_HandleSelectWithPocoStatusChangeListDetail(EntityCardMixin_CopySource, 'representatives', 'postBirth', item, $event, posts)"
        />
      </template>
      <template
        #[`edit-street`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).street"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).street"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.representatives, item.id, 'street', $event.value)"
        />
      </template>
      <template
        #[`edit-houseNumber`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).houseNumber"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).houseNumber"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.representatives, item.id, 'houseNumber', $event.value)"
        />
      </template>
      <template
        #[`edit-post`]="item"
      >
        <base-field
          v-if="item.post"
          v-model="item.post"
          base-field-container-type="div"
          base-field-type="autocomplete"
          :defaultEventHandler="false"
          :disabled="EntityCardMixin_Disabled"
          :items="posts"
          :readonly="EntityCardMixin_Readonly"
          @change="ContentMixin_HandleSelectWithPocoStatusChangeListDetail(EntityCardMixin_CopySource, 'representatives', 'post', item, $event, posts)"
        />
      </template>
      <template
        #[`edit-eMail`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).eMail"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).eMail"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.eMail, validationMixin.rules.charactersMax(ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).eMail.value, 255)]"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.representatives, item.id, 'eMail', $event.value)"
        />
      </template>
      <template
        #[`edit-telephone`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).telephone"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).telephone"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.representatives, item.id, 'telephone', $event.value)"
        />
      </template>
      <template
        #[`edit-relation`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).relation"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.representatives, item.id).relation"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.representatives, item.id, 'relation', $event.value)"
        />
      </template>
    </base-list-detail>
  </base-card>
</template>

<script>
import BaseListDetail from '@/components/shared/base/base-list-detail'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardFamiliesDetailLegalFamilyRepresentatives',
  components: {
    BaseListDetail
  },
  mixins: [
    EntityCardMixin
  ],
  props: {
    type: {
      default: 'representative',
      type: String
    }
  },
  data () {
    return {
      hiddenColumns: [
        'fullName'
      ]
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('legalFamilyRepresentatives')
      await this.ContentMixin_Service_BC_InitializeContentService('Family')
    }
  }
}
</script>
