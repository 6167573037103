<template>
  <div
    class="pa-5"
  >
    <entity-card-calendar-overview
      :data-items="lists.dataItems"
      :source="source"
      @create="EntityCardMixin_PickersCreate(Pickers.activity)"
      @update="EntityCardMixin_PickersShowDialog(Pickers.activity, 'update', false, $event)"
      @datechange="GetActivities()"
    />
    <dialog-detail
      v-if="entityCardMixin.dialogDetail.show"
      v-model="entityCardMixin.dialogDetail.show"
      :content="entityCardMixin.dialogDetail"
      @close="EntityCardMixin_PickersClose()"
      @save="DialogDetailSave()"
    />
  </div>
</template>

<script>
import DialogDetail from '@/components/shared/dialogs/dialog-detail'
import EntityCardCalendarOverview from '@/components/shared/entitycards/entity-card-calendar-overview'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'CalendarOverview',
  components: {
    DialogDetail,
    EntityCardCalendarOverview
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Activity',
      lists: {
        dataItems: []
      }
    }
  },
  computed: {
    Pickers () {
      return {
        activity: this.EntityCardMixin_Pickers(false, 'Activity', 'activity', 'activities', 'activities-detail', this.PickersReadonly.activity, null, null, null, true)
      }
    },
    PickersReadonly () {
      return {
        activity: this.readonly || this.AuthenticationMixin_UserIsAdministrator()
      }
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async DialogDetailSave () {
      await this.EntityCardMixin_PickersClose(this.Pickers.activities)
      await this.GetActivities()
    },
    async GetActivities () {
      const response = await this.ContentMixin_Service_BC_Scheduler(this.FormatMixin_FormatDateTime(this.ContentMixin_Store_SchedulerDate.toUTCString(), null, this.formatMixin.date.form), this.ContentMixin_Store_SchedulerView)

      if (response) {
        this.lists.dataItems = response.dataItems.map((dataItem) => ({
          ...dataItem,
          end: new Date(dataItem.end),
          start: new Date(dataItem.start)
        }))
      }
    },
    async GetPickerLists () {
      await this.EntityCardMixin_PickersInitialize(this.Pickers)
      await this.EntitiCardMixin_PickersInitializeValue(this.Pickers, this.parameters)
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.GetPickerLists()
      this.ContentMixin_Store_SchedulerDate = new Date()
      this.ContentMixin_Store_SchedulerView = 'month'
      await this.GetActivities()
    }
  }
}
</script>
