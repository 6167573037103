import DialogLinkEntities from '@/components/shared/dialogs/dialog-link-entities'
import BaseListMixin from '@/mixins/base-list-mixin'

export default {
  components: {
    DialogLinkEntities
  },
  mixins: [
    BaseListMixin
  ],
  props: {
    // Filter to use on overview
    filter: Array,
    // Controller used to attach and detach
    linkController: String,
    // Primary key property of currently selected property
    linkKey: String,
    // Table is a link table
    linkTable: Boolean,
    // Inside card
    insideCard: {
      default: false,
      type: Boolean
    },
    // Redirect to page instead of opening in new tab
    redirect: Boolean,
    // Page to redirect to if necessary
    redirectPage: String,
    // Rights to overwrite if necessary
    rights: Object,
    // Current tab
    tab: {
      default: 'overview',
      type: String
    },
    // Type of element
    type: String
  },
  data () {
    return {
      baseListOverviewMixin: {
        link: {
          dialog: false
        }
      }
    }
  },
  computed: {
    BaseListOverviewMixin_Rights () {
      return this.rights ?? this.baseListMixin.rights
    }
  },
  watch: {
    async 'baseListMixin.refresh' (value) {
      if (value) {
        await this.BaseListOverviewMixin_List()
        this.baseListMixin.refresh = false
      }
    },
    selection (value) {
      if (!this.CommonMixin_HardCompare(this.baseListMixin.selection, value)) {
        this.baseListMixin.selection = this.CommonMixin_Clone(value)
      }
    }
  },
  methods: {
    async BaseListOverviewMixin_Create (customCreate) {
      if (customCreate) {
        this.$emit('custom-create')
      } else if (this.linkTable) {
        this.baseListOverviewMixin.link.dialog = true
      } else {
        await this.ContentMixin_Service_BC_Detail()
      }
    },
    async BaseListOverviewMixin_Delete (value) {
      if (this.linkTable) {
        const params = {}
        params[this.linkKey] = this.ContentMixin_Store_DetailSourceCopy[this.linkKey]
        params[this.contentMixin.key] = value[this.contentMixin.key]
        await this.ContentMixin_Service_BC_Detach(this.linkController, params)
        await this.BaseListMixin_Refresh()
      } else if (value && value[this.contentMixin.key]) {
        if (await this.ContentMixin_Service_BC_Delete(value[this.contentMixin.key])) {
          await this.BaseListOverviewMixin_List()
        }
      }
    },
    async BaseListOverviewMixin_Initialize (controller) {
      await this.ContentMixin_Service_BC_InitializeContentService(controller)
      await this.BaseListOverviewMixin_List()
      this.baseListMixin.selection = this.CommonMixin_Clone(this.selection)
    },
    async BaseListOverviewMixin_Link (value) {
      const params = {}
      params[this.linkKey] = this.ContentMixin_Store_DetailSourceCopy[this.linkKey]
      params[this.contentMixin.key] = value
      await this.ContentMixin_Service_BC_Attach(this.linkController, params)
      await this.BaseListMixin_Refresh()
      this.baseListOverviewMixin.link.dialog = false
    },
    async BaseListOverviewMixin_List (options = this.baseListMixin.optionsCurrent, filter = this.filter) {
      const methodName = `BaseListOverviewMixin_List(${this.name ?? ''})`
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      options ??= this.baseListMixin.optionsDefault
      this.baseListMixin.optionsCurrent = options
      await this.BaseListMixin_Setup(await this.ContentMixin_Service_List(options.page, options.itemsPerPage, options.sortBy, options.sortDesc, filter, this.baseListMixin.searchText))
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async BaseListOverviewMixin_Read (value) {
      await this.ContentMixin_Service_BC_Detail(value)
    },
    async BaseListOverviewMixin_Select (customUpdate, value) {
      if (customUpdate) {
        this.$emit('custom-update', value)
      } else {
        await this.redirect ? this.ContentMixin_Service_BC_Detail(value, null, this.redirectPage) : this.ContentMixin_Service_BC_Detail(value)
      }
    }
  }
}
