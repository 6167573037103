export default {
  data () {
    return {
      textMixin: {
        cookie: {
          name: 'PelicanoWebLanguage',
          timespan: '1Y'
        },
        default: {
          language: 'NL'
        },
        languages: [
          {
            abbreviation: 'FR',
            name: 'Français'
          },
          {
            abbreviation: 'NL',
            name: 'Nederlands'
          }
        ],
        placeholders: {
          parameter: '%PARAMETER%'
        }
      }
    }
  },
  computed: {
    TextMixin_Language () {
      return this.FrameworkMixin_Language ?? this.textMixin.default.language
    }
  }
}
