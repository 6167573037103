import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_Steps () {
      return {
        inProcess: {
          fr: 'En cours',
          nl: 'In behandeling'
        },
        process: {
          fr: `Traiter ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} behandelen`
        },
        processed: {
          fr: 'Traité',
          nl: 'Behandeld'
        },
        submit: {
          fr: `Soumettre ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} indienen`
        },
        toBeSubmitted: {
          fr: 'À soumettre',
          nl: 'In te dienen'
        }
      }
    }
  }
}
