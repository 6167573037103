<template>
  <div
    class="base-field-text"
  >
    <v-text-field
      ref="base-field-text"
      v-model="childMixin.element"
      :color="color"
      :class="baseFieldClass"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :outlined="outlined"
      :readonly="readonly"
      :rules="rules"
      @change="Change($event)"
      @keyup="Keyup($event)"
    />
  </div>
</template>

<script>
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseFieldText',
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // Value to be shown
    value: String
  },
  data () {
    return {
      copy: null,
      interval: null,
      timeout: 3,
      timer: 0
    }
  },
  watch: {
    'childMixin.element' () {
      this.Emit()
    },
    value () {
      this.FormatData()
    }
  },
  mounted () {
    this.FormatData()
  },
  methods: {
    Change (value) {
      this.childMixin.element = value
      this.$emit('change', value)
    },
    Emit () {
      // Fire when the data has changed
      this.$emit('input', this.childMixin.element)
    },
    FormatData (value = this.value) {
      this.childMixin.element = this.CommonMixin_Clone(value)
      this.copy = this.CommonMixin_Clone(value)
    },
    Keyup (value) {
      this.SetTimer(this.timeout)
      this.$emit('keyup', value)
    },
    SetTimer (time, interval = 1) {
      this.timer = time

      if (this.interval && time <= 0) {
        clearInterval(this.interval)
        this.interval = null
        this.timer = 0
        this.Change(this.copy)
      } else if (!this.interval && time > 0) {
        this.interval = setInterval(() => this.SetTimer(this.timer - interval), interval * 100)
      }
    }
  }
}
</script>
