<template>
  <base-list-overview
    controller="Child"
    :filter="Filter"
    redirect
    redirect-page="Children"
    tab="children"
    :visible="visible"
  />
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'FamiliesChildren',
  components: {
    BaseListOverview
  },
  mixins: [
    ContentMixin
  ],
  computed: {
    Filter () {
      const key = 'idFamily'
      return [
        {
          key: key,
          value: this.ContentMixin_Element[key]
        }
      ]
    }
  }
}
</script>
