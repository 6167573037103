<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="FrameworkMixin_ShowSidebar"
    v-bind="$attrs"
    app
    class="customScrollbar"
    color="secondary"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    :right="$vuetify.rtl"
    style="direction: rtl"
    :style="DrawerStyle"
    width="330"
  >
    <template
      #prepend
    >
      <v-list
      class="logo"
      dense
      nav
    >
      <v-list-item>
        <v-img
          contain
          max-height="150"
          src="@/assets/images/logo.png"
          style="margin: auto; margin-bottom: -107px; cursor: pointer; z-index: 5;"
          @click="Home()"
        />
      </v-list-item>
      </v-list>
      <v-divider
        class="mb-2"
      />
    </template>
    <div
      style="direction:ltr"
    >
      <v-list
        v-if="AuthenticationMixin_LoggedIn"
        expand
        nav
        style="margin-top: 95px;"
      >
        <template
          v-for="(item, i) in Items"
        >
          <framework-sidebar-menu-item-group
            v-if="AuthenticationMixin_HasRights(item) && (!item.optional || item.children.length > 0)"
            :key="`group-${i}`"
            :item="item"
          />
        </template>
      </v-list>
    </div>
    <template
      #append
    >
      <v-divider
        class="mt-2"
      />
      <framework-login
        check-query-for-change-password-token
        is-sidebar
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import FrameworkLogin from '@/components/framework/framework-login'
import FrameworkSidebarMenuItemGroup from '@/components/framework/framework-sidebar-menu-item-group'

export default {
  name: 'FrameworkSidebar',
  components: {
    FrameworkLogin,
    FrameworkSidebarMenuItemGroup
  },
  props: {
    // Expand sidebar when hovering over it
    expandOnHover: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    DrawerStyle () {
      const mobile = this.UserAgent.includes('mobile')
      const apple = this.UserAgent.includes('mac')
      const iphone = this.UserAgent.includes('iphone')
      let compensation = mobile || apple ? 56 : 0
      compensation *= iphone ? 1.5 : 1
      return `max-height: calc(100vh - ${compensation}px);`
    },
    Items () {
      return [
        // TODO: Inhoud voorzien en terug zichtbaar maken
        // this.GetMenuItem('view-dashboard', 'dashboard', 'Dashboard'),
        this.GetMenuItem('account-group', 'userManagement', 'UserManagement', null, [
          this.GetMenuItem(null, 'userManagement_users', 'UserManagement_Users', this.constantMixin.menuItem.userManagement.users.general),
          this.GetMenuItem(null, 'userManagement_roles', 'UserManagement_Roles', this.constantMixin.menuItem.userManagement.roles.general),
          this.GetMenuItem(null, 'userManagement_profiles', 'UserManagement_Profiles', this.constantMixin.menuItem.userManagement.profiles.general),
          this.GetMenuItem(null, 'userManagement_loginHistory', 'UserManagement_LoginHistory', this.constantMixin.menuItem.userManagement.loginHistory.general)
        ]),
        this.GetMenuItem('handshake', 'carePartners', 'CarePartners', this.constantMixin.menuItem.carePartners.general),
        this.GetMenuItem('human-male-board', 'monitors', 'Monitors', this.constantMixin.menuItem.monitors.general),
        this.GetMenuItem('home-heart', 'families', 'Families', this.constantMixin.menuItem.families.general),
        this.GetMenuItem('human-child', 'children', 'Children', this.constantMixin.menuItem.children.general),
        this.GetMenuItem('file-document', 'documents', 'Documents', this.constantMixin.menuItem.documents.general),
        this.GetMenuItem('comment-question', 'requests', 'Requests', this.constantMixin.menuItem.requests.general),
        // this.GetMenuItem('chart-areaspline', 'reports', 'Reports', this.constantMixin.menuItem.reports.general),
        this.GetMenuItem('bank-transfer', 'bankStatements', 'BankStatements', this.constantMixin.menuItem.bankStatements.general),
        this.GetMenuItem('calendar-month', 'calendar', 'Calendar', this.constantMixin.menuItem.calendar.general),
        this.GetMenuItem('email', 'eMail', 'EMail', this.constantMixin.menuItem.eMail.general)
      ]
    },
    UserAgent () {
      return navigator.userAgent.toLowerCase()
    }
  },
  methods: {
    GetMenuItem (icon, title, to, idComponent, children) {
      return {
        children: children,
        group: '',
        icon: `mdi-${icon}`,
        idComponent: idComponent,
        title: this.TextMixin_GetPage(title),
        to: to
      }
    },
    Home () {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' })
      }
    }
  }
}
</script>

<style
  scoped
>
#core-navigation-drawer .v-list-group__header.v-list-item--active:before {
  opacity: .24;
}

#core-navigation-drawer .v-list-item__icon--text, #core-navigation-drawer .v-list-item__icon:first-child {
  justify-content: center;
  text-align: center;
  width: 20px;
}

.v-application--is-ltr #core-navigation-drawer .v-list-item__icon--text, .v-application--is-ltr #core-navigation-drawer .v-list-item__icon:first-child {
  margin-left: 12px !important;
  margin-right: 24px;
}

.v-application--is-rtl #core-navigation-drawer .v-list-item__icon--text, .v-application--is-rtl #core-navigation-drawer .v-list-item__icon:first-child {
  margin-left: 24px;
  margin-right: 12px !important;
}

#core-navigation-drawer .v-list--dense .v-list-item__icon--text, #core-navigation-drawer .v-list--dense .v-list-item__icon:first-child {
  margin-top: 10px;
}

.v-application--is-ltr #core-navigation-drawer .v-list-group--sub-group .v-list-item {
  padding-left: 8px;
}

.v-application--is-rtl #core-navigation-drawer .v-list-group--sub-group .v-list-item {
  padding-right: 8px;
}

.v-application--is-ltr #core-navigation-drawer .v-list-group--sub-group .v-list-group__header {
  padding-right: 0;
}

.v-application--is-rtl #core-navigation-drawer .v-list-group--sub-group .v-list-group__header {
  padding-right: 0;
}

#core-navigation-drawer .v-list-group--sub-group .v-list-group__header .v-list-item__icon--text {
  margin-top: 19px;
  order: 0;
}

#core-navigation-drawer .v-list-group--sub-group .v-list-group__header .v-list-group__header__prepend-icon {
  order: 2;
}

.v-application--is-ltr #core-navigation-drawer .v-list-group--sub-group .v-list-group__header .v-list-group__header__prepend-icon {
  margin-right: 8px;
}

.v-application--is-rtl #core-navigation-drawer .v-list-group--sub-group .v-list-group__header .v-list-group__header__prepend-icon {
  margin-left: 8px;
}

.logo {
  background-color: white !important;
  cursor: pointer;
}
</style>

<style>
.v-navigation-drawer__border {
  margin-top: 75px;
  visibility: hidden;
}
</style>
