import Axios from 'axios'

let _svc

export default {
  Content (folderKey, params = []) {
    try {
      var query = ''
      params.forEach(param => {
        if (param) {
          query = `&${param.key}=${param.value}`
        }
      })
      return _svc.get('Folder/Content?AddDocuments=true' + (folderKey ? `&FolderKey=${folderKey}` : '') + query)
    } catch {
      return null
    }
  },
  CreateFolder (parentFolderKey, name) {
    try {
      if (parentFolderKey && name) {
        return _svc.post(`Folder/Detail/Create?ParentFolderKey=${parentFolderKey}&FolderDescription=${name}`)
      }
    } catch {
      return null
    }
  },
  Download (id) {
    try {
      if (id) {
        return _svc.get(`Document/Download/${id}`,
          { responseType: 'blob' }
        )
      }
    } catch {
      return null
    }
  },
  Init (errorHandler) {
    _svc = Axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        errorHandler(error)
      }
    )
  },
  RenameFolder (folderKey, newName) {
    try {
      if (folderKey && newName) {
        return _svc.post(`Folder/Detail/Rename?FolderKey=${folderKey}&NewFolderDescription=${newName}`)
      }
    } catch {
      return null
    }
  },
  Upload (folder, file) {
    try {
      const formData = new FormData()
      formData.append('file', file)

      return _svc.post(`Document/Upload/${folder}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch {
      return null
    }
  }
}
