<template>
  <base-tabs-view
    :tabs="Tabs"
  />
</template>

<script>
import BaseTabsView from '@/components/shared/base/base-tabs-view'
import BaseTabsViewMixin from '@/mixins/base-tabs-view-mixin'

export default {
  name: 'Children',
  components: {
    BaseTabsView
  },
  mixins: [
    BaseTabsViewMixin
  ],
  computed: {
    Tabs () {
      return [
        {
          component: 'children-overview',
          idComponent: this.constantMixin.menuItem.children.general,
          name: this.TextMixin_GetTab('overview')
        },
        {
          component: 'children-detail',
          disabled: !this.ContentMixin_Element,
          idComponent: this.constantMixin.menuItem.children.general,
          name: this.TextMixin_GetTab(this.BaseTabsViewMixin_GetDetailsTab(), this.TextMixin_GetParameter('child'))
        },
        {
          component: 'children-documents',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.children.documents,
          name: this.TextMixin_GetTab('documents')
        },
        {
          component: 'children-requests',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.children.requests,
          name: this.TextMixin_GetTab('requests')
        },
        {
          component: 'children-bank-statements',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.children.bankStatements,
          name: this.TextMixin_GetTab('bankStatements')
        }
      ]
    }
  }
}
</script>
