import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_Titles () {
      return {
        accountInformation: {
          fr: 'Informations de compte',
          nl: 'Accountinformatie'
        },
        activities: {
          fr: 'Activités',
          nl: 'Activiteiten'
        },
        add: {
          fr: `Ajouter ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} toevoegen`
        },
        budgetDetails: {
          fr: 'Budget',
          nl: 'Budget'
        },
        bankStatements: {
          fr: 'Transactions',
          nl: 'Transacties'
        },
        carePartners: {
          fr: 'Partenaires sociaux',
          nl: 'Sociale partners'
        },
        changePassword: {
          fr: 'Changer le mot de passe',
          nl: 'Verander wachtwoord'
        },
        children: {
          fr: 'Enfants',
          nl: 'Kinderen'
        },
        contactDetails: {
          fr: `Détails du contact ${this.textMixin.placeholders.parameter}`,
          nl: `Contactgegevens ${this.textMixin.placeholders.parameter}`
        },
        create: {
          fr: `Créer ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} aanmaken`
        },
        details: {
          fr: 'Détails',
          nl: 'Details'
        },
        extraInformation: {
          fr: 'Informations supplémentaires',
          nl: 'Extra informatie'
        },
        feedback: {
          fr: 'Feedback',
          nl: 'Feedback'
        },
        financialSituation: {
          fr: 'Situation financière',
          nl: 'Financiële situatie'
        },
        forgotPassword: {
          fr: 'Mot de passe oublié',
          nl: 'Wachtwoord vergeten'
        },
        generalInformation: {
          fr: `Informations générales ${this.textMixin.placeholders.parameter}`,
          nl: `Algemene informatie ${this.textMixin.placeholders.parameter}`
        },
        information: {
          fr: 'Information',
          nl: 'Informatie'
        },
        legalFamilyRepresentatives: {
          fr: 'Représentants légaux de famille',
          nl: 'Wettelijke gezinsvertegenwoordigers'
        },
        logIn: {
          fr: 'Se connecter',
          nl: 'Aanmelden'
        },
        processed: {
          fr: `${this.textMixin.placeholders.parameter} traité(e)`,
          nl: `Verwerkte ${this.textMixin.placeholders.parameter}`
        },
        profiles: {
          fr: 'Profils',
          nl: 'Profielen'
        },
        read: {
          fr: `${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter}`
        },
        rights: {
          fr: 'Droits',
          nl: 'Rechten'
        },
        siblings: {
          fr: 'Frères et sœurs',
          nl: 'Broers en zussen'
        },
        situation: {
          fr: 'Statut',
          nl: 'Toestand'
        },
        status: {
          fr: 'Statut',
          nl: 'Status'
        },
        unprocessed: {
          fr: `${this.textMixin.placeholders.parameter} non-traité(e)`,
          nl: `Niet-verwerkte ${this.textMixin.placeholders.parameter}`
        },
        update: {
          fr: `Modifier ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} bewerken`
        },
        website: {
          fr: 'Pelicano - Ensemble contre la pauvreté infantile en Belgique',
          nl: 'Stichting Pelicano - Samen kinderarmoede bestrijden in België'
        }
      }
    }
  }
}
