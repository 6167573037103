<template>
  <base-tabs-view
    :tabs="Tabs"
  />
</template>

<script>
import BaseTabsView from '@/components/shared/base/base-tabs-view'
import BaseTabsViewMixin from '@/mixins/base-tabs-view-mixin'

export default {
  name: 'CarePartners',
  components: {
    BaseTabsView
  },
  mixins: [
    BaseTabsViewMixin
  ],
  computed: {
    Tabs () {
      return [
        {
          component: 'care-partners-overview',
          idComponent: this.constantMixin.menuItem.carePartners.general,
          name: this.TextMixin_GetTab('overview')
        },
        {
          component: 'care-partners-detail',
          disabled: !this.ContentMixin_Element,
          idComponent: this.constantMixin.menuItem.carePartners.general,
          name: this.TextMixin_GetTab(this.BaseTabsViewMixin_GetDetailsTab(), this.TextMixin_GetParameter('carePartner'))
        },
        {
          component: 'care-partners-monitors',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.carePartners.monitors,
          name: this.TextMixin_GetTab('monitors')
        },
        {
          component: 'care-partners-children',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.carePartners.children,
          name: this.TextMixin_GetTab('children')
        },
        {
          component: 'care-partners-documents',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.carePartners.documents,
          name: this.TextMixin_GetTab('documents')
        },
        {
          component: 'care-partners-requests',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.carePartners.requests,
          name: this.TextMixin_GetTab('requests')
        },
        {
          component: 'care-partners-bank-statements',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.carePartners.bankStatements,
          name: this.TextMixin_GetTab('bankStatements')
        },
        {
          component: 'base-under-construction',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.carePartners.eMail,
          name: this.TextMixin_GetTab('eMail')
        },
        {
          component: 'care-partners-activities',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.carePartners.activities,
          name: this.TextMixin_GetTab('activities')
        }
      ]
    }
  }
}
</script>
