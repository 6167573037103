<template>
  <base-list-overview
    controller="EntityChangeLog"
    :double-click-edit="false"
    :edit-action="false"
    :filter="Filter"
    :hidden-columns="HiddenColumns"
    hide-filter
    readonly
    :visible="visible"
  >
    <template
      #[`read-oldDescription`]="item"
    >
      <v-chip
        v-if="RequestStatusDescription(item.oldValue)"
        :color="RequestStatusColor(item.oldValue)"
        small
      >
        {{ item.oldDescription.value }}
      </v-chip>
      <template
        v-else
      >
        {{ item.oldDescription.value }}
      </template>
    </template>
    <template
      #[`read-newDescription`]="item"
    >
      <v-chip
        v-if="RequestStatusDescription(item.newValue)"
        :color="RequestStatusColor(item.newValue)"
        small
      >
        {{ item.newDescription.value }}
      </v-chip>
      <template
        v-else
      >
        {{ item.newDescription.value }}
      </template>
    </template>
  </base-list-overview>
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'RequestsUpdateHistory',
  components: {
    BaseListOverview
  },
  mixins: [
    ContentMixin
  ],
  computed: {
    Filter () {
      return [
        {
          key: 'IdEntity',
          value: this.constantMixin.menuItem.requests.general
        },
        {
          key: 'idReference',
          value: this.ContentMixin_Element.idRequest
        }
      ]
    },
    HiddenColumns () {
      return [
        'entity',
        'oldValue',
        'newValue'
      ]
    }
  },
  methods: {
    RequestStatusColor (value) {
      const status = this.constantMixin.settings.requestStatus[this.RequestStatusDescription(value)]
      return status?.color
    },
    RequestStatusDescription (value) {
      const values = this.constantMixin.codes.requestStatus.values
      return Object.keys(values).find(key => values[key].toUpperCase() === (value?.value ?? '').toUpperCase())
    }
  }
}
</script>
