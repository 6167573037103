<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-monitors-detail-general-information
      :readonly="readonly"
      :source="source"
    />
    <entity-card-monitors-detail-care-partners
      :care-partners="lists.carePartners"
      :codes="contentMixin.codes"
      :readonly="readonly"
      :source="source"
    />
    <entity-card-monitors-detail-contact-details
      :posts="lists.posts"
      :readonly="readonly"
      :source="source"
    />
    <entity-card-monitors-detail-extra-information
      :readonly="readonly"
      :source="source"
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardMonitorsDetailCarePartners from '@/components/shared/entitycards/entity-card-monitors-detail-care-partners'
import EntityCardMonitorsDetailContactDetails from '@/components/shared/entitycards/entity-card-monitors-detail-contact-details'
import EntityCardMonitorsDetailExtraInformation from '@/components/shared/entitycards/entity-card-monitors-detail-extra-information'
import EntityCardMonitorsDetailGeneralInformation from '@/components/shared/entitycards/entity-card-monitors-detail-general-information'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'

export default {
  name: 'MonitorsDetail',
  components: {
    BaseFormDetail,
    EntityCardMonitorsDetailCarePartners,
    EntityCardMonitorsDetailContactDetails,
    EntityCardMonitorsDetailExtraInformation,
    EntityCardMonitorsDetailGeneralInformation
  },
  mixins: [
    BaseFormDetailMixin
  ],
  data () {
    return {
      controller: 'Monitor',
      lists: {
        carePartners: [],
        posts: []
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.ContentMixin_Service_BC_InitializeCodes()
      this.lists.carePartners = await this.ContentMixin_Service_BC_PickSource('CarePartner')
      this.lists.posts = await this.ContentMixin_Service_BC_PickSource('Post')
    }
  }
}
</script>
