<template>
  <v-form
    v-if="ContentMixin_Store_DetailSourceCopy"
    ref="form"
    v-model="baseFormDetailMixin.valid"
    class="pa-5"
  >
    <slot />
    <base-crud-buttons
      v-if="!readonly"
      :deletable="ContentMixin_ElementIsDeletable()"
      :dirty="ContentMixin_ElementIsDirty"
      :disabled="FrameworkMixin_Disable"
      :edit="ContentMixin_ElementIsEditable()"
      :revertable="ContentMixin_ElementIsRevertable()"
      :save-label="saveLabel"
      @cancel="BaseFormDetailMixin_SetUpLock()"
      @delete="BaseFormDetailMixin_Delete()"
      @edit="BaseFormDetailMixin_Edit()"
      @revert-changes="BaseFormDetailMixin_RevertChanges()"
      @save="BaseFormDetailMixin_Save()"
    >
      <template
        v-for="slot in CustomActionsSlots()"
        #[`${slot}`]
      >
        <slot
          :name="slot"
        />
      </template>
    </base-crud-buttons>
  </v-form>
</template>

<script>
import BaseCrudButtons from '@/components/shared/base/base-crud-buttons'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'

export default {
  name: 'BaseFormDetail',
  components: {
    BaseCrudButtons
  },
  mixins: [
    BaseFormDetailMixin
  ],
  props: {
    // BaseFormDetailMixin-element
    value: Object,
    // Controller it should use
    controller: String,
    // Alternate label for save crud button
    saveLabel: String
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    CustomActionsSlots () {
      return Object.keys(this.$scopedSlots).filter(key => key.includes('custom-actions'))
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
    }
  }
}
</script>
