<template>
  <div>
    <base-list-treeview
      :active-item="activeItem"
      :items="items"
      @active-change="ActiveItemChange($event)"
    >
      <template
        #treeview-prepend="{ open }"
      >
        <v-icon
          :color="documentMixin.fileTypeColors.folder"
        >
          mdi-folder{{ open ? '-open' : '' }}
        </v-icon>
      </template>
      <template
        #overview=""
      >
        <div>
          <v-row
            class="pa-0 ma-0"
          >
            <v-col
              class="pa-0 ma-0"
            >
              <h2
                v-if="activeItem"
                class="px-3 pb-3 pt-1"
              >
                <span
                  v-if="activeItem.parentKey"
                >
                  <a
                    @click="ChangeFolder(activeItem.parentKey)"
                  >
                    {{ activeItem.parentText }}
                  </a>
                  /
                </span>
                {{ activeItem.text?.value }}
              </h2>
            </v-col>
          </v-row>
          <div
            class="py-2 px-2 ma-0"
            style="height: calc(100vh - 287px) !important; overflow-y: auto; overflow-x: hidden"
          >
            <base-crud-table
              v-if="activeItem"
              :default-contextmenu="false"
              :doubleClickEdit="false"
              :headers="BaseListMixin_Headers()"
              :edit-action="false"
              :items="baseListMixin.list"
              item-key="itemKey"
              hide-border
              :paging="baseListMixin.paging"
              :rights="baseListMixin.rights"
              single-click-select
              @contextmenu="OpenMenu($event)"
              @double-click-select="SelectItem($event)"
              @filter="BaseListMixin_Filter($event)"
            >
              <template
                #[`customActions`]="item"
              >
                <v-tooltip
                  v-if="editAction"
                  bottom
                >
                  <template
                    #activator="{ on, attrs }"
                  >
                    <v-icon
                      v-if="item?.itemType?.value !== 'Folder'"
                      v-bind="attrs"
                      class="mr-2"
                      :class="$vuetify.breakpoint.xs ? '' : 'tableAction'"
                      v-on="on"
                      @click="Download(item)"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>
                    {{ TextMixin_GetLabel('download') }}
                  </span>
                </v-tooltip>
              </template>
              <template
                #[`read-itemType`]="item"
              >
                <v-icon
                  :color="DocumentMixin_GetFileIcon(item).color"
                >
                  {{ DocumentMixin_GetFileIcon(item).icon }}
                </v-icon>
              </template>
            </base-crud-table>
            <v-menu
              v-model="viewMenu"
              absolute
              class="d-none"
              offset-y
              :position-x="contextMenuPosition.X"
              :position-y="contextMenuPosition.Y"
              transition="slide-y-transition"
              @blur="CloseMenu"
            >
              <v-list
                class="pa-0 rounded"
              >
                <v-list-item
                  class="menu-item px-3"
                  @click="RenameFolder(contextMenuItem)"
                >
                  <v-list-item-title>
                    {{ TextMixin_GetLabel('rename') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="contextMenuItem?.itemType?.value !== 'Folder'"
                  class="menu-item px-3"
                  @click="Download(contextMenuItem)"
                >
                  <v-list-item-title>
                    {{ TextMixin_GetLabel('download') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-btn
            v-if="allowFolders"
            class="mt-2 ml-2"
            color="secondary"
            outlined
            @click="CreateFolder()"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            {{ TextMixin_GetLabel('add', TextMixin_GetParameter('folder')) }}
          </v-btn>
          <v-btn
            v-if="allowDocuments"
            class="mt-2 ml-2"
            color="secondary"
            outlined
            @click="CreateFile()"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            {{ TextMixin_GetLabel('add', TextMixin_GetParameter('file')) }}
          </v-btn>
        </div>
      </template>
    </base-list-treeview>
    <dialog-detail
      v-if="entityCardMixin.dialogDetail.show"
      v-model="entityCardMixin.dialogDetail.show"
      :content="entityCardMixin.dialogDetail"
      @close="EntityCardMixin_PickersClose()"
      @save="DialogDetailSave()"
    />
  </div>
</template>

<script>
import BaseListTreeview from '@/components/shared/base/base-list-treeview'
import DialogDetail from '@/components/shared/dialogs/dialog-detail'
import BaseListMixin from '@/mixins/base-list-mixin'
import BlobMixin from '@/mixins/blob-mixin'
import DocumentMixin from '@/mixins/document-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'
import DocumentService from '@/services/document-service'

export default {
  name: 'EntityCardDocumentsList',
  components: {
    BaseListTreeview,
    DialogDetail
  },
  mixins: [
    BaseListMixin,
    BlobMixin,
    DocumentMixin,
    DocumentService,
    EntityCardMixin
  ],
  props: {
    // parameters for document service
    folderParams: Array,
    visible: Boolean
  },
  data () {
    return {
      activeItem: null,
      allowDocuments: false,
      allowFolders: false,
      contextMenuItem: null,
      contextMenuPosition: {
        X: 0,
        Y: 0
      },
      documentController: 'Document',
      items: [],
      viewMenu: false
    }
  },
  computed: {
    Pickers () {
      return {
        document: this.EntityCardMixin_Pickers(false, 'Document', 'document', 'documents', 'documents-detail', this.PickersReadonly.document, null, null, null, true, this.PickersRules.document)
      }
    },
    PickersReadonly () {
      return {
        document: this.readonly
      }
    },
    PickersRules () {
      return {
        document: [this.validationMixin.rules.required]
      }
    }
  },
  watch: {
    activeItem (value) {
      if (value) {
        this.BaseListMixin_Setup({
          data: value.items
        })
      }
    },
    visible (value) {
      if (value ?? false) {
        this.Initialize()
      }
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async ActiveItemChange (item) {
      if (item) {
        const methodName = 'ActiveItemChange'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        const content = await this.DocumentMixin_Folders(item.key)
        item.children = this.GetTreeviewItemsFormatted(content.folders, item)
        item.items = content.items
        this.allowDocuments = content.allowDocuments
        this.allowFolders = content.allowCustomFolders
        this.FrameworkMixin_Load(this.$options.name, methodName, false)
      } else {
        this.allowDocuments = false
        this.allowFolders = false
      }

      this.activeItem = item
    },
    ChangeFolder (key) {
      this.ActiveItemChange({
        key: key
      })
    },
    CloseMenu () {
      this.viewMenu = false
    },
    async CreateFile () {
      const methodName = 'CreateFile'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const input = document.createElement('input')
      input.type = 'file'
      input.multiple = true

      input.onchange = async e => {
        const files = e.target.files
        for (let index = 0; index < files.length; index++) {
          const file = files[index]

          await this.DocumentMixin_UploadFile(this.activeItem.key, file)
        }
        this.ChangeFolder(this.activeItem.key)
      }

      input.click()
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async CreateFolder () {
      const methodName = 'CreateFolder'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const name = prompt(this.TextMixin_GetLabel('add', this.TextMixin_GetParameter('folder')), 'Nieuwe map')
      const response = await this.DocumentMixin_CreateFolder(this.activeItem?.key, name)

      if (response && response.folderKey) {
        this.activeItem.items.items.push(this.GetOverviewItemFormatted(response, 'Folder'))
        this.activeItem.children.push(this.GetTreeviewItemFormatted(response, this.activeItem))
      }

      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async Download (item) {
      const methodName = 'Download'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const response = await this.ContentMixin_Service_BC_DetailBasic(item.idDocument, this.documentController)
      if (response && response.data) {
        const blob = await DocumentService.Download(response.data.idBlob)
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob.data)
        link.download = item.itemName.value
        link.click()
        URL.revokeObjectURL(link.href)
      }

      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    GetOverviewItemFormatted (item, itemType) {
      return {
        description: item.description,
        itemKey: item.folderKey,
        itemName: item.description,
        itemType: {
          value: itemType
        }
      }
    },
    GetTreeviewItemFormatted (item, parent) {
      return {
        children: null,
        items: null,
        key: item?.folderKey,
        parentKey: parent?.key,
        parentText: parent?.text?.value,
        text: item?.description
      }
    },
    GetTreeviewItemsFormatted (items, parent) {
      return items ? items.map(item => this.GetTreeviewItemFormatted(item, parent)) : []
    },
    async Initialize () {
      const methodName = 'Initialize'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      DocumentService.Init(this.ErrorMixin_HandleError)
      await this.ContentMixin_Service_BC_InitializeContentService(this.documentController)
      const content = await this.DocumentMixin_Folders(null, this.folderParams)
      this.items = this.GetTreeviewItemsFormatted(content?.folders)
      this.allowDocuments = false
      this.allowFolders = false
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async OpenMenu (rightclick) {
      const e = rightclick.event
      this.contextMenuItem = rightclick.item
      this.contextMenuPosition.X = e.clientX
      this.contextMenuPosition.Y = e.clientY
      this.viewMenu = true
    },
    async RenameFolder (item) {
      const methodName = 'RenameFolder'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const newName = prompt(`${this.TextMixin_GetLabel('rename')} "${item.description.value}"`, item.description.value)
      const response = await this.DocumentMixin_RenameFolder(item.itemKey, newName)

      if (response && response.description.value === newName) {
        const child = this.activeItem.children.filter(folder => folder.key === item.itemKey)[0]
        const subItem = this.activeItem.items.items.filter(x => x.itemKey === item.itemKey)[0]

        child.text.value = newName
        subItem.description.value = newName
        subItem.itemName.value = newName
      }

      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async SelectItem (item) {
      if (item && item.itemType) {
        if (item.itemType.value === 'Folder') {
          this.ChangeFolder(item.itemKey)
        } else {
          this.EntityCardMixin_PickersUpdate(this.Pickers.document, item)
        }
      }
    }
  }
}
</script>
