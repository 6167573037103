import FrameworkStore from '@/stores/framework-store'

export default {
  data () {
    return {
      errorMixin: {
        errors: [
          {
            code: 0,
            description: 'Unknown',
            redirect: false,
            text: [
              'Something went wrong.'
            ]
          },
          {
            code: 401,
            description: 'Unauthorized',
            redirect: true,
            text: [
              'The HyperText Transfer Protocol (HTTP) <b>401 Unauthorized</b> response status code indicates that the client request has not been completed because it lacks valid authentication credentials for the requested resource.',
              'This status code is sent with an HTTP WWW-Authenticate response header that contains information on how the client can request for the resource again after prompting the user for authentication credentials.',
              'This status code is similar to the 403 Forbidden status code, except that in situations resulting in this status code, user authentication can allow access to the resource.'
            ]
          },
          {
            code: 404,
            description: 'Not Found',
            redirect: true,
            text: [
              'The HTTP <b>404 Not Found</b> response status code indicates that the server cannot find the requested resource. Links that lead to a 404 page are often called broken or dead links and can be subject to link rot.',
              'A 404 status code only indicates that the resource is missing: not whether the absence is temporary or permanent. If a resource is permanently removed, use the 410 (Gone) status instead.'
            ]
          },
          {
            code: 417,
            description: 'Expectation Failed',
            redirect: false,
            text: [
              'The HTTP <b>417 Expectation Failed</b> client error response code indicates that the expectation given in the request\'s Expect header could not be met.',
              'See the Expect header for more details.'
            ]
          },
          {
            code: 500,
            description: 'Internal Server Error',
            redirect: false,
            text: [
              'The HyperText Transfer Protocol (HTTP) <b>500 Internal Server Error</b> server error response code indicates that the server encountered an unexpected condition that prevented it from fulfilling the request.',
              'This error response is a generic "catch-all" response. Usually, this indicates the server cannot find a better 5xx error code to response. Sometimes, server administrators log error responses like the 500 status code with more details about the request to prevent the error from happening again in the future.'
            ]
          }
        ]
      }
    }
  },
  methods: {
    ErrorMixin_GetError (code) {
      return this.errorMixin.errors.find(error => error.code.toString() === code.toString()) ?? this.errorMixin.errors.find(error => error.code === 0)
    },
    async ErrorMixin_HandleError (error) {
      if (error.response) {
        if (error.response.status === 403) {
          await this.AuthenticationMixin_Logout()
        }

        const errorInformation = this.ErrorMixin_GetError(error.response.status)

        if (errorInformation.redirect) {
          this.$router.push({ path: 'Error', query: { code: error.response.status } })
        } else {
          let message = errorInformation.text

          if (errorInformation.code === 417) {
            message = error.response.data != null && error.response.data.toString().trim().length > 0 ? error.response.data : this.TextMixin_GetMessage('error')
          }

          this.FrameworkMixin_Snackbar(message, 'error')
        }
      } else {
        if (!this.FrameworkMixin_Error) {
          FrameworkStore().error = true
          console.error(this.TextMixin_GetMessage('error'), 'error')
        }
      }
    }
  }
}
