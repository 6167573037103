<template>
  <care-partners />
</template>

<script>
import CarePartners from '@/views/carepartners/components/care-partners'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'CarePartnersView',
  components: {
    CarePartners
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
