<template>
  <div
    class="d-flex"
  >
    <v-btn
      x-small
      @click="CopyContentToClipboard()"
    >
      <v-icon
        x-small
      >
        mdi-content-copy
      </v-icon>
    </v-btn>
    <pre
      v-html="JsonTree(value, name)"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseDebugger',
  props: {
    // Value to visually debug
    value: null,
    // Name of object
    name: {
      default: 'debugger',
      type: String
    }
  },
  methods: {
    async CopyContentToClipboard () {
      try {
        await navigator.clipboard.writeText(JSON.stringify(this.value, null, 2))
      } catch (err) {
        alert('⚠️ Json niet gekopieerd!')
      }
    }
  }
}
</script>

<style>
.tree {
  --radius : 10px;
  --spacing : 1.5rem;
}

.tree li {
  display : block;
  padding-left : calc(2 * var(--spacing) - var(--radius) - 2px);
  position : relative;
}

.tree ul {
  margin-left : calc(var(--radius) - var(--spacing));
  padding-left : 0;
}

.tree ul li {
  border-left : 2px solid #ddd;
}

.tree ul li:last-child {
  border-color : transparent;
}

.tree ul li::before {
  border : solid #ddd;
  border-width : 0 0 2px 2px;
  content : '';
  display : block;
  height : calc(var(--spacing) + 1px);
  left : -2px;
  position : absolute;
  top : calc(var(--spacing) / -2);
  width : calc(var(--spacing) + 2px);
}

.tree summary {
  cursor : pointer;
  display : block;
}

.tree summary::marker, .tree summary::-webkit-details-marker {
  display : none;
}

.tree summary:focus {
  outline : none;
}

.tree summary:focus-visible {
  outline : 1px dotted #000;
}

.tree li::after, .tree summary::before {
  background : #ddd;
  border-radius : 50%;
  content : '';
  display : block;
  height : calc(2 * var(--radius));
  left : calc(var(--spacing) - var(--radius) - 1px);
  position : absolute;
  top : calc(var(--spacing) / 2 - var(--radius));
  width : calc(2 * var(--radius));
}

.tree summary::before {
  background : #696;
  color : #fff;
  content : '+';
  line-height : calc(2 * var(--radius) - 2px);
  text-align : center;
  z-index : 1;
}

.tree details[open] > summary::before {
  content : '−';
}

details[open] > summary > span.debugMixin-object {
  display: none;
}

.debugMixin-string {
  color: red
}

.debugMixin-key {
  color: grey
}

.debugMixin-boolean {
  color: blue
}

.debugMixin-null {
  color: darkblue
}

.debugMixin-number {
  color: green
}
</style>
