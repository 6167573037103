<template>
  <base-dialog
    :value="value"
    :max-width="1620"
    persistent
    scrollable
    :title="Title"
    @close="$emit('close')"
    @input="$emit('input', $event)"
  >
    <component
      :is="content.component"
      v-if="content.component && content.element"
      :defaults="content.defaults"
      :parameters="parameters"
      :readonly="content.readonly"
      source="dialog"
      @close="$emit('close')"
      @save="$emit('save')"
    />
  </base-dialog>
</template>

<script>
import ActivitiesDetail from '@/views/activities/components/activities-detail'
import BankStatementsDetail from '@/views/bankstatements/components/bank-statements-detail'
import CarePartnersDetail from '@/views/carepartners/components/care-partners-detail'
import ChildrenDetail from '@/views/children/components/children-detail'
import DocumentsDetail from '@/views/documents/components/documents-detail'
import FamiliesDetail from '@/views/families/components/families-detail'
import ContentMixin from '@/mixins/content-mixin'
import DialogMixin from '@/mixins/dialog-mixin'

export default {
  name: 'DialogDetail',
  components: {
    ActivitiesDetail,
    BankStatementsDetail,
    CarePartnersDetail,
    ChildrenDetail,
    DocumentsDetail,
    FamiliesDetail
  },
  mixins: [
    ContentMixin,
    DialogMixin
  ],
  props: {
    content: Object,
    parameters: Array
  },
  computed: {
    Title () {
      return this.title ?? (this.content.title && this.content.type ? this.TextMixin_GetTitle(this.content.title, this.TextMixin_GetParameter(this.content.type)) : '')
    }
  },
  watch: {
    content: {
      deep: true,
      handler () {
        this.Initialize()
      }
    }
  },
  beforeDestroy () {
    this.Destroy()
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    Destroy () {
      this.ContentMixin_Store_Detail.dialog.element = null
      this.ContentMixin_Store_Detail.dialog.copy = null
    },
    Initialize () {
      this.ContentMixin_Store_Detail.dialog.element = this.CommonMixin_Clone(this.content.element)
    }
  }
}
</script>
