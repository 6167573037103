import CodeService from '@/services/code-service'

export default {
  async mounted () {
    CodeService.Init(this.ErrorMixin_HandleError)
  },
  methods: {
    async CodeMixin_GetCodes (codeTypes = [], idOptionLevel) {
      const methodName = 'CodeMixin_GetCodes'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const idLanguage = this.constantMixin.codes.language.values[this.FrameworkMixin_Language.toLowerCase()]
      const response = await CodeService.List(idLanguage, codeTypes, idOptionLevel)
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
      return response?.data
    }
  }
}
