<template>
  <div>
    <entity-card-requests-detail-process-request-intervention-children />
    <entity-card-families-detail-situation
      v-if="ContentMixin_Store_DetailSourceCopy.family?.value"
      v-model="ContentMixin_Store_DetailSourceCopy.familyDetail"
    />
  </div>
</template>

<script>
import EntityCardFamiliesDetailSituation from '@/components/shared/entitycards/entity-card-families-detail-situation'
import EntityCardRequestsDetailProcessRequestInterventionChildren from '@/components/shared/entitycards/entity-card-requests-detail-process-request-intervention-children'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'RequestsDetailProcessRequestIntervention',
  components: {
    EntityCardFamiliesDetailSituation,
    EntityCardRequestsDetailProcessRequestInterventionChildren
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Request',
      details: {
        family: null
      }
    }
  },
  computed: {
    Pickers () {
      return {
        family: this.EntityCardMixin_Pickers(false, 'Family', 'family', 'families')
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    HasRequestStatus (value) {
      return this.ContentMixin_Element.requestStatus.value && this.ContentMixin_Element.requestStatus.value.toUpperCase() === this.constantMixin.codes.requestStatus.values[value].toUpperCase()
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller, false)
      await this.EntityCardMixin_PickersGetDetails(this.Pickers.family, this.ContentMixin_Store_DetailSourceCopy.family.value)
    }
  }
}
</script>
