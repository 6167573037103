<template>
  <div
    class="base-field-checkbox"
  >
    <v-checkbox
      ref="base-field-checkbox"
      v-model="childMixin.element"
      :class="baseFieldClass"
      :color="color"
      :dense="dense"
      :disabled="disabled"
      :indeterminate="childMixin.element == null"
      :label="label"
      :outlined="outlined"
      :readonly="readonly"
      :rules="rules"
      @change="$emit('change', $event)"
      @keyup="$emit('keyup', $event)"
      />
  </div>
</template>

<script>
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseFieldCheckbox',
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // Value to be shown
    value: Boolean
  },
  watch: {
    'childMixin.element' () {
      this.Emit()
    },
    value () {
      this.FormatData()
    }
  },
  mounted () {
    this.FormatData()
  },
  methods: {
    Emit () {
      // Fire when the data has changed
      this.$emit('input', this.childMixin.element)
    },
    FormatData (value = this.value) {
      this.childMixin.element = this.CommonMixin_Clone(value)
    }
  }
}
</script>
