import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const theme = {
  accent: '#F3F3F3',
  error: '#B22222',
  info: '#008ECC',
  primary: '#EA3326',
  secondary: '#123958',
  success: '#009E60',
  warning: '#DAA520'
}

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
})
