import TextMixinHeaders from '@/mixins/text-mixin.headers'
import TextMixinLabels from '@/mixins/text-mixin.labels'
import TextMixinMessages from '@/mixins/text-mixin.messages'
import TextMixinPages from '@/mixins/text-mixin.pages'
import TextMixinParameters from '@/mixins/text-mixin.parameters'
import TextMixinSteps from '@/mixins/text-mixin.steps'
import TextMixinTabs from '@/mixins/text-mixin.tabs'
import TextMixinTitles from '@/mixins/text-mixin.titles'
import TextMixinValidationErrors from '@/mixins/text-mixin.validation-errors'

export default {
  mixins: [
    TextMixinHeaders,
    TextMixinLabels,
    TextMixinMessages,
    TextMixinPages,
    TextMixinParameters,
    TextMixinSteps,
    TextMixinTabs,
    TextMixinTitles,
    TextMixinValidationErrors
  ],
  computed: {
    TextMixin_Content () {
      return {
        headers: this.TextMixin_Headers,
        labels: this.TextMixin_Labels,
        messages: this.TextMixin_Messages,
        pages: this.TextMixin_Pages,
        parameters: this.TextMixin_Parameters,
        steps: this.TextMixin_Steps,
        tabs: this.TextMixin_Tabs,
        titles: this.TextMixin_Titles,
        validationErrors: this.TextMixin_ValidationErrors
      }
    }
  },
  methods: {
    TextMixin_GetHeader (header, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`headers.${header}`, parameters)
    },
    TextMixin_GetLabel (label, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`labels.${label}`, parameters)
    },
    TextMixin_GetMessage (message, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`messages.${message}`, parameters)
    },
    TextMixin_GetPage (page = this.$route.name, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`pages.${page}`, parameters)
    },
    TextMixin_GetParameter (parameter, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`parameters.${parameter}`, parameters)
    },
    TextMixin_GetStep (step, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`steps.${step}`, parameters)
    },
    TextMixin_GetTab (tab, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`tabs.${tab}`, parameters)
    },
    TextMixin_GetTextInCurrentLanguage (path, parameters, base = this.TextMixin_Content) {
      const subItems = path.split('.')
      let value = base

      subItems.forEach(subItem => {
        if (value != null) {
          value = value[subItem]
        }
      })

      let text = value ? value[this.TextMixin_Language.toLowerCase()] : null

      if (text != null) {
        if (parameters) {
          if (!Array.isArray(parameters)) {
            parameters = [parameters]
          }

          parameters.forEach(parameter => {
            text = text.replace(this.textMixin.placeholders.parameter, parameter)
          })
        }

        text = text.replaceAll(this.textMixin.placeholders.parameter, '').replaceAll('  ', ' ').trim()
        return text
      } else {
        const parameter = `<%${path}[${this.TextMixin_Language}]%>`
        console.error(`TO BE TRANSLATED: ${parameter}`)
        return parameter
      }
    },
    TextMixin_GetTitle (title, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`titles.${title}`, parameters)
    },
    TextMixin_GetValidationError (validationError, parameters) {
      return this.TextMixin_GetTextInCurrentLanguage(`validationErrors.${validationError}`, parameters)
    },
    TextMixin_LogAllCsvFormat () {
      const names = ['Headers', 'Labels', 'Messages', 'Pages', 'Parameters', 'Steps', 'Tabs', 'Titles']

      names.forEach(name => {
        const list = this[`TextMixin_${name}`]
        let log = `--------------------\n${name}\n--------------------\n\nSystem;FR;NL`
        Object.keys(list).forEach(key => {
          log += `\n${key};${list[key].fr};${list[key].nl}`
        })

        console.log(log)
      })
    },
    TextMixin_LoadLanguage () {
      this.FrameworkMixin_Language = this.$cookie.get(this.textMixin.cookie.name) ?? this.textMixin.default.language
      this.TextMixin_SetLanguage(this.FrameworkMixin_Language, false)
    },
    async TextMixin_SetLanguage (languageAbbreviation, setCookie = true, setStore = false) {
      this.TextMixin_SetWebsiteTitle()

      if (this.AuthenticationMixin_LoggedIn) {
        await this.AuthenticationMixin_ChangeLanguage(this.constantMixin.codes.language.values[languageAbbreviation.toLowerCase()])
      }

      if (setStore) {
        this.FrameworkMixin_Language = languageAbbreviation
      }

      if (setCookie) {
        this.$cookie.set(this.textMixin.cookie.name, languageAbbreviation, { expires: this.textMixin.cookie.timespan })
      }
    },
    TextMixin_SetWebsiteTitle (name = 'website') {
      document.title = this.TextMixin_GetTitle(name)
    }
  }
}
