<template>
  <div
    class="mt-3"
  >
    <v-menu
      v-if="deletable || $slots.default"
      class="pa-0 rounded"
      offset-x
    >
      <template
        #activator="{ on, attrs }"
      >
        <v-btn
          v-bind="attrs"
          class="mr-1"
          color="primary"
          :disabled="disabled"
          style="min-width: 0; width: 35px;"
          v-on="on"
        >
          <v-icon>
            mdi-dots-horizontal
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <slot
          name="custom-actions-menu-1"
        />
        <v-list-item
          v-if="deletable"
          class="v-menu-list-item"
          @click="$emit('delete')"
        >
          <v-list-item-title>
            {{ TextMixin_GetLabel('delete') }}
          </v-list-item-title>
        </v-list-item>
        <slot
          name="custom-actions-menu-2"
        />
      </v-list>
    </v-menu>
    <div
      class="float-right"
    >
      <slot
        name="custom-actions-1"
      />
      <v-btn
        v-if="dirty"
        class="ml-1"
        color="primary"
        :disabled="disabled"
        @click="$emit('save')"
      >
        {{ TextMixin_GetLabel(saveLabel) }}
      </v-btn>
      <slot
        name="custom-actions-2"
      />
      <v-btn
        v-if="edit && dirty"
        class="ml-1"
        color="primary"
        :disabled="disabled"
        @click="$emit('revert-changes')"
      >
        {{ TextMixin_GetLabel('cancel') }}
      </v-btn>
      <slot
        name="custom-actions-3"
      />
    </div>
    <div
      class="clear"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseCrudButtons',
  props: {
    // Is element deletable
    deletable: Boolean,
    // True if there are unsaved changes
    dirty: Boolean,
    // Disable buttons
    disabled: Boolean,
    // Is it edit mode or read-only
    edit: Boolean,
    // Extra options for in the ...-button
    extraOptions: {
      default: () => [],
      type: Array
    },
    // True if changes are revertable
    revertable: Boolean,
    // Label for save button
    saveLabel: {
      default: 'save',
      type: String
    }
  }
}
</script>

<style
  scoped
>
.v-list {
  padding: 0;
}
</style>
