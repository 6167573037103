import FrameworkStore from '@/stores/framework-store'

export default {
  computed: {
    FrameworkMixin_LeaveConfirmed: {
      get () {
        return FrameworkStore().leaveConfirmed
      },
      set (value) {
        FrameworkStore().leaveConfirmed = value
      }
    },
    FrameworkMixin_Disable () {
      // return this.FrameworkMixin_Error || this.FrameworkMixin_Loading
      return this.FrameworkMixin_Loading
    },
    FrameworkMixin_Error () {
      return FrameworkStore().error
    },
    FrameworkMixin_Language: {
      get () {
        return FrameworkStore().language
      },
      set (value) {
        FrameworkStore().language = value
      }
    },
    FrameworkMixin_Loading () {
      return FrameworkStore().loading && FrameworkStore().loading.length > 0
    },
    FrameworkMixin_LoadingComponents () {
      return FrameworkStore().loading
    },
    FrameworkMixin_ShowSidebar: {
      get () {
        return FrameworkStore().sidebar
      },
      set (value) {
        this.FrameworkMixin_ToggleSidebar(value)
      }
    },
    FrameworkMixin_Snackbars () {
      return FrameworkStore().snackbars.filter(snackbar => snackbar.show)
    },
    FrameworkMixin_Theme () {
      if (this.$vuetify.theme.isDark) {
        return this.$vuetify.theme.themes.dark
      } else {
        return this.$vuetify.theme.themes.light
      }
    }
  },
  methods: {
    FrameworkMixin_Load (component, method, loading) {
      const object = {
        component: component,
        method: method,
        loading: loading
      }

      FrameworkStore().ShowLoader(object)
    },
    FrameworkMixin_SetElementWithPocoStatus (elementWithPocoStatus, property, value, listItemIndex = null) {
      if (listItemIndex) {
        elementWithPocoStatus[property][listItemIndex] = this.CommonMixin_Clone(value)
      } else {
        elementWithPocoStatus[property].value = this.CommonMixin_Clone(value)
      }
    },
    FrameworkMixin_Snackbar (text, color, timeout = 10) {
      const snackbar = {
        color: color,
        show: true,
        text: text,
        timeout: timeout
      }

      FrameworkStore().ShowSnackbar(snackbar)
    },
    FrameworkMixin_ToggleSidebar (show = !FrameworkStore().sidebar) {
      FrameworkStore().sidebar = show
    },
    async FrameworkMixin_UpdateElementWithPocoStatus (elementWithPocoStatus, property, value, listItemIndex = null) {
      if (elementWithPocoStatus.pocoStatus == null || this.ContentMixin_ElementHasPocoStatus('unchanged', elementWithPocoStatus) || this.ContentMixin_ElementHasPocoStatus('changed', elementWithPocoStatus)) {
        if (elementWithPocoStatus.originalElement == null) {
          elementWithPocoStatus.originalElement = this.CommonMixin_Clone(elementWithPocoStatus)

          if (property) {
            elementWithPocoStatus.pocoStatus = this.constantMixin.pocoStatus.changed
            this.FrameworkMixin_SetElementWithPocoStatus(elementWithPocoStatus, property, value, listItemIndex)
          }
        } else {
          if (property) {
            elementWithPocoStatus.pocoStatus = this.constantMixin.pocoStatus.changed
            this.FrameworkMixin_SetElementWithPocoStatus(elementWithPocoStatus, property, value, listItemIndex)

            if (this.ContentMixin_ElementHasPocoStatus('unchanged', elementWithPocoStatus) || this.ContentMixin_ElementHasPocoStatus('changed', elementWithPocoStatus)) {
              const duplicate = this.CommonMixin_Clone(elementWithPocoStatus)
              const duplicateOriginal = this.CommonMixin_Clone(duplicate.originalElement)
              delete duplicate.originalElement
              delete duplicate.pocoStatus
              delete duplicateOriginal.pocoStatus

              if (this.CommonMixin_HardCompare(duplicate, duplicateOriginal)) {
                const originalPocoStatus = this.CommonMixin_Clone(elementWithPocoStatus.originalElement.pocoStatus)
                elementWithPocoStatus.pocoStatus = originalPocoStatus
                delete elementWithPocoStatus.originalElement
                return true
              }
            }
          }
        }
      } else if (this.ContentMixin_ElementHasPocoStatus('new', elementWithPocoStatus)) {
        this.FrameworkMixin_SetElementWithPocoStatus(elementWithPocoStatus, property, value, listItemIndex)
      }

      return false
    }
  }
}
