<template>
  <v-app>
    <framework-loader />
    <framework-sidebar />
    <framework-topbar />
    <framework-view />
    <framework-snackbar-stack />
  </v-app>
</template>

<script>
import FrameworkLoader from '@/components/framework/framework-loader'
import FrameworkSidebar from '@/components/framework/framework-sidebar'
import FrameworkSnackbarStack from '@/components/framework/framework-snackbar-stack'
import FrameworkTopbar from '@/components/framework/framework-topbar'
import FrameworkView from '@/components/framework/framework-view'

export default {
  name: 'FrameworkContainer',
  components: {
    FrameworkLoader,
    FrameworkSidebar,
    FrameworkSnackbarStack,
    FrameworkTopbar,
    FrameworkView
  },
  data () {
    return {
      expandOnHover: false,
      valid: false
    }
  },
  created () {
    this.TextMixin_LoadLanguage()
  }
}
</script>
