<template>
  <v-container
    class="px-3"
    fluid
    tag="section"
  >
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        lg="6"
      >
        <v-card
          v-if="error != null"
          class="px-5 mt-15"
        >
          <v-card-title>
            <h1
              class="my-5 center text-center"
              style="font-size: 50px; line-height: 55px;"
            >
              ERROR{{ error.code ? ` ${error.code}` : ''}}: {{ error.description }}
            </h1>
          </v-card-title>
          <v-card-text
            v-if="redirect || (showDescription && error.text)"
            class="text-center"
          >
            <div
              v-if="showDescription"
            >
              <p
                v-for="(paragraph, index) in error.text"
                :key="index"
                v-html="paragraph"
              />
            </div>
            <p
              v-if="redirect"
            >
              <a @click="$router.push('/')">
                {{ this.TextMixin_GetMessage('redirect') }}
              </a>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Error',
  data () {
    return {
      error: null,
      redirect: false,
      showDescription: false
    }
  },
  mounted () {
    this.GetError()
    this.Redirect()
  },
  methods: {
    GetError () {
      if (this.$route.query != null && this.$route.query.code != null && this.$route.query.code !== '') {
        this.error = this.ErrorMixin_GetError(this.$route.query.code)
      } else {
        this.$router.push({ path: 'Error', query: { code: 404 } })
        this.error = this.ErrorMixin_GetError(this.$route.query.code)
      }
    },
    Redirect () {
      if (!this.AuthenticationMixin_LoggedIn) {
        this.redirect = true
        setTimeout(() => this.$router.push({ path: '/' }), 0)
      }
    }
  }
}
</script>
