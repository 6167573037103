<template>
  <base-form-detail
    ref="baseFormDetail"
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-requests-detail-submit-request-general-information
      :codes="contentMixin.codes"
      :readonly="Readonly"
      :source="source"
      :visible="visible"
    />
    <requests-detail-submit-request-extra-budget
      v-if="HasRequestType('extraBudget')"
      :codes="contentMixin.codes"
      :readonly="Readonly"
      :source="source"
      :visible="visible"
    />
    <requests-detail-submit-request-intervention
      v-if="HasRequestType('intervention')"
      :readonly="Readonly"
      :source="source"
      :visible="visible"
    />
    <template
      slot="custom-actions-1"
    >
      <v-btn
        v-if="HasRequestStatus('moreInformationRequired')"
        color="primary"
        :disabled="FrameworkMixin_Disable"
        @click="Resubmit()"
      >
        {{ TextMixin_GetLabel('resubmit') }}
      </v-btn>
    </template>
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardRequestsDetailSubmitRequestGeneralInformation from '@/components/shared/entitycards/entity-card-requests-detail-submit-request-general-information'
import RequestsDetailSubmitRequestExtraBudget from '@/views/requests/components/requests-detail-submit-request-extra-budget'
import RequestsDetailSubmitRequestIntervention from '@/views/requests/components/requests-detail-submit-request-intervention'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'RequestsDetailSubmitRequest',
  components: {
    BaseFormDetail,
    EntityCardRequestsDetailSubmitRequestGeneralInformation,
    RequestsDetailSubmitRequestExtraBudget,
    RequestsDetailSubmitRequestIntervention
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Request'
    }
  },
  computed: {
    Readonly () {
      return !(this.ContentMixin_ElementHasPocoStatus('new') || this.ContentMixin_Element.requestStatus.value.toUpperCase() === this.constantMixin.codes.requestStatus.values.moreInformationRequired.toUpperCase()) // TODO: Enkel indien oorspronkelijke aanvrager
    },
    RequestType () {
      return this.ContentMixin_Element?.requestType?.value
    }
  },
  watch: {
    async RequestType (value) {
      if (value && this.ContentMixin_ElementHasPocoStatus('new')) {
        const response = await this.ContentMixin_Service_Custom(`${this.controller}/Detail/NewRequestDetailByType?idRequestType=${value}`)

        if (response?.data) {
          this.CommonMixin_HardCopy(this.ContentMixin_Element, response.data)
        }
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    HasRequestStatus (value) {
      return this.ContentMixin_Element.requestStatus.value && this.ContentMixin_Element.requestStatus.value.toUpperCase() === this.constantMixin.codes.requestStatus.values[value].toUpperCase()
    },
    HasRequestType (value) {
      return this.ContentMixin_Element.requestType.value && this.ContentMixin_Element.requestType.value.toUpperCase() === this.constantMixin.codes.requestType.values[value].toUpperCase()
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.ContentMixin_Service_BC_InitializeCodes()
    },
    async Resubmit () {
      this.ContentMixin_Element.requestStatus.value = this.constantMixin.codes.requestStatus.values.resubmitted.toUpperCase()
      await this.$refs.baseFormDetail.BaseFormDetailMixin_Save()
      this.$emit('save')
    }
  }
}
</script>
