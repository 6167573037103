<template>
  <div
    class="base-field-file-input"
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-file-input
      v-model="file"
      :accept="accept"
      :class="baseFieldClass"
      :clearable="Clearable"
      :color="color"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :multiple="multiple"
      :show-size="showSize"
      :small-chips="chips"
      prepend-icon=""
      :prepend-inner-icon="icon"
      :rules="rules"
      @blur="Blur()"
      @focus="Focus()"
    >
      <template
        v-if="Downloadable"
        #append
      >
        <v-btn
          class="pa-3"
          icon
          @click="Download()"
        >
          <v-icon>
            mdi-download
          </v-icon>
        </v-btn>
      </template>
    </v-file-input>
  </div>
</template>

<script>
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseFieldFileInput',
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // v-model value
    value: Object,
    // Accepted file types
    accept: String,
    // Display Chips
    chips: Boolean,
    // Icon
    icon: String,
    // Allow multiple files
    multiple: Boolean,
    // Show file size
    showSize: Boolean
  },
  data () {
    return {
      file: null,
      focus: false,
      hovering: false,
      initializing: true
    }
  },
  computed: {
    Clearable () {
      return this.ShowControls && !this.readonly
    },
    Downloadable () {
      return this.ShowControls && this.childMixin.element.pocoStatus === this.constantMixin.pocoStatus.unchanged && this.childMixin.element?.fileName
    },
    ShowControls () {
      return (this.hovering || this.focus)
    }
  },
  watch: {
    file () {
      if (!this.initializing) {
        this.Emit()
      } else {
        this.initializing = false
      }
    },
    value (newValue, oldValue) {
      if (!this.CommonMixin_HardCompare(newValue, oldValue)) {
        this.FormatData()
      }
    }
  },
  mounted () {
    this.FormatData()
  },
  methods: {
    Blur () {
      this.focus = false
    },
    Download () {
      this.ChildMixin_Emit('download')
    },
    Emit () {
      if (this.file?.size) {
        const reader = new FileReader()

        reader.onload = (e) => {
          if (this.childMixin.element.pocoStatus === this.constantMixin.pocoStatus.unchanged && !this.childMixin.element?.fileName) {
            this.childMixin.element.pocoStatus = this.constantMixin.pocoStatus.new
          } else if (this.childMixin.element.pocoStatus !== this.constantMixin.pocoStatus.new) {
            this.childMixin.element.pocoStatus = this.constantMixin.pocoStatus.changed
          }

          this.childMixin.element.blobDataString = e.target.result
          this.childMixin.element.fileName = this.file.name
        }

        reader.readAsDataURL(this.file)
      } else {
        this.childMixin.element.fileName = null
        this.childMixin.element.blobDataString = null

        if (this.childMixin.element.pocoStatus === this.constantMixin.pocoStatus.new) {
          this.childMixin.element.pocoStatus = this.constantMixin.pocoStatus.unchanged
        } else {
          this.childMixin.element.pocoStatus = this.constantMixin.pocoStatus.deleted
        }
      }
    },
    Focus () {
      this.focus = true
    },
    FormatData () {
      this.childMixin.initializing = true
      this.initializing = this.value?.fileName != null
      this.childMixin.element = this.CommonMixin_Clone(this.value)

      if (this.childMixin.element?.fileName) {
        this.file = {
          name: this.childMixin.element?.fileName
        }
      } else {
        this.file = null
      }
    },
    MouseLeave () {
      this.hovering = false
    },
    MouseOver () {
      this.hovering = true
    }
  }
}
</script>
