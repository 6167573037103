import AuthenticationService from '@/services/authentication-service'
import ConfigurationService from '@/services/configuration-service'
import Axios from 'axios'
import AuthenticationStore from '@/stores/authentication-store'

export default {
  data () {
    return {
      authenticationMixin: {
        checkedLogin: false,
        cookies: {
          username: {
            name: 'PelicanoPublicLogin',
            timespan: '1Y'
          }
        },
        headers: {
          authorization: 'pelicano-authorization'
        },
        storageKeys: {
          authentication: 'PelicanoAuthentication',
          menu: 'PelicanoMenu'
        }
      }
    }
  },
  computed: {
    AuthenticationMixin_CurrentUser () {
      return {
        email: AuthenticationStore()?.authentication?.email,
        familienaam: AuthenticationStore()?.authentication?.lastName,
        naam: `${AuthenticationStore()?.authentication?.firstName} ${AuthenticationStore()?.authentication?.lastName}`.trim(),
        naamOmgekeerd: `${AuthenticationStore()?.authentication?.lastName} ${AuthenticationStore()?.authentication?.firstName}`.trim(),
        voornaam: AuthenticationStore()?.authentication?.firstName
      }
    },
    AuthenticationMixin_LoggedIn () {
      return AuthenticationStore().authentication != null
    },
    AuthenticationMixin_LoggedInType () {
      return this.AuthenticationMixin_LoggedIn ? AuthenticationStore().authentication.idUserType : null
    },
    AuthenticationMixin_Menu () {
      return AuthenticationStore()?.menu ?? []
    },
    AuthenticationMixin_WrongRights () {
      return this.ContentMixin_Element && ((this.ContentMixin_ElementHasPocoStatus('new') && !this.ContentMixin_ElementHasRights('allowCreate')) || (!this.ContentMixin_ElementHasPocoStatus('new') && !this.ContentMixin_ElementHasRights('allowUpdate')))
    }
  },
  async created () {
    AuthenticationService.Init(this.ErrorMixin_HandleError)
    ConfigurationService.Init(this.ErrorMixin_HandleError)
    await this.AuthenticationMixin_LoadSessionStorageAuthentication()
  },
  methods: {
    async AuthenticationMixin_ChangeLanguage (idCodeLanguage) {
      await AuthenticationService.ChangeLanguage(idCodeLanguage)
    },
    async AuthenticationMixin_CheckIfLoggedIn () {
      await AuthenticationService.CheckIfLoggedIn()
      this.authenticationMixin.checkedLogin = true
    },
    AuthenticationMixin_HasRights (component) {
      return component && (this.AuthenticationMixin_Menu.includes(component.idComponent) || (!component.idComponent && component.children && component.children.filter(child => this.AuthenticationMixin_HasRights(child)).length > 0))
    },
    AuthenticationMixin_HasValidChangePasswordToken () {
      return !!this.$route.query.rpwToken && this.AuthenticationMixin_RecoverPasswordValidateToken(this.$route.query.rpwToken)
    },
    AuthenticationMixin_InputFieldIsDisabled (inputField) {
      const disable = [this.constantMixin.codes.memberRight.values.undefined, this.constantMixin.codes.memberRight.values.hidden, this.constantMixin.codes.memberRight.values.readonly]
      return this.AuthenticationMixin_WrongRights || disable.includes(inputField?.meta?.memberRight)
    },
    AuthenticationMixin_InputFieldIsReadonly (inputField) {
      const readonly = [this.constantMixin.codes.memberRight.values.undefined, this.constantMixin.codes.memberRight.values.hidden, this.constantMixin.codes.memberRight.values.readonly]
      return this.AuthenticationMixin_WrongRights || readonly.includes(inputField?.meta?.memberRight)
    },
    AuthenticationMixin_InputFieldIsShown (inputField) {
      return true
      // TODO
      // const show = [this.constantMixin.codes.memberRight.values.readonly, this.constantMixin.codes.memberRight.values.editable]
      // return show.includes(inputField?.meta?.memberRight)
    },
    async AuthenticationMixin_LoadMenu () {
      const methodName = 'AuthenticationMixin_LoadMenu'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const response = await AuthenticationService.Menu()

      if (response && response.data) {
        AuthenticationStore().menu = response.data.map(item => item.toUpperCase())
        sessionStorage.setItem(this.authenticationMixin.storageKeys.menu, JSON.stringify(AuthenticationStore().menu))
      }

      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async AuthenticationMixin_LoadSessionStorageAuthentication () {
      AuthenticationStore().authentication = JSON.parse(sessionStorage.getItem(this.authenticationMixin.storageKeys.authentication))
      AuthenticationStore().menu = JSON.parse(sessionStorage.getItem(this.authenticationMixin.storageKeys.menu))

      if (AuthenticationStore().authentication) {
        AuthenticationStore().loggedIn = true
        await this.AuthenticationMixin_SetHeader(this.authenticationMixin.headers.authorization, AuthenticationStore().authentication.token)
      } else {
        AuthenticationStore().loggedIn = false
      }
    },
    async AuthenticationMixin_Login (account, remember = false) {
      if (account) {
        const methodName = 'AuthenticationMixin_Login'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        const response = await AuthenticationService.Login(account)

        if (response && response.data) {
          if (remember) {
            this.$cookie.set(this.authenticationMixin.cookies.username.name, account.username, { expires: this.authenticationMixin.cookies.username.timespan })
          } else {
            this.$cookie.delete(this.authenticationMixin.cookies.username.name)
          }

          AuthenticationStore().authentication = response.data
          sessionStorage.setItem(this.authenticationMixin.storageKeys.authentication, JSON.stringify(AuthenticationStore().authentication))
          await this.AuthenticationMixin_SetHeader(this.authenticationMixin.headers.authorization, response.data.token)
          await this.AuthenticationMixin_LoadMenu()
          this.FrameworkMixin_Load(this.$options.name, methodName, false)
          location.reload()
        } else {
          this.FrameworkMixin_Load(this.$options.name, methodName, false)
        }
      }
    },
    async AuthenticationMixin_Logout () {
      if (this.AuthenticationMixin_LoggedIn) {
        const methodName = 'AuthenticationMixin_Logout'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        AuthenticationStore().authentication = null
        AuthenticationStore().loggedIn = false
        await this.AuthenticationMixin_SetHeader(this.authenticationMixin.headers.authorization, null)
        sessionStorage.clear(this.authenticationMixin.storageKeys.authentication)
        this.FrameworkMixin_Load(this.$options.name, methodName, false)

        const redirectLocation = '/'

        if (this.$router.currentRoute.path !== redirectLocation) {
          await this.$router.push('/')
        }

        location.reload()
      }
    },
    async AuthenticationMixin_SetHeader (headerName, value) {
      Axios.defaults.headers.common[headerName] = value
    },
    async AuthenticationMixin_RecoverPasswordSendMail (email) {
      return await AuthenticationService.RecoverPasswordSendMail(email)
    },
    async AuthenticationMixin_RecoverPasswordUpdatePassword (account, token) {
      return await AuthenticationService.RecoverPasswordUpdatePassword(account, token)
    },
    async AuthenticationMixin_RecoverPasswordValidateToken (rpwToken) {
      const response = await AuthenticationService.RecoverPasswordValidateToken(rpwToken)
      return response && response.data ? response.data.isTokenValid : false
    },
    AuthenticationMixin_UserIsAdministrator (userType = this.AuthenticationMixin_LoggedInType) {
      return this.AuthenticationMixin_UserIsOfType(this.constantMixin.userType.administrator, userType)
    },
    AuthenticationMixin_UserIsCarePartner (userType = this.AuthenticationMixin_LoggedInType) {
      return this.AuthenticationMixin_UserIsOfType(this.constantMixin.userType.carePartner, userType)
    },
    AuthenticationMixin_UserIsMonitor (userType = this.AuthenticationMixin_LoggedInType) {
      return this.AuthenticationMixin_UserIsOfType(this.constantMixin.userType.monitor, userType)
    },
    AuthenticationMixin_UserIsOfType (type, userType = this.AuthenticationMixin_LoggedInType) {
      return type === userType
    }
  }
}
