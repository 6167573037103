<template>
  <div>
    <div
      v-for="(snackbar, index) in FrameworkMixin_Snackbars"
      :key="index"
      class="clickAction"
      @click="Hide(snackbar)"
    >
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        right
        :style="`bottom: ${Bottom(index)}px`"
        timeout="-1"
      >
        <div
          :id="GetId(index)"
        >
          {{ snackbar.text }}
        </div>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FrameworkSnackbarStack',
  data () {
    return {
      running: false
    }
  },
  watch: {
    FrameworkMixin_Snackbars (newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        const snackbar = newValue[newValue.length - 1]
        snackbar.interval = setInterval(() => this.Timeout(snackbar), 1000)
      }
    }
  },
  methods: {
    Bottom (index) {
      let bottom = 0

      if (index > 0) {
        const previous = this.FrameworkMixin_Snackbars[index - 1].bottom
        let height = this.FrameworkMixin_Snackbars[index - 1].height

        if (!height) {
          const element = document.getElementById(this.GetId(index - 1))
          const rectangle = element.getBoundingClientRect()
          height = rectangle.height + 36
          this.FrameworkMixin_Snackbars[index - 1].height = height
        }

        bottom = previous + height
      }

      this.FrameworkMixin_Snackbars[index].bottom = bottom
      return bottom
    },
    GetId (index) {
      return `snackbar-item-${index}`
    },
    Hide (snackbar) {
      snackbar.show = false
    },
    Timeout (snackbar) {
      if (snackbar && snackbar.timeout > 0) {
        snackbar.timeout--

        if (snackbar.timeout <= 0) {
          this.Hide(snackbar)
          clearInterval(snackbar.interval)
        }
      } else if (snackbar) {
        clearInterval(snackbar.interval)
      }
    }
  }
}
</script>
