<template>
  <entity-card-documents-list
    :folder-params="folderParams"
    :visible="visible"
  />
</template>

<script>
import EntityCardDocumentsList from '@/components/shared/entitycards/entity-card-documents-list'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'RequestsDocuments',
  components: {
    EntityCardDocumentsList
  },
  mixins: [
    ContentMixin
  ],
  computed: {
    folderParams () {
      const key = 'idRequest'
      return [
        {
          key: key,
          value: this.ContentMixin_Element[key]
        }
      ]
    }
  }
}
</script>
