import { render, staticRenderFns } from "./entity-card-e-mail-detail.vue?vue&type=template&id=72287e1e&"
import script from "./entity-card-e-mail-detail.vue?vue&type=script&lang=js&"
export * from "./entity-card-e-mail-detail.vue?vue&type=script&lang=js&"
import style0 from "./entity-card-e-mail-detail.vue?vue&type=style&index=0&id=72287e1e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports