export default {
  methods: {
    async CacheMixin_GetCachedElement (key, fallbackMethod) {
      const cachedElement = sessionStorage.getItem(key)

      if (cachedElement) {
        return JSON.parse(cachedElement)
      } else {
        const element = await fallbackMethod()
        sessionStorage.setItem(key, JSON.stringify(element))
        return element
      }
    }
  }
}
