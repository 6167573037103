import Moment from 'moment'

export default {
  data () {
    return {
      formatMixin: {
        date: {
          database: 'YYYY-MM-DDTHH:mm:ss',
          date: 'DD/MM/YYYY',
          dateTime: 'DD/MM/YYYY HH:mm',
          dateTimeExact: 'YYYY-MM-DD HH:mm:ss',
          form: 'YYYY-MM-DD',
          formDateTime: 'YYYY-MM-DD HH:mm',
          hours: 'HH',
          minutes: 'mm',
          time: 'HH:mm'
        }
      }
    }
  },
  methods: {
    FormatMixin_Base64ToArrayBuffer (base64) {
      const binaryString = window.atob(base64)
      const binaryLen = binaryString.length
      const bytes = new Uint8Array(binaryLen)

      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }

      return bytes
    },
    FormatMixin_FormatCurrency (value, currency = 'EUR', digits = 2, showZero = true) {
      if (value || showZero) {
        const formatter = new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: digits
        })

        return formatter.format(value ?? 0)
      } else {
        return null
      }
    },
    FormatMixin_FormatDateTime (dateTime, oldFormat, newFormat, culture = null) {
      if (dateTime && dateTime.trim() !== '') {
        if (culture) {
          return Moment(dateTime, oldFormat).locale(culture).format(newFormat)
        }
        return Moment(dateTime, oldFormat).format(newFormat)
      } else {
        return null
      }
    },
    FormatMixin_FormatDecimal (value, digits = 2, showZero = true) {
      if (value || showZero) {
        const formatter = new Intl.NumberFormat('nl-NL', {
          style: 'decimal',
          maximumFractionDigits: digits,
          minimumFractionDigits: digits
        })

        return formatter.format(value ?? 0)
      } else {
        return null
      }
    }
  }
}
