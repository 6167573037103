import ContentMixin from '@/mixins/content-mixin'
import LockService from '@/services/lock-service'

export default {
  mixins: [
    ContentMixin
  ],
  props: {
    // Element to override the shown element in some circumstances
    value: {
      default: () => {},
      type: Object
    },
    // Is in a dialog
    dialog: Boolean,
    // Is readonly
    readonly: Boolean,
    // Return to overview after saving
    returnAfterSave: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      baseFormDetailMixin: {
        autosaveTime: 100,
        dialog: false,
        edit: true,
        extraLocks: [],
        initialized: false,
        initializing: true,
        interval: null,
        key: null,
        returnUrl: '',
        subcomponent: null,
        valid: false
      }
    }
  },
  watch: {
    ContentMixin_ElementIsDirty (value) {
      if (this.baseFormDetailMixin.initialized) {
        if (value && this.baseFormDetailMixin.edit) {
          this.BaseFormDetailMixin_HandleDirty()
        } else {
          if (this.baseFormDetailMixin.initializing) {
            this.baseFormDetailMixin.initializing = false
          } else if (!this.ContentMixin_ElementIsRevertable()) {
            this.BaseFormDetailMixin_HandleNotDirty()
          }
        }
      }
    }
  },
  beforeDestroy () {
    if (this.baseFormDetailMixin.interval) {
      clearInterval(this.baseFormDetailMixin.interval)
      this.baseFormDetailMixin.interval = null
    }
  },
  methods: {
    async BaseFormDetailMixin_Autosave () {
      if (this.ContentMixin_ElementIsDirty && !this.ContentMixin_ElementHasPocoStatus('new') && !this.baseFormDetailMixin.dialog) {
        await this.BaseFormDetailMixin_Save(true)
      } else if (!this.ContentMixin_ElementIsDirty || this.ContentMixin_ElementHasPocoStatus('new')) {
        if (this.baseFormDetailMixin.interval) {
          clearInterval(this.baseFormDetailMixin.interval)
          this.baseFormDetailMixin.interval = null
        }
      }
    },
    async BaseFormDetailMixin_CheckIfReleased (editing = false) {
      return true
    },
    async BaseFormDetailMixin_Delete () {
      if (this.ContentMixin_ElementId) {
        if (await this.ContentMixin_Service_BC_Delete(this.ContentMixin_ElementId)) {
          this.ContentMixin_Store_DetailSourceElement = null
        }
      }
    },
    async BaseFormDetailMixin_Duplicate () {
      if (this.ContentMixin_ElementId && this.CommonMixin_Confirmation(this.ContentMixin_ElementIsDirty, this.TextMixin_GetMessage('unsavedChanges'), true)) {
        const methodName = 'BaseFormDetailMixin_Duplicate'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        const response = await this.ContentMixin_Service_DetailDuplicate(this.ContentMixin_ElementId)

        if (response && response.data) {
          response.data.pocoStatus = this.constantMixin.pocoStatus.new
          response.data.rights = this.ContentMixin_Element.rights
          this.ContentMixin_Store_DetailSourceElement = response.data
        }

        this.FrameworkMixin_Load(this.$options.name, methodName, false)
      }
    },
    async BaseFormDetailMixin_HandleDirty () {
      if (!this.ContentMixin_ElementHasPocoStatus('new')) {
        if (await this.BaseFormDetailMixin_CheckIfReleased(true)) {
          this.baseFormDetailMixin.interval = setInterval(() => this.BaseFormDetailMixin_Autosave(), this.baseFormDetailMixin.autosaveTime * 1000 * 60)
        }
      }
    },
    async BaseFormDetailMixin_HandleNotDirty () {
      if (!this.ContentMixin_ElementHasPocoStatus('new')) {
        if (await this.BaseFormDetailMixin_CheckIfReleased()) {
          if (this.baseFormDetailMixin.interval) {
            clearInterval(this.baseFormDetailMixin.interval)
            this.baseFormDetailMixin.interval = null
          }
        }
      }
    },
    async BaseFormDetailMixin_Initialize (controller, mainComponent = true) {
      this.contentMixin.key = `id${controller}`
      await this.ContentMixin_Service_BC_InitializeContentService(controller)
      this.baseFormDetailMixin.initialized = mainComponent
      LockService.Init(this.ErrorMixin_HandleError)
      await this.BaseFormDetailMixin_CheckIfReleased()

      if (mainComponent) {
        this.ContentMixin_Store_DetailSourceParameters = this.parameters
      }
    },
    async BaseFormDetailMixin_RevertChanges () {
      if (this.CommonMixin_Confirmation(this.ContentMixin_ElementIsDirty || this.ContentMixin_Store_DetailSourceAutosavedVersion, this.TextMixin_GetMessage('confirmationRevertChanges'), true)) {
        if (this.ContentMixin_ElementHasPocoStatus('new')) {
          if (this.dialog) {
            this.$emit('close')
          } else {
            this.ContentMixin_Store_DetailSourceElement = null
          }
        } else {
          this.ContentMixin_Store_DetailSourceCopy = this.CommonMixin_Clone(this.ContentMixin_Store_DetailSourceElement)

          if (this.ContentMixin_Store_DetailSourceAutosavedVersion) {
            this.ContentMixin_Store_DetailSourceCopy.autoUpdateTimestamp = this.ContentMixin_Store_DetailSourceAutosavedVersion.autoUpdateTimestamp
            await this.BaseFormDetailMixin_Save(false, true)
          } else {
            await this.BaseFormDetailMixin_CheckIfReleased()
          }
        }
      }
    },
    async BaseFormDetailMixin_Save (autosave = false, reverting = false, redirect = false) {
      if (await this.BaseFormDetailMixin_CheckIfReleased(true)) {
        this.$refs.form.validate()

        if (this.baseFormDetailMixin.valid || reverting) {
          const methodName = 'BaseFormDetailMixin_Save'
          this.FrameworkMixin_Load(this.$options.name, methodName, true)
          const duplicate = this.CommonMixin_Clone(this.ContentMixin_Store_DetailSourceCopy)
          duplicate.pocoStatus = this.ContentMixin_IgnorePocoStatus(this.ContentMixin_Store_DetailSourceCopy) ? this.ContentMixin_Store_DetailSourceCopy.pocoStatus : (this.ContentMixin_ElementIsDirty ? this.constantMixin.pocoStatus.changed : this.constantMixin.pocoStatus.unchanged)
          const response = await this.ContentMixin_Service_DetailSave(duplicate)

          if (response && response.data) {
            this.ContentMixin_Store_DetailSourceCopy = this.CommonMixin_Clone(response.data)
            this.ContentMixin_Store_DetailSourceAutosavedVersion = this.CommonMixin_Clone(response.data)

            if (!autosave) {
              if (redirect) {
                this.$router.push({ path: this.$options.name, query: { returnUrl: this.baseFormDetailMixin.returnUrl, [this.contentMixin.key]: this.ContentMixin_ElementId } })
              }

              if (this.source === 'dialog') {
                this.$emit('save')
              } else if (this.returnAfterSave) {
                this.ContentMixin_Store_DetailSourceElement = null
              } else {
                await this.ContentMixin_Service_BC_Detail(this.ContentMixin_Store_DetailSourceElement, true)
              }
            }
          } else {
            this.FrameworkMixin_Snackbar(autosave ? this.TextMixin_GetMessage('autosaveFailed') : this.TextMixin_GetMessage('saveFailed'), 'error')
          }

          this.FrameworkMixin_Load(this.$options.name, methodName, false)
        } else {
          if (autosave) {
            this.FrameworkMixin_Snackbar(`${this.TextMixin_GetMessage('autosaveFailed')}\n${this.TextMixin_GetMessage('formInvalid')}`, 'error')
          } else {
            this.FrameworkMixin_Snackbar(this.TextMixin_GetMessage('formInvalid'), 'error')
          }
        }
      }
    },
    BaseFormDetailMixin_SetReturnUrl (defaultUrl) {
      this.baseFormDetailMixin.returnUrl = defaultUrl

      if (this.$route.query != null) {
        const returnUrl = this.$route.query.returnUrl

        if (returnUrl && returnUrl !== '') {
          this.baseFormDetailMixin.returnUrl = returnUrl
        }
      }
    }
  }
}
