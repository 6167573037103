<template>
  <base-tabs-view
    :tabs="Tabs"
  />
</template>

<script>
import BaseTabsView from '@/components/shared/base/base-tabs-view'
import BaseTabsViewMixin from '@/mixins/base-tabs-view-mixin'

export default {
  name: 'Requests',
  components: {
    BaseTabsView
  },
  mixins: [
    BaseTabsViewMixin
  ],
  computed: {
    Tabs () {
      return [
        {
          component: 'requests-overview',
          idComponent: this.constantMixin.menuItem.requests.general,
          name: this.TextMixin_GetTab('overview')
        },
        {
          component: 'requests-detail',
          disabled: !this.ContentMixin_Element,
          idComponent: this.constantMixin.menuItem.requests.general,
          name: this.TextMixin_GetTab(this.BaseTabsViewMixin_GetDetailsTab(), this.TextMixin_GetParameter('request'))
        },
        {
          component: 'requests-update-history',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.requests.general,
          name: this.TextMixin_GetTab('history')
        },
        {
          component: 'requests-documents',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.requests.documents,
          name: this.TextMixin_GetTab('documents')
        },
        {
          component: 'base-under-construction',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.requests.invoices,
          name: this.TextMixin_GetTab('invoices')
        },
        {
          component: 'base-under-construction',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.requests.paymentOrders,
          name: this.TextMixin_GetTab('paymentOrders')
        },
        {
          component: 'base-under-construction',
          disabled: this.BaseTabsViewMixin_DisableIfNew,
          idComponent: this.constantMixin.menuItem.requests.updateHistory,
          name: this.TextMixin_GetTab('updateHistory')
        }
      ]
    }
  }
}
</script>
