<template>
  <div
    class="pa-0"
  >
    <base-crud-table
      v-if="baseListMixin.rights && baseListMixin.rights.allowRead"
      v-model="baseListMixin.selection"
      :add-action="addAction"
      :double-click-edit="doubleClickEdit"
      :edit-action="editAction"
      :editable="!readonly"
      :headers="BaseListMixin_Headers()"
      :hidden-columns="hiddenColumns"
      :hide-actions="hideActions"
      hide-border
      :hide-filter="hideFilter"
      :hide-footer="hideFooter"
      :hide-pagination="hidePagination"
      :item-key="contentMixin.key"
      :items="baseListMixin.list"
      list-detail
      :list-detail-selected-element="baseListMixin.selected"
      :paging="baseListMixin.paging"
      :readonly="readonly"
      :rights="baseListMixin.rights"
      :show-select="showSelect"
      single-click-select
      :single-select="singleSelect"
      :text-new-item="BaseListDetailMixin_GetAddLabel(readonly, hideFooter, baseListMixin.rights)"
      @create="BaseListDetailMixin_Create(customCreate)"
      @delete="BaseListDetailMixin_Delete($event)"
      @filter="BaseListMixin_Filter($event)"
      @options-change="BaseListDetailMixin_List($event)"
      @read="BaseListDetailMixin_Read($event)"
      @single-click-select="BaseListDetailMixin_Select($event)"
      @update="BaseListDetailMixin_Update(customUpdate, $event)"
    >
      <template
        #[`customActions`]="item"
      >
        <slot
          v-bind="item"
          name="customActions"
        />
      </template>
      <template
        v-for="slot in OverwriteSlots()"
        #[`${slot}`]="item"
      >
        <slot
          v-bind="item"
          :name="slot"
        />
      </template>
      <template
        slot="footer-prepend-1"
      >
        <slot
          name="footer-prepend-1"
        />
      </template>
      <template
        slot="footer-prepend-2"
      >
        <slot
          name="footer-prepend-2"
        />
      </template>
    </base-crud-table>
  </div>
</template>

<script>
import BaseListDetailMixin from '@/mixins/base-list-detail-mixin'

export default {
  name: 'BaseListDetail',
  mixins: [
    BaseListDetailMixin
  ],
  mounted () {
    this.BaseListDetailMixin_Initialize(this.controller)
  },
  methods: {
    OverwriteSlots () {
      return Object.keys(this.$scopedSlots)
    }
  }
}
</script>
