<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-children-detail-general-information
      :codes="contentMixin.codes"
      :readonly="readonly"
      :source="source"
    />
    <entity-card-care-partners-detail-general-information
      v-model="details.carePartner"
      :care-partners="lists.carePartners"
      :codes="lists.codes.carePartner"
      :pickers="[Pickers.carePartner]"
      readonly
      :title="TextMixin_GetTitle('generalInformation', `(${TextMixin_GetParameter('carePartner').toLowerCase()})`)"
      @pickers-change="EntityCardMixin_PickersChange($event)"
    />
    <entity-card-monitors-detail-general-information
      v-if="SelectedCarePartner"
      v-model="details.monitor"
      :monitors="lists.monitors"
      :pickers="[Pickers.monitor]"
      readonly
      :title="TextMixin_GetTitle('generalInformation', `(${TextMixin_GetParameter('monitor').toLowerCase()})`)"
      @pickers-change="EntityCardMixin_PickersChange($event)"
    />
    <entity-card-families-detail-general-information
      v-model="details.family"
      :codes="lists.codes.family"
      :families="lists.families"
      :pickers="[Pickers.family]"
      :posts="lists.posts"
      readonly
      :title="TextMixin_GetTitle('generalInformation', `(${TextMixin_GetParameter('family').toLowerCase()})`)"
      @pickers-change="EntityCardMixin_PickersChange($event)"
    />
    <entity-card-families-detail-legal-family-representatives
      v-if="SelectedFamily"
      v-model="details.family"
      readonly
    />
    <entity-card-children-detail-budget-details
      :readonly="readonly"
      :source="source"
    />
    <entity-card-children-detail-siblings
      :readonly="readonly"
      :source="source"
    />
    <entity-card-families-detail-situation
      v-if="SelectedFamily"
      v-model="details.family"
      readonly
    />
    <entity-card-families-detail-financial-situation-list
      v-if="SelectedFamily"
      v-model="details.family"
      :name="$options.name"
      readonly
    />
    <entity-card-families-detail-financial-situation
      v-if="SelectedFamily"
      v-model="details.family"
      readonly
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardCarePartnersDetailGeneralInformation from '@/components/shared/entitycards/entity-card-care-partners-detail-general-information'
import EntityCardChildrenDetailBudgetDetails from '@/components/shared/entitycards/entity-card-children-detail-budget-details'
import EntityCardChildrenDetailGeneralInformation from '@/components/shared/entitycards/entity-card-children-detail-general-information'
import EntityCardChildrenDetailSiblings from '@/components/shared/entitycards/entity-card-children-detail-siblings'
import EntityCardFamiliesDetailFinancialSituation from '@/components/shared/entitycards/entity-card-families-detail-financial-situation'
import EntityCardFamiliesDetailFinancialSituationList from '@/components/shared/entitycards/entity-card-families-detail-financial-situation-list'
import EntityCardFamiliesDetailGeneralInformation from '@/components/shared/entitycards/entity-card-families-detail-general-information'
import EntityCardFamiliesDetailLegalFamilyRepresentatives from '@/components/shared/entitycards/entity-card-families-detail-legal-family-representatives'
import EntityCardFamiliesDetailSituation from '@/components/shared/entitycards/entity-card-families-detail-situation'
import EntityCardMonitorsDetailGeneralInformation from '@/components/shared/entitycards/entity-card-monitors-detail-general-information'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'ChildrenDetail',
  components: {
    BaseFormDetail,
    EntityCardCarePartnersDetailGeneralInformation,
    EntityCardChildrenDetailBudgetDetails,
    EntityCardChildrenDetailGeneralInformation,
    EntityCardChildrenDetailSiblings,
    EntityCardFamiliesDetailFinancialSituation,
    EntityCardFamiliesDetailFinancialSituationList,
    EntityCardFamiliesDetailGeneralInformation,
    EntityCardFamiliesDetailLegalFamilyRepresentatives,
    EntityCardFamiliesDetailSituation,
    EntityCardMonitorsDetailGeneralInformation
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Child',
      details: {
        carePartner: null,
        family: null,
        monitor: null
      },
      lists: {
        carePartners: [],
        codes: {
          carePartner: [],
          family: []
        },
        families: [],
        monitors: [],
        posts: []
      }
    }
  },
  computed: {
    Pickers () {
      return {
        carePartner: this.EntityCardMixin_Pickers(true, 'CarePartner', 'carePartner', 'carePartners'),
        family: this.EntityCardMixin_Pickers(true, 'Family', 'family', 'families'),
        monitor: this.EntityCardMixin_Pickers(true, 'Monitor', 'monitor', 'monitors')
      }
    },
    SelectedCarePartner () {
      return this.ContentMixin_Store_DetailSourceCopy?.carePartner?.value
    },
    SelectedFamily () {
      return this.ContentMixin_Store_DetailSourceCopy?.family?.value
    },
    SelectedMonitor () {
      return this.ContentMixin_Store_DetailSourceCopy?.monitor?.value
    }
  },
  watch: {
    async SelectedCarePartner (value) {
      await this.EntityCardMixin_PickersInitialize(this.Pickers, 'carePartner')
      await this.GetMonitorList(value)
      await this.EntityCardMixin_PickersClear(this.Pickers.monitor)
    },
    async SelectedFamily () {
      await this.EntityCardMixin_PickersInitialize(this.Pickers, 'family')
    },
    async SelectedMonitor () {
      await this.EntityCardMixin_PickersInitialize(this.Pickers, 'monitor')
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async GetMonitorList (idCarePartner = this.SelectedCarePartner) {
      const params = {
        idCarePartner: idCarePartner
      }

      this.lists.monitors = idCarePartner ? await this.ContentMixin_Service_BC_PickSource('Monitor', params) : []
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.ContentMixin_Service_BC_InitializeCodes()
      this.lists.carePartners = await this.ContentMixin_Service_BC_PickSource('CarePartner')
      this.lists.codes.carePartner = await this.ContentMixin_Service_BC_InitializeCodes('CarePartner')
      this.lists.codes.family = await this.ContentMixin_Service_BC_InitializeCodes('Family')
      this.lists.families = await this.ContentMixin_Service_BC_PickSource('Family')
      this.lists.posts = await this.ContentMixin_Service_BC_PickSource('Post')
      await this.GetMonitorList()
      await this.EntityCardMixin_PickersInitialize(this.Pickers)
      await this.EntitiCardMixin_PickersInitializeValue(this.Pickers, this.parameters)
    }
  }
}
</script>
