import ContentMixinService from '@/mixins/content-mixin.service'
import ContentService from '@/services/content-service'

export default {
  mixins: [
    ContentMixinService
  ],
  methods: {
    async ContentMixin_Service_BC_Attach (controller, parameters = {}) {
      const methodName = 'ContentMixin_Service_BC_Attach'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      await this.contentMixin.service.Attach(controller, parameters)
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    ContentMixin_Codes (currentCode) {
      return this.contentMixin.codes.filter(code => code.type === currentCode.type)
    },
    async ContentMixin_Service_BC_Delete (id) {
      if (this.CommonMixin_Confirmation(true, this.TextMixin_GetMessage('confirmationDelete', [this.contentMixin.controller]))) {
        const methodName = 'ContentMixin_Service_BC_Delete'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        await this.ContentMixin_Service_Delete(id)
        this.FrameworkMixin_Load(this.$options.name, methodName, false)
        return true
      } else {
        return false
      }
    },
    async ContentMixin_Service_BC_Detach (controller, parameters = {}) {
      if (this.CommonMixin_Confirmation(true, this.TextMixin_GetMessage('confirmationDelete', [this.contentMixin.controller]))) {
        const methodName = 'ContentMixin_Service_BC_Detach'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        await this.contentMixin.service.Detach(controller, parameters)
        this.FrameworkMixin_Load(this.$options.name, methodName, false)
        return true
      } else {
        return false
      }
    },
    async ContentMixin_Service_BC_Detail (value, refresh = false, redirectPage) {
      const id = value ? value[this.contentMixin.key] : null

      if (!this.ContentMixin_ElementId || !id || this.ContentMixin_ElementId !== id || !this.ContentMixin_ElementIsDirty) {
        const rights = refresh ? this.CommonMixin_Clone(this.ContentMixin_Element.rights) : this.baseListMixin.rights

        if (this.CommonMixin_Confirmation(!refresh && this.ContentMixin_ElementIsDirty, this.TextMixin_GetMessage('unsavedChanges'), true)) {
          const methodName = 'ContentMixin_Service_BC_Detail'
          await this.FrameworkMixin_Load(this.$options.name, methodName, true)
          const response = await this.ContentMixin_Service_Detail(id)

          if (response && response.data) {
            response.data.pocoStatus = id ? this.constantMixin.pocoStatus.unchanged : this.constantMixin.pocoStatus.new
            response.data.rights = rights

            if (redirectPage) {
              this.FrameworkMixin_LeaveConfirmed = true
              await this.$router.push(redirectPage)
            }

            this.ContentMixin_Store_DetailSourceElement = response.data
          }

          await this.FrameworkMixin_Load(this.$options.name, methodName, false)
        }
      } else {
        this.ContentMixin_Store_SelectedTab = 1
      }
    },
    async ContentMixin_Service_BC_DetailBasic (id, controller = this.contentMixin.controller) {
      return await this.contentMixin.service.Detail(controller, id)
    },
    async ContentMixin_Service_BC_DetailDialog (id, controller = this.contentMixin.controller) {
      const methodName = 'ContentMixin_Service_BC_DetailDialog'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const response = await this.contentMixin.service.Detail(controller, id, this.ContentMixin_Store_DetailSourceParameters)

      if (response && response.data) {
        response.data.pocoStatus = id ? this.constantMixin.pocoStatus.unchanged : this.constantMixin.pocoStatus.new
      }

      this.FrameworkMixin_Load(this.$options.name, methodName, false)
      return response?.data
    },
    async ContentMixin_Service_BC_InitializeCodes (controller = this.contentMixin.controller, subroute = 'Detail') {
      const methodName = 'ContentMixin_Service_BC_InitializeCodes'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const response = await this.contentMixin.service.Codes(controller, subroute)
      if (response && response.data) {
        if (controller === this.contentMixin.controller) {
          this.contentMixin.codes = response.data
        }

        this.FrameworkMixin_Load(this.$options.name, methodName, false)
        return response.data
      }
    },
    async ContentMixin_Service_BC_InitializeContentService (controller, idParent) {
      this.contentMixin.controller = controller
      this.contentMixin.idParent = idParent
      this.contentMixin.service = ContentService
      await this.ContentMixin_Service_Init()
    },
    async ContentMixin_Service_BC_PickSource (controller = this.contentMixin.controller, parameters = {}, ignoreMaxResultCount = true) {
      const methodName = 'ContentMixin_Service_BC_PickSource'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const response = await this.contentMixin.service.PickSource(controller, ignoreMaxResultCount, parameters)
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
      return response && response.data ? response.data : []
    },
    async ContentMixin_Service_BC_Scheduler (date, view, controller = this.contentMixin.controller) {
      const methodName = 'ContentMixin_Service_BC_Scheduler'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      const response = await this.contentMixin.service.Scheduler(controller, date, view)
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
      return response && response.data ? response.data : []
    }
  }
}
