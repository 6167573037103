<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-bank-statements-detail-general-information
      :readonly="readonly"
      :source="source"
    />
    <entity-card-bank-statements-detail-extra-information
      :children="lists.children"
      :codes="contentMixin.codes"
      :readonly="readonly"
      :source="source"
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardBankStatementsDetailExtraInformation from '@/components/shared/entitycards/entity-card-bank-statements-detail-extra-information'
import EntityCardBankStatementsDetailGeneralInformation from '@/components/shared/entitycards/entity-card-bank-statements-detail-general-information'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'

export default {
  name: 'ActivitiesDetail',
  components: {
    BaseFormDetail,
    EntityCardBankStatementsDetailExtraInformation,
    EntityCardBankStatementsDetailGeneralInformation
  },
  mixins: [
    BaseFormDetailMixin
  ],
  data () {
    return {
      controller: 'BankStatement',
      lists: {
        children: []
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.ContentMixin_Service_BC_InitializeCodes()
      await this.GetChildren()
    },
    async GetChildren () {
      this.lists.children = await this.ContentMixin_Service_BC_PickSource('Child')
    }
  }
}
</script>
