<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-data-table
      dense
      :headers="Headers"
      hide-default-footer
      :items="EntityCardMixin_CopySource.entities"
      :itemsPerPage="-1"
      :mobile-breakpoint="0"
    >
      <template
        #[`header.expanded`]
      >
        <v-btn
          icon
          small
          :title="TextMixin_GetLabel(ExpandedAll ? 'collapseAll' : 'expandAll')"
          @click="ExpandAll()"
        >
          <v-icon>
            mdi-chevron-{{ ExpandedAll ? 'up' : 'down' }}
          </v-icon>
        </v-btn>
      </template>
      <template
        #item="props"
      >
        <tr
          style="background-color: #F3F3F3"
        >
          <td>
            <v-btn
              v-if="props.item.fields.length > 0"
              icon
              small
              :title="TextMixin_GetLabel(Expanded(props.item) ? 'collapse' : 'expand')"
              @click="Expand(props.item)"
            >
              <v-icon>
                mdi-chevron-{{ Expanded(props.item) ? 'up' : 'down' }}
              </v-icon>
            </v-btn>
          </td>
          <td>
            <div
              v-if="AuthenticationMixin_InputFieldIsShown(props.item.description)"
              v-html="props.item.description.value"
            />
          </td>
          <td
            class="text-center"
          >
            <v-simple-checkbox
              v-if="AuthenticationMixin_InputFieldIsShown(props.item.allowRead)"
              :value="props.item.allowRead.value"
              color="primary"
              :disabled="AuthenticationMixin_InputFieldIsDisabled(props.item.allowRead)"
              :indeterminate="props.item.allowRead.value == null"
              :readonly="AuthenticationMixin_InputFieldIsReadonly(props.item.allowRead)"
              :ripple="false"
              :title="TextMixin_GetHeader('read')"
              @input="ToggleAllowRead(props.item, $event)"
            />
          </td>
          <td
            class="text-center"
          >
            <v-simple-checkbox
              v-if="props.item.allowRead.value && AuthenticationMixin_InputFieldIsShown(props.item.allowUpdate)"
              :value="props.item.allowUpdate.value"
              color="primary"
              :disabled="AuthenticationMixin_InputFieldIsDisabled(props.item.allowUpdate)"
              :indeterminate="props.item.allowUpdate.value == null"
              :readonly="AuthenticationMixin_InputFieldIsReadonly(props.item.allowUpdate)"
              :ripple="false"
              :title="TextMixin_GetHeader('update')"
              @input="ContentMixin_UpdateElementWithPocoStatus(props.item, 'allowUpdate', $event)"
            />
          </td>
          <td
            class="text-center"
          >
            <v-simple-checkbox
              v-if="props.item.allowRead.value && props.item.allowUpdate.value && AuthenticationMixin_InputFieldIsShown(props.item.allowCreate)"
              :value="props.item.allowCreate.value"
              color="primary"
              :disabled="AuthenticationMixin_InputFieldIsDisabled(props.item.allowCreate)"
              :indeterminate="props.item.allowCreate.value == null"
              :readonly="AuthenticationMixin_InputFieldIsReadonly(props.item.allowCreate)"
              :ripple="false"
              :title="TextMixin_GetHeader('create')"
              @input="ContentMixin_UpdateElementWithPocoStatus(props.item, 'allowCreate', $event)"
            />
          </td>
          <td
            class="text-center"
          >
            <v-simple-checkbox
              v-if="props.item.allowRead.value && props.item.allowUpdate.value && AuthenticationMixin_InputFieldIsShown(props.item.allowDelete)"
              :value="props.item.allowDelete.value"
              color="primary"
              :disabled="AuthenticationMixin_InputFieldIsDisabled(props.item.allowDelete)"
              :indeterminate="props.item.allowDelete.value == null"
              :readonly="AuthenticationMixin_InputFieldIsReadonly(props.item.allowDelete)"
              :ripple="false"
              :title="TextMixin_GetHeader('delete')"
              @input="ContentMixin_UpdateElementWithPocoStatus(props.item, 'allowDelete', $event)"
            />
          </td>
        </tr>
        <tr
          v-for="(field, index) in Fields(props.item)"
          :key="`${field.idEntityField}-${index}`"
        >
          <td/>
          <td>
            <div
              v-if="AuthenticationMixin_InputFieldIsShown(field.description)"
              v-html="field.description.value"
            />
          </td>
          <td
            class="text-center"
          >
            <v-simple-checkbox
              v-if="props.item.allowRead.value && AuthenticationMixin_InputFieldIsShown(field.allowRead)"
              :value="field.allowRead.value"
              color="secondary"
              :disabled="AuthenticationMixin_InputFieldIsDisabled(field.allowRead)"
              :indeterminate="field.allowRead.value == null"
              :readonly="AuthenticationMixin_InputFieldIsReadonly(field.allowRead)"
              :ripple="false"
              :title="TextMixin_GetHeader('read')"
              @input="ToggleFieldProperty(props.item, index, field, 'allowRead', $event)"
            />
          </td>
          <td
            class="text-center"
          >
            <v-simple-checkbox
              v-if="props.item.allowRead.value && props.item.allowUpdate.value && field.allowRead.value && AuthenticationMixin_InputFieldIsShown(field.allowUpdate)"
              :value="field.allowUpdate.value"
              color="secondary"
              :disabled="AuthenticationMixin_InputFieldIsDisabled(field.allowUpdate)"
              :indeterminate="field.allowUpdate.value == null"
              :readonly="AuthenticationMixin_InputFieldIsReadonly(field.allowUpdate)"
              :ripple="false"
              :title="TextMixin_GetHeader('update')"
              @input="ToggleFieldProperty(props.item, index, field, 'allowUpdate', $event)"
            />
          </td>
          <td/>
          <td/>
        </tr>
      </template>
    </v-data-table>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardUserManagementProfilesDetailRights',
  mixins: [
    EntityCardMixin
  ],
  data () {
    return {
      checkboxHeaderWidth: '80px',
      expanded: []
    }
  },
  computed: {
    ExpandedAll () {
      return this.expanded?.length === this.EntityCardMixin_CopySource?.entities?.length
    },
    Headers () {
      return [
        {
          sortable: false,
          value: 'expanded',
          width: '44px'
        },
        {
          text: this.TextMixin_GetHeader('moduleOrField'),
          value: 'description.value'
        },
        {
          align: 'center',
          sortable: false,
          text: this.TextMixin_GetHeader('read'),
          value: 'allowRead.value',
          width: this.checkboxHeaderWidth
        },
        {
          align: 'center',
          sortable: false,
          text: this.TextMixin_GetHeader('update'),
          value: 'allowUpdate.value',
          width: this.checkboxHeaderWidth
        },
        {
          align: 'center',
          sortable: false,
          text: this.TextMixin_GetHeader('create'),
          value: 'allowCreate.value',
          width: this.checkboxHeaderWidth
        },
        {
          align: 'center',
          sortable: false,
          text: this.TextMixin_GetHeader('delete'),
          value: 'allowDelete.value',
          width: this.checkboxHeaderWidth
        }
      ]
    }
  },
  watch: {
    ContentMixin_Store_DetailSourceElement () {
      this.expanded = []
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    Expand (entity) {
      if (this.Expanded(entity)) {
        this.expanded = this.expanded.filter(idEntity => idEntity !== entity.idEntity)
      } else {
        this.expanded.push(entity.idEntity)
      }
    },
    ExpandAll () {
      if (this.ExpandedAll) {
        this.expanded = []
      } else {
        this.expanded = this.EntityCardMixin_CopySource.entities.map(entity => entity.idEntity)
      }
    },
    Expanded (entity) {
      return this.expanded.includes(entity.idEntity)
    },
    Fields (entity) {
      return this.Expanded(entity) ? entity.fields : []
    },
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('rights')
    },
    ToggleAllowRead (entity, value) {
      this.ContentMixin_UpdateElementWithPocoStatus(entity, 'allowRead', value)

      if (value && !this.Expanded(entity)) {
        this.Expand(entity)
      }
    },
    async ToggleFieldProperty (entity, key, field, property, value) {
      await this.ContentMixin_UpdateElementWithPocoStatus(entity, null, null)
      await this.ContentMixin_UpdateElementWithPocoStatus(field, property, value)
      await this.ContentMixin_UpdateElementWithPocoStatus(entity, 'fields', field, key)
      this.$forceUpdate()
    }
  }
}
</script>
