<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-requests-detail-process-request-feedback
      :codes="contentMixin.codes"
      :readonly="readonly"
      :source="source"
      :visible="visible"
    />
    <requests-detail-process-request-extra-budget
      v-if="HasRequestType('extraBudget')"
      :codes="contentMixin.codes"
      :readonly="readonly"
      :source="source"
      :visible="visible"
    />
    <requests-detail-process-request-intervention
      v-if="HasRequestType('intervention')"
      :codes="contentMixin.codes"
      :readonly="readonly"
      :source="source"
      :visible="visible"
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardRequestsDetailProcessRequestFeedback from '@/components/shared/entitycards/entity-card-requests-detail-process-request-feedback'
import RequestsDetailProcessRequestExtraBudget from '@/views/requests/components/requests-detail-process-request-extra-budget'
import RequestsDetailProcessRequestIntervention from '@/views/requests/components/requests-detail-process-request-intervention'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'RequestsDetailProcessRequest',
  components: {
    BaseFormDetail,
    EntityCardRequestsDetailProcessRequestFeedback,
    RequestsDetailProcessRequestExtraBudget,
    RequestsDetailProcessRequestIntervention
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Request'
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    HasRequestType (value) {
      return this.ContentMixin_Element.requestType.value && this.ContentMixin_Element.requestType.value.toUpperCase() === this.constantMixin.codes.requestType.values[value].toUpperCase()
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.ContentMixin_Service_BC_InitializeCodes()
    }
  }
}
</script>
