<template>
  <v-data-table
    v-model="selection"
    :class="(editable ? 'edit-crud-table' : 'crud-table') + (hideBorder ? '' : ' elevation-1') + (hidePagination ? ' mb-6' : '')"
    dense
    :disable-pagination="editable || (paging && !paging.pagingEnabled)"
    :footer-props="FooterProps"
    :headers="Headers"
    :hide-default-footer="hideFooter || editable || hidePagination || (paging && !paging.pagingEnabled)"
    :item-class="RowClass"
    :item-key="itemKey"
    :items="Items"
    multi-sort
    :options.sync="options"
    :server-items-length="ServerItemsLength"
    :show-select="ShowSelect"
    :single-select="singleSelect"
    @click:row="Click"
    @contextmenu:row="RightClick"
    @dblclick:row="DblClick"
    @page-count="pageCount = $event"
  >
    <template
      #top
    >
      <v-row
        v-if="!editable && !hideFilter"
        class="pa-0 px-3"
      >
        <v-col
          class="d-flex pa-0"
          cols="12"
        >
          <v-text-field
            class="px-2"
            :label="TextMixin_GetLabel('filter')"
            @input.native="Filter($event)"
          />
        <v-tooltip
          bottom
        >
          <template
            #activator="{ on, attrs }"
          >
            <v-btn
              v-bind="attrs"
              class="mr-2 mt-2"
              color="secondary"
              icon
              style="padding: 0px 2px 0px 5px"
              v-on="on"
              @click="$emit('refresh')"
            >
              <v-icon
                class="mr-1"
              >
                mdi-refresh
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ TextMixin_GetLabel('refresh') }}
          </span>
        </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template
      v-for="(header, index) in Headers.filter(x => x.value !== 'actions')"
      #[`item.${header.value}`]="{ item }"
    >
      <template>
        <div
          :key="index"
          :style="EditFieldStyle(header)"
        >
          <slot
            v-if="editable && IsOverwrittenSlot(`edit-${HeaderValue(header)}`)"
            v-bind="item"
            :name="`edit-${HeaderValue(header)}`"
          />
          <slot
            v-else-if="IsOverwrittenSlot(`read-${HeaderValue(header)}`)"
            v-bind="item"
            :name="`read-${HeaderValue(header)}`"
          />
          <template
            v-else
          >
            <template
              v-if="header.dataType == constantMixin.dataType.date"
            >
              {{ FormatMixin_FormatDateTime(ItemValueByHeader(item, header), formatMixin.date.database, formatMixin.date.date) }}
            </template>
            <template
              v-else-if="header.dataType == constantMixin.dataType.datetime"
            >
              {{ FormatMixin_FormatDateTime(ItemValueByHeader(item, header), formatMixin.date.database, formatMixin.date.dateTime) }}
            </template>
            <template
              v-else
            >
              {{ ItemValueByHeader(item, header) }}
            </template>
          </template>
        </div>
      </template>
    </template>
    <template
      #[`item.actions`]="{ item }"
    >
      <slot
        v-bind="item"
        name="customActions"
      />
      <template
        v-if="!hideActions"
      >
        <v-tooltip
          v-if="editAction"
          bottom
        >
          <template
            #activator="{ on, attrs }"
          >
            <v-icon
              v-if="!readonly && AllowReadOrUpdate && !editable && !hideActions"
              v-bind="attrs"
              class="mr-2"
              :class="$vuetify.breakpoint.xs ? '' : 'tableAction'"
              size="18"
              v-on="on"
              @click="EditItem(item)"
            >
              mdi-{{ rights.allowUpdate ? 'pencil' : 'eye' }}
            </v-icon>
          </template>
          <span>
            {{ TextMixin_GetLabel('edit') }}
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="deleteAction"
          bottom
        >
          <template
            #activator="{ on, attrs }"
          >
            <v-icon
              v-if="!readonly && rights.allowDelete && !hideActions && !hideActions"
              v-bind="attrs"
              class="mr-2"
              :class="$vuetify.breakpoint.xs ? '' : 'tableAction'"
              size="20"
              v-on="on"
              @click="DeleteItem(item)"
            >
              mdi-close
            </v-icon>
          </template>
          <span>
            {{ TextMixin_GetLabel('delete') }}
          </span>
        </v-tooltip>
      </template>
    </template>
    <template
      v-if="!editable && !hidePagination && !hideFooter && !hideFooter"
      #[`footer.prepend`]
    >
      <slot
        name="footer-prepend-1"
      />
      <v-btn
        v-if="!readonly && rights.allowCreate && !hideActions && addAction"
        color="secondary"
        outlined
        @click="Create()"
      >
        <v-icon
          class="mr-1"
        >
          mdi-plus
        </v-icon>
        {{ textNewItem }}
      </v-btn>
      <slot
        name="footer-prepend-2"
      />
    </template>
    <template
      v-if="(editable || hidePagination) && !hideFooter && !readonly"
      #[`footer`]
    >
      <v-row
        class="mx-0 pa-0 justify-space-between"
      >
        <v-col
          class="mt-3 pb-0 pt-3"
          cols="12"
          style="border-top: 1px solid #E0E0E0"
        />
        <v-col
          cols="12"
          class="pb-2 px-2"
        >
          <slot
            name="footer-prepend-1"
          />
          <v-btn
            v-if="rights.allowCreate && !hideActions && addAction && addAction"
            color="secondary"
            outlined
            @click="Create()"
          >
            <v-icon
              class="mr-1"
            >
              mdi-plus
            </v-icon>
            {{ textNewItem }}
          </v-btn>
          <slot
            name="footer-prepend-2"
          />
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import _ from 'lodash'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'BaseCrudTable',
  mixins: [
    ContentMixin
  ],
  props: {
    // Selected rows
    value: {
      default: () => [],
      type: Array
    },
    // Show add action button?
    addAction: {
      default: true,
      type: Boolean
    },
    // Use default contextmenu
    defaultContextmenu: {
      default: true,
      type: Boolean
    },
    // Show delete action button?
    deleteAction: {
      default: true,
      type: Boolean
    },
    // Edit when doubleclicked row
    doubleClickEdit: {
      default: true,
      type: Boolean
    },
    // Is the table editable?
    editable: Boolean,
    // Show edit action button?
    editAction: {
      default: true,
      type: Boolean
    },
    // Table headers
    headers: {
      default: () => [],
      type: Array
    },
    // Hide CRUD-actions
    hideActions: Boolean,
    // Display no table border
    hideBorder: Boolean,
    // Hide filter input
    hideFilter: {
      default: false,
      type: Boolean
    },
    // Hide footer
    hideFooter: Boolean,
    // Hide pagination
    hidePagination: Boolean,
    // Table items
    items: {
      default: () => [],
      type: Array
    },
    // Items key (e.g. IdUser)
    itemKey: {
      default: 'id',
      type: String
    },
    // Is a link table
    linkTable: Boolean,
    // Is listDetail
    listDetail: Boolean,
    // Selected item from listDetail
    listDetailSelectedElement: {
      default: () => {},
      type: Object
    },
    // Paging
    paging: {
      default: () => {},
      type: Object
    },
    // Readonly
    readonly: {
      default: false,
      type: Boolean
    },
    // Rights
    rights: {
      // { allowCreate `Boolean`, allowDelete `Boolean`, allowRead `Boolean`, allowUpdate `Boolean` }
      default: () => {
        return {
          allowCreate: false,
          allowDelete: false,
          allowRead: false,
          allowUpdate: false
        }
      },
      type: Object
    },
    // Make items selectable
    showSelect: {
      default: false,
      type: Boolean
    },
    // Select with single click
    singleClickSelect: Boolean,
    // Be able to select only one row
    singleSelect: {
      default: true,
      type: Boolean
    },
    // Text button new item
    textNewItem: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      debounce: _.debounce((event) => {
        // Fire when filter input has changed
        // @arg `String`
        this.$emit('filter', event.target.value)
      }, 500),
      dialog: false,
      editedItem: null,
      options: null,
      selectedElement: null,
      selectedElementDetail: null,
      selection: []
    }
  },
  computed: {
    AllowReadOrUpdate () {
      return this.rights.allowUpdate || this.rights.allowRead
    },
    FooterProps () {
      const props = {
        itemsPerPageOptions: this.editable ? [-1, -1] : [10, 25, 50, 100],
        itemsPerPageText: this.TextMixin_GetLabel('itemsPerPage'),
        showCurrentPage: true
      }
      return props
    },
    Headers () {
      const headers = this.headers

      if (!this.hideActions) {
        const actionHeader = {
          align: 'center',
          sortable: false,
          text: '',
          value: 'actions'
        }

        headers.push(actionHeader)
      }

      return headers
    },
    Items () {
      let items = this.items.filter(item => !this.ContentMixin_ElementHasPocoStatus('deleted', item))

      if (this.showSelect && this.readonly) {
        items = items.filter(item => this.SelectedIds.includes(item[this.itemKey]))
      }

      return items
    },
    SelectedIds () {
      return this.value.map(y => y[this.itemKey])
    },
    ServerItemsLength () {
      return this.paging?.totalItems && (!this.editable || this.hidePagination) ? this.paging.totalItems : null
    },
    ShowSelect () {
      return this.showSelect && !this.readonly
    }
  },
  watch: {
    listDetailSelectedElement: {
      handler (value) {
        this.selectedElementDetail = value
      },
      deep: true
    },
    options: {
      handler (value) {
        // Fire when options changed
        // @arg options
        this.$emit('options-change', value)
      },
      deep: true
    },
    paging (value) {
      this.options.page = value.pageIndex + 1
    },
    selection (value) {
      if (this.showSelect) {
        const selectedItems = value.map(x => ({
          [this.itemKey]: x[this.itemKey],
          id: x[this.itemKey]
        }))

        // Fire when row selection changes
        // @arg selected
        this.$emit('input', selectedItems)
      }
    },
    value: {
      deep: true,
      handler (newValue, oldValue) {
        if (!this.CommonMixin_HardCompare(newValue, oldValue)) {
          this.Initialize()
        }
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    Click (event, row) {
      if (this.singleClickSelect) {
        row.select(true)
        this.selectedElementDetail = row.item
        this.selectedElement = row.item
      }
      // Fire when row has been clicked and singleselect has been enabled
      // @arg { item: `selected item`, index: `selected item index` }
      this.$emit('single-click-select', row.item)
    },
    Create () {
      // Fire when new item button has been clicked
      this.$emit('create')
    },
    DblClick (event, row) {
      if (!(this.showSelect && this.readonly)) {
        if (this.AllowReadOrUpdate) {
          row.select(true)
          this.selectedElementDetail = row.item
          this.selectedElement = row.item
          if (this.doubleClickEdit) {
            this.EditItem(row.item)
          }
        }
        // Fire when row has been double clicked
        // @arg { item: `selected item`, index: `selected item index` }
        this.$emit('double-click-select', row.item)
      }
    },
    DeleteItem (item) {
      // Fire when row delete button has been clicked and confirmed
      // @arg all items
      this.$emit('delete', item)
    },
    EditFieldStyle (header) {
      const headerValue = this.HeaderValue(header)
      const overwrittenEdit = this.IsOverwrittenSlot(`edit-${headerValue}`)
      const overwrittenRead = this.IsOverwrittenSlot(`read-${headerValue}`)
      const overwritten = overwrittenEdit || overwrittenRead
      const overwrittenClass = 'margin-bottom: -7px; margin-top: 10px'
      return this.editable && overwritten ? overwrittenClass : ''
    },
    EditItem (item) {
      this.editedItem = Object.assign({}, item)
      this.selectedElementDetail = item
      this.selectedElement = item
      // Fire when row edit button has been clicked
      // @arg { item: `selected item`, index: `selected item index` }
      this.$emit('select', this.editedItem)
    },
    Filter (event) {
      this.debounce.cancel()
      this.debounce(event)
    },
    HeaderValue (header) {
      return header.value.replace('.value', '')
    },
    Initialize () {
      this.selection = this.CommonMixin_Clone(this.items.filter(x => this.SelectedIds.includes(x[this.itemKey])))
    },
    IsNumber (header) {
      return header.dataType === this.constantMixin.dataType.int || header.dataType === this.constantMixin.dataType.decimal
    },
    IsOverwrittenSlot (slot) {
      return !!this.$scopedSlots[slot]
    },
    ItemValueByHeader (item, header) {
      const itemObject = item[this.HeaderValue(header)]
      if (!itemObject) {
        return null
      }

      if (itemObject.text) {
        return itemObject.text
      }

      return itemObject.value
    },
    RightClick (event, row) {
      if (!this.defaultContextmenu) {
        event.preventDefault()
        if (this.singleClickSelect) {
          row.select(true)
          this.selectedElementDetail = row.item
          this.selectedElement = row.item
        }
        // Fire when row has been clicked and singleselect has been enabled
        // @arg { item: `selected item`, index: `selected item index` }
        this.$emit('contextmenu', { event: event, item: row.item })
      }
    },
    RowClass (item) {
      if (!this.listDetail) {
        return this.ContentMixin_Store_DetailSourceElement && item[this.itemKey] === this.ContentMixin_Store_DetailSourceElement[this.itemKey] ? 'v-data-table__selected' : ''
      }

      return this.selectedElement && item[this.itemKey] === this.selectedElement[this.itemKey] ? 'v-data-table__selected' : ''
    }
  }
}
</script>

<style>
.edit-crud-table .base-field-autocomplete .v-autocomplete {
  margin-top: -10px !important;
}
</style>
