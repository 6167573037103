<template>
  <base-dialog
    :value="value"
    multiple-actions
    :title="TextMixin_GetTitle('logIn')"
    @input="$emit('input', $event)"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-text-field
        v-model="account.username"
        :autofocus="!account.username"
        class="pb-1"
        :class="{'shake' : animated}"
        :label="TextMixin_GetLabel('eMail')"
        :prepend-inner-icon="'mdi-email'"
        required
        :rules="[validationMixin.rules.eMail, validationMixin.rules.required]"
        @keyup.enter="Login()"
      />
      <v-text-field
        v-model="account.password"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :autofocus="!!account.username"
        :class="{'shake' : animated}"
        :label="TextMixin_GetLabel('password')"
        :prepend-inner-icon="'mdi-lock'"
        required
        :rules="[validationMixin.rules.required]"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
        @keyup.enter="Login()"
      />
      <div
        class="text-right pa-0 mt-0"
        style="position: relative; top: -16px"
      >
        <a
          class="secondary--text text-decoration-underline"
          @click="OpenForgotPasswordDialog()"
        >
          {{ TextMixin_GetLabel('forgotPassword') }}
        </a>
      </div>
    </v-form>
    <template
      #actions
    >
      <v-checkbox
        v-model="remember"
        :label="TextMixin_GetLabel('remember')"
      />
      <v-btn
        color="primary"
        @click="Login()"
      >
        {{ TextMixin_GetLabel('logIn') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog-mixin'

export default {
  name: 'DialogLogin',
  mixins: [
    DialogMixin
  ],
  data () {
    return {
      account: {
        password: null,
        username: null
      },
      animated: false,
      remember: true,
      show: false,
      valid: false
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      if (this.$cookie) {
        this.account.username = this.$cookie.get(this.authenticationMixin.cookies.username.name)
      }
    },
    async Login () {
      this.$refs.form.validate()

      if (this.valid && this.account.username != null && this.account.password != null) {
        const response = await this.AuthenticationMixin_Login(this.account, this.remember)

        if (!response) {
          this.Shake()
        }
      } else {
        this.Shake()
      }
    },
    OpenForgotPasswordDialog () {
      // Fire when forgot password link has been pressed
      this.$emit('forgotPassword')
    },
    Shake () {
      this.animated = true
      setTimeout(() => { this.animated = false }, 1000)
    }
  }
}
</script>
