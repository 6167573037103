<template>
  <base-list-overview
    add-label="compose"
    controller="Mail"
    :filter="Filter"
    :visible="visible"
  >
    <template
      #[`read-incoming`]="item"
    >
      <v-icon
        :color="item.incoming.value ? 'green' : 'red'"
      >
        mdi-arrow-{{ item.incoming.value ? 'down' : 'up'}}
      </v-icon>
    </template>
  </base-list-overview>
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'MailOverview',
  components: {
    BaseListOverview
  },
  mixins: [
    ContentMixin
  ],
  props: {
    mailType: {
      default: 0,
      type: Number
    }
  },
  computed: {
    Filter () {
      return [
        {
          key: 'mailType',
          value: this.mailType
        }
      ]
    }
  }
}
</script>
