<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row
      v-if="!EntityCardMixin_Picker"
    >
      <base-field
        v-if="EntityCardMixin_CopySource.firstName"
        v-model="EntityCardMixin_CopySource.firstName"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('firstName')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.firstName.value, 255), validationMixin.rules.required]"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.lastName"
        v-model="EntityCardMixin_CopySource.lastName"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('lastName')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.lastName.value, 255), validationMixin.rules.required]"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.isActive"
        v-model="EntityCardMixin_CopySource.isActive"
        base-field-container-type="v-col"
        base-field-type="checkbox"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('active')"
        :readonly="EntityCardMixin_Readonly"
      />
    </v-row>
    <v-row>
      <template
        v-if="EntityCardMixin_Picker"
      >
        <base-picker
          v-if="entityCardMixin.pickers.components[0]"
          v-model="entityCardMixin.pickers.components[0]"
          base-field-container-type="v-col"
          :items="children"
          :label="TextMixin_GetLabel('child')"
          @pickers-create="$emit('pickers-create', $event)"
          @pickers-read="$emit('pickers-read', $event)"
          @pickers-update="$emit('pickers-update', $event)"
        />
      </template>
      <base-field
        v-if="EntityCardMixin_CopySource.dateOfBirth"
        v-model="EntityCardMixin_CopySource.dateOfBirth"
        base-field-container-type="v-col"
        base-field-type="date"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('dateOfBirth')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.gender"
        v-model="EntityCardMixin_CopySource.gender"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        :disabled="EntityCardMixin_Disabled"
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('gender')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.nationality"
        v-model="EntityCardMixin_CopySource.nationality"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('nationality')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.nationality.value, 255)]"
      />
    </v-row>
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.childStatus"
        v-model="EntityCardMixin_CopySource.childStatus"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        :disabled="EntityCardMixin_Disabled"
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('status')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.region"
        v-model="EntityCardMixin_CopySource.region"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        :disabled="EntityCardMixin_Disabled"
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('region')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.childType"
        v-model="EntityCardMixin_CopySource.childType"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        :disabled="EntityCardMixin_Disabled"
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('type')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.schoolName"
        v-model="EntityCardMixin_CopySource.schoolName"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('school')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.schoolName.value, 255)]"
      />
    </v-row>
    <template>
      <v-row>
        <base-field
          v-if="EntityCardMixin_CopySource.continueStudy"
          v-model="EntityCardMixin_CopySource.continueStudy"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('continueStudy')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.studyType"
          v-model="EntityCardMixin_CopySource.studyType"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('studyType')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.studyType.value && EntityCardMixin_CopySource.studyTypeLevel"
          v-model="EntityCardMixin_CopySource.studyTypeLevel"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="lists.studyTypeLevels"
          :label="TextMixin_GetLabel('level')"
          :readonly="EntityCardMixin_Readonly"
        />
      </v-row>
      <v-row>
        <base-field
          v-if="EntityCardMixin_CopySource.graduatedAs"
          v-model="EntityCardMixin_CopySource.graduatedAs"
          base-field-container-type="v-col"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('graduatedAs')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.graduatedAs.value, 255)]"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.dateEndStudies"
          v-model="EntityCardMixin_CopySource.dateEndStudies"
          base-field-container-type="v-col"
          base-field-type="date"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('dateEndStudies')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.employment"
          v-model="EntityCardMixin_CopySource.employment"
          base-field-container-type="v-col"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('employment')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.employment.value, 255)]"
        />
      </v-row>
    </template>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardChildrenDetailGeneralInformation',
  mixins: [
    EntityCardMixin
  ],
  data () {
    return {
      lists: {
        studyTypeLevels: []
      }
    }
  },
  computed: {
    SelectedStudyType () {
      return this.EntityCardMixin_CopySource?.studyType?.value
    },
    SelectedStudyTypeLevel () {
      return this.EntityCardMixin_CopySource?.studyTypeLevel?.value ?? 'niks'
    }
  },
  watch: {
    async SelectedStudyType (value) {
      await this.ContentMixin_HandleSelectChange(this.EntityCardMixin_CopySource.studyTypeLevel)
      await this.GetCodes(value)
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async GetCodes (value) {
      if (value) {
        this.lists.studyTypeLevels = await this.CodeMixin_GetCodes([this.EntityCardMixin_CopySource.studyTypeLevel.type], this.EntityCardMixin_CopySource.studyType.value)
      } else {
        this.lists.studyTypeLevels = []
      }
    },
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('generalInformation')
      await this.GetCodes(this.EntityCardMixin_CopySource.studyType.value)
    }
  }
}
</script>
