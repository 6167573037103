import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_Tabs () {
      return {
        activities: {
          fr: 'Activités',
          nl: 'Activiteiten'
        },
        children: {
          fr: 'Enfants',
          nl: 'Kinderen'
        },
        deletedItems: {
          fr: 'Éléments supprimés',
          nl: 'Verwijderde items'
        },
        details: {
          fr: `${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter}`
        },
        detailsCreate: {
          fr: `Créer ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} aanmaken`
        },
        detailsUpdate: {
          fr: `Modifier ${this.textMixin.placeholders.parameter}`,
          nl: `${this.textMixin.placeholders.parameter} bewerken`
        },
        documents: {
          fr: 'Documents',
          nl: 'Documenten'
        },
        eMail: {
          fr: 'E-mail',
          nl: 'E-mail'
        },
        history: {
          fr: 'Historique',
          nl: 'Historiek'
        },
        inAndOutbox: {
          fr: 'Boîte de réception/envoi',
          nl: 'Postvak in/uit'
        },
        inbox: {
          fr: 'Boîte de réception',
          nl: 'Postvak in'
        },
        invoices: {
          fr: 'Factures',
          nl: 'Facturen'
        },
        mailDetails: {
          fr: 'Détails e-mail',
          nl: 'E-mail details'
        },
        monitors: {
          fr: 'Accompagnants',
          nl: 'Begeleiders'
        },
        overview: {
          fr: 'Aperçu',
          nl: 'Overzicht'
        },
        outbox: {
          fr: 'Boîte d\'envoi',
          nl: 'Postvak uit'
        },
        paymentOrders: {
          fr: 'Ordres de paiement',
          nl: 'Betaalopdrachten'
        },
        requests: {
          fr: 'Demandes',
          nl: 'Aanvragen'
        },
        bankStatements: {
          fr: 'Tableur',
          nl: 'Rekenblad'
        },
        updateHistory: {
          fr: 'Historique des mises à jour',
          nl: 'Updategeschiedenis'
        }
      }
    }
  }
}
