<template>
  <div
    class="customScrollbar"
  >
    <div
      v-if="title"
      class="overline"
      v-html="title"
    />
    <v-card
      :id="cardId"
      :class="CardClass"
      :style="cardStyle"
    >
      <slot />
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    // Class for the v-card
    cardClass: String,
    // Override default class for the v-card
    cardClassOverride: {
      default: false,
      type: Boolean
    },
    // Id for the v-card
    cardId: String,
    // Style for the v-card
    cardStyle: String,
    // Is of type list-detail
    listDetail: Boolean,
    // Style for the v-card
    noPadding: Boolean,
    // Title of the v-card
    title: String
  },
  computed: {
    CardClass () {
      const marginTop = this.title ? 0 : 3
      let defaultClass = ''

      if (this.noPadding) {
        defaultClass = this.cardClassOverride ? '' : `pa-0 pt-3 ${marginTop > 0 ? `mt-${marginTop}` : ''}`
      } else {
        defaultClass = this.cardClassOverride ? '' : `pb-3 ${this.listDetail ? 'px-0' : 'pt-6 px-3'} ${marginTop > 0 ? `mt-${marginTop}` : ''}`
      }
      defaultClass += ` ${this.cardClass ?? ''}`
      return defaultClass
    }
  }
}
</script>
