import BaseDialog from '@/components/shared/base/base-dialog'

export default {
  components: {
    BaseDialog
  },
  props: {
    // Dialog is visible or not
    value: Boolean,
    // Dialog title
    title: String
  }
}
