<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    list-detail
    :title="EntityCardMixin_Title"
  >
    <base-list-detail
      v-model="EntityCardMixin_CopySource.children"
      controller="Child"
      hide-actions
      hide-filter
      hide-footer
      :readonly="EntityCardMixin_Readonly"
      :visible="visible"
    >
      <template
        #[`edit-budget`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.children, item.id)?.budget"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.children, item.id).budget"
          base-field-type="number"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          prefix="€"
          :readonly="EntityCardMixin_Readonly"
          :rules="RulesBudget(item)"
          @input="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.children, item.id, 'budget', $event.value)"
        />
      </template>
    </base-list-detail>
  </base-card>
</template>

<script>
import BaseListDetail from '@/components/shared/base/base-list-detail'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardRequestsDetailProcessRequestInterventionChildren',
  components: {
    BaseListDetail
  },
  mixins: [
    EntityCardMixin
  ],
  props: {
    type: {
      default: 'child',
      type: String
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    HasRequestStatus (value) {
      return this.ContentMixin_Element.requestStatus.value && this.ContentMixin_Element.requestStatus.value.toUpperCase() === this.constantMixin.codes.requestStatus.values[value].toUpperCase()
    },
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('details')
      await this.ContentMixin_Service_BC_InitializeContentService('Child')
    },
    RulesBudget (item) {
      const rules = [this.validationMixin.rules.numberGreaterThan(this.ContentMixin_GetBaseListDetailItem(this.EntityCardMixin_CopySource.children, item.id).budget.value, 0)]

      if (this.HasRequestStatus('approved')) {
        rules.push(this.validationMixin.rules.required)
      }

      return rules
    }
  }
}
</script>
