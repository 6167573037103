<template>
  <div>
    <label
      class="v-label theme--light"
      for="input-24"
      style="left: 0px; right: auto;"
    >
        {{label}}
    </label>
    <ckeditor
      v-model="content"
      :config="editorConfig"
      :editor="editor"
    />
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import CKEditorUploadAdapter from '@/plugins/ckeditor-upload-adapter'
import ClassicEditor from '@/plugins/ckeditor'

function MyCKEditorUploadAdapter (editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
    // Configure the URL to the upload script in your back-end here!
    return new CKEditorUploadAdapter(loader)
  }
}

export default {
  name: 'BaseHtmlEditor',
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    // Value
    value: String,
    // Label
    label: String
  },
  data () {
    return {
      content: this.value,
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [
          MyCKEditorUploadAdapter
        ]
      }
    }
  },
  watch: {
    content (value) {
      this.$emit('input', value)
    }
  }
}
</script>
