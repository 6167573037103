<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.debtMediation"
        v-model="EntityCardMixin_CopySource.debtMediation"
        base-field-container-type="v-col"
        base-field-type="checkbox"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('debtMediation')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.debtMediatorName"
        v-model="EntityCardMixin_CopySource.debtMediatorName"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('name', TextMixin_GetParameter('debtMediator').toLowerCase())"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.debtMediatorName.value, 255)]"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.debtMediatorEMail"
        v-model="EntityCardMixin_CopySource.debtMediatorEMail"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('eMail', TextMixin_GetParameter('debtMediator').toLowerCase())"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.eMail, validationMixin.rules.charactersMax(EntityCardMixin_CopySource.debtMediatorEMail.value, 255)]"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.debtMediatorTelephone"
        v-model="EntityCardMixin_CopySource.debtMediatorTelephone"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('telephone', TextMixin_GetParameter('debtMediator').toLowerCase())"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.debtMediatorTelephone.value, 255)]"
      />
    </v-row>
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.supportOtherOrganisation"
        v-model="EntityCardMixin_CopySource.supportOtherOrganisation"
        base-field-container-type="v-col"
        base-field-type="checkbox"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('supportOtherOrganisation')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.noSupportOtherOrganisationReason"
        v-model="EntityCardMixin_CopySource.noSupportOtherOrganisationReason"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('whyNot')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.noSupportOtherOrganisationReason.value, 255)]"
      />
    </v-row>
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.dateStartCsr"
        v-model="EntityCardMixin_CopySource.dateStartCsr"
        base-field-container-type="v-col"
        base-field-type="date"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('dateStart', TextMixin_GetParameter('csr'))"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.dateEndCsr"
        v-model="EntityCardMixin_CopySource.dateEndCsr"
        base-field-container-type="v-col"
        base-field-type="date"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('dateEnd', TextMixin_GetParameter('csr'))"
        :readonly="EntityCardMixin_Readonly"
      />
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardFamiliesDetailFinancialSituation',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('financialSituation')
    }
  }
}
</script>
