<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.requestType"
        v-model="EntityCardMixin_CopySource.requestType"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        :disabled="EntityCardMixin_Disabled"
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('type')"
        :readonly="readonly || !ContentMixin_ElementHasPocoStatus('new')"
        :rules="[validationMixin.rules.required]"
      />
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardRequestsDetailSubmitRequestGeneralInformation',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('generalInformation')
    }
  }
}
</script>
