<template>
  <div>
    <base-under-construction />
  </div>
</template>

<script>
import BaseUnderConstruction from '@/components/shared/base/base-under-construction'

export default {
  name: 'Reports',
  components: {
    BaseUnderConstruction
  }
}
</script>
