import Axios from 'axios'

let _svc

export default {
  Attach (controller, parameters = {}) {
    try {
      let query = ''
      if (parameters) {
        Object.keys(parameters).forEach(key => {
          query += `${query ? '&' : '?'}${key}=${parameters[key]}`
        })
      }

      return _svc.post(`${controller}/Attach${query}`)
    } catch {
      return null
    }
  },
  Codes (controller, subroute) {
    try {
      subroute = subroute ? `/${subroute}` : ''
      return _svc.get(`${controller}${subroute}/Codes`)
    } catch {
      return null
    }
  },
  Custom (path, httpMethod = 'get') {
    try {
      return _svc[httpMethod](path)
    } catch {
      return null
    }
  },
  Delete (controller, id) {
    try {
      return _svc.post(`${controller}/Delete/${id}`)
    } catch {
      return null
    }
  },
  Detach (controller, parameters = {}) {
    try {
      let query = ''
      if (parameters) {
        Object.keys(parameters).forEach(key => {
          query += `${query ? '&' : '?'}${key}=${parameters[key]}`
        })
      }

      return _svc.post(`${controller}/Detach${query}`)
    } catch {
      return null
    }
  },
  Detail (controller, id, parameters) {
    try {
      let query = ''
      query += id ? `?id=${id}` : ''

      if (parameters) {
        parameters.forEach(element => {
          query += `${query ? '&' : '?'}${element.key}=${element.value}`
        })
      }

      return _svc.get(`${controller}/Detail${query}`)
    } catch {
      return null
    }
  },
  DetailDuplicate (controller, id) {
    try {
      return _svc.get(`${controller}/Detail/Duplicate/${id}`)
    } catch {
      return null
    }
  },
  DetailSave (controller, detail) {
    try {
      return _svc.post(`${controller}/Detail/Save`, detail)
    } catch {
      return null
    }
  },
  Init (errorHandler) {
    _svc = Axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        errorHandler(error)
      }
    )
  },
  List (controller, page = 1, pageSize = 10, sortBy = [], sortDesc = [], filter, searchText) {
    try {
      let query = `?Paging.PageIndex=${page - 1}&Paging.PageSize=${pageSize}`
      if (sortBy && sortDesc) {
        sortBy.forEach((item, index) => {
          query += `&Sort[${index}].SortField=${item.replace('.value', '')}&Sort[${index}].SortOrder=${sortDesc[index] ? 'DESC' : 'ASC'}`
        })
      }

      if (filter) {
        filter.forEach(element => {
          query += `&${element.key}=${element.value}`
        })
      }

      query += searchText ? `&searchText=${searchText}` : ''
      return _svc.get(`${controller}/List${query}`)
    } catch {
      return null
    }
  },
  NewDetailForParent (controller, childType, idParent) {
    try {
      return _svc.get(`${controller}/${childType}/NewDetailFor${controller}?id${controller}=${idParent}`)
    } catch {
      return null
    }
  },
  PickSource (controller, ignoreMaxResultCount = true, parameters = {}) {
    try {
      let query = `?IgnoreMaxResultCount=${ignoreMaxResultCount}`
      if (parameters) {
        Object.keys(parameters).forEach(key => {
          query += `&${key}=${parameters[key]}`
        })
      }

      return _svc.get(`${controller}/PickSource${query}`)
    } catch {
      return null
    }
  },
  Process (controller) {
    try {
      return _svc.post(`${controller}/Process`)
    } catch {
      return null
    }
  },
  Scheduler (controller, date, view) {
    try {
      let query = '?'
      query += date ? `&selectedDate=${date}` : ''
      query += view ? `&view=${view}` : ''
      return _svc.get(`${controller}/Scheduler${query}`)
    } catch {
      return null
    }
  },
  Upload (controller, file) {
    try {
      const formData = new FormData()
      formData.append('file', file)

      return _svc.post(`${controller}/Upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch {
      return null
    }
  }
}
