<template>
  <base-tabs-view
    :default-tab-on-select="3"
    :tabs="Tabs"
  />
</template>

<script>
import BaseTabsView from '@/components/shared/base/base-tabs-view'
import BaseTabsViewMixin from '@/mixins/base-tabs-view-mixin'

export default {
  name: 'EMail',
  components: {
    BaseTabsView
  },
  mixins: [
    BaseTabsViewMixin
  ],
  computed: {
    Tabs () {
      return [
        {
          component: 'e-mail-overview',
          idComponent: this.constantMixin.menuItem.eMail.general,
          mailType: this.constantMixin.mailType.received,
          name: this.TextMixin_GetTab('inbox')
        },
        {
          component: 'e-mail-overview',
          idComponent: this.constantMixin.menuItem.eMail.general,
          mailType: this.constantMixin.mailType.sent,
          name: this.TextMixin_GetTab('outbox')
        },
        {
          component: 'e-mail-overview',
          idComponent: this.constantMixin.menuItem.eMail.general,
          mailType: this.constantMixin.mailType.all,
          name: this.TextMixin_GetTab('inAndOutbox')
        },
        {
          component: 'e-mail-detail',
          disabled: !this.ContentMixin_Element,
          idComponent: this.constantMixin.menuItem.eMail.general,
          name: this.TextMixin_GetTab(this.BaseTabsViewMixin_GetDetailsTab(), this.TextMixin_GetParameter('eMail'))
        },
        {
          component: 'base-under-construction',
          idComponent: this.constantMixin.menuItem.eMail.general,
          name: this.TextMixin_GetTab('deletedItems')
        }
      ]
    }
  }
}
</script>
