<template>
  <user-management-roles />
</template>

<script>
import UserManagementRoles from '@/views/usermanagement/roles/components/user-management-roles'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'UserManagementRolesView',
  components: {
    UserManagementRoles
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
