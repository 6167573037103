import { defineStore } from 'pinia'

export default defineStore({
  id: 'framework',
  state () {
    return {
      error: false,
      language: null,
      leaveConfirmed: false,
      loading: [],
      sidebar: null,
      snackbars: []
    }
  },
  actions: {
    ShowLoader (object) {
      const key = `${object.component}_${object.method}`
      const isCurrentlyLoading = this.loading.includes(key)

      if (object.loading && !isCurrentlyLoading) {
        this.loading.push(key)
      } else if (!object.loading && isCurrentlyLoading) {
        this.loading.splice(this.loading.indexOf(key), 1)
      }
    },
    ShowSnackbar (snackbar) {
      let show = true

      if (this.snackbars.length > 0) {
        const lastSnackbar = this.snackbars[this.snackbars.length - 1]

        if (lastSnackbar.show && lastSnackbar.color === snackbar.color && lastSnackbar.text === snackbar.text) {
          show = false
        }
      }

      if (show) {
        this.snackbars.push(snackbar)
      }
    }
  }
})
