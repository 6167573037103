<template>
  <e-mail />
</template>

<script>
import EMail from '@/views/email/components/e-mail'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'EMailView',
  components: {
    EMail
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
