<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.firstName"
        v-model="EntityCardMixin_CopySource.firstName"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('firstName')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.firstName.value, 255), validationMixin.rules.required]"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.lastName"
        v-model="EntityCardMixin_CopySource.lastName"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('lastName')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.lastName.value, 255), validationMixin.rules.required]"
      />
    </v-row>
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.userType"
        v-model="EntityCardMixin_CopySource.userType"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        :disabled="EntityCardMixin_Disabled"
        :items="userTypes"
        :label="TextMixin_GetLabel('userType')"
        :readonly="EntityCardMixin_Readonly"
        @change="UserTypeChangeEvent($event)"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.userGroup"
        v-model="EntityCardMixin_CopySource.userGroup"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        :disabled="EntityCardMixin_Disabled"
        :items="lists.userGroups"
        :label="TextMixin_GetLabel('userGroup')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.required]"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.userGroup && (UserIsCarePartner || UserIsMonitor)"
        v-model="EntityCardMixin_CopySource.userEntity"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        :disabled="EntityCardMixin_Disabled"
        :items="UserEntityField.items"
        :label="UserEntityField.label"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.required]"
      />
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardUserManagementUsersDetailAccountInformation',
  mixins: [
    EntityCardMixin
  ],
  data () {
    return {
      lists: {
        userGroups: []
      }
    }
  },
  computed: {
    UserEntityField () {
      const properties = {
        items: [],
        label: null
      }

      if (this.UserIsCarePartner) {
        properties.items = this.carePartners
        properties.label = this.TextMixin_GetLabel('carePartner')
      } else if (this.UserIsMonitor) {
        properties.items = this.monitors
        properties.label = this.TextMixin_GetLabel('monitor')
      }

      return properties
    },
    UserIsCarePartner () {
      return this.AuthenticationMixin_UserIsCarePartner(this.EntityCardMixin_CopySource.userType.value)
    },
    UserIsMonitor () {
      return this.AuthenticationMixin_UserIsMonitor(this.EntityCardMixin_CopySource.userType.value)
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async GetUserGroups (idUserType = this.EntityCardMixin_CopySource.userType.value) {
      const params = {
        idUserType: idUserType
      }

      this.lists.userGroups = await this.ContentMixin_Service_BC_PickSource('UserGroup', params)
    },
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('accountInformation')
      await this.ContentMixin_Service_BC_InitializeContentService('User')
      await this.GetUserGroups()
    },
    async UserTypeChangeEvent (value) {
      await this.GetUserGroups(value)
      this.ContentMixin_HandleSelectChange(this.EntityCardMixin_CopySource.userGroup)
      this.ContentMixin_HandleSelectChange(this.EntityCardMixin_CopySource.userEntity)
    }
  }
}
</script>
