<template>
  <v-row
    v-if="EntityCardMixin_CopySource"
    :id="TableFamilyCostIncomeId()"
    class="pt-3"
    @mouseover="Mouseover()"
  >
    <v-col
      v-for="(type, index) in financialCostIncomeTypes"
      :key="index"
      class="py-0"
      lg="6"
      md="12"
    >
      <base-card
        v-if="EntityCardMixin_CopySource.familyCostIncome"
        :card-id="TableFamilyCostIncomeId(index)"
        list-detail
        :title="EntityCardMixin_CopySource.familyCostIncome[type].description"
      >
        <base-list-detail
          v-if="EntityCardMixin_CopySource.familyCostIncome[type].details"
          v-model="EntityCardMixin_CopySource.familyCostIncome[type].details"
          class="table-border-bottom"
          controller="Family/Detail"
          hide-filter
          :readonly="EntityCardMixin_Readonly"
          :type="type"
          :visible="visible"
        >
          <template
            #[`edit-amount`]="item"
          >
            <base-field
              v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.familyCostIncome[type].details, item.id).amount"
              :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.familyCostIncome[type].details, item.id).amount"
              base-field-type="number"
              :decimals="2"
              dense
              :disabled="EntityCardMixin_Disabled"
              ignore-default-event-handler
              :readonly="EntityCardMixin_Readonly"
              @input="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.familyCostIncome[type].details, item.id, 'amount', $event.value)"
            />
          </template>
          <template
            #[`edit-extraInfo`]="item"
          >
            <base-field
              v-if="DisplayExtraInfoField(item, type)"
              :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.familyCostIncome[type].details, item.id).extraInfo"
              dense
              :disabled="EntityCardMixin_Disabled"
              ignore-default-event-handler
              :readonly="EntityCardMixin_Readonly"
              :rules="ExtraInfoRules(item, type)"
              @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.familyCostIncome[type].details, item.id, 'extraInfo', $event.value)"
            />
          </template>
          <template
            #[`footer-prepend-1`]
          >
              <div
                class="subtotal-amount"
              >
                {{TextMixin_GetLabel('totalAmount')}}: €{{ GetTotalAmount(type) }}
              </div>
          </template>
        </base-list-detail>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import BaseListDetail from '@/components/shared/base/base-list-detail'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardFamiliesDetailFinancialSituationList',
  mixins: [
    EntityCardMixin
  ],
  components: {
    BaseListDetail
  },
  data () {
    return {
      financialCostIncomeTypes: [
        'fixedCosts',
        'income',
        'variableCosts',
        'yearlyCosts'
      ],
      formatted: false,
      index: 0
    }
  },
  watch: {
    FrameworkMixin_Loading (value) {
      if (!value) {
        this.FormatVCards()
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    DisplayExtraInfoField (item, type) {
      const extraInfo = this.ContentMixin_GetBaseListDetailItem(this.EntityCardMixin_CopySource.familyCostIncome[type].details, item.id).extraInfo
      const amount = this.ContentMixin_GetBaseListDetailItem(this.EntityCardMixin_CopySource.familyCostIncome[type].details, item.id).amount.value
      return extraInfo && amount != null
    },
    ExtraInfoRules (item, type) {
      if (this.ContentMixin_GetBaseListDetailItem(this.EntityCardMixin_CopySource.familyCostIncome[type].details, item.id).needsExtraInfo) {
        return [this.validationMixin.rules.required]
      } else {
        return []
      }
    },
    FormatVCards () {
      if (!this.formatted) {
        let index = 0

        while (index < this.financialCostIncomeTypes.length - 1) {
          this.CommonMixin_SetVCardHeights(this.TableFamilyCostIncomeId(index), this.TableFamilyCostIncomeId(index + 1))
          index += 2
        }
      }
    },
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('financialSituation')
      this.FormatVCards()
    },
    Mouseover () {
      if (!this.formatted) {
        this.FormatVCards()
        this.formatted = true
      }
    },
    TableFamilyCostIncomeId (index) {
      return `table-family-cost-income-${this.name}${index == null ? '' : `-${index}`}`
    },
    GetTotalAmount (type) {
      var items = this.EntityCardMixin_CopySource.familyCostIncome[type].details.items
      var total = parseFloat(items.reduce((total, item) => total + item.amount.value, 0))
      if (type === 'yearlyCosts') {
        return `${(total / 12).toFixed(2)}/${this.TextMixin_GetLabel('month')}`
      } else {
        return total.toFixed(2)
      }
    }
  }
}
</script>

<style scoped>
.subtotal-amount {
  font-weight: bold;
  width: 33%;
}
</style>
