import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_Pages () {
      return {
        bankStatements: {
          fr: 'Relevé banquaires',
          nl: 'Bankafschriften'
        },
        calendar: {
          fr: 'Calendrier',
          nl: 'Kalender'
        },
        carePartners: {
          fr: 'Partenaires sociaux',
          nl: 'Sociale partners'
        },
        children: {
          fr: 'Enfants',
          nl: 'Kinderen'
        },
        dashboard: {
          fr: 'Tableau de bord',
          nl: 'Dashboard'
        },
        documents: {
          fr: 'Documents',
          nl: 'Documenten'
        },
        eMail: {
          fr: 'E-mail',
          nl: 'E-mail'
        },
        error: {
          fr: 'Erreur',
          nl: 'Foutmelding'
        },
        families: {
          fr: 'Familles',
          nl: 'Gezinnen'
        },
        homepage: {
          fr: 'Page d\'accueil',
          nl: 'Startpagina'
        },
        monitors: {
          fr: 'Accompagnants',
          nl: 'Begeleiders'
        },
        reports: {
          fr: 'Rapports',
          nl: 'Rapporten'
        },
        requests: {
          fr: 'Demandes',
          nl: 'Aanvragen'
        },
        underConstruction: {
          fr: 'En construction',
          nl: 'In opbouw'
        },
        userManagement: {
          fr: 'Gestion des utilisateurs',
          nl: 'Gebruikersbeheer'
        },
        userManagement_loginHistory: {
          fr: 'Historique de connexion',
          nl: 'Aanmeldgeschiedenis'
        },
        userManagement_profiles: {
          fr: 'Profils',
          nl: 'Profielen'
        },
        userManagement_roles: {
          fr: 'Rôles',
          nl: 'Rollen'
        },
        userManagement_users: {
          fr: 'Utilisateurs',
          nl: 'Gebruikers'
        }
      }
    }
  }
}
