<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-documents-detail-general-information
      :readonly="readonly"
      :source="source"
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardDocumentsDetailGeneralInformation from '@/components/shared/entitycards/entity-card-documents-detail-general-information'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'

export default {
  name: 'DocumentsDetail',
  components: {
    BaseFormDetail,
    EntityCardDocumentsDetailGeneralInformation
  },
  mixins: [
    BaseFormDetailMixin
  ],
  data () {
    return {
      controller: 'Document'
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
    }
  }
}
</script>
