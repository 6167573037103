<template>
  <base-field
    v-if="childMixin.element"
    v-model="childMixin.element.value"
    :base-field-container-type="baseFieldContainerType"
    base-field-type="autocomplete"
    :disabled="childMixin.element.disabled"
    :items="items"
    :label="label"
    :readonly="childMixin.element.readonly"
    :rules="rules"
  >
    <template
      #inner-append
    >
      <v-btn
        v-if="ShowRead"
        class="pa-3"
        icon
        @click="$emit('pickers-read', value)"
      >
        <v-icon
          small
        >
          mdi-eye
        </v-icon>
      </v-btn>
      <v-btn
        v-if="ShowUpdate"
        class="pa-3 show"
        icon
        @click="$emit('pickers-update', value)"
      >
        <v-icon
          small
        >
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
        v-if="ShowCreate"
        class="pa-3 show"
        icon
        @click="$emit('pickers-create', value)"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
  </base-field>
</template>

<script>
import BaseField from '@/components/shared/base/base-field'
import ChildMixin from '@/mixins/child-mixin'

export default {
  name: 'BasePicker',
  components: {
    BaseField
  },
  mixins: [
    ChildMixin
  ],
  props: {
    baseFieldContainerType: String,
    disabled: Boolean,
    items: Array,
    label: String,
    readonly: Boolean,
    rules: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    ShowCreate () {
      return this.childMixin.element.enableCreate && !this.childMixin.element.readonly && !this.childMixin.element.disabled
    },
    ShowRead () {
      return this.childMixin.element.enableRead && !this.childMixin.element.disabled && this.childMixin.element.value?.value
    },
    ShowUpdate () {
      return this.childMixin.element.enableEdit && !this.childMixin.element.readonly && !this.childMixin.element.disabled && this.childMixin.element.value?.value
    }
  }
}
</script>
