import Moment from 'moment'

export default {
  data () {
    return {
      validationMixin: {
        rules: {
          belgianNationalNumber: value => {
            let valid = false
            const pattern = /^[0-9]{2}[.][0-9]{2}[.][0-9]{2}[-][0-9]{3}[.][0-9]{2}$/

            if (pattern.test(value)) {
              const number = value.replaceAll('.', '').replaceAll('-', '')
              const remainder = parseInt(number.substr(9, 2))
              let firstPart = parseInt(number.substr(0, 9))
              let checkPart = 97 - (firstPart % 97)

              if (checkPart !== remainder) {
                // If birth date is after 2000 add: add a 2 before the first 9 numbers
                firstPart = 2000000000 + firstPart
                checkPart = 97 - (firstPart % 97)
              }

              if (checkPart === remainder) {
                valid = true
              }
            }

            return (!value || valid) || this.TextMixin_GetValidationError('invalidNationalNumber')
          },
          charactersExact: (value, number) => !value || value.length === number || this.TextMixin_GetValidationError('charactersExact', number),
          charactersMax: (value, number) => !value || value.length <= number || this.TextMixin_GetValidationError('charactersMaximum', number),
          charactersMin: (value, number) => !value || value.length >= number || this.TextMixin_GetValidationError('charactersMinimum', number),
          compareString: (value, string, error) => value === string || error || this.TextMixin_GetValidationError('compareString'),
          date: value => {
            const pattern = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](18|19|20|21)\d\d$/
            return (!value || (pattern.test(value) && Moment(value, 'DD/MM/YYYY').isValid())) || this.TextMixin_GetValidationError('invalidDate')
          },
          eMail: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (Array.isArray(value)) {
              let correct = true
              value.forEach(email => {
                if (correct !== false) {
                  correct = pattern.test(email)
                }
              })
              return (!value || correct) || this.TextMixin_GetValidationError('invalidEMailAddress')
            } else {
              return (!value || pattern.test(value)) || this.TextMixin_GetValidationError('invalidEMailAddress')
            }
          },
          familyNumber: value => {
            const pattern = /^[BVW]\d{2}\/\d{4}/
            return (!value || pattern.test(value)) || this.TextMixin_GetValidationError('invalidFamilyNumber')
          },
          guid: value => {
            const pattern = /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/
            return (!value || pattern.test(value))
          },
          maxFileSize: (value, size) => !value || !value.size || value.size < (size * 1024 * 1024) || this.TextMixin_GetValidationError('maximumFileSize', size),
          numberBetween: (value, min, max) => value == null || (value >= min && value <= max) || this.TextMixin_GetValidationError('invalidNumber'),
          numberGreaterThan: (value, min, include) => value == null || (value > min || (include && value >= min)) || this.TextMixin_GetValidationError('invalidNumber'),
          numberLesserThan: (value, max, include) => value == null || (value < max || (include && value <= max)) || this.TextMixin_GetValidationError('invalidNumber'),
          numberNatural: value => {
            const pattern = /^(?:0|[1-9][0-9]*)$/
            return (!value || pattern.test(value)) || this.TextMixin_GetValidationError('invalidNumber')
          },
          password: value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W])/
            return (!value || pattern.test(value)) || this.TextMixin_GetValidationError('passwordConstruction')
          },
          required: value => {
            if (Array.isArray(value)) {
              return (value != null && value.length > 0) || this.TextMixin_GetValidationError('required')
            } else {
              return (value != null && value !== '' && value !== this.constantMixin.guid.empty) || this.TextMixin_GetValidationError('required')
            }
          }
        }
      }
    }
  }
}
