<template>
  <base-dialog
    :value="value"
    :title="AuthenticationMixin_HasValidChangePasswordToken() ? TextMixin_GetTitle('changePassword') : TextMixin_GetTitle('forgotPassword')"
    @input="$emit('input', $event)"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="AuthenticationMixin_HasValidChangePasswordToken() ? ChangePassword() : SendMail()"
    >
      <template
        v-if="AuthenticationMixin_HasValidChangePasswordToken() || hadValidChangePasswordToken"
      >
        <v-text-field
          v-if="!passwordChanged"
          v-model="account.username"
          :autofocus="!account.username"
          class="pb-1"
          :class="{'shake' : animated}"
          :label="TextMixin_GetLabel('eMail')"
          :prepend-inner-icon="'mdi-email'"
          required
          :rules="[validationMixin.rules.eMail, validationMixin.rules.required]"
          @keyup.enter="ChangePassword()"
        />
        <v-text-field
          v-if="!passwordChanged"
          v-model="account.password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          class="pb-1"
          :class="{'shake' : animated}"
          :label="TextMixin_GetLabel('password')"
          :prepend-inner-icon="'mdi-lock'"
          required
          :rules="[validationMixin.rules.required, validationMixin.rules.charactersMin(account.password, 8)]"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          @keyup.enter="ChangePassword()"
        />
        <v-text-field
          v-if="!passwordChanged"
          v-model="account.repeatPassword"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          class="pb-1"
          :class="{'shake' : animated}"
          :label="TextMixin_GetLabel('repeatPassword')"
          :prepend-inner-icon="'mdi-lock'"
          required
          :rules="[validationMixin.rules.required, validationMixin.rules.compareString(account.repeatPassword, account.password, this.TextMixin_GetValidationError('comparePassword'))]"
          :type="show2 ? 'text' : 'password'"
          @click:append="show2 = !show2"
          @keyup.enter="ChangePassword()"
        />
        <p
          v-else
          class="pb-10"
        >
          {{ result }}.
        </p>
      </template>
      <template
        v-else
      >
        <v-text-field
          v-if="!mailSent"
          v-model="account.username"
          :autofocus="!account.username"
          class="pb-1"
          :class="{'shake' : animated}"
          :label="TextMixin_GetLabel('eMail')"
          :prepend-inner-icon="'mdi-email'"
          required
          :rules="[validationMixin.rules.eMail, validationMixin.rules.required]"
          @keyup.enter="SendMail()"
        />
        <p
          v-else
          class="pb-10"
        >
          {{ result }}
        </p>
      </template>
    </v-form>
    <template
      v-if="!mailSent && !passwordChanged"
      #actions
    >
      <v-btn
        color="primary"
        type="button"
        @click="AuthenticationMixin_HasValidChangePasswordToken() ? ChangePassword() : SendMail()"
      >
        {{ AuthenticationMixin_HasValidChangePasswordToken() ? TextMixin_GetLabel('changePassword') : TextMixin_GetLabel('send', TextMixin_GetParameter('eMail')) }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog-mixin'

export default {
  name: 'DialogForgotPassword',
  mixins: [
    DialogMixin
  ],
  data () {
    return {
      account: {
        password: null,
        repeatPassword: null,
        username: null
      },
      animated: false,
      hadValidChangePasswordToken: false,
      mailSent: false,
      passwordChanged: false,
      remember: true,
      result: '',
      show1: false,
      show2: false,
      valid: false
    }
  },
  mounted () {
    this.Initialize()
  },
  watch: {
    value (newValue) {
      if (!newValue && this.passwordChanged) {
        this.hadValidChangePasswordToken = false
        this.passwordChanged = false

        const query = Object.assign({}, this.$route.query)
        delete query.rpwToken
        this.$router.replace({ query })
      }
    }
  },
  methods: {
    async ChangePassword () {
      this.$refs.form.validate()
      if (this.valid && this.AuthenticationMixin_HasValidChangePasswordToken()) {
        const methodName = 'ChangePassword'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        const response = await this.AuthenticationMixin_RecoverPasswordUpdatePassword(this.account, this.$route.query.rpwToken)

        if (response && response.data) {
          if (response.data.passwordIsChanged) {
            this.result = this.TextMixin_GetLabel('passwordChanged')
            this.passwordChanged = true
            this.hadValidChangePasswordToken = true
          }
        }
        this.FrameworkMixin_Load(this.$options.name, methodName, false)
      } else {
        this.Shake()
      }
    },
    Initialize () {
      this.account.username = null
    },
    async SendMail () {
      this.$refs.form.validate()

      if (this.valid && this.account.username != null) {
        const methodName = 'SendMail'
        this.FrameworkMixin_Load(this.$options.name, methodName, true)
        const response = await this.AuthenticationMixin_RecoverPasswordSendMail(this.account.username)

        if (response && response.data) {
          if (response.data.mailIsSent) {
            this.showResult = true
            this.result = this.TextMixin_GetMessage('mailSent', [this.account.username])
            this.mailSent = true
          }
        }
        this.FrameworkMixin_Load(this.$options.name, methodName, false)
      } else {
        this.Shake()
      }
    },
    Shake () {
      this.animated = true
      setTimeout(() => { this.animated = false }, 1000)
    }
  }
}
</script>
