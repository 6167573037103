<template>
  <base-list-overview
    controller="Request"
    :visible="visible"
  >
    <template
      #[`read-requestStatus`]="item"
    >
      <v-chip
        :color="RequestStatusColor(item)"
        small
      >
        {{ item.requestStatus.value }}
      </v-chip>
    </template>
  </base-list-overview>
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'RequestsOverview',
  components: {
    BaseListOverview
  },
  mixins: [
    ContentMixin
  ],
  methods: {
    RequestStatusColor (item) {
      return this.constantMixin.settings.requestStatus[this.RequestStatusDescription(item)].color
    },
    RequestStatusDescription (item) {
      const values = this.constantMixin.codes.requestStatus.values
      return Object.keys(values).find(key => values[key].toUpperCase() === item.idCodeRequestStatus.value.toUpperCase())
    }
  }
}
</script>
