import DocumentService from '@/services/document-service'

export default {
  data () {
    return {
      documentMixin: {
        fileTypes: {
          csv: 'mdi-file-delimited-outline',
          doc: 'mdi-microsoft-word',
          docx: 'mdi-microsoft-word',
          folder: 'mdi-folder',
          gif: 'mdi-file-gif-box',
          jpg: 'mdi-file-jpg-box',
          jpeg: 'mdi-file-jpg-box',
          mp3: 'mdi-music-box',
          mp4: 'mdi-multimedia',
          one: 'mdi-microsoft-onenote',
          pdf: 'mdi-file-pdf-box',
          png: 'mdi-file-png-box',
          ppt: 'mdi-microsoft-powerpoint',
          pptx: 'mdi-microsoft-powerpoint',
          spfx: 'mdi-microsoft-sharepoint',
          txt: 'mdi-file-document-outline',
          unknown: 'mdi-help-box',
          xls: 'mdi-microsoft-excel',
          xlsx: 'mdi-microsoft-excel',
          xml: 'mdi-microsoft-excel'
        },
        fileTypeColors: {
          csv: 'grey',
          doc: '#285192',
          docx: '#285192',
          folder: '#f7aa24',
          gif: '#58c948',
          jpg: '#58c948',
          jpeg: '#58c948',
          mp3: '#1184cb',
          mp4: '1184cb',
          one: '#7b3576',
          pdf: '#ff0000',
          png: '#58c948',
          ppt: '#c94324',
          pptx: '#c94324',
          spfx: '#02777f',
          txt: 'grey',
          unknown: 'grey',
          xls: '#1e6e42',
          xlsx: '#1e6e42',
          xml: '#1e6e42'
        }
      }
    }
  },
  mounted () {
    DocumentService.Init(this.ErrorMixin_HandleError)
  },
  methods: {
    async DocumentMixin_CreateFolder (parentFolderKey, name) {
      const response = await DocumentService.CreateFolder(parentFolderKey, name)
      if (response && response.data) {
        return response.data
      }
      return null
    },
    DocumentMixin_GetFileIcon (item) {
      const itemType = item.itemType.value.toLowerCase().replaceAll('.', '')
      return {
        color: this.documentMixin.fileTypeColors[itemType] ?? this.documentMixin.fileTypeColors.unknown,
        icon: this.documentMixin.fileTypes[itemType] ?? this.documentMixin.fileTypes.unknown
      }
    },
    async DocumentMixin_Folders (folderKey, params) {
      const response = await DocumentService.Content(folderKey, params)
      if (response && response.data) {
        return {
          allowCustomFolders: response.data.allowCustomFolders,
          allowDocuments: response.data.allowDocuments,
          folders: response.data.folders,
          items: response.data.items
        }
      }
      return []
    },
    async DocumentMixin_RenameFolder (folderKey, newName) {
      const response = await DocumentService.RenameFolder(folderKey, newName)
      if (response && response.data) {
        return response.data
      }
      return null
    },
    async DocumentMixin_UploadFile (parentFolderKey, file) {
      if (file) {
        const response = await DocumentService.Upload(parentFolderKey, file)

        if (response && (response.data || response.data !== '')) {
          return response.data
        }
      }

      return null
    }
  }
}
