<template>
  <div>
    <entity-card-care-partners-detail-general-information
      v-model="ContentMixin_Element.carePartnerDetail"
      :care-partners="lists.carePartners"
      :codes="lists.codes.carePartner"
      :pickers="[Pickers.carePartner]"
      readonly
      :source="source"
      :title="TextMixin_GetTitle('generalInformation', `(${TextMixin_GetParameter('carePartner').toLowerCase()})`)"
      @pickers-change="EntityCardMixin_PickersChange($event)"
      @pickers-read="EntityCardMixin_PickersRead($event)"
    />
    <entity-card-families-detail-general-information
      v-model="ContentMixin_Element.familyDetail"
      :codes="lists.codes.family"
      :families="lists.families"
      :pickers="[Pickers.family]"
      :posts="posts"
      readonly
      :source="source"
      :title="TextMixin_GetTitle('generalInformation', `(${TextMixin_GetParameter('family').toLowerCase()})`)"
      @pickers-change="FamilySelected($event)"
      @pickers-create="EntityCardMixin_PickersCreate($event)"
      @pickers-read="EntityCardMixin_PickersRead($event)"
      @pickers-update="EntityCardMixin_PickersUpdate($event)"
    />
    <entity-card-requests-detail-submit-request-extra-budget-details
      v-if="ContentMixin_Element.family?.value"
      :children="lists.children"
      :codes="contentMixin.codes"
      :pickers="[Pickers.child]"
      :source="source"
      @pickers-change="FamilySelected($event)"
      @pickers-create="EntityCardMixin_PickersCreate($event)"
      @pickers-read="EntityCardMixin_PickersRead($event)"
      @pickers-update="EntityCardMixin_PickersUpdate($event)"
    />
    <dialog-detail
      v-if="entityCardMixin.dialogDetail.show"
      v-model="entityCardMixin.dialogDetail.show"
      :content="entityCardMixin.dialogDetail"
      @close="EntityCardMixin_PickersClose()"
      @save="EntityCardMixin_PickersSave(Pickers[entityCardMixin.dialogDetail.type])"
    />
  </div>
</template>

<script>
import DialogDetail from '@/components/shared/dialogs/dialog-detail'
import EntityCardCarePartnersDetailGeneralInformation from '@/components/shared/entitycards/entity-card-care-partners-detail-general-information'
import EntityCardFamiliesDetailGeneralInformation from '@/components/shared/entitycards/entity-card-families-detail-general-information'
import EntityCardRequestsDetailSubmitRequestExtraBudgetDetails from '@/components/shared/entitycards/entity-card-requests-detail-submit-request-extra-budget-details'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'RequestsDetailSubmitRequestExtraBudget',
  components: {
    DialogDetail,
    EntityCardCarePartnersDetailGeneralInformation,
    EntityCardFamiliesDetailGeneralInformation,
    EntityCardRequestsDetailSubmitRequestExtraBudgetDetails
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Request',
      lists: {
        carePartners: [],
        children: [],
        codes: {
          carePartner: [],
          family: []
        },
        families: []
      }
    }
  },
  computed: {
    Pickers () {
      return {
        carePartner: this.EntityCardMixin_Pickers(false, 'CarePartner', 'carePartner', 'carePartners', 'care-partners-detail', this.PickersReadonly.carePartner, null, null, null, true, this.PickersRules.carePartner),
        child: this.EntityCardMixin_Pickers(false, 'Child', 'child', 'children', 'children-detail', this.PickersReadonly.child, null, !this.PickersReadonly.child, !this.PickersReadonly.child, true, this.PickersRules.child),
        family: this.EntityCardMixin_Pickers(false, 'Family', 'family', 'families', 'families-detail', this.PickersReadonly.family, null, !this.PickersReadonly.family, !this.PickersReadonly.family, true, this.PickersRules.family)
      }
    },
    PickersReadonly () {
      return {
        carePartner: this.readonly || !(this.ContentMixin_ElementHasPocoStatus('new') && this.AuthenticationMixin_UserIsAdministrator()),
        child: this.readonly,
        family: this.readonly
      }
    },
    PickersRules () {
      return {
        carePartner: [this.validationMixin.rules.required],
        child: [this.validationMixin.rules.required],
        family: [this.validationMixin.rules.required]
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async FamilySelected (value) {
      await this.EntityCardMixin_PickersChange(value)
      await this.GetChildren()
    },
    async GetChildren () {
      const params = {
        idFamily: this.ContentMixin_Element.family?.value ?? this.constantMixin.guid.empty
      }

      this.lists.children = await this.ContentMixin_Service_BC_PickSource('Child', params)
    },
    async GetPickerCodes () {
      this.lists.codes.carePartner = await this.ContentMixin_Service_BC_InitializeCodes('CarePartner')
      this.lists.codes.family = await this.ContentMixin_Service_BC_InitializeCodes('Family')
    },
    async GetPickerLists () {
      await this.GetPickerCodes()
      await this.GetPickerPickSources()
      await this.EntityCardMixin_PickersInitialize(this.Pickers)
      await this.EntitiCardMixin_PickersInitializeValue(this.Pickers, this.parameters)
    },
    async GetPickerPickSources () {
      this.lists.carePartners = await this.ContentMixin_Service_BC_PickSource('CarePartner')
      this.lists.families = await this.ContentMixin_Service_BC_PickSource('Family')
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller, false)
      await this.GetPickerLists()

      if (this.ContentMixin_Element.family?.value) {
        this.GetChildren()
      }
    }
  }
}
</script>
