<template>
  <div>
    <entity-card-requests-detail-process-request-extra-budget-details
      :children="lists.children"
      :codes="contentMixin.codes"
      :pickers="[Pickers.child]"
      :source="source"
      @pickers-change="FamilySelected($event)"
      @pickers-create="EntityCardMixin_PickersCreate($event)"
      @pickers-read="EntityCardMixin_PickersRead($event)"
      @pickers-update="EntityCardMixin_PickersUpdate($event)"
    />
  </div>
</template>

<script>
import EntityCardRequestsDetailProcessRequestExtraBudgetDetails from '@/components/shared/entitycards/entity-card-requests-detail-process-request-extra-budget-details'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'RequestsDetailProcessRequestExtraBudget',
  components: {
    EntityCardRequestsDetailProcessRequestExtraBudgetDetails
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Request',
      lists: {
        children: []
      }
    }
  },
  computed: {
    Pickers () {
      return {
        child: this.EntityCardMixin_Pickers(false, 'Child', 'child', 'children', 'children-detail', true, true, !this.PickersReadonly.child, !this.PickersReadonly.child, true, this.PickersRules.child)
      }
    },
    PickersReadonly () {
      return {
        child: this.readonly
      }
    },
    PickersRules () {
      return {
        child: [this.validationMixin.rules.required]
      }
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller, false)
      this.lists.children = await this.ContentMixin_Service_BC_PickSource('Child')
    }
  }
}
</script>
