<template>
  <div
    class="base-field-date"
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-text-field
      ref="base-field-date"
      v-model="childMixin.element"
      :class="Class"
      :color="color"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :outlined="outlined"
      :readonly="readonly"
      :rules="rules"
      :type="Type"
      @blur="Blur()"
      @change="$emit('change', $event)"
      @click="InputReset()"
      @focus="Focus()"
      @keydown="Keydown($event)"
    >
      <template
        v-if="showNavigation"
        #append
      >
        <v-btn
          v-for="(navigationButton, index) in NavigationButtons"
          :key="index"
          class="navigation-button"
          icon
          @click="Navigate(navigationButton.days)"
        >
          <v-icon
            :color="Color"
          >
            {{ navigationButton.icon }}
          </v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseFieldDate',
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // v-model value
    value: String
  },
  data () {
    return {
      dateInput: [],
      focus: false,
      hovering: false,
      ignoreBlur: false
    }
  },
  computed: {
    Class () {
      return [
        this.baseFieldClass,
        `${this.ShowControls ? 'show' : 'hide'}-icons`,
        `base-field-date-${this.Type}`,
        this.dense ? 'dense' : '',
        this.childMixin.element ? '' : 'empty'
      ]
    },
    Color () {
      return this.focus ? 'primary' : ''
    },
    NavigationButtons () {
      return [
        this.GetNavigationButton(-1, 'left'),
        this.GetNavigationButton(1, 'right')
      ]
    },
    IsDateField () {
      return this.ShowControls || this.childMixin.element
    },
    ShowControls () {
      return !this.readonly && (this.hovering || this.focus)
    },
    Type () {
      return this.IsDateField ? 'date' : 'text'
    }
  },
  watch: {
    'childMixin.element' () {
      this.Emit()
    },
    focus (value) {
      if (value && !this.hovering) {
        this.ignoreBlur = true
      }
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    value () {
      this.FormatData()
    }
  },
  mounted () {
    this.FormatData()
  },
  methods: {
    Blur () {
      if (this.ignoreBlur) {
        this.ignoreBlur = false
        this.$refs['base-field-date'].focus()
      } else {
        this.focus = false
      }
    },
    Emit () {
      // Fire when the date has changed
      // @arg The argument is a datetime value (v-model)
      this.$emit('input', this.FormatMixin_FormatDateTime(this.childMixin.element, this.formatMixin.date.form, this.formatMixin.date.database))
    },
    Focus () {
      this.focus = true
      this.InputReset()
    },
    FormatData () {
      this.childMixin.element = this.FormatMixin_FormatDateTime(this.value, this.formatMixin.date.database, this.formatMixin.date.form)
    },
    GetNavigationButton (days, icon) {
      return {
        days: days,
        icon: `mdi-chevron-${icon}`
      }
    },
    InputReset () {
      this.dateInput = []
    },
    Keydown (value) {
      if (this.autocomplete) {
        if (parseInt(value.key)) {
          this.dateInput.push(value.key)
        } else if (value.key === 'Backspace' || (value.key === 'Tab' && value.shiftKey)) {
          this.InputReset()
        } else if (value.key === 'Enter' && value.shiftKey && this.dateInput.length) {
          const today = new Date()
          const length = this.dateInput.length > 1 ? 2 : 1
          const month = today.getMonth() + 1
          let dateString = `${today.getFullYear()}-${month < 10 ? `0${month}` : month}-`
          let day = ''

          for (let counter = length; counter > 0; counter--) {
            day += this.dateInput[this.dateInput.length - counter]
          }

          if (day.toString().length < 2) {
            day = `0${day}`
          }

          if (Date.parse(`${dateString}${day}`)) {
            dateString += day
          } else {
            dateString += `0${this.dateInput[this.dateInput.length - 1]}`
          }

          this.childMixin.element = dateString
          this.InputReset()

          let next = false
          let child = this
          let parent = this.$parent
          let found = false

          while (parent.$children[parent.$children.length - 1] === child) {
            if (next) {
              child = parent
              parent = parent.$parent
            }

            parent.$parent.$children.forEach(parentChild => {
              if (next) {
                child = parent
                parent = parentChild
                next = false
              } else {
                next = parentChild === parent
              }
            })
          }

          parent.$children.forEach(child => {
            if (!found && (parent !== this.$parent || next)) {
              let childElement = child

              while (childElement.$children.length > 0 && !childElement.focus) {
                childElement = childElement.$children[0]
              }

              if (childElement.focus) {
                childElement.focus()
                next = false
                found = true
              }
            } else {
              next = child === this
            }
          })
        }
      }
    },
    MouseLeave () {
      this.hovering = false
    },
    MouseOver () {
      this.hovering = true
    },
    Navigate (days) {
      this.childMixin.element = this.childMixin.element ? this.CommonMixin_GetDateWithDaysAdded(this.childMixin.element, days) : this.CommonMixin_GetDate()
    }
  }
}
</script>

<style>
.base-field-date .hide-icons input[type="date"]::-webkit-inner-spin-button,
.base-field-date .hide-icons input[type="date"]::-webkit-calendar-picker-indicator,
.base-field-date .hide-icons .navigation-button {
  visibility: hidden;
  -webkit-appearance: none;
}

.base-field-date .dense {
  margin-top: 2px !important;
}

.base-field-date .navigation-button {
  margin-top: 2px;
}

.base-field-date .dense .navigation-button {
  margin-top: 5px;
}

.base-field-date-text.dense.empty {
  margin-top: 4px !important;
}
</style>
