<template>
  <base-card
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <template
        v-if="EntityCardMixin_Picker"
      >
        <base-picker
          v-if="entityCardMixin.pickers.components[0]"
          v-model="entityCardMixin.pickers.components[0]"
          base-field-container-type="v-col"
          :items="families"
          :label="TextMixin_GetLabel('family')"
          :rules="pickers[0].rules"
          @pickers-create="$emit('pickers-create', $event)"
          @pickers-read="$emit('pickers-read', $event)"
          @pickers-update="$emit('pickers-update', $event)"
        />
      </template>
      <template
        v-if="EntityCardMixin_CopySource"
      >
        <base-field
          v-if="!EntityCardMixin_Picker && EntityCardMixin_CopySource.uniqueCode"
          v-model="EntityCardMixin_CopySource.uniqueCode"
          base-field-container-type="v-col"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('uniqueCode')"
          :readonly="EntitycardMixin_Readonly"
        />
        <base-field
          v-if="!EntityCardMixin_Picker && EntityCardMixin_CopySource.description"
          v-model="EntityCardMixin_CopySource.description"
          base-field-container-type="v-col"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('description')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.eMail"
          v-model="EntityCardMixin_CopySource.eMail"
          base-field-container-type="v-col"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('eMail')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.eMail, validationMixin.rules.charactersMax(EntityCardMixin_CopySource.eMail.value, 255)]"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.telephone"
          v-model="EntityCardMixin_CopySource.telephone"
          base-field-container-type="v-col"
          :disabled="EntityCardMixin_Disabled"
          :label="TextMixin_GetLabel('telephone')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.telephone.value, 255)]"
        />
      </template>
    </v-row>
    <template
      v-if="EntityCardMixin_CopySource"
    >
      <v-row>
        <v-col
          v-if="EntityCardMixin_InputFieldIsShown(EntityCardMixin_CopySource.street) || EntityCardMixin_InputFieldIsShown(EntityCardMixin_CopySource.houseNumber)"
          cols="12"
          lg="3"
          md="6"
        >
          <v-row>
            <base-field
              v-if="EntityCardMixin_CopySource.street"
              v-model="EntityCardMixin_CopySource.street"
              base-field-container-type="v-col"
              :disabled="EntityCardMixin_Disabled"
              :label="TextMixin_GetLabel('street')"
              lg="9"
              md="9"
              :readonly="EntityCardMixin_Readonly"
              :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.street.value, 255)]"
            />
            <base-field
              v-if="EntityCardMixin_CopySource.houseNumber"
              v-model="EntityCardMixin_CopySource.houseNumber"
              base-field-container-type="v-col"
              :disabled="EntityCardMixin_Disabled"
              :label="TextMixin_GetLabel('houseNumber')"
              lg="3"
              md="3"
              :readonly="EntityCardMixin_Readonly"
              :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.houseNumber.value, 15)]"
            />
          </v-row>
        </v-col>
        <base-field
          v-if="EntityCardMixin_CopySource.post"
          v-model="EntityCardMixin_CopySource.post"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          :disabled="EntityCardMixin_Disabled"
          :items="posts"
          :label="TextMixin_GetLabel('postalCode')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.province"
          v-model="EntityCardMixin_CopySource.province"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="EntityCardMixin_Disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('province')"
          :readonly="EntityCardMixin_Readonly"
        />
      </v-row>
    </template>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardFamiliesDetailGeneralInformation',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('generalInformation')
    }
  }
}
</script>
