<template>
  <div>
    <v-list-group
      v-if="item.children"
      v-model="activeGroup"
      append-icon="mdi-menu-down"
      color="white"
      :group="group"
      :prepend-icon="item.icon"
      :sub-group="subGroup"
    >
      <template
        #activator
      >
        <v-list-item-icon
          v-if="text"
          class="v-list-item__icon--text"
          v-text="ComputedText"
        />
        <v-list-item-content>
          <v-list-item-title
            v-text="item.title"
          />
        </v-list-item-content>
      </template>
      <template
        v-for="(child, i) in Children"
      >
        <framework-sidebar-menu-item-subgroup
          v-if="child.children && AuthenticationMixin_HasRights(child)"
          :key="`sub-group-${i}`"
          :item="child"
        />
        <framework-sidebar-menu-item
          v-else-if="AuthenticationMixin_HasRights(child)"
          :key="`item-${i}`"
          hide-icon
          :item="child"
        />
      </template>
    </v-list-group>
    <framework-sidebar-menu-item
      v-else-if="AuthenticationMixin_HasRights(item)"
      :item="item"
    />
  </div>
</template>

<script>
// Utilities
import FrameworkSidebarMenuItem from '@/components/framework/framework-sidebar-menu-item'
import FrameworkSidebarMenuItemSubgroup from '@/components/framework/framework-sidebar-menu-item-subgroup'
import KebabCase from 'lodash/kebabCase'

export default {
  name: 'FrameworkSidebarMenuItemGroup',
  components: {
    FrameworkSidebarMenuItem,
    FrameworkSidebarMenuItemSubgroup
  },
  inheritAttrs: false,
  props: {
    // The item to show
    item: {
      default: () => ({
        avatar: undefined,
        children: undefined,
        group: undefined,
        title: undefined
      }),
      type: Object
    },
    // Designate the component as nested list group
    subGroup: {
      default: false,
      type: Boolean
    },
    // Show text instead of icon
    text: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      activeGroup: false
    }
  },
  computed: {
    Children () {
      if (this.item.children) {
        return this.item.children.map(item => ({
          ...item,
          to: !item.to ? undefined : `${this.item.group}/${item.to}`
        }))
      }
      return this.item.children
    },
    ComputedText () {
      if (!this.item || !this.item.title) return ''

      let text = ''

      this.item.title.split(' ').forEach(val => {
        text += val.substring(0, 1)
      })

      return text
    },
    group () {
      return this.GenGroup(this.item.children)
    }
  },
  watch: {
    async '$route.path' () {
      this.$nextTick(() => {
        this.SetActiveGroup()
      })
    }
  },
  mounted () {
    this.SetActiveGroup()
  },
  methods: {
    GenGroup (children) {
      return children
        .filter(item => item.to)
        .map(item => {
          const parent = item.group || this.item.group
          let group = `${parent}/${KebabCase(item.to)}`

          if (item.children) {
            group = `${group}|${this.GenGroup(item.children)}`
          }

          return group
        }).join('|')
    },
    SetActiveGroup () {
      if (this.item.children) {
        this.activeGroup = this.Children.filter(child => this.$route.path === child.to).length > 0
      }
    }
  }
}
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style>
