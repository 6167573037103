export default {
  props: {
    // The element of which information is shown
    value: Object,
    // Is disabled
    disabled: Boolean,
    // Is readonly
    readonly: Boolean
  },
  data () {
    return {
      childMixin: {
        element: null,
        initializing: false
      }
    }
  },
  watch: {
    'childMixin.element': {
      handler (value) {
        if (!this.childMixin.initializing) {
          // Fire when input changed
          // @arg input
          this.$emit('input', value)
        } else {
          this.childMixin.initializing = false
        }
      },
      deep: true
    },
    value: {
      handler (newValue, oldValue) {
        if (!this.CommonMixin_HardCompare(newValue, oldValue)) {
          this.ChildMixin_Initialize()
        }
      },
      deep: true
    }
  },
  created () {
    this.ChildMixin_Initialize()
  },
  methods: {
    ChildMixin_Emit (event, value) {
      // Event triggered by base-fields
      // @arg value
      this.$emit(event, value)
    },
    ChildMixin_Initialize () {
      if (this.value) {
        this.childMixin.initializing = true
        this.childMixin.element = this.CommonMixin_Clone(this.value)
        this.$forceUpdate()
      }
    }
  }
}
