<template>
  <base-under-construction />
</template>

<script>
import BaseUnderConstruction from '@/components/shared/base/base-under-construction'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'UnderConstructionView',
  components: {
    BaseUnderConstruction
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
