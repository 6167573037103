<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    no-padding
    :title="EntityCardMixin_Title"
  >
    <base-list-overview
      controller="Child"
      :filter="filter"
      hide-filter
      inside-card
      readonly
      redirect
      :visible="visible"
    />
  </base-card>
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardChildrenDetailBudgetDetails',
  components: {
    BaseListOverview
  },
  mixins: [
    EntityCardMixin
  ],
  computed: {
    filter () {
      return [
        {
          key: 'idChildSiblings',
          value: this.EntityCardMixin_CopySource.idChild
        }
      ]
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('siblings')
      await this.ContentMixin_Service_BC_InitializeContentService('Child')
    }
  }
}
</script>
