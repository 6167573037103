<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.eventType"
        v-model="EntityCardMixin_CopySource.eventType"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        :disabled="disabled"
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('eventType')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.carePartner"
        v-model="EntityCardMixin_CopySource.carePartner"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        :disabled="disabled"
        :items="carePartners"
        :label="TextMixin_GetLabel('carePartner')"
        :readonly="ReadonlyField('CarePartner')"
      />
    </v-row>
    <template
      v-if="EntityCardMixin_CopySource.eventType.value"
    >
      <v-row
        v-if="!EntityCardMixin_Picker"
      >
        <base-field
          v-if="EntityCardMixin_CopySource.subject"
          v-model="EntityCardMixin_CopySource.subject"
          base-field-container-type="v-col"
          :disabled="disabled"
          :label="TextMixin_GetLabel('subject')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.subject.value, 255), validationMixin.rules.required]"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.user"
          v-model="EntityCardMixin_CopySource.user"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          :disabled="disabled"
          :items="users"
          :label="TextMixin_GetLabel('user')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.required]"
        />
      </v-row>
      <v-row>
        <base-field
          v-if="EntityCardMixin_CopySource.dateStart"
          v-model="EntityCardMixin_CopySource.dateStart"
          base-field-container-type="v-col"
          base-field-type="dateTime"
          :disabled="disabled"
          :label="TextMixin_GetLabel('dateStart')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.dateEnd"
          v-model="EntityCardMixin_CopySource.dateEnd"
          base-field-container-type="v-col"
          base-field-type="dateTime"
          :disabled="disabled"
          :label="TextMixin_GetLabel('dateEnd')"
          :readonly="EntityCardMixin_Readonly"
        />
      </v-row>
      <v-row>
        <base-field
          v-if="EntityCardMixin_CopySource.status"
          v-model="EntityCardMixin_CopySource.status"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('status')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.sendNotification"
          v-model="EntityCardMixin_CopySource.sendNotification"
          base-field-container-type="v-col"
          base-field-type="checkbox"
          :disabled="disabled"
          :label="TextMixin_GetLabel('send', TextMixin_GetParameter('notification').toLowerCase())"
          :readonly="EntityCardMixin_Readonly"
        />
      </v-row>
      <v-row>
        <base-field
          v-if="EntityCardMixin_CopySource.activityType"
          v-model="EntityCardMixin_CopySource.activityType"
          base-field-container-type="v-col"
          base-field-type="autocomplete"
          code
          :disabled="disabled"
          :items="contentMixin.codes"
          :label="TextMixin_GetLabel('activityType')"
          :readonly="EntityCardMixin_Readonly"
        />
        <base-field
          v-if="EntityCardMixin_CopySource.location"
          v-model="EntityCardMixin_CopySource.location"
          base-field-container-type="v-col"
          :disabled="disabled"
          :label="TextMixin_GetLabel('location')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.location.value, 255), validationMixin.rules.required]"
        />
      </v-row>
      <v-row>
        <base-field
          v-if="EntityCardMixin_CopySource.description"
          v-model="EntityCardMixin_CopySource.description"
          base-field-container-type="v-col"
          base-field-type="textarea"
          :disabled="disabled"
          :label="TextMixin_GetLabel('description')"
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.description.value, 1024), validationMixin.rules.required]"
        />
      </v-row>
    </template>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardActivitiesDetailInformation',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('information')
    },
    ReadonlyField (field) {
      let readonly = this.EntityCardMixin_Readonly

      if (!readonly && this.source === 'dialog' && this.ContentMixin_Store_DetailSourceParameters) {
        readonly = !!this.ContentMixin_Store_DetailSourceParameters.filter(x => x.key === `id${field}`)
      }

      return readonly
    }
  }
}
</script>
