<template>
  <v-list-item
    v-if="!ContentMixin_ElementHasPocoStatus('new')"
    class="v-menu-list-item"
    @click="Duplicate()"
  >
    <v-list-item-title>
      {{ TextMixin_GetLabel('duplicate') }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'UserManagementProfilesDetailCustomActions',
  mixins: [
    EntityCardMixin
  ],
  methods: {
    Duplicate () {
      // User clicks 'Duplicate'
      this.$emit('duplicate')
    }
  }
}
</script>
