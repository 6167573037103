import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_Parameters () {
      return {
        activity: {
          fr: 'Activité',
          nl: 'Activiteit'
        },
        allocated: {
          fr: 'Attribué',
          nl: 'Toegekend'
        },
        attachments: {
          fr: 'Pièces jointe',
          nl: 'Bijlagen'
        },
        bankStatement: {
          fr: 'Transation',
          nl: 'Transactie'
        },
        bankStatements: {
          fr: 'Relevés bancaires (coda\'s)',
          nl: 'Bankafschriften (coda\'s)'
        },
        carePartner: {
          fr: 'Partenaire social',
          nl: 'Sociale partner'
        },
        child: {
          fr: 'Enfant',
          nl: 'Kind'
        },
        contact: {
          fr: 'Contact',
          nl: 'Contact'
        },
        csr: {
          fr: 'RIS',
          nl: 'CSR'
        },
        debtMediator: {
          fr: 'Médiateur de dettes',
          nl: 'Schuldbemiddelaar'
        },
        document: {
          fr: 'Document',
          nl: 'Document'
        },
        eMail: {
          fr: 'E-mail',
          nl: 'E-mail'
        },
        family: {
          fr: 'Famille',
          nl: 'Gezin'
        },
        file: {
          fr: 'Fichier',
          nl: 'Bestand'
        },
        folder: {
          fr: 'Dossier',
          nl: 'Map'
        },
        functionalityGroup: {
          fr: 'Profil',
          nl: 'Profiel'
        },
        landline: {
          fr: 'Fixe',
          nl: 'Vast'
        },
        mail: {
          fr: 'E-mail',
          nl: 'E-mail'
        },
        mobile: {
          fr: 'Mobile',
          nl: 'Mobiel'
        },
        monitor: {
          fr: 'Accompagnant',
          nl: 'Begeleider'
        },
        notification: {
          fr: 'Notification',
          nl: 'Notificatie'
        },
        personal: {
          fr: 'Personnel',
          nl: 'Persoonlijk'
        },
        representative: {
          fr: 'Représentant',
          nl: 'Vertegenwoordiger'
        },
        request: {
          fr: 'Demande',
          nl: 'Aanvraag'
        },
        requested: {
          fr: 'Demandé',
          nl: 'Aangevraagd'
        },
        user: {
          fr: 'Utilisateur',
          nl: 'Gebruiker'
        },
        userGroup: {
          fr: 'Rôle',
          nl: 'Rol'
        },
        zip: {
          fr: 'ZIP',
          nl: 'ZIP'
        }
      }
    }
  }
}
