<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-bank-statements-overview
      filterKey="idChild"
      :readonly="readonly"
      :source="source"
      :visible="ShowOverview"
      @create="EntityCardMixin_PickersCreate(Pickers.bankStatement)"
      @update="EntityCardMixin_PickersUpdate(Pickers.bankStatement, $event)"
    />
    <dialog-detail
      v-if="entityCardMixin.dialogDetail.show"
      v-model="entityCardMixin.dialogDetail.show"
      :content="entityCardMixin.dialogDetail"
      :parameters="Parameters()"
      @close="EntityCardMixin_PickersClose()"
      @save="DialogDetailSave()"
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import DialogDetail from '@/components/shared/dialogs/dialog-detail'
import EntityCardBankStatementsOverview from '@/components/shared/entitycards/entity-card-bank-statements-overview'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'CarePartnersActivities',
  components: {
    BaseFormDetail,
    DialogDetail,
    EntityCardBankStatementsOverview
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'BankStatement',
      lists: {
        bankStatements: []
      }
    }
  },
  computed: {
    Pickers () {
      return {
        bankStatement: this.EntityCardMixin_Pickers(false, 'BankStatement', 'bankStatement', 'bankStatements', 'bank-statements-detail', this.PickersReadonly.bankStatement, null, null, null, true)
      }
    },
    PickersReadonly () {
      return {
        bankStatement: this.readonly || !(this.ContentMixin_ElementHasPocoStatus('new') && this.AuthenticationMixin_UserIsAdministrator())
      }
    },
    ShowOverview () {
      return this.visible && !this.entityCardMixin.dialogDetail.show
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async GetPickerLists () {
      await this.GetPickerPickSources()
      await this.EntityCardMixin_PickersInitialize(this.Pickers)
      await this.EntitiCardMixin_PickersInitializeValue(this.Pickers, this.parameters)
    },
    async GetPickerPickSources () {
      this.lists.activities = await this.ContentMixin_Service_BC_PickSource('BankStatement')
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.GetPickerLists()
      this.SetParameters()
    },
    Parameters () {
      const key = 'idChild'
      return [
        {
          key: key,
          value: this.ContentMixin_Element[key]
        }
      ]
    },
    async DialogDetailSave () {
      this.EntityCardMixin_PickersSave(this.Pickers[this.entityCardMixin.dialogDetail.type])
    },
    SetParameters () {
      this.ContentMixin_Store_DetailSourceParameters = this.Parameters()
    },
    ShowComponent (value) {
      return this.ContentMixin_Element.requestType.value && this.ContentMixin_Element.requestType.value.toUpperCase() === this.constantMixin.codes.requestType.values[value].toUpperCase()
    }
  }
}
</script>
