<template>
  <v-card
    :style="StyleVStepperHeader"
  >
    <v-stepper-header
      v-if="steps.length > 1"
      v-model="ContentMixin_Store_SelectedStep"
      class="mt-3 mx-5 overflow-hidden"
    >
      <template
        v-for="(step, index) in steps"
      >
        <v-divider
          v-if="index > 0"
          :key="`divider-${index}`"
          class="mx-1"
          style="z-index: 10;"
        />
        <v-stepper-step
          :key="index"
          active-class="red"
          :class="step.class"
          :color="StepColor(step)"
          :complete="step.complete"
          :complete-icon="StepCompleteIcon(step)"
          :editable="step.editable"
          :edit-icon="StepEditableIcon(step)"
          :step="index + 1"
          :style="step.style"
        >
          {{ StepName(step) }}
          <small
            v-if="step.description"
            v-html="step.description"
          />
        </v-stepper-step>
      </template>
    </v-stepper-header>
  </v-card>
</template>

<script>
import BaseStepsViewMixin from '@/mixins/base-steps-view-mixin'

export default {
  name: 'BaseSteps',
  mixins: [
    BaseStepsViewMixin
  ],
  props: {
    // Level of steps
    level: {
      default: 0,
      type: Number
    }
  },
  computed: {
    StyleVStepperHeader () {
      const base = 'background-color: white; z-index: 2; padding-bottom: 15px; margin-bottom: -35px;'

      if (this.level === 0) {
        return `${base} position: sticky; top: 0px;`
      } else if (this.level === 1) {
        return base
      } else {
        return base
      }
    }
  },
  watch: {
    steps (value) {
      if (this.value > value.length - 1) {
        this.ContentMixin_Store_SelectedStep = 0
      }
    }
  },
  methods: {
    StepColor (step) {
      return step.color ?? '#9E9E9E'
    },
    StepCompleteIcon (step) {
      return step.completeIcon ?? 'mdi-check'
    },
    StepEditableIcon (step) {
      return this.StepCompleteIcon(step)
    },
    StepName (step) {
      return step.name ? step.name.charAt(0).toUpperCase() + step.name.slice(1) : ''
    }
  }
}
</script>

<style>
.v-stepper__step {
  border-radius: 4px;
  cursor: pointer;
  margin: 12px !important;
  padding: 6px 18px;
}

.v-stepper__step--active {
  background-color: #00000014;
}
</style>
