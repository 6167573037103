import Axios from 'axios'

let _svc

export default {
  Download (id) {
    try {
      return _svc.get(`Blob/Download/${id}`, {
        responseType: 'blob'
      })
    } catch {
      return null
    }
  },
  Upload (file) {
    try {
      const formData = new FormData()
      formData.append('file', file)

      return _svc.post('Blob/Upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch {
      return null
    }
  },
  Init (errorHandler) {
    _svc = Axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        errorHandler(error)
      }
    )
  }
}
