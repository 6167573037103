<template>
  <dashboard />
</template>

<script>
import Dashboard from '@/views/dashboard/components/dashboard'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'DashboardView',
  components: {
    Dashboard
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
