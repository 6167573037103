<template>
  <div
    class="base-field-time"
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-text-field
      ref="base-field-time"
      v-model="childMixin.element"
      :class="Class"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :outlined="outlined"
      :readonly="readonly"
      :rules="rules"
      :type="Type"
      @blur="Blur()"
      @change="$emit('change', $event)"
      @focus="Focus()"
      @keydown="Keydown($event)"
    />
  </div>
</template>

<script>
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseFieldTime',
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // Value to be shown
    value: String,
    // Format for value
    format: String
  },
  data () {
    return {
      date: null,
      defaultDate: null,
      focus: false,
      hovering: false,
      ignoreBlur: false
    }
  },
  computed: {
    Class () {
      return [
        this.baseFieldClass,
        `${this.ShowControls ? 'show' : 'hide'}-icons`,
        `base-field-time-${this.Type}`,
        this.dense ? 'dense' : '',
        this.childMixin.element ? '' : 'empty'
      ]
    },
    IsTimeField () {
      return this.ShowControls || this.childMixin.element
    },
    ShowControls () {
      return !this.readonly && (this.hovering || this.focus)
    },
    Type () {
      return this.IsTimeField ? 'time' : 'text'
    }
  },
  watch: {
    'childMixin.element' () {
      this.Emit()
    },
    focus (value) {
      if (value && !this.hovering) {
        this.ignoreBlur = true
      }
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    value () {
      this.FormatData()
    }
  },
  mounted () {
    this.FormatData()
  },
  methods: {
    Blur () {
      if (this.ignoreBlur) {
        this.ignoreBlur = false
        this.$refs['base-field-time'].focus()
      } else {
        this.focus = false
      }
    },
    Emit () {
      if (this.childMixin.element && this.childMixin.element.trim() !== '') {
        if (!this.date || this.date.trim() !== '') {
          this.date = this.CommonMixin_GetDate(this.formatMixin.date.form)
        }

        // Value has been changed
        this.$emit('input', this.FormatMixin_FormatDateTime(`${this.date} ${this.childMixin.element}:00`, this.formatMixin.date.dateTimeExact, this.format ?? this.formatMixin.date.database))
      } else {
        // Value has been cleared
        this.$emit('input', null)
      }
    },
    EmitChange (value) {
      // Value has changed
      this.$emit('change', value)
    },
    Focus () {
      this.focus = true
    },
    FormatData () {
      this.date = this.FormatMixin_FormatDateTime(this.value, this.formatMixin.date.database, this.formatMixin.date.form)
      this.childMixin.element = this.FormatMixin_FormatDateTime(this.value, this.formatMixin.date.database, this.formatMixin.date.time)
      this.defaultDate ??= this.date
    },
    MouseLeave () {
      this.hovering = false
      // Not hovering over element anymore
      this.$emit('mouseleave')
    },
    MouseOver () {
      this.hovering = true
      // Hovering over element
      this.$emit('mouseover')
    }
  }
}
</script>

<style>
.base-field-time .hide-icons input[type="time"]::-webkit-inner-spin-button,
.base-field-time .hide-icons input[type="time"]::-webkit-calendar-picker-indicator {
  visibility: hidden;
  -webkit-appearance: none;
}

.base-field-time .dense {
  margin-top: 1px !important;
}

.base-field-time-text.dense.empty {
  margin-top: 4px !important;
}
</style>
