import Axios from 'axios'

let _svc

export default {
  Init (errorHandler) {
    _svc = Axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        errorHandler(error)
      }
    )
  },
  ReleaseLock (releaseRequest) {
    try {
      return _svc.post('Lock/Release', releaseRequest)
    } catch {
      return null
    }
  },
  SetLock (lockRequest) {
    try {
      return _svc.post('Lock', lockRequest)
    } catch {
      return null
    }
  }
}
