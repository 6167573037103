<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-user-management-profiles-detail-general-information
      :readonly="readonly"
      :source="source"
    />
    <entity-card-user-management-profiles-detail-rights
      :readonly="readonly"
      :source="source"
    />
    <template
      v-if="!ContentMixin_ElementHasPocoStatus('new')"
      #[`custom-actions-menu-1`]
    >
      <user-management-profiles-detail-custom-actions
        :readonly="readonly"
        :source="source"
        @duplicate="BaseFormDetailMixin_Duplicate()"
      />
    </template>
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardUserManagementProfilesDetailGeneralInformation from '@/components/shared/entitycards/entity-card-user-management-profiles-detail-general-information'
import EntityCardUserManagementProfilesDetailRights from '@/components/shared/entitycards/entity-card-user-management-profiles-detail-rights'
import UserManagementProfilesDetailCustomActions from '@/views/usermanagement/profiles/components/user-management-profiles-detail-custom-actions'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'

export default {
  name: 'UserManagementProfilesDetail',
  components: {
    BaseFormDetail,
    EntityCardUserManagementProfilesDetailGeneralInformation,
    EntityCardUserManagementProfilesDetailRights,
    UserManagementProfilesDetailCustomActions
  },
  mixins: [
    BaseFormDetailMixin
  ],
  data () {
    return {
      controller: 'FunctionalityGroup'
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
    }
  }
}
</script>
