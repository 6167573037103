import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_ValidationErrors () {
      return {
        charactersExact: {
          fr: `${this.textMixin.placeholders.parameter} caractères exactement.`,
          nl: `Exact ${this.textMixin.placeholders.parameter} tekens.`
        },
        charactersMaximum: {
          fr: `${this.textMixin.placeholders.parameter} caractères maximum.`,
          nl: `Maximaal ${this.textMixin.placeholders.parameter} tekens.`
        },
        charactersMinimum: {
          fr: `${this.textMixin.placeholders.parameter} caractères minimum.`,
          nl: `Minimaal ${this.textMixin.placeholders.parameter} tekens.`
        },
        comparePassword: {
          fr: 'Les mots de passe ne correspondent pas.',
          nl: 'De wachtwoorden komen niet overeen.'
        },
        compareString: {
          fr: 'Les données ne correspondent pas.',
          nl: 'De gegevens komen niet overeen.'
        },
        invalidDate: {
          fr: 'Date invalide.',
          nl: 'Ongeldige datum.'
        },
        invalidEMailAddress: {
          fr: 'Adresse e-mail invalide.',
          nl: 'Ongeldig e-mailadres.'
        },
        invalidFamilyNumber: {
          fr: 'Numéro de famille invalide.',
          nl: 'Ongeldig familienummer.'
        },
        invalidNationalNumber: {
          fr: 'Numéro d\'enregistrement national invalide.',
          nl: 'Ongeldig rijksregisternummer.'
        },
        invalidNumber: {
          fr: 'Numéro invalide.',
          nl: 'Ongeldig getal.'
        },
        maximumCharacters: {
          fr: `${this.textMixin.placeholders.parameter} caractères maximum.`,
          nl: `Maximaal ${this.textMixin.placeholders.parameter} tekens.`
        },
        minimumCharacters: {
          fr: `${this.textMixin.placeholders.parameter} caractères minimum.`,
          nl: `Minimaal ${this.textMixin.placeholders.parameter} tekens.`
        },
        passwordConstruction: {
          fr: 'Votre mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial.',
          nl: 'Uw wachtwoord moet minstens één kleine letter, hoofdletter, cijfer en speciaal teken bevatten.'
        },
        required: {
          fr: 'Requise.',
          nl: 'Vereist.'
        }
      }
    }
  }
}
