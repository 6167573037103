<template>
  <div
    class="base-field-number"
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-text-field
      v-model="childMixin.element"
      :append-icon="Reverse ? icon : ''"
      :class="baseFieldClass"
      :color="color"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :outlined="outlined"
      :prefix="Reverse ? suffix : prefix"
      :prepend-inner-icon="Reverse ? '' : icon"
      :readonly="readonly"
      :reverse="Reverse"
      :rules="rules"
      :suffix="Reverse ? prefix : suffix"
      @blur="Blur()"
      @focus="Focus()"
      @keypress="Keypress($event)"
    />
  </div>
</template>
<script>
import BaseFieldMixin from '@/mixins/base-field-mixin'

export default {
  name: 'BaseFieldNumber',
  mixins: [
    BaseFieldMixin
  ],
  props: {
    // Value to show
    value: [
      Number,
      String
    ],
    // Align right
    reverse: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      focus: false,
      hovering: false
    }
  },
  computed: {
    Reverse () {
      return this.reverse ?? true
    },
    ShowControls () {
      return !this.readonly && (this.hovering || this.focus)
    }
  },
  watch: {
    'childMixin.element' (value) {
      if (value === '') {
        this.childMixin.element = null
      } else {
        this.Emit()
      }
    },
    focus (value) {
      if (!value) {
        this.FormatData()
        this.Emit()
      }
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    value () {
      this.FormatData()
    }
  },
  mounted () {
    this.FormatData()
  },
  methods: {
    Blur () {
      this.focus = false
    },
    Emit () {
      if (this.childMixin.element == null || !isNaN(this.childMixin.element)) {
        let emitValue = null

        if (this.childMixin.element != null) {
          emitValue = parseFloat(parseFloat(this.childMixin.element).toFixed(this.decimals ?? 0))
        }

        this.$emit('input', emitValue)
        this.$emit('change', emitValue)
      }
    },
    Focus () {
      this.focus = true
    },
    FormatData (value = this.value) {
      if (!(this.focus && this.SetDecimals(this.childMixin.element) === this.SetDecimals(value))) {
        this.childMixin.element = this.SetDecimals(value)
      }
    },
    Keypress (value) {
      const char = String.fromCharCode(value.keyCode)
      let prevent = false

      if (!(/^[-.0-9]+$/.test(char))) {
        prevent = true
      } else {
        const singleUseChars = ['-', '.']

        if (singleUseChars.includes(char) && this.childMixin.element && this.childMixin.element.includes(char)) {
          prevent = true
        }
      }

      if (prevent) {
        value.preventDefault()
      }
    },
    MouseLeave () {
      this.hovering = false
      // Not hovering over element anymore
      this.$emit('mouseleave')
    },
    MouseOver () {
      this.hovering = true
      // Hovering over element
      this.$emit('mouseover')
    },
    SetDecimals (value, decimals = null, format = 'en-US') {
      if (value == null || isNaN(value)) {
        return null
      } else {
        const formatter = new Intl.NumberFormat(format, {
          style: 'decimal',
          maximumFractionDigits: decimals ?? this.decimals ?? 0,
          minimumFractionDigits: decimals ?? this.decimals ?? 0
        })

        if (format === 'en-US') {
          return formatter.format(value ?? 0).replaceAll(',', '')
        } else {
          return formatter.format(value ?? 0)
        }
      }
    }
  }
}
</script>
