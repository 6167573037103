<template>
  <v-dialog
    :value="value"
    :fullscreen="$vuetify.breakpoint.mobile"
    :max-width="`${maxWidth}px`"
    :persistent="persistent"
    transition="scale-transition"
    @input="DialogInput($event)"
  >
    <v-card
      class="overflow-hidden pa-0"
    >
      <v-card-title
        class="pa-0 pb-3"
      >
        <v-toolbar
          color="secondary text-center"
          dark
        >
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            v-if="closable"
            icon
            @click="$emit('input', false)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text
        v-if="scrollable"
        class="customScrollbar pa-0"
      >
        <div
          class="pb-0"
          :style="DialogContentStyle"
        >
          <slot />
        </div>
      </v-card-text>
      <v-card-text
        v-else
        class="pb-0 pt-3"
      >
        <slot />
      </v-card-text>
      <v-card-actions
        v-if="$scopedSlots['actions']"
        class="pb-3 pt-0"
        :class="ActionsClass"
      >
        <slot
          name="actions"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    // Dialog is closable with X-button
    closable: {
      default: true,
      type: Boolean
    },
    // Max width of dialog
    maxWidth: {
      default: 500,
      type: Number
    },
    // Are there multiple components in the actions?
    multipleActions: Boolean,
    // Dialog is pepersistent
    persistent: Boolean,
    // Dialog content is scrollable
    scrollable: Boolean,
    // Title of dialog
    title: String,
    // Dialog is visible or not
    value: Boolean
  },
  computed: {
    ActionsClass () {
      return `justify-${this.multipleActions ? 'space-between' : 'end'}`
    },
    DialogContentStyle () {
      let style = 'max-height: 75vh; '
      style += this.scrollable ? 'overflow-y: scroll;' : ''
      return style
    }
  },
  methods: {
    DialogInput (value) {
      this.$emit('input', value)

      if (!value) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style>
.v-dialog {
  overflow: hidden;
}
</style>
