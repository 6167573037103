<template>
  <monitors />
</template>

<script>
import Monitors from '@/views/monitors/components/monitors'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'MonitorsView',
  components: {
    Monitors
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
