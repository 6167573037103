import BaseListMixin from '@/mixins/base-list-mixin'

export default {
  mixins: [
    BaseListMixin
  ],
  props: {
    // List
    value: {
      default: () => {},
      type: Object
    },
    // Type of element (for example to show on add-label)
    type: String
  },
  watch: {
    async 'baseListMixin.refresh' (value) {
      if (value) {
        await this.BaseListDetailMixin_List()
        this.baseListMixin.refresh = false
      }
    },
    async ContentMixin_ElementId (value) {
      if (value && this.contentMixin.controller) {
        await this.BaseListDetailMixin_Initialize(this.contentMixin.controller)
      }
    },
    async value (value) {
      await this.BaseListMixin_Setup({ data: value })
    }
  },
  methods: {
    async BaseListDetailMixin_Create (customCreate) {
      if (customCreate) {
        this.$emit('custom-create')
      } else {
        await this.BaseListDetailMixin_Detail()
      }
    },
    async BaseListDetailMixin_Detail (value) {
      const id = value ? value[this.contentMixin.key] : null
      if (id) {
        this.baseListMixin.selected = this.baseListMixin.list.filter(x => x.id === id)[0]
      } else {
        // Emit Add to detailList
        this.$emit('add')
      }
    },
    async BaseListDetailMixin_Delete (value) {
      // Emit Delete to detailList
      // @arg item id
      this.$emit('delete', value.id)
      if (this.baseListMixin.selected?.id === value.id) {
        this.baseListMixin.selected = null
      }
    },
    BaseListDetailMixin_GetAddLabel (readonly, hideFooter, rights) {
      if (!readonly && !hideFooter && rights.allowCreate) {
        return this.type ? this.TextMixin_GetLabel('add', this.TextMixin_GetParameter(this.type)) : this.BaseListMixin_GetAddLabel(readonly, hideFooter, rights)
      } else {
        return null
      }
    },
    async BaseListDetailMixin_Initialize (controller) {
      this.baseListMixin.edit = true
      await this.ContentMixin_Service_BC_InitializeContentService(controller, this.ContentMixin_ElementId)
      await this.BaseListDetailMixin_List()
      this.baseListMixin.selection = this.CommonMixin_Clone(this.selection)
    },
    async BaseListDetailMixin_List (options = this.baseListMixin.optionsCurrent) {
      const methodName = 'BaseListDetailMixin_List'
      this.FrameworkMixin_Load(this.$options.name, methodName, true)
      this.baseListMixin.optionsCurrent = options
      await this.BaseListMixin_Setup({ data: this.value })
      this.FrameworkMixin_Load(this.$options.name, methodName, false)
    },
    async BaseListDetailMixin_Read (value) {
      await this.BaseListDetailMixin_Detail(value)
    },
    async BaseListDetailMixin_Select (value) {
      await this.BaseListDetailMixin_Detail(value)
    },
    async BaseListDetailMixin_Update (customUpdate, value) {
      alert(customUpdate)
      if (customUpdate) {
        this.$emit('custom-update')
      } else {
        await this.BaseListDetailMixin_Detail(value)
      }
    }
  }
}
