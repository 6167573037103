<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    list-detail
    :title="EntityCardMixin_Title"
  >
    <base-list-detail
      v-model="EntityCardMixin_CopySource.contacts"
      controller="CarePartner"
      hide-filter
      :readonly="EntityCardMixin_Readonly"
      :type="type"
      :visible="visible"
      @add="EntityCardMixin_ListItemAdd('contacts')"
      @delete="EntityCardMixin_ListItemDelete('contacts', $event)"
    >
      <template
        #[`edit-contactName`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).contactName"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).contactName"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).contactName.value, 255)]"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.contacts, item.id, 'contactName', $event.value)"
        />
      </template>
      <template
        #[`edit-street`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).street"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).street"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).street.value, 255)]"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.contacts, item.id, 'street', $event.value)"
        />
      </template>
      <template
        #[`edit-houseNumber`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).houseNumber"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).houseNumber"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).houseNumber.value, 255)]"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.contacts, item.id, 'houseNumber', $event.value)"
        />
      </template>
      <template
        #[`edit-eMail`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).eMail"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).eMail"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.eMail, validationMixin.rules.charactersMax(ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).eMail.value, 255)]"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.contacts, item.id, 'eMail', $event.value)"
        />
      </template>
      <template
        #[`edit-post`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).post"
          v-model="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).post"
          base-field-type="autocomplete"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :items="posts"
          :readonly="EntityCardMixin_Readonly"
          @change="ContentMixin_HandleSelectWithPocoStatusChangeListDetail(EntityCardMixin_CopySource, 'contacts', 'post', item, $event, posts)"
        />
      </template>
      <template
        #[`edit-telephone`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).telephone"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).telephone"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          :rules="[validationMixin.rules.charactersMax(ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).telephone.value, 255)]"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.contacts, item.id, 'telephone', $event.value)"
        />
      </template>
      <template
        #[`edit-province`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).province"
          v-model="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.contacts, item.id).province"
          base-field-type="autocomplete"
          code
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :items="contentMixin.codes"
          :readonly="EntityCardMixin_Readonly"
          @change="ContentMixin_HandleSelectWithPocoStatusChangeListDetail(EntityCardMixin_CopySource, 'contacts', 'province', item, $event)"
        />
      </template>
    </base-list-detail>
  </base-card>
</template>

<script>
import BaseListDetail from '@/components/shared/base/base-list-detail'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardCarePartnersDetailContactDetails',
  components: {
    BaseListDetail
  },
  mixins: [
    EntityCardMixin
  ],
  props: {
    type: {
      default: 'contact',
      type: String
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('contactDetails')
      await this.ContentMixin_Service_BC_InitializeContentService('CarePartner')
    }
  }
}
</script>
