<template>
  <v-dialog
    v-model="loading"
    content-class="dialog-loader"
    persistent
  >
    <div>
      <ul>
        <li
          v-for="(item, key) in ['A', 'B', 'C']"
          :key="key"
          :id="`dot${item}`"
          class="primary"
        />
      </ul>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'FrameworkLoader',
  data () {
    return {
      interval: null,
      loading: false,
      stop: false
    }
  },
  watch: {
    FrameworkMixin_Loading (value) {
      if (value) {
        this.loading = true
        this.stop = false
        this.interval = setInterval(() => this.SetStop(), 500)
      } else if (this.stop) {
        this.loading = false
      }
    }
  },
  methods: {
    SetStop () {
      this.stop = true
      clearInterval(this.interval)

      if (!this.FrameworkMixin_Loading) {
        this.loading = false
      }
    }
  }
}
</script>

<style
  scoped
>
ul {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: rotate(0deg) translate(50%, 50%);
}

li {
  background: #f5d4a1;
  border-radius: 50%;
  height: 12px;
  left: 0px;
  list-style-type: none;
  position: absolute;
  top: 0px;
  width: 12px;
}

#dotA {
  animation: dotA 600ms ease-in-out infinite;
  left: -30px;
  top: 15px;
}

#dotB {
  animation: dotB 600ms ease-in-out infinite;
  left: 30px;
  top: 15px;
}

#dotC {
  animation: dotC 600ms ease-in-out infinite;
  left: 0px;
  top: -30px;
}

@keyframes dotA {
  100% {
    top: -30px;
    left: 0px;
  }
}

@keyframes dotB {
  100% {
    top: 15px;
    left: -30px;
  }
}

@keyframes dotC {
  100% {
    top: 15px;
    left: 30px;
  }
}

.loader {
  left: calc(50% - 50px);
  position: absolute;
  top: calc(50% - 50px);
}
</style>
