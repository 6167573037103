<template>
  <base-form-detail
    :controller="controller"
    :readonly="readonly"
    :source="source"
    @close="$emit('close')"
    @save="$emit('save')"
  >
    <entity-card-requests-detail-process-request-feedback
      :codes="contentMixin.codes"
      readonly
      :source="source"
      :visible="visible"
    />
    <entity-card-requests-detail-process-request-intervention-children
      v-if="HasRequestStatus('approved')"
      readonly
    />
  </base-form-detail>
</template>

<script>
import BaseFormDetail from '@/components/shared/base/base-form-detail'
import EntityCardRequestsDetailProcessRequestFeedback from '@/components/shared/entitycards/entity-card-requests-detail-process-request-feedback'
import EntityCardRequestsDetailProcessRequestInterventionChildren from '@/components/shared/entitycards/entity-card-requests-detail-process-request-intervention-children'
import BaseFormDetailMixin from '@/mixins/base-form-detail-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'RequestsDetailStatusRequest',
  components: {
    BaseFormDetail,
    EntityCardRequestsDetailProcessRequestFeedback,
    EntityCardRequestsDetailProcessRequestInterventionChildren
  },
  mixins: [
    BaseFormDetailMixin,
    EntityCardMixin
  ],
  data () {
    return {
      controller: 'Request'
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    HasRequestStatus (value) {
      return this.ContentMixin_Element.requestStatus.value && this.ContentMixin_Element.requestStatus.value.toUpperCase() === this.constantMixin.codes.requestStatus.values[value].toUpperCase()
    },
    async Initialize () {
      await this.BaseFormDetailMixin_Initialize(this.controller)
      await this.ContentMixin_Service_BC_InitializeCodes()
    }
  }
}
</script>
