import BankStatementsView from '@/views/bankstatements/bank-statements-view'
import CalendarView from '@/views/calendar/calendar-view'
import CarePartnersView from '@/views/carepartners/care-partners-view'
import ChildrenView from '@/views/children/children-view'
import FrameworkContainer from '@/components/framework/framework-container'
import DashboardView from '@/views/dashboard/dashboard-view'
import DocumentsView from '@/views/documents/documents-view'
import EMailView from '@/views/email/e-mail-view'
import ErrorView from '@/views/error/error-view'
import FamiliesView from '@/views/families/families-view'
import HomeView from '@/views/home/home-view'
import MonitorsView from '@/views/monitors/monitors-view'
import ReportsView from '@/views/reports/reports-view'
import RequestsView from '@/views/requests/requests-view'
import UnderConstructionView from '@/views/underconstruction/under-construction-view'
import UserManagementLoginHistoryView from '@/views/usermanagement/loginhistory/user-management-login-history-view'
import UserManagementProfilesView from '@/views/usermanagement/profiles/user-management-profiles-view'
import UserManagementRolesView from '@/views/usermanagement/roles/user-management-roles-view'
import UserManagementUsersView from '@/views/usermanagement/users/user-management-users-view'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    children: [
      {
        component: BankStatementsView,
        name: 'bankStatements',
        path: 'BankStatements'
      },
      {
        component: CalendarView,
        name: 'calendar',
        path: 'Calendar'
      },
      {
        component: CarePartnersView,
        name: 'carePartners',
        path: 'CarePartners'
      },
      {
        component: ChildrenView,
        name: 'children',
        path: 'Children'
      },
      {
        component: DashboardView,
        name: 'dashboard',
        path: 'Dashboard'
      },
      {
        component: DocumentsView,
        name: 'documents',
        path: 'Documents'
      },
      {
        component: EMailView,
        name: 'eMail',
        path: 'EMail'
      },
      {
        component: ErrorView,
        name: 'error',
        path: 'Error'
      },
      {
        component: FamiliesView,
        name: 'families',
        path: 'Families'
      },
      {
        component: HomeView,
        name: 'homepage',
        path: '/'
      },
      {
        component: MonitorsView,
        name: 'monitors',
        path: 'Monitors'
      },
      {
        component: ReportsView,
        name: 'reports',
        path: 'Reports'
      },
      {
        component: RequestsView,
        name: 'requests',
        path: 'Requests'
      },
      {
        component: UnderConstructionView,
        name: 'underConstruction',
        path: 'UnderConstruction'
      },
      {
        component: UserManagementLoginHistoryView,
        name: 'userManagement_loginHistory',
        path: 'UserManagement_LoginHistory'
      },
      {
        component: UserManagementProfilesView,
        name: 'userManagement_profiles',
        path: 'UserManagement_Profiles'
      },
      {
        component: UserManagementRolesView,
        name: 'userManagement_roles',
        path: 'UserManagement_Roles'
      },
      {
        component: UserManagementUsersView,
        name: 'userManagement_users',
        path: 'UserManagement_Users'
      },
      {
        component: ErrorView,
        name: 'error',
        path: '*'
      }
    ],
    component: FrameworkContainer,
    path: '/'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
