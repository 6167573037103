<template>
  <base-list-overview
    controller="Child"
    :visible="visible"
  />
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'ChildrenOverview',
  components: {
    BaseListOverview
  },
  mixins: [
    ContentMixin
  ]
}
</script>
