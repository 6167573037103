import TextMixinData from '@/mixins/text-mixin.data'

export default {
  mixins: [
    TextMixinData
  ],
  computed: {
    TextMixin_Headers () {
      return {
        activityType: {
          fr: 'Type d\'activité',
          nl: 'Type activiteit'
        },
        amount: {
          fr: 'Montant',
          nl: 'Waarde'
        },
        budget: {
          fr: 'Budget',
          nl: 'Budget'
        },
        carePartner: {
          fr: 'Partenaire sociaux',
          nl: 'Sociale partner'
        },
        changeLogDate: {
          fr: 'Date de modification',
          nl: 'Datum wijziging'
        },
        child: {
          fr: 'Enfant',
          nl: 'Kind'
        },
        contactName: {
          fr: 'Nom',
          nl: 'Naam'
        },
        contactPersonalEmail: {
          fr: 'Contact e-mail personnel',
          nl: 'Contact persoonlijke e-mail'
        },
        costIncomeDescription: {
          fr: 'Description',
          nl: 'Beschrijving'
        },
        create: {
          fr: 'Créer',
          nl: 'Aanmaken'
        },
        date: {
          fr: 'Date',
          nl: 'Datum'
        },
        dateLastUpdate: {
          fr: 'Dernière mise à jour',
          nl: 'Laatste update'
        },
        dateLogin: {
          fr: 'Temps',
          nl: 'Tijd'
        },
        dateOfBirth: {
          fr: 'Date de naissance',
          nl: 'Geboortedatum'
        },
        dateReceived: {
          fr: 'Date de réception',
          nl: 'Datum ontvangen'
        },
        dateEnd: {
          fr: 'Date de fin',
          nl: 'Einddatum'
        },
        dateSent: {
          fr: 'Date d\'envoi',
          nl: 'Datum verzonden'
        },
        dateStart: {
          fr: 'Date de début',
          nl: 'Startdatum'
        },
        delete: {
          fr: 'Effacer',
          nl: 'Verwijderen'
        },
        description: {
          fr: 'Description',
          nl: 'Beschrijving'
        },
        documentDate: {
          fr: 'Date de document',
          nl: 'Datum document'
        },
        educationalNetwork: {
          fr: 'Réseau éducatif',
          nl: 'Leernetwerk'
        },
        eMail: {
          fr: 'E-mail',
          nl: 'E-mail'
        },
        eMail2: {
          fr: 'E-mail 2',
          nl: 'E-mail 2'
        },
        entityField: {
          fr: 'Champ',
          nl: 'Veld'
        },
        extraInfo: {
          fr: 'Informations supplémentaires',
          nl: 'Extra informatie'
        },
        field: {
          fr: 'Champ',
          nl: 'Veld'
        },
        firstName: {
          fr: 'Prénom',
          nl: 'Voornaam'
        },
        folder: {
          fr: 'Prospectus',
          nl: 'Map'
        },
        from: {
          fr: 'Par',
          nl: 'Van'
        },
        fullName: {
          fr: 'Nom et prénom',
          nl: 'Volledigee naam'
        },
        gender: {
          fr: 'Sexe',
          nl: 'Geslacht'
        },
        houseNumber: {
          fr: 'Numéro de maison',
          nl: 'Huisnummer'
        },
        ibanNumber: {
          fr: 'IBAN',
          nl: 'IBAN'
        },
        idBlob: {
          fr: 'Pièce jointe',
          nl: 'Bijlage'
        },
        incoming: {
          fr: '',
          nl: ''
        },
        info: {
          fr: 'Information',
          nl: 'Informatie'
        },
        ipAddress: {
          fr: 'Adresse IP',
          nl: 'IP-adres'
        },
        itemCost: {
          fr: 'Coût de l\'article',
          nl: 'Kosten artikel'
        },
        itemName: {
          fr: 'Nom',
          nl: 'Naam'
        },
        itemType: {
          fr: 'Type',
          nl: 'Type'
        },
        juridicalStatus: {
          fr: 'Statut juridique',
          nl: 'Juridische status'
        },
        lastName: {
          fr: 'Nom de famille',
          nl: 'Familienaam'
        },
        module: {
          fr: 'Module',
          nl: 'Module'
        },
        moduleOrField: {
          fr: 'Module / Champ',
          nl: 'Module / Veld'
        },
        monitor: {
          fr: 'Accompagnant',
          nl: 'Begeleider'
        },
        name: {
          fr: 'Nom',
          nl: 'Naam'
        },
        numberBankStatement: {
          fr: 'Numéro de relevé bancaire',
          nl: 'Nummer bankafschrift'
        },
        newDescription: {
          fr: 'Nouvelle description',
          nl: 'Nieuwe waarde'
        },
        oldDescription: {
          fr: 'Ancienne description',
          nl: 'Oude waarde'
        },
        post: {
          fr: 'Commune',
          nl: 'Gemeente'
        },
        postalCode: {
          fr: 'Code postal',
          nl: 'Postcode'
        },
        postBirth: {
          fr: 'Lieu de naissance',
          nl: 'Geboorteplaats'
        },
        proofNumber: {
          fr: 'Numéro de preuve',
          nl: 'Bewijsnummer'
        },
        province: {
          fr: 'Province',
          nl: 'Provincie'
        },
        read: {
          fr: 'Lire',
          nl: 'Lezen'
        },
        relation: {
          fr: 'Relation',
          nl: 'Relatie'
        },
        remark: {
          fr: 'Remarque',
          nl: 'Opmerking'
        },
        requestType: {
          fr: 'Type',
          nl: 'Type'
        },
        requestStatus: {
          fr: 'Statut',
          nl: 'Status'
        },
        saldo: {
          fr: 'Solde',
          nl: 'Saldo'
        },
        saldoNew: {
          fr: 'Nouveau solde',
          nl: 'Nieuw saldo'
        },
        saldoOld: {
          fr: 'Solde ancien',
          nl: 'Oud saldo'
        },
        score: {
          fr: 'Score',
          nl: 'Score'
        },
        status: {
          fr: 'Statut',
          nl: 'Status'
        },
        street: {
          fr: 'Rue',
          nl: 'Straat'
        },
        subject: {
          fr: 'Sujet',
          nl: 'Onderwerp'
        },
        telephone: {
          fr: 'Téléphone',
          nl: 'Telefoon'
        },
        telephoneLandline: {
          fr: 'Téléphone maison',
          nl: 'Telefoon vast'
        },
        telephoneMobile: {
          fr: 'Téléphone mobile',
          nl: 'Telefoon mobiel'
        },
        to: {
          fr: 'À',
          nl: 'Aan'
        },
        transactionNumber: {
          fr: 'Numéro de transaction',
          nl: 'transactie'
        },
        type: {
          fr: 'Type',
          nl: 'Type'
        },
        uniqueCode: {
          fr: 'Code unique',
          nl: 'Unieke code'
        },
        update: {
          fr: 'Modifier',
          nl: 'Aanpassen'
        },
        user: {
          fr: 'Utilisateur',
          nl: 'Gebruiker'
        },
        userName: {
          fr: 'Nom d\'utilisateur',
          nl: 'Gebruikersnaam'
        },
        userType: {
          fr: 'Type d\'utilisateur',
          nl: 'Gebruikerstype'
        }
      }
    }
  }
}
