<template>
  <base-card
    v-if="EntityCardMixin_CopySource?.family?.value"
    no-padding
    :title="EntityCardMixin_Title"
  >
    <base-list-overview
      v-if="EntityCardMixin_CopySource?.children"
      controller="Child"
      custom-create
      custom-update
      :delete-action="false"
      :double-click-edit="false"
      :filter="Filter"
      hide-filter
      hide-pagination
      inside-card
      :readonly="EntityCardMixin_Readonly"
      :selection="EntityCardMixin_CopySource.children.items"
      show-select
      :single-select="false"
      :visible="ShowOverview"
      @custom-create="$emit('create')"
      @custom-update="$emit('update', $event)"
      @selection="SelectionChanged($event)"
    />
  </base-card>
</template>

<script>
import BaseListOverview from '@/components/shared/base/base-list-overview'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardRequestsDetailSubmitRequestInterventionChildren',
  components: {
    BaseListOverview
  },
  mixins: [
    EntityCardMixin
  ],
  data () {
    return {
      copy: null,
      initialized: false,
      showOverview: true
    }
  },
  watch: {
    async Filter () {
      await this.SetCopy()
    }
  },
  computed: {
    Filter () {
      const key = 'idFamily'
      return [
        {
          key: key,
          value: this.EntityCardMixin_CopySource?.family?.value ?? this.constantMixin.guid.empty
        }
      ]
    },
    ShowOverview () {
      return this.visible && this.showOverview
    }
  },
  async mounted () {
    await this.Initialize()
  },
  methods: {
    async Initialize () {
      this.EntityCardMixin_SetDefaultTitle('children')
      await this.SetCopy()
    },
    async SetCopy () {
      this.showOverview = false
      this.copy = this.CommonMixin_Clone(this.value)
      this.showOverview = true
      this.$forceUpdate()
    },
    SelectionChanged (value) {
      this.EntityCardMixin_CopySource.children.items = value
    }
  }
}
</script>
