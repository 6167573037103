<template>
  <menu-item-group
    :item="item"
    sub-group
    text
  />
</template>

<script>
export default {
  name: 'FrameworkSidebarMenuItemSubgroup',
  props: {
    // The item to show
    item: {
      default: () => ({
        avatar: undefined,
        children: [],
        group: undefined,
        title: undefined
      }),
      type: Object
    }
  }
}
</script>
