import ContentMixinServiceBc from '@/mixins/content-mixin.service.bc'
import ContentMixinStore from '@/mixins/content-mixin.store'

export default {
  mixins: [
    ContentMixinServiceBc,
    ContentMixinStore
  ],
  computed: {
    ContentMixin_Element () {
      return this.ContentMixin_Store_DetailSourceCopy ?? this.ContentMixin_Store_DetailSourceElement
    },
    ContentMixin_DirtyCheckElement () {
      return this.ContentMixin_Store_DetailSourceAutosavedVersion ?? this.ContentMixin_Store_DetailSourceElement
    },
    ContentMixin_ElementIsDirty () {
      const dirty = this.ContentMixin_ElementIsDirtyCheck()
      this.$emit('dirty', dirty)
      return dirty
    }
  },
  methods: {
    ContentMixin_ElementHasPocoStatus (status, element = this.ContentMixin_Element) {
      return element?.pocoStatus === this.constantMixin.pocoStatus[status]
    },
    ContentMixin_ElementHasRights (right, element = this.ContentMixin_Element) {
      return element?.rights && element.rights[right]
    },
    ContentMixin_ElementId (element = this.ContentMixin_Element) {
      return element ? (this.contentMixin.key ? element[this.contentMixin.key] : element.id) : null
    },
    ContentMixin_ElementIsDeletable (element = this.ContentMixin_Element) {
      return element && !this.ContentMixin_ElementHasPocoStatus('new', element) && this.ContentMixin_ElementHasRights('allowDelete', element)
    },
    ContentMixin_ElementIsEditable (editable = this.ContentMixin_Store_DetailSourceEditable, element = this.ContentMixin_Element) {
      return element && editable && ((this.ContentMixin_ElementHasPocoStatus('new', element) && this.ContentMixin_ElementHasRights('allowCreate', element)) || (!this.ContentMixin_ElementHasPocoStatus('new', element) && this.ContentMixin_ElementHasRights('allowUpdate', element)))
    },
    ContentMixin_ElementIsDirtyCheck (copy = this.ContentMixin_Store_DetailSourceCopy, original = this.ContentMixin_DirtyCheckElement) {
      return (copy && original && !this.CommonMixin_HardCompare(copy, original)) || this.ContentMixin_ElementHasPocoStatus('new', copy)
    },
    ContentMixin_ElementIsRevertable (editable = this.ContentMixin_Store_DetailSourceEditable, copy = this.ContentMixin_Store_DetailSourceCopy, original = this.ContentMixin_Store_DetailSourceElement) {
      return editable && !this.CommonMixin_HardCompare(copy, original) && !this.ContentMixin_ElementHasPocoStatus('new', copy) && this.ContentMixin_ElementHasRights('allowUpdate', copy)
    },
    ContentMixin_GetBaseListDetailItem (list, id) {
      return list.items.filter(x => x.id === id)[0]
    },
    ContentMixin_HandlePickersChange (element, value) {
      // TODO: HARDCOPY NAKIJKEN
      // this.CommonMixin_HardCopy(element.value, value.value)
      const original = element
      const changed = value

      original.value = changed.value
      original.text = changed.text
    },
    ContentMixin_HandleSelectChange (element, list, value, property = 'value') {
      const newElement = (value ? list.find(listElement => listElement[property] === value) : null) ?? {
        text: '',
        value: null
      }

      // TODO: HARDCOPY NAKIJKEN
      // this.CommonMixin_HardCopy(element, newElement)
      element.value = newElement.value
      element.text = newElement.text
    },
    ContentMixin_HandleSelectWithPocoStatusChange (element, property, value, list = null) {
      if (!element.originalElement) {
        this.ContentMixin_UpdateElementWithPocoStatus(element, property, value)
      }

      list ? this.ContentMixin_HandleSelectChange(element[property], list, value) : this.ContentMixin_HandleSelectCodeChange(element[property], value)

      if (element.originalElement) {
        this.ContentMixin_UpdateElementWithPocoStatus(element, property, value)
      }
    },
    ContentMixin_HandleSelectWithPocoStatusChangeListDetail (source, listName, property, item, value, list = null) {
      this.ContentMixin_HandleSelectWithPocoStatusChange(source[listName].items.filter(x => x.id === item.id)[0], property, value, list)
    },
    ContentMixin_HandleSelectCodeChange (element, value) {
      const newCode = this.ContentMixin_Codes(element).find(listElement => listElement.value === value)
      element.shortDescription = this.CommonMixin_Clone(newCode?.shortDescription ?? '')
      element.text = this.CommonMixin_Clone(newCode?.text ?? '')
      element.value = this.CommonMixin_Clone(newCode?.value)
    },
    ContentMixin_IgnorePocoStatus (element) {
      return this.ContentMixin_ElementHasPocoStatus('new', element) || this.ContentMixin_ElementHasPocoStatus('deleted', element)
    },
    async ContentMixin_Leave (next) {
      if (this.FrameworkMixin_LeaveConfirmed || this.CommonMixin_Confirmation(this.ContentMixin_ElementIsDirty, this.TextMixin_GetMessage('unsavedChanges'), true)) {
        this.FrameworkMixin_LeaveConfirmed = false
        await this.ContentMixin_Release(true)

        if (next) {
          next()
        } else {
          return true
        }
      } else {
        return false
      }
    },
    async ContentMixin_Release (allSubcomponents = false) {
      // TODO
    },
    async ContentMixin_UpdateBaseListDetailElementWithPocoStatus (list, id, property, value) {
      const item = this.ContentMixin_GetBaseListDetailItem(list, id)
      await this.ContentMixin_UpdateElementWithPocoStatus(item, property, value)
    },
    async ContentMixin_UpdateElementWithPocoStatus (elementWithPocoStatus, property, value, listItemIndex = null) {
      if (await this.FrameworkMixin_UpdateElementWithPocoStatus(elementWithPocoStatus, property, value, listItemIndex)) {
        // Because otherwise ContentMixin_ElementIsDirty won't update if necessary
        if (!this.ContentMixin_ElementIsDirtyCheck()) {
          this.ContentMixin_Store_DetailSourceCopy = this.CommonMixin_Clone(this.ContentMixin_DirtyCheckElement)
        }
      }
    }
  }
}
