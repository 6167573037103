import ContentMixinData from '@/mixins/content-mixin.data'
import ContentStore from '@/stores/content-store'

export default {
  mixins: [
    ContentMixinData
  ],
  watch: {
    ContentMixin_Store_DetailSourceElement (value) {
      this.ContentMixin_Store_DetailSourceAutosavedVersion = null
      this.ContentMixin_Store_DetailSourceCopy = this.CommonMixin_Clone(value)
    }
  },
  computed: {
    ContentMixin_Store_Detail () {
      return ContentStore().detail
    },
    ContentMixin_Store_DetailSource () {
      return this.ContentMixin_Store_Detail[this.source]
    },
    ContentMixin_Store_DetailSourceAutosavedVersion: {
      get () {
        return this.ContentMixin_Store_DetailSource.autosavedVersion
      },
      set (value) {
        this.ContentMixin_Store_DetailSource.autosavedVersion = value
      }
    },
    ContentMixin_Store_DetailSourceCopy: {
      get () {
        return this.ContentMixin_Store_DetailSource.copy
      },
      set (value) {
        this.ContentMixin_Store_DetailSource.copy = value
      }
    },
    ContentMixin_Store_DetailSourceEditable: {
      get () {
        return this.ContentMixin_Store_DetailSource.editable
      },
      set (value) {
        this.ContentMixin_Store_DetailSource.editable = value
      }
    },
    ContentMixin_Store_DetailSourceElement: {
      get () {
        return this.ContentMixin_Store_DetailSource.element
      },
      set (value) {
        this.ContentMixin_Store_DetailSource.element = value
      }
    },
    ContentMixin_Store_DetailSourceParameters: {
      get () {
        return this.ContentMixin_Store_DetailSource.parameters
      },
      set (value) {
        this.ContentMixin_Store_DetailSource.parameters = value
      }
    },
    ContentMixin_Store_SchedulerDate: {
      get () {
        return ContentStore().schedulerDate
      },
      set (value) {
        ContentStore().schedulerDate = value
      }
    },
    ContentMixin_Store_SchedulerView: {
      get () {
        return ContentStore().schedulerView
      },
      set (value) {
        ContentStore().schedulerView = value
      }
    },
    ContentMixin_Store_SelectedStep: {
      get () {
        return ContentStore().selectedStep
      },
      set (value) {
        ContentStore().selectedStep = value
      }
    },
    ContentMixin_Store_SelectedTab: {
      get () {
        return ContentStore().selectedTab
      },
      set (value) {
        ContentStore().selectedTab = value
      }
    }
  }
}
