<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.child"
        v-model="EntityCardMixin_CopySource.child"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        :disabled="disabled"
        :items="children"
        :label="TextMixin_GetLabel('child')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.typeBankstatement"
        v-model="EntityCardMixin_CopySource.typeBankstatement"
        base-field-container-type="v-col"
        base-field-type="autocomplete"
        code
        :disabled="disabled"
        :items="contentMixin.codes"
        :label="TextMixin_GetLabel('type')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.blob"
        v-model="EntityCardMixin_CopySource.blob"
        base-field-container-type="v-col"
        base-field-type="fileInput"
        :disabled="disabled"
        :label="TextMixin_GetLabel('file')"
        :multiple="false"
        :readonly="EntityCardMixin_Readonly"
        @download="Download()"
      />
    </v-row>
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.remark"
        v-model="EntityCardMixin_CopySource.remark"
        base-field-container-type="v-col"
        base-field-type="textarea"
        :disabled="disabled"
        :label="TextMixin_GetLabel('remark')"
        lg="6"
        :readonly="EntityCardMixin_Readonly"
      />
    </v-row>
  </base-card>
</template>

<script>
import BlobMixin from '@/mixins/blob-mixin'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardBankStatementsDetailExtraInformation',
  mixins: [
    BlobMixin,
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    async Download () {
      this.BlobMixin_DownloadById(this.EntityCardMixin_CopySource.blob.value.idBlob, this.EntityCardMixin_CopySource.blob.value.fileName)
    },
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('extraInformation')
    }
  }
}
</script>
