<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    :title="EntityCardMixin_Title"
  >
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.dateStart"
        v-model="EntityCardMixin_CopySource.dateStart"
        base-field-container-type="v-col"
        base-field-type="date"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('dateStart')"
        :readonly="EntityCardMixin_Readonly"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.subCarePartner"
        v-model="EntityCardMixin_CopySource.subCarePartner"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('subCarePartner')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.subCarePartner.value, 255)]"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.alliance"
        v-model="EntityCardMixin_CopySource.alliance"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('alliance')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersMax(EntityCardMixin_CopySource.alliance.value, 255)]"
      />
    </v-row>
    <v-row>
      <base-field
        v-if="EntityCardMixin_CopySource.bankAccountNumber"
        v-model="EntityCardMixin_CopySource.bankAccountNumber"
        base-field-container-type="v-col"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('bankAccountNumber')"
        :readonly="EntityCardMixin_Readonly"
        :rules="[validationMixin.rules.charactersExact(EntityCardMixin_CopySource.bankAccountNumber.value, 19)]"
        mask="AA## #### #### ####"
      />
      <base-field
        v-if="EntityCardMixin_CopySource.isPelicanoBelfiusWebAccount"
        v-model="EntityCardMixin_CopySource.isPelicanoBelfiusWebAccount"
        base-field-container-type="v-col"
        base-field-type="checkbox"
        :disabled="EntityCardMixin_Disabled"
        :label="TextMixin_GetLabel('pelicanoBelfiusWebAccount')"
        :readonly="EntityCardMixin_Readonly"
      />
    </v-row>
  </base-card>
</template>

<script>
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardCarePartnersDetailExtraInformation',
  mixins: [
    EntityCardMixin
  ],
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('extraInformation')
    }
  }
}
</script>
