<template>
  <user-management-login-history-overview />
</template>

<script>
import UserManagementLoginHistoryOverview from '@/views/usermanagement/loginhistory/components/user-management-login-history-overview'
import ContentMixin from '@/mixins/content-mixin'

export default {
  name: 'UserManagementLoginHistoryView',
  components: {
    UserManagementLoginHistoryOverview
  },
  mixins: [
    ContentMixin
  ],
  beforeRouteLeave (to, from, next) {
    this.ContentMixin_Leave(next)
  }
}
</script>
