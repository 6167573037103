import _ from 'lodash'

export default {
  methods: {
    CommonMixin_ArrayRemoveElement (array, element) {
      const index = array.indexOf(array.find(x => this.CommonMixin_HardCompare(x, element)))

      if (index !== -1) {
        array.splice(index, 1)
      }

      return array
    },
    CommonMixin_Clone (source) {
      const clone = _.clone(source)

      if (source != null) {
        if (Array.isArray(source)) {
          clone.length = 0
          source.forEach(element => {
            clone.push(this.CommonMixin_Clone(element))
          })
        } else if (typeof (source) === 'object') {
          Object.keys(source).forEach(key => {
            clone[key] = this.CommonMixin_Clone(source[key])
          })
        }
      }

      return clone
    },
    CommonMixin_Confirmation (condition, text, defaultValue) {
      let confirmation = defaultValue

      if (condition) {
        confirmation = window.confirm(text)
      }

      return confirmation
    },
    CommonMixin_DeepCopy (object) {
      return JSON.parse(JSON.stringify(object))
    },
    CommonMixin_GetDate (format = this.formatMixin.date.database) {
      return this.FormatMixin_FormatDateTime(new Date().toUTCString(), null, format)
    },
    CommonMixin_GetDateWithDaysAdded (date, days, format = this.formatMixin.date.database) {
      const newDate = new Date(date)
      newDate.setDate(newDate.getDate() + days)
      return this.FormatMixin_FormatDateTime(newDate.toUTCString(), null, format)
    },
    CommonMixin_GetElementByPropertyValue (list, property, value) {
      if (list == null) {
        return {}
      } else {
        return list.find(element => element[property] === value) ?? {}
      }
    },
    CommonMixin_GetElementsByPropertyValue (list, property, value) {
      if (list == null) {
        return []
      } else {
        return list.filter(element => element[property] === value) ?? []
      }
    },
    CommonMixin_GetPageCount (list, numberPerPage) {
      const minimum = Math.floor(list.length / numberPerPage)
      const extra = list.length % numberPerPage > 0
      const total = minimum + (extra ? 1 : 0)

      return total > 0 ? total : 1
    },
    CommonMixin_GetPageList (list, page, numberPerPage) {
      const end = page * numberPerPage > list.length ? list.length : page * numberPerPage
      return list.slice((page - 1) * numberPerPage, end)
    },
    CommonMixin_HardCompare (source, target) {
      return _.isEqual(source, target)
    },
    CommonMixin_HardCopy (object, source, path = 'element') {
      if (source != null) {
        if (Array.isArray(source)) {
          object ??= []
          object.length = 0
          source.forEach(element => {
            object.push(this.CommonMixin_Clone(element))
          })
        } else if (typeof (source) === 'object') {
          object ??= {}
          Object.keys(source).forEach(key => {
            if (Array.isArray(source[key])) {
              object[key] ??= []
              this.CommonMixin_HardCopy(object[key], source[key], `${path}.${key}`)
            } else if (typeof (source[key]) === 'object') {
              object[key] ??= source[key] ? {} : null
              this.CommonMixin_HardCopy(object[key], source[key], `${path}.${key}`)
            } else {
              object[key] = this.CommonMixin_Clone(source[key])
            }
          })
        } else {
          object = this.CommonMixin_Clone(source)
        }
      }

      return object
    },
    CommonMixin_SelectedStyle (selected) {
      const backgroundColor = this.FrameworkMixin_Theme.primary ? this.FrameworkMixin_Theme.primary : 'gray'

      if (selected) {
        return {
          'background-color': backgroundColor,
          color: 'white'
        }
      }
    },
    CommonMixin_SetVCardHeights (elementId1, elementId2) {
      const element1 = document.getElementById(elementId1)
      const element2 = document.getElementById(elementId2)

      if (element1 && element2) {
        const rectangle1 = element1.getBoundingClientRect()
        const rectangle2 = element2.getBoundingClientRect()

        if (rectangle1.left !== rectangle2.left) {
          if (rectangle1.bottom > rectangle2.bottom) {
            document.getElementById(elementId2).style.height = `${rectangle1.bottom - rectangle2.top}px`
          } else if (rectangle2.bottom > rectangle1.bottom) {
            document.getElementById(elementId1).style.height = `${rectangle2.bottom - rectangle1.top}px`
          }
        }
      }
    },
    CommonMixin_SuccessOrFailMessage (success, successMessage, failMessage) {
      if (success) {
        if (successMessage) {
          this.FrameworkMixin_Snackbar(successMessage, 'success')
        }
      } else {
        if (failMessage) {
          this.FrameworkMixin_Snackbar(failMessage, 'error')
        }
      }
    }
  }
}
