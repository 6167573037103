<template>
  <base-card
    v-if="EntityCardMixin_CopySource"
    list-detail
    :title="EntityCardMixin_Title"
  >
    <base-list-detail
      v-if="EntityCardMixin_CopySource.situations"
      v-model="EntityCardMixin_CopySource.situations"
      class="table-border-bottom"
      controller="Family/Detail"
      hide-filter
      :readonly="EntityCardMixin_Readonly"
      type="situations"
      :visible="visible"
    >
      <template
        #[`edit-info`]="item"
      >
        <base-field
          v-if="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.situations, item.id).info"
          :value="ContentMixin_GetBaseListDetailItem(EntityCardMixin_CopySource.situations, item.id).info"
          dense
          :disabled="EntityCardMixin_Disabled"
          ignore-default-event-handler
          :readonly="EntityCardMixin_Readonly"
          @keyup="ContentMixin_UpdateBaseListDetailElementWithPocoStatus(EntityCardMixin_CopySource.situations, item.id, 'info', $event.value)"
        />
      </template>
      <template
        #[`edit-score`]="item"
      >
        <base-field
          v-if="item.score"
          v-model="item.score"
          base-field-type="autocomplete"
          :defaultEventHandler="false"
          :disabled="EntityCardMixin_Disabled"
          :items="ScoreItems"
          :readonly="EntityCardMixin_Readonly"
          reverse
          @change="ContentMixin_HandleSelectWithPocoStatusChangeListDetail(EntityCardMixin_CopySource, 'situations', 'score', item, $event)"
        />
      </template>
      <template
        #[`read-score`]="item"
      >
        <template
          v-if="!item.value || (item.value.toString() === '-1')"
        >
          {{TextMixin_GetLabel('noInfo')}}
        </template>
        <template
          v-else
        >
          {{item.value}}
        </template>
      </template>
    </base-list-detail>
    <base-field
      v-if="EntityCardMixin_CopySource.familySituation"
      v-model="EntityCardMixin_CopySource.familySituation"
      base-field-container-type="v-col"
      base-field-type="textarea"
      class="pt-3"
      :disabled="EntityCardMixin_Disabled"
      :label="TextMixin_GetLabel('unmetNeeds')"
      lg="12"
      md="12"
      :readonly="EntityCardMixin_Readonly"
    />
    <base-field
      v-if="EntityCardMixin_CopySource.estimatedAnnualAmount"
      v-model="EntityCardMixin_CopySource.estimatedAnnualAmount"
      base-field-container-type="v-col"
      base-field-type="textarea"
      class="pt-3"
      :disabled="EntityCardMixin_Disabled"
      :label="TextMixin_GetLabel('estimatedAnnualAmount')"
      lg="12"
      md="12"
      :readonly="EntityCardMixin_Readonly"
    />
    <base-field
      v-if="EntityCardMixin_CopySource.coverageMissingNeeds"
      v-model="EntityCardMixin_CopySource.coverageMissingNeeds"
      base-field-container-type="v-col"
      base-field-type="textarea"
      class="pt-3"
      :disabled="EntityCardMixin_Disabled"
      :label="TextMixin_GetLabel('coverageMissingNeeds')"
      lg="12"
      md="12"
      :readonly="EntityCardMixin_Readonly"
    />
  </base-card>
</template>

<script>
import BaseListDetail from '@/components/shared/base/base-list-detail'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardFamiliesDetailSituation',
  mixins: [
    EntityCardMixin
  ],
  components: {
    BaseListDetail
  },
  computed: {
    ScoreItems () {
      const items = [
        {
          text: this.TextMixin_GetLabel('noInfo'),
          value: -1
        }
      ]

      for (let index = 0; index <= 5; index++) {
        items.push(index)
      }

      return items
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    Initialize () {
      this.EntityCardMixin_SetDefaultTitle('situation')
    }
  }
}
</script>
