<template>
  <base-card
    card-class="pt-0"
    card-class-override
    no-padding
    :title="EntityCardMixin_Title"
  >
    <scheduler
      :data-items="dataItems"
      :date="ContentMixin_Store_SchedulerDate"
      item="itemRender"
      style="height: 100%"
      :view="ContentMixin_Store_SchedulerView"
      :views="Views"
      @datechange="SetDate($event)"
      @viewchange="SetView($event)"
    >
      <template
        #itemRender="{ props }"
      >
        <scheduler-item
          v-bind="props"
          :style="`background-color: ${props.dataItem.colorUser}`"
          @doubleclick="$emit('update', props.dataItem.id)"
        >
          <div
            class="k-event-template"
          >
            {{ props.title }}
          </div>
        </scheduler-item>
      </template>
    </scheduler>
    <v-btn
      v-if="!readonly"
      class="ma-2"
      color="secondary"
      outlined
      @click="$emit('create')"
    >
      <v-icon
        class="mr-1"
      >
        mdi-plus
      </v-icon>
      {{ TextMixin_GetLabel('add', TextMixin_GetParameter('activity')) }}
    </v-btn>
  </base-card>
</template>

<script>
import { Scheduler, SchedulerItem } from '@progress/kendo-vue-scheduler'
import EntityCardMixin from '@/mixins/entity-card-mixin'

export default {
  name: 'EntityCardCalendarOverview',
  components: {
    Scheduler,
    SchedulerItem
  },
  mixins: [
    EntityCardMixin
  ],
  props: {
    dataItems: Array
  },
  data () {
    return {
      initialized: false,
      showOverview: true
    }
  },
  computed: {
    ShowOverview () {
      return this.visible && this.showOverview
    },
    Views () {
      return [
        {
          name: 'day',
          title: 'Day'
        },
        {
          name: 'week',
          title: 'Week'
        },
        {
          name: 'month',
          title: 'Month'
        }
      ]
    }
  },
  methods: {
    SetDate (value) {
      this.ContentMixin_Store_SchedulerDate = value.date
      this.$emit('datechange')
    },
    SetView (value) {
      this.ContentMixin_Store_SchedulerView = value.viewName
    }
  }
}
</script>
