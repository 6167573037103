<template>
  <div
    :class="ContainerClass"
  >
    <base-crud-table
      v-if="BaseListOverviewMixin_Rights.allowRead"
      v-model="baseListMixin.selection"
      :add-action="addAction"
      :delete-action="deleteAction"
      :double-click-edit="doubleClickEdit"
      :edit-action="editAction"
      :headers="BaseListMixin_Headers()"
      :hide-actions="hideActions || (redirect && !linkTable)"
      :items="baseListMixin.list"
      :item-key="contentMixin.key"
      :link-table="linkTable"
      :hide-border="insideCard"
      :hide-filter="hideFilter"
      :hide-footer="hideFooter"
      :hide-pagination="hidePagination"
      :paging="baseListMixin.paging"
      :readonly="readonly"
      :rights="BaseListOverviewMixin_Rights"
      :show-select="showSelect"
      :single-select="singleSelect"
      :text-new-item="BaseListMixin_GetAddLabel(readonly, hideFooter, baseListMixin.rights)"
      @create="BaseListOverviewMixin_Create(customCreate)"
      @delete="BaseListOverviewMixin_Delete($event)"
      @filter="BaseListMixin_Filter($event)"
      @options-change="BaseListOverviewMixin_List($event)"
      @read="BaseListOverviewMixin_Read($event)"
      @refresh="BaseListMixin_Refresh()"
      @select="BaseListOverviewMixin_Select(customUpdate, $event)"
    >
      <template
        #[`customActions`]="item"
      >
        <slot
          v-bind="item"
          name="customActions"
        />
      </template>
      <template
        v-for="slot in OverwriteSlots()"
        #[`${slot}`]="item"
      >
        <slot
          v-bind="item"
          :name="slot"
        />
      </template>
      <template
        slot="footer-prepend-1"
      >
        <slot
          name="footer-prepend-1"
        />
      </template>
      <template
        slot="footer-prepend-2"
      >
        <slot
          name="footer-prepend-2"
        />
      </template>
    </base-crud-table>
    <dialog-link-entities
      v-if="linkTable"
      v-model="baseListOverviewMixin.link.dialog"
      :controller="controller"
      :current-items="baseListMixin.list.map(x => x[contentMixin.key])"
      :type="type"
      @link="BaseListOverviewMixin_Link($event)"
    />
  </div>
</template>

<script>
import BaseListOverviewMixin from '@/mixins/base-list-overview-mixin'

export default {
  name: 'BaseListOverview',
  mixins: [
    BaseListOverviewMixin
  ],
  computed: {
    ContainerClass () {
      return this.containerClass ?? (this.insideCard ? 'pa-0' : 'pa-5 pt-10')
    }
  },
  watch: {
    async visible (value) {
      if (value) {
        await this.Initialize()
      }
    }
  },
  mounted () {
    this.Initialize()
  },
  methods: {
    async Initialize () {
      await this.BaseListOverviewMixin_Initialize(this.controller)
    },
    OverwriteSlots () {
      return Object.keys(this.$scopedSlots)
    }
  }
}
</script>
