<template>
  <v-main>
    <router-view
      ref="routerView"
    />
  </v-main>
</template>

<script>
export default {
  name: 'FrameworkView',
  watch: {
    FrameworkMixin_Language () {
      this.ValidateForms(this.$refs.routerView)
    }
  },
  methods: {
    ValidateForms (component) {
      if (Array.isArray(component.$refs)) {
        component.$refs.forEach(ref => {
          const $ref = component.$refs[ref]
          if ($ref && typeof $ref.validate === 'function') {
            $ref.validate()
          }
        })

        component.$children.forEach(child => {
          this.ValidateForms(child)
        })
      }
    }
  }
}
</script>
